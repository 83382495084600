import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root',
})
export class RecycleService {
    constructor(private http: HttpClient) { }

    async getRecycles(idBrand: any, dateI: any, dateF: any) {
        let data = await this.http
            .get<any>(
                AUTH_API +
                'recycleClothes/recycle_list?id_brand=' + idBrand + '&inicial_date=' +
                dateI +
                '&final_date=' +
                dateF
            )
            .toPromise();
        return data;
    }


    async getRecycleLines(recycle_id: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'recycleClothes/recycle_lines?recycle_id=' + recycle_id)
            .toPromise();
        return data;
    }

    async getRecycleLabel(id_submission: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'recycleClothes/recyclelabel?id_submission=' + id_submission)
            .toPromise();
        return data;
    }

    async getTotalBrandsRecycle() {
        let data = await this.http.get<any>(AUTH_API + 'dashBoard/totalBrandsRecycle').toPromise();
        return data;
    }

    async getTotalMethodsRecycle() {
        let data = await this.http.get<any>(AUTH_API + 'dashBoard/totalMethodsRecycle').toPromise();
        return data;
    }
}
