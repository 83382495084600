<div class="grid">
    <div class="col-12">
        <p-breadcrumb [style]="{background: 'none' , border: 'none'}" [model]="breadcumbItems" (onItemClick)="itemClicked($event)"></p-breadcrumb>
        <div class="card">
            <p-toast key="tst" [baseZIndex]="99999"></p-toast>
            <div *ngIf="loadingCreate" class="p-datatable-loading-overlay p-component-overlay ng-star-inserted"
                style="z-index: 1000; display: flex; justify-content: center; align-items: center;">
                <i class="p-datatable-loading-icon pi pi-spin pi-spinner"></i>
            </div>
            <form [formGroup]="reactiveForm" (ngSubmit)="onSubmit()">
                <div class="p-fluid p-formgrid grid" style="justify-content: flex-end;">
                    <div class="field col-12 md:col-6">
                        <label htmlFor="name">{{ 'suppliers.name' | translate }} <small>*</small></label>
                        <input pInputText type="text" pInputText required formControlName="name" [ngClass]="{'ng-invalid ng-dirty' : submitted && !name}">
                    </div>
                    <div class=" field col-12 md:col-6">
                        <label htmlFor="email">{{ 'suppliers.email' | translate }} <small>*</small></label>
                        <input pInputText type="text" pInputText required formControlName="email" [ngClass]="{'ng-invalid ng-dirty' : submitted && !email}">
                    </div>
                    <div class="field col-12 md:col-4">
                        <label htmlFor="codigo_wms">{{ 'suppliers.wms_code' | translate }} <small>*</small></label>
                        <input pInputText type="text" pInputText required formControlName="codigo_wms" [ngClass]="{'ng-invalid ng-dirty' : submitted && !codigo_wms}">
                    </div>
                    <div class="field col-12 md:col-4" *ngIf="selectedCountry !== 'PT'">
                        <label htmlFor="phone">{{ 'suppliers.phone' | translate }} <small>*</small></label>
                        <input pInputText type="number" pInputText required formControlName="phone" [ngClass]="{'ng-invalid ng-dirty' : submitted && !phone}">
                    </div>
                    <div class="field col-12 md:col-4" *ngIf="selectedCountry === 'PT'">
                        <label htmlFor="phone">{{ 'suppliers.phone' | translate }} <small>*</small></label>
                        <input pInputText type="number" pInputText required formControlName="phone"
                            [pKeyFilter]="ccPhoneRegex" [pValidateOnly]="true" [ngClass]="{'ng-invalid ng-dirty' : submitted && !phone}">
                    </div>
                    <div class="field col-12 md:col-4" *ngIf="selectedCountry !== 'PT'">
                        <label htmlFor="nif">{{ 'suppliers.vat' | translate }} <small>*</small></label>
                        <input pInputText type="number" pInputText required formControlName="nif" [ngClass]="{'ng-invalid ng-dirty' : submitted && !nif}">
                    </div>
                    <div class="field col-12 md:col-4" *ngIf="selectedCountry === 'PT'">
                        <label htmlFor="nif">{{ 'suppliers.vat' | translate }} <small>*</small></label>
                        <input pInputText type="number" pInputText required formControlName="nif"
                            [pKeyFilter]="ccNIFRegex" [pValidateOnly]="true" [ngClass]="{'ng-invalid ng-dirty' : submitted && !nif}">
                    </div>
                    <div class="field col-12 md:col-12">
                        <label htmlFor="address">{{ 'suppliers.address' | translate }} <small>*</small></label>
                        <input pInputText type="text" pInputText required formControlName="address" [ngClass]="{'ng-invalid ng-dirty' : submitted && !address}">
                    </div>
                    <div class="field col-12 md:col-6">
                        <label htmlFor="city">{{ 'suppliers.city' | translate }} <small>*</small></label>
                        <input pInputText type="text" pInputText required formControlName="city" [ngClass]="{'ng-invalid ng-dirty' : submitted && !city}">
                    </div>
                    <div class="field col-12 md:col-3">
                        <label htmlFor="country">{{ 'suppliers.country' | translate }} <small>*</small></label>
                        <p-dropdown appendTo="body" [options]="countries" [(ngModel)]="selectedCountry"
                            formControlName="selectedCountry" placeholder="{{ 'suppliers.select_country' | translate }}"
                            [style]="{'minWidth':'100%'}" optionLabel="country_name" optionValue="country_code"
                            (onChange)="createZipRegex()" [ngClass]="{'ng-invalid ng-dirty' : submitted && !selectedCountry}"></p-dropdown>
                    </div>
                    <div class="field col-12 md:col-3" *ngIf="!selectedCountry">
                        <label htmlFor="zip">{{ 'suppliers.zipcode' | translate }} <small>*</small></label>
                        <input pInputText disabled type="text" pInputText required formControlName="zip" [ngClass]="{'ng-invalid ng-dirty' : submitted && !zip}">
                    </div>
                    <div class="field col-12 md:col-3" *ngIf="selectedCountry">
                        <label htmlFor="zip">{{ 'suppliers.zipcode' | translate }} <small>*</small></label>
                        <input id="zip" type="text" name="zip" formControlName="zip" required pInputText
                            [pKeyFilter]="ccRegex" [pValidateOnly]="true" [(placeholder)]="regex_example" [ngClass]="{'ng-invalid ng-dirty' : submitted && !zip}">
                    </div>
                    <div id="transfer-block-create" class="field col-12 md:col-12" style="display: inline-flex; gap: 0.5rem; align-items: center;">
                        <p style="margin: 0 !important">{{ 'suppliers.transfer_supplier' | translate }}</p>
                        <p-selectButton [options]="stateOptions" formControlName="transfer" optionLabel="label" optionValue="value"></p-selectButton>
                    </div>
                    <div class="field col-12 md:col-2" *ngIf="!isError">
                        <button id="btn-create" type="reset" value="Reset" pButton pRipple label="{{ 'general.create' | translate }}" icon="pi pi-plus" (click)="onSubmit()" style="justify-content: center;"></button>
                    </div>
                    <div class="field col-12 md:col-2" *ngIf="isError">
                        <button id="btn-create" type="button" value="Button" pButton pRipple label="{{ 'general.create' | translate }}" icon="pi pi-plus" (click)="onSubmit()" style="justify-content: center;"></button>
                    </div>
                </div>
                <small>* {{ 'suppliers.required_fields' | translate }}</small>
            </form>
        </div>
    </div>
</div>