import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { AppMainComponent } from 'src/app/app.main.component';
import { BrandService } from 'src/app/services/brands/brand.service';
import { AbandonedShoppingBagsService } from 'src/app/services/abandoned_shopping_bags/AbandonedShoppingBags.service';

@Component({
    selector: 'pages-abandoned_shopping_bags',
    templateUrl: './abandoned_shopping_bags.component.html',
    styleUrls: ['abandoned_shopping_bags.component.scss'],
})
export class AbandonedShoppingBagsComponent implements OnInit {

    pipe = new DatePipe('en-US');

    rangeDates: Date[] = [
        new Date(new Date().setDate(new Date().getDate() - 31)),
        new Date(),
    ];

    dateIAux: string;

    dateFAux: string;

    allAShoppingBags: any = [];

    aShoppingBagsAux: any;

    aShoppingBags: any;

    aShoppingBag: any;

    loading: boolean = true;

    loadingSidebar: boolean = false;

    ordersStatus: any;

    statusOptions: any = [];

    idBrand: any;

    statusReturn: any;

    selectedAShoppingBags: any[] = [];

    selectAShoppingBags: any;

    visibleSidebar: boolean = false;

    cols: any = [];

    colsOptional: any = [];

    selectedColumns: any = [];

    _selectedColumnsAux: any = [];

    editTrackingDialog: boolean = false;

    show_IBAN: boolean = false;

    subtotal: string = '0';

    processing_fee: number = 0;

    refund_total: string = '0';

    line_images: any = [];

    order_items: any = [];

    displayLabel = false;

    labelUpload = []

    menuShow: boolean = false;

    items = [
        { label: 'Update', icon: 'pi pi-refresh' },
        { label: 'Delete', icon: 'pi pi-times' },
        { label: 'Angular.io', icon: 'pi pi-info' },
        { separator: true },
        { label: 'Setup', icon: 'pi pi-cog' }
    ];


    @ViewChild('dtreturns') table: Table;

    @ViewChild('filter') filter: ElementRef;


    constructor(
        private app: AppMainComponent,
        private readonly translateService: TranslateService,
        private messageService: MessageService,
        private abandonedShoppingBagsService: AbandonedShoppingBagsService,
        private brandService: BrandService,
    ) { }

    copiedToclipboard() {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Copied to clipboard.' });
    }

    async init2HandOrders() {

        this.loading = true
        this.cols = [
            { field: 'idInterno', header: 'Subm. ID', sort: true, filter: false }
        ]

        this.colsOptional = [
            { field: 'customer', header: 'Customer', sort: true, filter: false },
            { field: 'items', header: 'Items', sort: true, filter: false },
            { field: 'refund_total', header: 'Total', sort: true, filter: false },
            { field: 'dataCriacao', header: 'Date', sort: true, filter: false },
        ];
        this.selectedColumns = this.cols.concat(this.colsOptional)
        this._selectedColumnsAux = this.cols.concat(this.colsOptional)

        this.idBrand = this.brandService.getBrand();


        const dateI = this.pipe.transform(this.rangeDates[0], 'yyyy-MM-dd');
        let dateF;
        if (this.rangeDates[1] == null) {
            dateF = dateI;
        } else {
            dateF = this.pipe.transform(this.rangeDates[1], 'yyyy-MM-dd');
        }

        //if (dateI != this.dateIAux || dateF != this.dateFAux || refresh) {
        await this.abandonedShoppingBagsService
            .getAbandonedBags(this.idBrand, dateI, dateF)
            .then((aShoppingBags) => {
                this.aShoppingBagsAux = [];
                aShoppingBags.map((ret) => {

                    const orderReturn: any = {
                        idInterno: ret.shopping_bag_id,
                        items: ret.count,
                        customer: ret.email,
                        country: ret.country_code,
                        refund_total: Number(ret.price),
                        currency: ret.code_currency,
                        //name: ret.first_name + ' ' + ret.last_name,
                        //dataCriacao: this.app.getDateOnFormatString(ret.created_at)
                        dataCriacao: ret.created_at
                    };
                    this.aShoppingBagsAux.push(orderReturn);
                });
                this.aShoppingBags = this.aShoppingBagsAux;
            });
        //}
        this.dateIAux = dateI;
        this.dateFAux = dateF;
        this.loading = false;

        const orderStatusFields =
            document.getElementsByClassName('order-status');
        const arr = Array.from(orderStatusFields);
        arr.map((orderStatusField) => {
            orderStatusField.removeAttribute('disabled');
        });

    }

    async ngOnInit() {
        await this.init2HandOrders();
    }

    filterTable(e) {
        var orderStatus = this.statusReturn[e['element'].index];
        var returnsFilters = this.allAShoppingBags.filter((e) => e.orderStatus == orderStatus);
        function arrayEquals(a, b) {
            return Array.isArray(a) &&
                Array.isArray(b) &&
                a.length === b.length &&
                a.every((val, index) => val === b[index]);
        }

        var filterEquals = arrayEquals(this.aShoppingBags, returnsFilters);
        if (filterEquals) {
            this.aShoppingBags = this.aShoppingBagsAux
        } else {
            this.aShoppingBags = returnsFilters
        }
    }

    async openMarketPlace() {
        const link_2hand = window.sessionStorage.getItem("2hand_link");
        window.open(link_2hand, '_blank').focus();
    }

    async refreshReturns() {
        await this.ngOnInit();
    }

    clear(table: Table) {
        table.clear();
        this.filter.nativeElement.value = '';
    }


    openOverlay(event, op) {
        event.stopPropagation();
        op.toggle(event)
    }

    showOverlay() {
        this.app.overlayActive = true
    }

    hideOverlay() {
        this.app.overlayActive = false
    }

    @Input() get selectedColumnsAux(): any[] {
        return this._selectedColumnsAux;
    }

    set selectedColumnsAux(val: any[]) {
        //restore original order
        this._selectedColumnsAux = this.colsOptional.filter(col => val.includes(col));
    }

    customize(event, op) {
        this.selectedColumns = this.cols.concat(this._selectedColumnsAux)
        this.app.overlayActive = false
        op.hide(event)
    }

    revert(event, op) {
        this.selectedColumns = this.cols.concat(this.colsOptional)
        this._selectedColumnsAux = this.cols.concat(this.colsOptional)
        this.app.overlayActive = false
        op.hide(event)
    }

    async openSideBar(selectAShoppingBags) {
        this.menuShow = false;
        this.aShoppingBag = null;
        this.visibleSidebar = true;
        this.selectAShoppingBags = selectAShoppingBags;
        document.getElementsByTagName('body')[0].classList.add('fixed');
        await this.returnLines(selectAShoppingBags.idInterno);
    }

    hideSideBar() {
        this.visibleSidebar = false;
        document.getElementsByTagName('body')[0].classList.remove('fixed');
    }

    openMenu(event) {
        event.stopPropagation()
        this.menuShow = !this.menuShow
    }

    async returnLines(id_return: any) {
        await this.abandonedShoppingBagsService.getAbandonedBagsLines(id_return).then(
            async (returnline) => {
                this.aShoppingBag = {};
                this.aShoppingBag = returnline.data[0];
                this.aShoppingBag.created_at = this.app.getDateOnFormatString(this.aShoppingBag.created_at);

            }
        ).catch((error) => {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: error.error,
            });
        });
    }


    dateTimeLine(event: any): string {
        return this.app.getDateOnFormatString(event.change_timestamp);
    }

    onUpload(event) {
        document.getElementsByClassName('p-fileupload')[0]["style"].border = 'none';
        if (document.getElementsByClassName('p-fileupload-files').length > 0) {
            document.getElementsByClassName('p-fileupload-files')[0]["style"].display = 'block';
        }
        for (let file of event.files) {
            this.labelUpload.push(file);
        }
        this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
    }

    openDialogLabel() {
        this.displayLabel = true;
        setTimeout(() => {
            if (document.getElementsByClassName('p-fileupload-files').length > 0) {
                document.getElementsByClassName('p-fileupload-files')[0]["style"].display = 'none';
            }
        }, 100);
    }

    onRemoveFile() {
        this.labelUpload.pop();
        document.getElementsByClassName('p-fileupload') ? document.getElementsByClassName('p-fileupload')[0]["style"].border = 'none' : null;
    }




    checkboxClick(e) {
        e.stopPropagation()
        var x = document.getElementsByClassName('p-checkbox-box')
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedAShoppingBags.length > 0) {
            x[0].classList.add('p-highlight')
            y[0].classList.add('pi')
            y[0].classList.add('pi-minus')
        } else {
            x[0].classList.remove('p-highlight')
            y[0].classList.remove('pi')
            y[0].classList.remove('pi-minus')
        }
    }

    mainCheckboxClick() {
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedAShoppingBags.length == this.aShoppingBagsAux.length) {
            y[0].classList.remove('pi-minus')
        }
    }

    async exportCSV() {
        var selectionAux = this.table._selection
        if (this.table._selection.length == 0) {
            if (this.table.filteredValue) {
                this.table._selection = this.table.filteredValue
            } else {
                this.table._selection = this.table._value
            }
        }
        await this.table.exportCSV({ selectionOnly: true })
        this.table._selection = selectionAux
    }

    set_clipboard() {
        navigator.clipboard.writeText(this.aShoppingBag.mb_return_payment);
        this.messageService.add({ key: 'tst', severity: 'info', summary: "Info", detail: "Copied to CLipboard!" });
    }
}

