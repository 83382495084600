import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { BrandService } from '../../../services/brands/brand.service';
import { DatePipe } from '@angular/common';
import { PackingListService } from 'src/app/services/packing_list/packing_list.service';
import { SupplierService } from 'src/app/services/supplier/supplier.service';
import { PoInsert } from 'src/app/api/po';
import { Supplier } from 'src/app/api/supplier';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'pages-insert_reception',
    templateUrl: './insert_reception.component.html',
    styleUrls: ['./insert_reception.component.sass']
})
export class InsertReceptionComponent implements OnInit {


    pipe = new DatePipe('en-US');

    tableVisible: boolean;

    todayDate: string;

    packingListNumber: number;

    suppliers: Supplier[];

    suppliersAux: Supplier[];

    selectedSupplier: any;

    id_brand: any;

    purchaseOrders: any = [];

    purchaseOrder: any;

    pos: any;

    arrayBuffer: any;

    fileAux: File;

    file: File;

    filebase64: any;

    loading: boolean;

    loadingUpload: boolean;

    deleteProductDialog: boolean;

    purchaseOrderDialog: boolean;

    submitted: boolean;

    isload: boolean;

    col_validation = [];

    idFile: any;

    clonedProducts: { [s: string]: PoInsert; } = {};

    transfer: boolean = false;

    stateOptions = [];

    workbook: any;

    id = 0;

    errorOrWarnField: boolean;

    fieldPoNumberError: boolean;

    fieldSKUError: boolean;

    fieldEANError: boolean;

    filteredPoNumber: any;

    filteredSKU: any;

    filteredEAN: any;

    products: any;

    deletedRows: number = 0;

    breadcumbItems: MenuItem[] = [];

    @ViewChild('dt') table: Table;

    @ViewChild('filter') filter: ElementRef;

    constructor(
        private brandservice: BrandService,
        private supplierService: SupplierService,
        private packingListService: PackingListService,
        private messageService: MessageService,
        private router: Router,
        private readonly translateService: TranslateService
    ) { }

    async ngOnInit() {

        this.col_validation = ['PO Number ', 'SO Number ', 'Customer Order/Ref. No.', 'SKU-No.', "Product Name", "Collection Name", "Color", "Size", "EAN/Barcode", "Quantity", "Box No", "Box Weight", "Box Dimension", "Pallet number"];

        await this.translateService.get('dummyTranslation').toPromise().then();

        this.translateService.stream('general.menu').subscribe(val => {
            this.breadcumbItems = [
                { label: '', icon: 'pi pi-home' },
                { label: val.products_reception.value },
                { label: val.products_reception.items.add_reception }
            ]
        });

        this.translateService.stream('primeng').subscribe(val => {
            this.stateOptions = [
                { label: val.accept, value: true },
                { label: val.reject, value: false }
            ]
        });
        this.supplierService.getAllSuppliers()
            .then((data) => {
                this.suppliers = data.filter(e => e.transfer == 0);
                this.suppliers = this.suppliers.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
                if (this.suppliers.length == 0) {
                    this.selectedSupplier = null;
                    this.idFile = null;
                } else {
                    this.selectedSupplier = this.suppliers[0];
                    this.idFile = this.selectedSupplier.name + "_" + this.packingListNumber + "_" + this.todayDate;
                }
                this.suppliersAux = data;
            });

        let packingList = await this.packingListService.getPackingListNumbers();
        this.packingListNumber = packingList.IDPackingList + 1;
        this.todayDate = this.pipe.transform(new Date(), 'yyyyMMdd');

        this.id_brand = this.brandservice.getBrand();
    }

    itemClicked(event) {
        if (event.item.label === '') {
            this.router.navigate(['main']);
        }
    }

    async onBeforeUpload(event) {
        this.loading = true;
        this.deletedRows = 0;
        window.stop();

        this.isload = true;
        this.fileAux = event.files[0];
        this.file = event.files[0];

        let fileReader64 = new FileReader();
        fileReader64.readAsBinaryString(this.file);
        fileReader64.onload = async () => {
            let binaryData: any = fileReader64.result;
            this.filebase64 = window.btoa(binaryData);
        };

        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(this.file);
        fileReader.onload = async (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary" });
            this.workbook = workbook;
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });

            if (JSON.stringify(this.col_validation) != JSON.stringify(Object.values(arraylist[6]))) {
                this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("reception.format_error") });
                let link = document.createElement('a');
                link.setAttribute('type', 'hidden');
                link.href = '../../assets/packingListFormat.xlsx';
                link.download = "packingList Format.xlsx";
                document.body.appendChild(link);
                link.click();
                link.remove();
                this.onRemoveFile();
                return;
            }

            let purchaseOrdersAux = []
            let count = 0;
            let id_po = null, SKU, ProductName, EAN, Color, Size, Quantity;
            let poValue, ids = [], hasTittle = true;

            for (let purchaseOrderExcel of arraylist) {
                poValue = Object.entries(purchaseOrderExcel)
                if (count == 0) {
                    if (poValue[0][0].includes("__EMPTY")) {
                        hasTittle = false;
                    }
                }

                if (count >= 8 && count < arraylist.length - 1) {
                    if (hasTittle) {
                        if (!poValue[0][0].includes("__EMPTY")) {
                            id_po = poValue.filter(e => !e[0].includes("__EMPTY"))[0][1];
                            SKU = this.checkValue(poValue, "__EMPTY_2");
                            ProductName = this.checkValue(poValue, "__EMPTY_3");
                            Color = this.checkValue(poValue, "__EMPTY_5");
                            Size = this.checkValue(poValue, "__EMPTY_6");
                            EAN = this.checkValue(poValue, "__EMPTY_7");
                            Quantity = this.checkValue(poValue, "__EMPTY_8");
                        } else {
                            SKU = this.checkValue(poValue, "__EMPTY_2");
                            Size = this.checkValue(poValue, "__EMPTY_6");
                            EAN = this.checkValue(poValue, "__EMPTY_7");
                            Quantity = this.checkValue(poValue, "__EMPTY_8");
                        }
                    } else {
                        if (poValue[0][0] == "__EMPTY") {
                            id_po = this.checkValue(poValue, "__EMPTY");
                            SKU = this.checkValue(poValue, "__EMPTY_3");
                            ProductName = this.checkValue(poValue, "__EMPTY_4");
                            Color = this.checkValue(poValue, "__EMPTY_6");
                            Size = this.checkValue(poValue, "__EMPTY_7");
                            EAN = this.checkValue(poValue, "__EMPTY_8");
                            Quantity = this.checkValue(poValue, "__EMPTY_9");
                        } else {
                            SKU = this.checkValue(poValue, "__EMPTY_3");
                            Size = this.checkValue(poValue, "__EMPTY_7");
                            EAN = this.checkValue(poValue, "__EMPTY_8");
                            Quantity = this.checkValue(poValue, "__EMPTY_9");
                        }
                    }

                    if (!ids.find(e => e.id_po == id_po)) {
                        ids.push({ id_po, idsSKU: [{}] })
                    }

                    if (Quantity > 0) {
                        if (ids.find(e => e.id_po == id_po).idsSKU.find(e => e.SKU == SKU && e.EAN == EAN) != null) {
                            let index = purchaseOrdersAux.findIndex(e => e.SKU == SKU && e.EAN == EAN)
                            purchaseOrdersAux[index].Quantity = purchaseOrdersAux[index].Quantity + Quantity;
                        } else {
                            let order: PoInsert = {
                                id_po: id_po,
                                id_line: this.id,
                                SKU: SKU,
                                ProductName: null,
                                EAN: EAN,
                                Color: null,
                                Size: null,
                                Quantity: Quantity,
                                SKU_check: true,
                                EAN_check: true,
                                SKU_EAN_check: true
                            }
                            ids.find(e => e.id_po == id_po).idsSKU.push({ SKU, EAN });
                            purchaseOrdersAux.push(order)
                        }
                    } else {
                        this.deletedRows++
                    }
                }
                count++;
                this.id++;
            }
            this.id = this.id - 1;
            this.purchaseOrders = await this.packingListService.postPOsValidateExcelLines(purchaseOrdersAux);
            this.purchaseOrders = this.purchaseOrders.sort((a, b) => Number(a.SKU_EAN_check) - Number(b.SKU_EAN_check) || Number(a.SKU_check) - Number(b.SKU_check) || Number(a.EAN_check) - Number(b.EAN_check));
            this.errorOrWarnField = false;
            if (this.purchaseOrders.find(po => (!po.SKU_check && po.EAN_check) || (po.SKU_check && !po.EAN_check) || (!po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check)))) {
                this.errorOrWarnField = true;
            }
            this.loading = false;
            this.tableVisible = true;
        }
    }

    async onSubmit() {
        this.loadingUpload = true;
        let id_po, purchaseOrderList, purchaseOrders = [];

        if (this.purchaseOrders.find(product => product.SKU_EAN_check == false || (product.SKU_check == false && product.EAN_check == false))) {
            this.loadingUpload = false;
            this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("reception.no_submit") });
        } else {
            this.purchaseOrders.map(async (order) => {

                if (id_po != order.id_po) {
                    id_po = order.id_po;
                    let poArr = this.purchaseOrders.filter(i => i.id_po == id_po)
                    let itens = [];
                    for (let poItem of poArr) {
                        const item = {
                            SKU: poItem.SKU != null ? poItem.SKU.toString().trim() : null,
                            ProductName: poItem.ProductName != null ? poItem.ProductName.toString().trim() : null,
                            EAN: poItem.EAN != null ? poItem.EAN.toString().trim() : null,
                            Color: poItem.Color != null ? poItem.Color.toString().trim() : null,
                            Size: poItem.Size != null ? poItem.Size.toString().trim() : null,
                            QuantityEstimated: poItem.Quantity
                        }
                        itens.push(item)
                    }
                    purchaseOrderList = {
                        PO: id_po != null ? id_po.toString().trim() : null,
                        item_list: itens
                    }
                    purchaseOrders.push(purchaseOrderList)
                }
            })

            this.loadingUpload = false;
            this.purchaseOrders = this.purchaseOrders.sort((a, b) => a.id_po.toString().localeCompare(b.id_po.toString()) || a.SKU.toString().localeCompare(b.SKU.toString()));
            const packingList = {
                id_supplier: this.selectedSupplier.id_supplier,
                id_brand: this.id_brand,
                transfer: this.transfer,
                identifier: this.idFile,
                packing_list: purchaseOrders,
                file64: this.filebase64
            }

            this.packingListService.postPackingInsert(packingList).then(
                data => {
                    this.loadingUpload = false;
                    this.transfer = false;             
                    this.onRemoveFile();
                    this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("reception.submit_success") });
                },
                error => {
                    this.loadingUpload = false;
                    this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("reception.submit_error") });
                }
            ).catch((error) => {
                this.loading = false;
                this.messageService.add({ key: 'tst', severity: 'error', summary: error.error });
            });
        }
    }

    async createPurchaseOrder() {
        this.submitted = true;

        this.fieldPoNumberError = false;
        this.fieldSKUError = false;
        this.fieldEANError = false;

        if (!this.pos.PO.find(po => po.POnumber == this.purchaseOrder.id_po)) {
            this.fieldPoNumberError = true;
        }
        if (!this.products.find(e => e.SKU == this.purchaseOrder.SKU)) {
            this.fieldSKUError = true;
        }
        if (!this.products.find(e => e.EAN == this.purchaseOrder.EAN)) {
            this.fieldEANError = true;
        }

        if (this.purchaseOrder.id_po && this.purchaseOrder.SKU && this.purchaseOrder.EAN && this.purchaseOrder.Quantity &&
            !this.fieldPoNumberError && !this.fieldSKUError && !this.fieldEANError) {
            this.purchaseOrder.SKU_check = true;
            this.purchaseOrder.EAN_check = true;
            this.purchaseOrder.SKU_EAN_check = true;
            this.purchaseOrder.id_line = this.id;

            if (this.purchaseOrders.find(e => e.id_line != this.purchaseOrder.id_line && e.id_po == this.purchaseOrder.id_po && e.SKU == this.purchaseOrder.SKU && e.EAN == this.purchaseOrder.EAN)) {
                var po = this.purchaseOrders.find(e => e.id_line != this.purchaseOrder.id_line && e.id_po == this.purchaseOrder.id_po && e.SKU == this.purchaseOrder.SKU && e.EAN == this.purchaseOrder.EAN);
                po.Quantity = po.Quantity + this.purchaseOrder.Quantity;
            } else {
                var poInsert = {
                    PO: this.purchaseOrder.id_po,
                    QUANT: this.purchaseOrder.Quantity,
                    SKU: this.purchaseOrder.SKU,
                    EAN: this.purchaseOrder.EAN
                }
                var po = await this.packingListService.postPOsInsertLine(poInsert);
                this.purchaseOrder.id_line = this.id;
                this.purchaseOrder.Color = po.Color;
                this.purchaseOrder.ProductName = po.ProductName;
                this.purchaseOrder.Size = po.Size;
                this.purchaseOrders.push(this.purchaseOrder);
                this.id++;
            }
            this.purchaseOrders = [...this.purchaseOrders];
            this.purchaseOrderDialog = false;
            this.purchaseOrder = {};
            this.messageService.add({ severity: 'success', summary: this.translateService.instant("general.successful"), detail: this.translateService.instant("reception.product_updated"), life: 3000 });
        } else {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: this.translateService.instant("general.warn"), detail: this.translateService.instant("reception.warning_update") });
        }
    }

    onRowEditInit(purchaseOrder: PoInsert) {
        purchaseOrder.edit = true;
        this.clonedProducts[purchaseOrder.id_line] = { ...purchaseOrder };
    }

    async onRowEditSave(purchaseOrder: PoInsert) {
        purchaseOrder.edit = false;

        if (purchaseOrder.id_po && purchaseOrder.SKU && purchaseOrder.EAN && purchaseOrder.Quantity) {
            var poInsert = {
                PO: purchaseOrder.id_po,
                QUANT: purchaseOrder.Quantity,
                SKU: purchaseOrder.SKU,
                EAN: purchaseOrder.EAN
            }
            var product = await this.packingListService.postPOsInsertLine(poInsert)
            var po = this.purchaseOrders.find(e => e.id_line == purchaseOrder.id_line)
            po.ProductName = product.ProductName;
            po.Color = product.Color;
            po.Size = product.Size;
            po.SKU_EAN_check = product.SKU_EAN_check;
            po.SKU_check = product.SKU_check;
            po.EAN_check = product.EAN_check;

            if (this.purchaseOrders.find(e => e.id_line != purchaseOrder.id_line && e.id_po == purchaseOrder.id_po && e.SKU == purchaseOrder.SKU && e.EAN == purchaseOrder.EAN)) {
                var po = this.purchaseOrders.find(e => e.id_line != purchaseOrder.id_line && e.id_po == purchaseOrder.id_po && e.SKU == purchaseOrder.SKU && e.EAN == purchaseOrder.EAN);
                po.Quantity = po.Quantity + purchaseOrder.Quantity;
                this.purchaseOrders = this.purchaseOrders.filter(e => e.id_line != purchaseOrder.id_line)
            }
            delete this.clonedProducts[purchaseOrder.id_line];
            this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("reception.product_updated") });
        } else {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: this.translateService.instant("general.warn"), detail: this.translateService.instant("reception.warning_update") });
        }
    }

    onRowEditCancel(purchaseOrder: PoInsert, index: number) {
        purchaseOrder.edit = false;
        this.purchaseOrders[index] = this.clonedProducts[purchaseOrder.id_line];
        delete this.clonedProducts[purchaseOrder.id_line];
    }

    deleteProduct(purchaseOrder: PoInsert, index: number) {
        this.deleteProductDialog = true;
        this.purchaseOrder = { ...purchaseOrder };
    }

    confirmDelete() {
        this.deleteProductDialog = false;
        this.purchaseOrders = this.purchaseOrders.filter(val => val.id_line !== this.purchaseOrder.id_line);
        this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("reception.product_delete") });
        this.purchaseOrder = {};
    }

    async openNew() {
        this.purchaseOrder = {};
        this.submitted = false;
        this.pos = await this.packingListService.getPOsInsertLine("");
        this.purchaseOrderDialog = true;
    }

    onRemoveFile() {
        this.purchaseOrders = [];
        this.tableVisible = false;
        this.isload = false;
    }

    checkValue(poValue, field) {
        return poValue.filter(e => e[0] == field).length == 0 ? null : poValue.filter(e => e[0] == field)[0][1]
    }

    filterField(event, field) {
        let filtered: any[] = [];
        let query = event.query;

        if (field == "poNumber") {
            for (let i = 0; i < this.pos.PO.length; i++) {
                let po = this.pos.PO[i].POnumber;
                if (po.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(po);
                }
            }
            this.filteredPoNumber = filtered;
        } else if (field == "SKU") {
            for (let i = 0; i < this.products.length; i++) {
                let product = this.products[i];
                if (product.SKU.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(product.SKU);
                }
            }
            this.filteredSKU = filtered;
        } else {
            for (let i = 0; i < this.products.length; i++) {
                let product = this.products[i];
                if (product.EAN.toString().toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(product.EAN);
                }
            }
            this.filteredEAN = filtered;
        }
    }

    async checkFields(order, field) {
        if (field == "poNumber") {
            this.products = []
            var po = this.pos.PO.find(po => po.POnumber == order.id_po)
            if (po != undefined) {
                this.products = await this.packingListService.getPOsInsertLine(po.POnumber);
            }
        } else if (field == "SKU") {
            var product = this.products.find(product => product.SKU == order.SKU)
            if (product != undefined) {
                order.EAN = product.EAN
            }
        } else if (field == "EAN") {
            var product = this.products.find(product => product.EAN == order.EAN)
            if (product != undefined) {
                order.SKU = product.SKU
            }
        }
    }

    clear(table: Table) {
        table.clear();
        this.filter.nativeElement.value = '';
    }

    editIdFile() {
        this.idFile = this.selectedSupplier.name + "_" + this.packingListNumber + "_" + this.todayDate;
    }

    changeSuppliersArray() {
        if (this.transfer == true) {
            this.suppliers = this.suppliersAux.filter(e => e.transfer == 1);
            this.suppliers = this.suppliers.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            this.selectedSupplier = this.suppliers[0]
        } else {
            this.suppliers = this.suppliersAux.filter(e => e.transfer == 0);
            this.suppliers = this.suppliers.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
            this.selectedSupplier = this.suppliers[0]
        }
        this.editIdFile()
    }

    async exportExcel() {
        var first_sheet_name = this.workbook.SheetNames[0];
        var worksheet = this.workbook.Sheets[first_sheet_name];
        var worksheetArr = Object.entries(worksheet)
        worksheetArr = worksheetArr.slice(0, 42)
        var number = 11;
        var Quantity = 0;
        for (var po of this.purchaseOrders) {
            worksheetArr.push([
                "A" + number,
                {
                    "t": "s",
                    "v": po.id_po,
                    "r": "<t>" + po.id_po + "</t>",
                    "h": po.id_po,
                    "w": po.id_po
                },
            ],
                ["D" + number,
                {
                    "t": "s",
                    "v": po.SKU ? po.SKU : "",
                    "r": "<t>" + po.SKU ? po.SKU : "" + "</t>",
                    "h": po.SKU ? po.SKU : "",
                    "w": po.SKU ? po.SKU : ""
                }],
                [
                    "E" + number,
                    {
                        "t": "s",
                        "v": po.ProductName ? po.ProductName : "",
                        "r": "<t>" + po.ProductName ? po.ProductName : "" + "</t>",
                        "h": po.ProductName ? po.ProductName : "",
                        "w": po.ProductName ? po.ProductName : ""
                    }
                ],
                [
                    "G" + number,
                    {
                        "t": "s",
                        "v": po.Color,
                        "r": "<t>" + po.Color + "</t>",
                        "h": po.Color,
                        "w": po.Color
                    }
                ],
                [
                    "H" + number,
                    {
                        "t": "s",
                        "v": po.Size,
                        "r": "<t>" + po.Size + "</t>",
                        "h": po.Size,
                        "w": po.Size
                    }
                ],
                [
                    "I" + number,
                    {
                        "t": "s",
                        "v": po.EAN,
                        "r": "<t>" + po.EAN + "</t>",
                        "h": po.EAN,
                        "w": po.EAN
                    }
                ],
                [
                    "J" + number,
                    {
                        "t": "n",
                        "v": po.Quantity,
                        "w": po.Quantity
                    }
                ]);

            Quantity = Quantity + po.Quantity;
            number++;
        };

        worksheetArr.push([
            "A" + number,
            {

                "t": "s",
                "v": "TOTAL",
                "r": "<t>TOTAL</t>",
                "h": "TOTAL",
                "w": "TOTAL"
            }
        ],
            [
                "J" + number,
                {
                    "t": "n",
                    "v": Quantity,
                    "f": "SUM(J11:J" + (number - 1) + ")",
                    "w": Quantity
                }
            ]);

        import("xlsx").then(async xlsx => {

            var worksheetJson = Array.from(worksheetArr).reduce(
                (acc, [key, value]) => Object.assign(acc, { [key]: value }),
                {},
            );
            Object(worksheetJson);
            this.workbook.Sheets[first_sheet_name] = worksheetJson;
            const excelBuffer: any = xlsx.write(this.workbook, { bookType: 'xlsx', type: 'buffer' });
            this.saveAsExcelFile(excelBuffer, "receptionProducts");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    downloadSampleExcel() {
        let link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.href = '../../assets/packingListFormat.xlsx';
        link.download = "packingList Format.xlsx";
        document.body.appendChild(link);
        link.click();
        link.remove();
        return;
    }
}
