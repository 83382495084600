import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;
const ID_BRAND = 'id_brand';
const SECRET_KEY = "YourSecretKeyForEncryption&Descryption";

@Injectable({
    providedIn: 'root'
})
export class BrandService {

    constructor(private http: HttpClient) { }

    public saveBrand(brand: string): void {

        const data = JSON.stringify(brand);

        const cryptoJS = CryptoJS.AES.encrypt(data, SECRET_KEY.trim()).toString();

        window.sessionStorage.removeItem(ID_BRAND);
        window.sessionStorage.setItem(ID_BRAND, cryptoJS);
    }

    public getBrand() {
        let result = window.sessionStorage.getItem(ID_BRAND);
        let cryptoJS = CryptoJS.AES.decrypt(result, SECRET_KEY.trim()).toString(CryptoJS.enc.Utf8);

        return JSON.parse(cryptoJS);
    }

    async getAllBrands() {

        let data = await this.http.get<any>(AUTH_API + 'brand/GetAll').toPromise();
        return data;
    }

    async getBrandById(id_brand) {

        let data = await this.http.get<any>(AUTH_API + 'brand/GetOne?id_brand=' + id_brand).toPromise();
        return data;
    }

    async getBrandReturn() {
        let result = await this.http.get<any>(AUTH_API + 'brand/Return').toPromise();
        return result;
    }

    async getBrandReturnFonts() {
        let result = await this.http.get<any>(AUTH_API + 'brand/ReturnFonts').toPromise();
        return result;
    }

    async putBrandReturn(data) {
        let result = await this.http.put<any>(AUTH_API + 'brand/updateReturn', data).toPromise();
        return result;
    }

    async putEmailsBrandReturn(data) {
        let result = await this.http.put<any>(AUTH_API + 'brand/updateEmailsReturn', data).toPromise();
        return result;
    }

    async putWarehouseBrandReturn(data) {
        let result = await this.http.put<any>(AUTH_API + 'brand/updateWarehouseReturn', data).toPromise();
        return result;
    }

    async getIsAdminBrand() {
        let result = await this.http.get<any>(AUTH_API + 'brand/isAdminBrand').toPromise();
        return result;
    }

    async getCarriersByBrand() {
        let result = await this.http.get<any>(AUTH_API + 'brand/carriersByBrand').toPromise();
        return result;
    }

    async getCountryAndCarriersByBrand() {
        let result = await this.http.get<any>(AUTH_API + 'brand/countryAndCarriersByBrand').toPromise();
        return result;
    }

    async postCountryAndCarriersByBrand(data_request: any) {
        let result = await this.http.post<any>(AUTH_API + 'brand/countryAndCarriersByBrand', data_request).toPromise();
        return result;
    }


    async getReturnSpecialDate() {
        let result = await this.http.get<any>(AUTH_API + 'brand/ReturnSpecialDate').toPromise();
        return result;
    }

    async deleteReturnSpecialDate(idReturns: any) {
        let result = await this.http.delete<any>(AUTH_API + 'brand/ReturnSpecialDate?id=' + idReturns).toPromise();
        return result;
    }

    async postReturnSpecialDate(request_data: any) {
        let result = await this.http.post<any>(AUTH_API + 'brand/ReturnSpecialDate', request_data).toPromise();
        return result;
    }

    async getNotificationEmailsByBrand() {
        let result = await this.http.get<any>(AUTH_API + 'brand/notificationEmailsByBrand').toPromise();
        return result;
    }

    async deleteNotificationEmailsByBrand(emails: any) {
        let result = await this.http.delete<any>(AUTH_API + 'brand/notificationEmailsByBrand?email=' + emails).toPromise();
        return result;
    }

    async postNotificationEmailsByBrand(request_data: any) {
        let result = await this.http.post<any>(AUTH_API + 'brand/notificationEmailsByBrand', request_data).toPromise();
        return result;
    }

    async checkIfBrandAccept() {
        let result = await this.http.get<any>(AUTH_API + 'brand/checkIfBrandAccept').toPromise();
        return result[0];
    }

    async getTags() {
        const result = await this.http.get<any>(AUTH_API + 'brand/getTags').toPromise();
        return result;
    }

    async get2handAdmin() {
        const result = await this.http.get<any>(AUTH_API + 'brand/2handadmins').toPromise();
        return result;
    }

    updateMarketUserAdmin(userAdmin) {
        const result = this.http.put<any>(AUTH_API + 'brand/2handadmins', userAdmin).toPromise();
        return result;
    }

    updateMarketUserAdminPassword(userAdminPassword) {
        const result = this.http.put<any>(AUTH_API + 'brand/2handadminspassword', userAdminPassword).toPromise();
        return result;
    }

    createMarkerUseradmin(userAdmin){
        const result = this.http.post<any>(AUTH_API + 'brand/2handadmins', userAdmin).toPromise();
        return result;
    }

    get2HandMarketplaceOptions(){
        const result = this.http.get<any>(AUTH_API + 'brand/2handfees').toPromise();
        return result;
    }

    update2HandMarketplaceOptions(fees){
        const result = this.http.put<any>(AUTH_API + 'brand/2handfees', fees).toPromise();
        return result;
    }

    postMarketplacePhoto(photo){
        const result = this.http.post<any>(AUTH_API + 'brand/2handadmins/photosbanner', photo).toPromise();
        return result;
    }

    async deleteMarketplacePhoto(url_photo){
        let result = await this.http.delete<any>(AUTH_API + 'brand/2handadmins/photosbanner', {body: url_photo}).toPromise();
        console.log(result)
        return result;
    }
}
