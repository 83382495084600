import { DashBoardComponent } from "./dashboard.component";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';
import { DashReturnModule } from '../returns/dash-return/dash-return.module';
import { Dash2HandModule } from "../2hand/dash-2hand/dash-2hand.module";
import { DashRepairModule } from "../repair/dash-repair/dash-repair.module";
import { DashRecycleModule } from "../recycle/dash-recycle/dash-recycle.module";

@NgModule({
    imports: [
        TabViewModule,
        DashReturnModule,
        Dash2HandModule,
        DashRepairModule,
        DashRecycleModule,
        CommonModule
    ],
    declarations: [
        DashBoardComponent
    ],
    exports: [
        DashBoardComponent
    ],

})
export class DashBoardModule { }