import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root',
})
export class RepairService {
    constructor(private http: HttpClient) { }

    async getRepairs(idBrand: any, dateI: any, dateF: any) {
        let data = await this.http
            .get<any>(
                AUTH_API +
                'repair/repair_list?id_brand=' + idBrand + '&inicial_date=' +
                dateI +
                '&final_date=' +
                dateF
            )
            .toPromise();
        return data;
    }

    async getRepairStatus() {
        let data = await this.http
            .get<any>(AUTH_API + 'repair/repair_status')
            .toPromise();
        return data;
    }

    async getRepairLines(repair_id: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'repair/repair_lines?repair_id=' + repair_id)
            .toPromise();
        return data;
    }

    async getRepairJournal(repair_id: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'repair/getHistoryJournalRepair?repair_id=' + repair_id)
            .toPromise();
        return data;
    }

    async getNRepairs() {

        try {
            let data = await this.http
                .get<any>(AUTH_API + 'dashBoard/getNRepairs')
                .toPromise();
            return data;
        } catch (error) {
            console.log(error)
            return null;
        }
    }

    async getTValueRepairs() {
        try {
            let data = await this.http
                .get<any>(AUTH_API + 'dashBoard/getTValueRepairs')
                .toPromise();
            return data;
        } catch (error) {
            return null;
        }
    }

    async getMRProducts() {
        try {
            let data = await this.http
                .get<any>(AUTH_API + 'dashBoard/getMostRepairProducts')
                .toPromise();
            return data;
        } catch (error) {
            console.log(error)
            return null;
        }
    }

    async getMPService() {
        try {
            let data = await this.http
                .get<any>(AUTH_API + 'dashBoard/getMostPopularService')
                .toPromise();
            return data;
        } catch (error) {
            console.log(error)
            return null;
        }
    }

    async getServices(id_brand: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'repair/repairservices?id_brand=' + id_brand)
            .toPromise();
        return data;

    }

    async addService(repair: any) {
        let data = await this.http
            .put<any>(AUTH_API + 'repair/addrepairservice', repair)
            .toPromise();
        return data;

    }

    async getRepairServices(id_brand: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'repair/getrepairservices?id_brand=' + id_brand)
            .toPromise();
        return data;

    }

    async deleteRepairService(id_brand: any, id_service: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'repair/deleterepairservices?id_brand=' + id_brand + '&id_service=' + id_service)
            .toPromise();
        return data;

    }
}
