import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root',
})
export class AbandonedShoppingBagsService {
    constructor(private http: HttpClient) { }

    async getAbandonedBags(idBrand: any, dateI: any, dateF: any) {
        let data = await this.http
            .get<any>(
                AUTH_API +
                'aShoppingBags?id_brand=' + idBrand + '&inicial_date=' +
                dateI +
                '&final_date=' +
                dateF
            )
            .toPromise();
        return data;
    }



    async getAbandonedBagsLines(id_order: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'aShoppingBags/abandonedbaglines?id_order=' + id_order)
            .toPromise();
        return data;
    }

}
