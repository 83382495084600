<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<div *ngIf="!loading" class="h-full">
	<p-toast></p-toast>
	<p-table #dtreturns [value]="returns" dataKey="idInterno" [columns]="selectedColumns" [rowHover]="true"
		[paginator]="true" [globalFilterFields]="['idInterno', 'order_name', 'customer', 'country']" [rows]="25"
		[rowsPerPageOptions]="[25,50,100]" [showCurrentPageReport]="true" [resizableColumns]="true"
		[reorderableColumns]="true" responsiveLayout="scroll" [responsive]="true" [(selection)]="selectedReturns"
		exportFilename="returns" [loading]="loading"
		currentPageReportTemplate="{{ 'products.list_product.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
		<ng-template pTemplate="caption">
			<div class="flex justify-content-between gap-2 flex-wrap">
				<div class="flex flex-column w-full sm:w-auto sm:flex-row gap-2">
					<p-calendar [showIcon]="true" [(ngModel)]="rangeDates" selectionMode="range" dateFormat="dd/mm/yy"
						(onClose)="ngOnInit()" [minDate]="minimumDate"></p-calendar>
					<div class="flex gap-2 w-full sm:w-auto">
						<span class="p-input-icon-left w-full sm:w-auto">
							<i class="pi pi-search"></i>
							<input pInputText type="text" #filter
								(input)="dtreturns.filterGlobal($event.target.value, 'contains')"
								placeholder="{{ 'general.search_here' | translate }}" />
						</span>
						<div (click)="initReturns()" class="flex align-items-center cursor-pointer"
							style="color: #6c757d;">
							<i class="pi pi-refresh"></i>
						</div>
					</div>
				</div>
				<div class="flex gap-2">
					<button pButton pRipple label="Export" class="p-button-outlined" (click)="exportCSV()">
						<span class="p-button-icon p-button-icon-left flex"><i class="pi pi-file-excel"></i></span>
					</button>
					<button pButton pRipple label="Submit a return" class="p-button-secundary" (click)="newReturn()">
						<span class="p-button-icon p-button-icon-left flex"><img
								src="assets/layout/images/desktop-icon.svg"></span>
					</button>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th style="width: 1rem">
					<p-tableHeaderCheckbox (click)="mainCheckboxClick()"></p-tableHeaderCheckbox>
				</th>
				<th pResizableColumn pReorderableColumn [pSortableColumn]="col.sort ? col.field : null"
					*ngFor="let col of columns" class="cursor-move">
					<div class="flex align-items-center"
						[ngClass]="{'cursor-pointer' : col.sort || col.filter, 'p-column-filter-menu': col.filter }">
						{{col.header}}
						<p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
						<i *ngIf="col.filter && activeStatus == null" class="pi pi-filter"
							(click)="openOverlay($event, op)"></i>
						<i *ngIf="col.filter && activeStatus != null" class="pi pi-filter-fill"
							(click)="openOverlay($event, op)"></i>
					</div>
				</th>
				<th style="width: 1rem; border-left: none;">
					<img src="assets/layout/images/config-icon.svg" (click)="openOverlay($event, op2)"
						class="cursor-pointer">
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-item let-columns="columns" let-ri="rowIndex">
			<tr (click)="openSideBar(item)" class="cursor-pointer">
				<td>
					<p-tableCheckbox [value]="item" (click)="checkboxClick($event)"></p-tableCheckbox>
				</td>
				<td *ngFor="let col of columns">
					<hr *ngIf="item.IDStatus == 99 && col.field != 'returnStatus'" class="draw-line">
					<div class="flex w-min"
						[ngClass]="col.field == 'returnStatus' ? 'return-badge status-' + item.IDStatus : ''">

						<div *ngIf="col.field != 'idInterno' && col.field!== 'dataCriacao'">
							{{item[col.field]}}
						</div>
						<div *ngIf="col.field === 'dataCriacao'">
							{{ item.dataCriacao | date:'MMM d, h:mm a' }}
						</div>
						<a *ngIf="col.field == 'idInterno'">
							{{item[col.field]}}
						</a>
						<div *ngIf="col.field == 'items'">
							<div *ngIf="item[col.field] == 1">&nbsp;item</div>
							<div *ngIf="item[col.field] !== 1">&nbsp;items</div>
						</div>
						<div *ngIf="col.field == 'refund_total'">
							&nbsp;{{item.currency}}
						</div>
						<div *ngIf="col.field == 'wmsStatus'">
							<!-- <div *ngIf="item.archived == 1 && item.error == 0">
								<i title="WMS Success" class="pi pi-exclamation-triangle text-green-600"></i>
							</div> -->
							<div *ngIf="item.archived == 0 && item.error == 1">
								<i title="WMS Error" class="pi pi-exclamation-triangle text-red-600"></i>
							</div>
						</div>
					</div>
				</td>
				<td></td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="11">{{ 'general.no_data' | translate }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr>
				<td colspan="11">{{ 'general.loading' | translate }}</td>
			</tr>
		</ng-template>
	</p-table>
</div>
<p-overlayPanel #op2 (onHide)="hideOverlay()" (onShow)="showOverlay()">
	<ng-template pTemplate>
		<div class="flex flex-column gap-3">
			<h6>CUSTOMIZE COLUMNS</h6>
			<div>
				<div *ngFor="let col of colsOptional" class="field-checkbox">
					<p-checkbox name="columns" value="col" [value]="col" [(ngModel)]="selectedColumnsAux"
						[inputId]="col.field"></p-checkbox>
					<label [for]="col.field">{{col.header}}</label>
				</div>
			</div>
		</div>
		<div class="mt-6 flex">
			<p-button icon="pi pi-replay" (click)="revert($event, op2)" label="Revert to default"
				styleClass="p-button-text"></p-button>
			<p-button label="Customize" (click)="customize($event, op2)"></p-button>
		</div>
	</ng-template>
</p-overlayPanel>
<p-sidebar [(visible)]="visibleSidebar" position="right" [baseZIndex]="10000" (onHide)="hideSideBar()"
	(click)="menuShow = false" [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false"
	class="relative">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="hideSideBar()"></button>
		</div>
	</div>
	<div *ngIf="!return || loadingSidebar" class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div>
	<div *ngIf="return && !loadingSidebar" class="flex flex-column">
		<div class="card">
			<div class="flex justify-content-between mb-3">
				<div class="flex flex-column sm:flex-row gap-2">
					<div>
						<h5>Return Request | {{return.id_return}}</h5>
					</div>
					<div class="flex gap-1">
						<div [class]="'return-badge status-' + return.IDStatus">{{return.returnStatus}}</div>
					</div>
					<div class="flex gap-1">
						<div *ngIf="return.archived == 1 && return.error == 0">
							<div [class]="'return-statuswms status-' + return.archived">WMS Success</div>
						</div>
						<div *ngIf="return.archived == 0 && return.error == 1">
							<div [class]="'return-statuswms status-' + return.archived">WMS Error</div>
						</div>
					</div>

				</div>
				<div class="relative flex flex-column align-items-end">
					<p-button *ngIf="app.isDesktop()" label="More actions" icon="pi pi-angle-down" iconPos="right"
						class="p-button-outlined" (click)="menu.toggle($event)"></p-button>
					<button *ngIf="!app.isDesktop()" pButton pRipple type="button" icon="pi pi-ellipsis-v"
						class="p-button-rounded p-button-text relative p-0 h-min"
						(click)="menu.toggle($event)"></button>
					<p-menu #menu [popup]="true" [model]="dropdown_options"></p-menu>
				</div>
			</div>
			<div class="grid">
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<span>Order: {{return.order_name}}</span>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>Submitted On: {{return.order_created_at}}</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>Customer: <a>{{return.first_name}} {{return.last_name}}</a>
					</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<div class="flex flex-wrap gap-1">
						<p class="mb-0">{{ 'orders.returns.email' | translate }}:</p>
						<div class="flex gap-1">
							<p class="mb-0"><a href="mailto:{{return.email}}"
									(click)="$event.stopPropagation()">{{return.email}}</a></p>
							<p-button (click)="copiedToclipboard()" icon="pi pi-copy"
								styleClass="h-auto w-auto p-0 text-color p-button-rounded p-button-text" ngxClipboard
								[cbContent]="return.email"></p-button>
						</div>
					</div>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-8">
					<p>{{ 'orders.returns.address' | translate }}: {{return.address1}}
						{{return.address2}} {{return.city}} {{return.zipcode}} {{return.province}}
						{{return.country}}</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4 pr-0">
					<p>Tracking:
						<a href="{{return.track_link}}{{return.tracking_id}}" (click)="$event.stopPropagation()"
							target="_blank" style="word-break: break-word;">{{return.tracking_id}}</a>
					</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-8">
					<p>{{ 'orders.returns.payment_description' | translate }}:
						{{return.payment_description}}</p>
				</div>
				<div *ngIf="return.mb_return_payment != null" class="col-12 sm:col-6 md:col-6 lg:col-4 flex">
					<p *ngIf="show_IBAN">IBAN: {{return.mb_return_payment}} <span class="pi pi-eye"
							(click)="set_show_IBAN()"></span> <span class="pl-1 pi pi-copy"
							(click)="set_clipboard()"></span></p>
					<p *ngIf="!show_IBAN">IBAN: ******************************* <span class="pi pi-eye-slash"
							(click)="set_show_IBAN()"></span></p>
				</div>
			</div>
		</div>
		<!--quando carregas na listas que está em em main/returns-->
		<div>
			<div class="returns-summary flex flex-column ">
				<div class="w-full flex flex-column lg:flex-row gap-3">
					<div class="card w-full lg:w-8" [ngClass]="{'w-full' : return?.order_name?.includes('exc.')}">
						<h5>Return Items</h5>
						<div class="flex align-items-start justify-content-center">
							<p-dataView class="w-full" #dv [value]="return.lines" [paginator]="false" layout="list">
								<ng-template let-returnline pTemplate="listItem">
									<div class="w-full" *ngIf="returnline.IDStatus !== 14">
										<div id="info-return"
											class="info-return flex flex-row flex-wrap gap-2 pt-5 pb-5 w-full justify-content-between">
											<div class="flex flex-column sm:flex-row gap-3 w-full">
												<div id="block-img-product"
													class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
													<img [src]="returnline.Image_url" [alt]="returnline.productname"
														class="w-7rem sm:w-10rem shadow-2" />
												</div>
												<div
													class="flex flex-wrap-reverse gap-3 w-full justify-content-between">
													<div class="md:text-left flex flex-column gap-3 max-w-full">
														<!--nome do itens-->
														<span
															class="align-items-start justify-content-start"><b>{{returnline.ProductName}}</b></span>
														<div class="flex align-items-center">
															<span class="mr-3 align-self-center md:align-self-end"><b>SKU:
																</b>{{returnline.sku}}</span>
														</div>
														<div class="flex">
															<div *ngIf="returnline.product_variant_option1.value != null"
																class="mr-3 ">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option1.key}}: </b>
																	{{returnline.product_variant_option1.value}}</span>
															</div>
															<div *ngIf="returnline.product_variant_option2.value != null"
																class="mr-3 ">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option2.key}}: </b>
																	{{returnline.product_variant_option2.value}}</span>
															</div>
															<div *ngIf="returnline.product_variant_option3.value != null"
																class="mr-3 ">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option3.key}}: </b>
																	{{returnline.product_variant_option3.value}}</span>
															</div>
														</div>
														<div *ngIf="[49,50,51].includes(returnline.IDStatus) == false">
															<b>Price: </b>
															<span class="align-items-start justify-content-start">
																{{returnline.total}} {{return.currency}}</span>
														</div>
														<div class="flex align-items-center">
															<span class="mr-3 align-self-center md:align-self-end"><b>
																	{{
																	'orders.returns.quantity' | translate }}: </b>
																{{returnline.quantity}}</span>
															<span class="mr-3 align-self-center md:align-self-end"><b>
																	{{
																	'orders.returns.received' | translate }}: </b>
																{{returnline.quantityReceived}}</span>
														</div>
														<div
															*ngIf="[49,50,51].includes(returnline.IDStatus) == false && returnline.return_reason_id">
															<span class="align-items-start justify-content-start"><b> {{
																	'orders.returns.reason' | translate }}: </b>
																{{returnline.return_reason_text}}</span>
														</div>
														<div
															*ngIf="[49,50,51].includes(returnline.IDStatus) == false && returnline.return_reason_note">
															<span class="align-items-start justify-content-start"><b> {{
																	'orders.returns.note' | translate }}: </b>
																{{returnline.return_reason_note}}</span>
														</div>
														<div
															*ngIf="[49,50,51,53,57].includes(returnline.IDStatus) == true">
															<p-button label="Order Exchange {{return.order_name_exc}}"
																styleClass="p-button-link p-0"
																(click)="getOrderExchangeDetails( return.order_id_exc , op, $event)">
															</p-button>
														</div>

														<div
															*ngIf="(returnline.images > 0 && returnline.images_data?.length == undefined)">
															<p-button
																(click)="onImagesClick(returnline.id_return,returnline.id_return_line)"
																label="{{ 'orders.returns.images' | translate }}"
																[badge]="returnline.images"
																styleClass="p-button-outlined p-button-secondary">
															</p-button>
														</div>

														<div *ngIf="returnline.images_data?.length > 0 ">
															<p-dataView [value]="returnline.images_data">
																<ng-template let-linesImages pTemplate="listItem">
																	<div class="m-2">
																		<p-image [src]="linesImages.image" width="100"
																			[preview]="true">
																		</p-image>
																	</div>
																</ng-template>
															</p-dataView>
														</div>
													</div>
													<div id="block-approve-or-no"
														class="flex flex-column gap-3 block-approve-or-no">
														<div *ngIf="return.IDStatus !== 1"
															class="text-align-center flex gap-1 justify-content-start sm:justify-content-end">
															<div class="flex flex-wrap gap-2 justify-content-start sm:justify-content-end"
																*ngIf="returnline.IDStatus === 13 || returnline.IDStatus === 9">
																<div class="wmsReturnStatus status-approved">
																	{{
																	'orders.returns.approved' | translate }}</div>
																<div class="wmsReturnStatus status-received">
																	{{
																	'orders.returns.received' | translate }}</div>
															</div>
															<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
																*ngIf="returnline.IDStatus === 4">
																<div class="wmsReturnStatus status-approved">
																	{{
																	'orders.returns.approved' | translate }}</div>
																<div class="wmsReturnStatus status-notreceived">
																	{{
																	'orders.returns.notreceived' | translate }}</div>
															</div>
															<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
																*ngIf="(returnline.IDStatus === 53 || returnline.IDStatus === 52)">
																<div class="wmsReturnStatus status-approved">
																	{{
																	'orders.returns.exchangeapproved' | translate }}
																</div>
																<div class="wmsReturnStatus status-notreceived">
																	{{
																	'orders.returns.notreceived' | translate }}</div>
															</div>
															<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
																*ngIf="returnline.IDStatus === 51">
																<div class="wmsReturnStatus status-approved">
																	{{
																	'orders.returns.exchangeapproved' | translate }}
																</div>
																<div class="wmsReturnStatus status-approved">
																	{{
																	'orders.returns.received' | translate }}</div>
															</div>
															<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
																*ngIf="returnline.IDStatus === 55">
																<div class="wmsReturnStatus status-approved">
																	{{
																	'orders.returns.exchangeapproved' | translate }}
																</div>
																<div class="wmsReturnStatus status-parcial">
																	{{
																	'orders.returns.parcialreceived' | translate }}
																</div>
															</div>
															<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
																*ngIf="returnline.IDStatus === 15">
																<div class="wmsReturnStatus status-approved">
																	{{
																	'orders.returns.approved' | translate }}</div>
																<div class="wmsReturnStatus status-parcial">
																	{{
																	'orders.returns.parcialreceived' | translate }}
																</div>
															</div>
															<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
																*ngIf="returnline.IDStatus === 17">
																<div class="wmsReturnStatus status-notreceived">
																	{{
																	'orders.returns.itemnotexpected' | translate }}
																</div>
																<div class="wmsReturnStatus status-approved">
																	{{
																	'orders.returns.approved' | translate }}</div>
																<div class="wmsReturnStatus status-approved">
																	{{
																	'orders.returns.received' | translate }}</div>
															</div>
															<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
																*ngIf="returnline.IDStatus === 14">
																<div class="wmsReturnStatus status-unexpected">
																	{{
																	'orders.returns.unexpected' | translate }} </div>
															</div>
															<div *ngIf="returnline.IDStatus === 2"
																class="wmsReturnStatus status-approved">
																{{
																'orders.returns.approved'
																| translate }}</div>
															<div *ngIf="returnline.IDStatus === 1"
																class="wmsReturnStatus status-notapproved">
																{{
																'orders.returns.notapproved' | translate }}</div>
															<div *ngIf="returnline.IDStatus === 16"
																class="wmsReturnStatus status-notapproved">
																{{
																'orders.returns.rejected' | translate }}</div>
															<div *ngIf="returnline.IDStatus === 50"
																class="wmsReturnStatus status-approved">
																{{
																'orders.returns.exchangeapproved' | translate }}</div>
															<div *ngIf="returnline.IDStatus === 49"
																class="wmsReturnStatus status-notapproved">
																{{
																'orders.returns.notexchangeapproved' | translate }}
															</div>
															<div *ngIf="returnline.IDStatus === 56"
																class="wmsReturnStatus status-notapproved">
																{{
																'orders.returns.rejectedexchange' | translate }}</div>
														</div>
														<div *ngIf="(return.IDStatus === 1 || return.IDStatus === 49)"
															class="flex justify-content-end">
															<div>{{returnline.returnStatus}}</div>
															<div
																*ngIf="[1,49].includes(returnline.IDStatus) ; else elseLineStatus">
																<button [disabled]="updateLine"
																	(click)="updateLineStatus(returnline.IDStatus, returnline.id_return, returnline.id_return_line)"
																	pButton
																	[ngStyle]="{'background-color':'var(--green-200)','color':'var(--gray-900)', 'border':'var(--yellow-200)'}"
																	icon="pi pi-check-circle"
																	label="{{ 'orders.returns.approve' | translate }}"
																	iconPos="right" class="mb-2"></button>
															</div>
															<ng-template #elseLineStatus>
																<button [disabled]="updateLine"
																	(click)="updateLineStatus(returnline.IDStatus, returnline.id_return, returnline.id_return_line)"
																	pButton
																	[ngStyle]="{'background-color':'var(--pink-200)','color':'var(--gray-900)', 'border':'var(--yellow-200)'}"
																	icon="pi pi-check-circle"
																	label="{{ 'orders.returns.reject' | translate }}"
																	iconPos="right" class="mb-2"></button>
															</ng-template>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</p-dataView>
						</div>
					</div>
					<div class="card w-full lg:w-4">
						<div class="flex-1 align-items-start justify-content-start">
							<div *ngIf="!all_exchange">
								<div class="flex justify-content-between mb-3">
									<span class="flex align-items-center justify-content-center font-bold text-xl">{{
										'orders.returns.summary' | translate }}</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start">{{
										'orders.returns.subtotal' | translate }}</span>
									<span
										class="flex align-items-center justify-content-end">{{subtotal}}{{return.currency}}</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start">{{
										'orders.returns.shippingcost' | translate }}</span>
									<span
										class="flex align-items-center justify-content-end">{{return.shipping_cost}}{{return.currency}}</span>
								</div>
								<div class="flex justify-content-between flex-wrap mb-2">
									<span class="flex align-items-start justify-content-start">{{
										'orders.returns.processingfee' | translate }}</span>
									<span *ngIf="(all_exchange == true)"
										class="flex align-items-center justify-content-end">0.00{{return.currency}}</span>
									<span *ngIf="(all_exchange == false)"
										class="flex align-items-center justify-content-end">
										<div *ngIf="return.fee_total !== '0.00'">-</div>
										{{return.fee_total}}{{return.currency}}
									</span>
								</div>
								<div class="flex justify-content-between flex-wrap mt-3 mb-2">
									<span class="flex align-items-start justify-content-start font-bold">{{
										'orders.returns.refundtotal' | translate }}</span>
									<span
										class="flex align-items-center justify-content-end font-bold">{{refundTotal()}}{{return.currency}}</span>
									</div>
							</div>
							<div *ngIf="all_exchange">
								<div class="flex justify-content-between mb-3">
									<span class="flex align-items-center justify-content-center font-bold text-xl">{{
										'orders.returns.summary' | translate }}</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start font-bold">Items to
										exchange</span>
									<span class="flex align-items-center justify-content-end">{{itemsToExchangeCount}}
										items</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start font-bold">Approved
										items</span>
									<span class="flex align-items-center justify-content-end">{{itemsApprovedCount}}
										items</span>
								</div>
								<div class="flex justify-content-between flex-wrap mb-2">
									<span class="flex align-items-start justify-content-start font-bold">Rejected
										items</span>
									<span class="flex align-items-center justify-content-end">{{itemsRejectedCount}}
										items</span>
								</div>
							</div>
							<!-- <p-divider *ngIf="(return.IDStatus === 1 || return.IDStatus === 49)" layout="horizontal">
						</p-divider> -->
							<button *ngIf="(return.IDStatus === 1 || return.IDStatus === 49)" (click)="ReturnFinalize()"
								pButton class="w-full button-secundary mt-3 mb-2"
								label="{{ 'general.finalize' | translate }}"></button>
							<!-- <div *ngIf="loading"
								class="p-datatable-loading-overlay p-component-overlay ng-star-inserted"
								style="z-index: 1000; display: flex; justify-content: center; align-items: center;">
								<i class="p-datatable-loading-icon pi pi-spin pi-spinner"></i>
							</div> -->
							<!-- <div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
								<div class="loading"></div>
							</div> -->
						</div>
					</div>
				</div>
				<!-- <div class="lg:col-1" *ngIf="all_exchange == false">
					<p-divider class="divider-line" layout="vertical"></p-divider>
				</div> -->
				<!-- Extra lines -->
				<div class="w-full flex flex-column md:flex-row gap-3 ">
					<div class="card w-full flex gap-2" *ngIf="return.linesExtra?.length > 0">
						<div class="w-full">
							<div class="flex gap-3">
								<div class="w-full lg:w-8">
									<h5>{{ 'orders.returns.itemsnotexpected' | translate }}</h5>
								</div>
								<div *ngIf="return.IDStatus == 13 || return.IDStatus == 51"
									class="hidden lg:flex md:w-4" style="padding: 0 21px">
									<h5>{{ 'orders.returns.newexchangeorder' | translate }}</h5>
								</div>
							</div>
							<div class="flex gap-2">
								<div class="flex flex-column align-items-start justify-content-center w-full">
									<p-dataView class="w-full" #dv [value]="return.linesExtra" [paginator]="false"
										layout="list">
										<ng-template let-returnline pTemplate="listItem">
											<div class="w-full flex flex-column lg:flex-row gap-2">
												<div id="info-return"
													class="w-full lg:w-8 info-return flex flex-row pt-5 pb-5 lg:pr-3 justify-content-between"
													*ngIf="returnline.IDStatus === 14">
													<div class="flex flex-column sm:flex-row gap-3">
														<div id="block-img-product"
															class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
															<img [src]="returnline.Image_url"
																[alt]="returnline.productname"
																class="w-7rem sm:w-10rem shadow-2" />
														</div>
														<div
															class="md:text-left flex flex-column gap-3 w-full white-space-nowrap">
															<span
																class="align-items-start justify-content-start"><b>{{returnline.ProductName}}</b></span>
															<div class="flex flex-wrap gap-3 align-items-start">
																<span class="align-self-start md:align-self-end"><b>
																		Sku:
																	</b>
																	{{returnline.sku}}</span>
																<span class="align-self-start md:align-self-end"><b>
																		{{
																		'orders.returns.order' | translate }}: </b>
																	{{return.order_name}}</span>
															</div>
															<div class="flex flex-wrap gap-3 align-items-start">
																<span class="align-self-start md:align-self-end"><b>
																		{{
																		'orders.returns.quantity' | translate }}:
																	</b>
																	{{returnline.quantity}}</span>
																<span class="align-self-start md:align-self-end"><b>
																		{{
																		'orders.returns.received' | translate }}:
																	</b>
																	{{returnline.quantityReceived}}</span>
															</div>
															<div><span
																	class="align-items-start justify-content-start"><b>
																		{{ 'orders.returns.price' | translate }}:
																	</b>
																	{{returnline.price}} {{return.currency}}</span>
															</div>
															<div
																*ngIf="returnline.product_variant_option1.value != null">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option1.key}}:
																	</b>
																	{{returnline.product_variant_option1.value}}</span>
															</div>
															<div
																*ngIf="returnline.product_variant_option2.value != null">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option2.key}}:
																	</b>
																	{{returnline.product_variant_option2.value}}</span>
															</div>
															<div
																*ngIf="returnline.product_variant_option3.value != null">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option3.key}}:
																	</b>
																	{{returnline.product_variant_option3.value}}</span>
															</div>
														</div>
													</div>
													<div id="block-approve-or-no"
														class="flex flex-column gap-3 w-auto block-approve-or-no">
														<div *ngIf="!(return.IDStatus == 13 || return.IDStatus == 51 || return.IDStatus == 9)"
															class="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end">
															<div *ngIf="return.IDStatus !== 1"
																style="text-align: center;">
																<div class="flex gap-2">
																	<div class="wmsReturnStatus status-notreceived">
																		{{
																		'orders.returns.itemnotexpected' | translate
																		}}
																	</div>
																</div>
															</div>
														</div>
														<div *ngIf="return.IDStatus == 13 || return.IDStatus == 51  || return.IDStatus == 9"
															class="flex justify-content-end">
															<button
																class="p-button-raised p-button-secondary p-button-text"
																type="button" pButton icon="pi pi-cog" label="Actions"
																(click)="menu.toggle($event)"
																(click)="selectedNotExpectedItem = returnline"></button>
															<p-menu #menu [popup]="true" appendTo="body"
																[model]="selectedNotExpectedItem?.notExpectedOptions">
															</p-menu>
														</div>
													</div>
												</div>
												<div *ngIf="(return.IDStatus == 13 || return.IDStatus == 51) && returnline.variantSizeSelect.length != 0"
													class="line-div flex align-items-center justify-content-center">
													<button pButton pRipple type="button" icon="pi pi-sort-alt"
														class="p-button-rounded" style="cursor: auto;"></button>
												</div>
												<div *ngIf="return.IDStatus == 13 || return.IDStatus == 51 || return.IDStatus == 9"
													class="select-sizes-block flex flex-column gap-3 justify-content-between">
													<div *ngFor="let sizeSelect of returnline.variantSizeSelect; let i = index"
														class="flex justify-content-between">
														<div class="md:text-left flex flex-column gap-3 w-max">
															<div class="flex align-items-center">
																<span
																	class="mr-3 align-self-center md:align-self-end"><b>
																		Sku:
																	</b>
																	{{sizeSelect?.sku}}</span>
															</div>
															<div class="flex align-items-center">
																<span
																	class="mr-3 align-self-center md:align-self-end"><b>
																		{{
																		'orders.returns.quantity' | translate }}:
																	</b>
																	{{returnline.quantityArrayNotExpectedItem[i]}}</span>
															</div>
															<div><span
																	class="align-items-start justify-content-start"><b>
																		{{sizeSelect?.product_variant_option2.key}}:
																	</b>
																	{{sizeSelect?.product_variant_option2.value}}</span>
															</div>
														</div>
														<i class="pi pi-trash flex align-items-center cursor-pointer"
															(click)="deleteSizeSelect(i, returnline)"></i>
													</div>
												</div>
											</div>
										</ng-template>
									</p-dataView>
									<div class="w-full flex justify-content-end" *ngIf="hasExchange.length > 0">
										<div class="summary-block pl-0 md:pl-3">
											<button pButton (click)="ReturnFinalizeExchange()"
												label="{{ 'orders.returns.finalizeexchange' | translate }}"
												class="w-full button-secundary mt-3 mb-2"></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--comments-->
	<div *ngIf="comments && return && !loadingSidebar" class="card">
		<div class="flex justify-content-between mb-3">
			<div class="flex gap-2" style="width: 100%;">
				<div class="comments-container" style="width: 100%;">
					<h5 class="comments-heading">Comments</h5>
					<div class="flex gap-3">
						<textarea [(ngModel)]="comment" maxlength={{maxLength}} id="texts" class="textarea-input"
							rows="1" placeholder="Write a comment..." pInputTextarea autoResize="autoResize"></textarea>
						<button pButton pRipple type="submit" label="Submit" [disabled]="comment.length == 0"
							(click)="submitComment()" style="min-width: 90px;"></button>
					</div>
					<br>
					<div *ngFor="let comment of comments">
						<div class="flex flex-column">
							<div class="flex justify-content-between align-items-end">
								<div class="flex align-items-end gap-2" style="height: 28px;">
									<div><b>{{ comment.author }}</b></div>
									<div class="comment-date">{{comment.date}}</div>
								</div>
								<button pButton pRipple type="button" icon="pi pi-trash"
									*ngIf="!disableDelComment(comment)" class="close-button"
									(click)="deleteComment(comment)"></button>
							</div>
							<div class="py-1">{{ comment.text }}</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>

</p-sidebar>

<!--histórico sidebar-->
<p-sidebar [(visible)]="isHistoryTrue" position="right" [baseZIndex]="10001" (onHide)="hideSidebarHistory()"
	(click)="menuShow = false" [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false"
	class="relative">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="hideSidebarHistory()"></button>
		</div>
	</div>
	<div *ngIf="loadingHistory" class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div>
	<div *ngIf="return && !loadingHistory" class="card mb-0" style="min-height: calc(100vh - 83px)">
		<div class="flex justify-content-between mb-3">
			<div class="flex gap-2">
				<div>
					<h5>Return history | {{return.id_return}}</h5>
				</div>
			</div>
		</div>
		<div *ngIf="dataHistory.length == 0" class="flex justify-content-center align-items-center h-full">
			<p>No history avaliable</p>
		</div>
		<div *ngIf="dataHistory.length > 0">
			<p-timeline [value]="dataHistory" align="alternate" styleClass="customized-timeline">
				<ng-template pTemplate="marker" let-event>
					<span [class]="'custom-marker shadow-2 timeline-badge status-' + event.IDStatus_new">
						<i [ngClass]="event.icon" style="color: white;"></i>
					</span>
				</ng-template>
				<ng-template pTemplate="content" let-event>
					<div class="card">
						<div><b>{{IDStatusDescriptionTimeLine(event)}}</b></div>
						<div>{{dateTimeLine(event)}}</div>
						<!-- <img *ngIf="event.image"
							[src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + event.image"
							[alt]="event.name" width="200" class="shadow-2" />
						<p>
							Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error
							repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa
							ratione quam perferendis esse, cupiditate
							neque quas!
						</p>
						<button pButton label="Read more" class="p-button-text"></button> -->
					</div>
				</ng-template>
			</p-timeline>
		</div>
	</div>
</p-sidebar>

<p-overlayPanel #op [showCloseIcon]="true" styleClass="w-9 sm:w-25rem max-w-11">
	<ng-template pTemplate>
		<div class="text-lg mb-3">
			<b> {{
				'orders.returns.orderstate' | translate }}: </b>
			<span
				[class]="'product-badge status-' + order_exc_detail.financial_status.toLowerCase()">{{order_exc_detail.financial_status}}</span>
		</div>
		<!-- <div class="col-12 lg:col-8 flex align-items-start justify-content-center"> -->
		<p-virtualScroller [value]="order_exc_detail.exc_lines" scrollHeight="180px">
			<ng-template pTemplate="ListItem" let-exc_lines>
				<div class="col-12 mb-3">
					<div class="flex p-0 w-full">
						<div class="w-8rem mr-3"><img [src]="exc_lines.image_url" [alt]="exc_lines.productname"
								class="w-full shadow-2 mr-3" /></div>
						<div class="w-full md:text-left">
							<div class="font-bold text-lg">{{exc_lines.name_aux}}</div>
							<div class="my-1"><span class="align-items-start justify-content-start"><b>
										Sku: </b> {{exc_lines.sku}}</span></div>
							<div class="my-1"><span class="align-items-start justify-content-start"><b>
										{{ 'orders.returns.quantity' | translate }}: </b>
									{{exc_lines.quantity}}</span></div>
							<div *ngIf="exc_lines.product_variant_option1.value != null" class="my-1"><span
									class="align-items-start justify-content-start"><b>
										{{exc_lines.product_variant_option1.key}} : </b>
									{{exc_lines.product_variant_option1.value}}</span></div>
							<div *ngIf="exc_lines.product_variant_option2.value != null" class="my-1"><span
									class="align-items-start justify-content-start"><b>
										{{exc_lines.product_variant_option2.key}} : </b>
									{{exc_lines.product_variant_option2.value}}</span></div>
							<div *ngIf="exc_lines.product_variant_option3.value != null" class="my-1"><span
									class="align-items-start justify-content-start"><b>
										{{exc_lines.product_variant_option3.key}} : </b>
									{{exc_lines.product_variant_option3.value}}</span></div>
							<!-- <div class="my-1"><span class="align-items-start justify-content-start"><b>
										{{ 'orders.returns.size' | translate }}: </b>
									{{exc_lines.size}}</span></div>
							<div class="my-1"><span class="align-items-start justify-content-start"><b>
										{{ 'orders.returns.color' | translate }} : </b>
									{{exc_lines.color}}</span></div> -->
						</div>
					</div>
				</div>
			</ng-template>
		</p-virtualScroller>
	</ng-template>
</p-overlayPanel>
<p-dialog [(visible)]="displayReceive" modal="false" showEffect="fade" [draggable]="false" [resizable]="false"
	[style]="{'width': '90%', 'max-width': '90%', 'max-height': '90%'}">
	<ng-template pTemplate="header">
		<h4>Manually Receive Items</h4>
	</ng-template>
	<div class="w-full flex flex-column lg:flex-row gap-3">
		<div class="w-full flex flex-column gap-3">
			<div class="card w-full">
				<h5>Items Expected</h5>
				<p-dataView class="w-full" #dv [value]="joinedItens.items" [paginator]="false" layout="list">
					<ng-template let-item pTemplate="listItem">
						<div *ngIf="item.Expeted" class="w-full">
							<div id="info-return" class="info-return flex flex-row flex-wrap gap-3 pt-5 pb-5 w-full">
								<div class="flex flex-column sm:flex-row gap-3 w-auto">
									<div id="block-img-product"
										class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
										<img [src]="item.image_url" [alt]="item.title"
											class="w-7rem sm:w-10rem shadow-2" />
									</div>
								</div>
								<div class="flex flex-wrap gap-3 w-auto justify-content-between">
									<div class="md:text-left flex flex-column gap-3 max-w-full">
										<span
											class="align-items-start justify-content-start"><b>{{item.title}}</b></span>
										<div class="flex align-items-center">
											<span class="mr-3 align-self-center md:align-self-end"><b>SKU:
												</b>{{item.SKU}}</span>
										</div>
										<div class="flex align-items-center">
											<span class="mr-3 align-self-center md:align-self-end"><b>EAN:
												</b>{{item.EAN}}</span>
										</div>
										<div class="flex align-items-center">
											<span class="mr-3 align-self-center md:align-self-end"><b>Estimated
													Quantity:
												</b>{{item.quantityLine !== undefined ? item.quantityLine : 0}}</span>
										</div>
										<div class="flex flex-column align-items-start">
											<form>
												<span class="mr-3 align-self-center md:align-self-end"><b>Received
														Quantity:</b></span>
												<div class="flex flex-column mt-2">
													<input type="number" min="0" pInputText id="quantity" ngModel
														name="quantity" #quantityInput="ngModel" quantity
														[ngClass]="{'exceeded-limit': isQuantityExceeded(item)}"
														[(ngModel)]="item.Quantity"
														[title]="quantityTooltip(item.Quantity, item)"
														[placement]="'top'" (change)="onQuantityChange($event, item)" />
													<span *ngIf="quantityTooltip(item.Quantity, item)!==''"
														class="mt-1">
														<img style="width: 20px; height: 20px; margin-right: 5px;"
															src="../../../assets/layout/images/triangle-exclamation-solidV2.svg" />
														{{quantityTooltip(item.Quantity, item)}}</span>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</p-dataView>
			</div>
			<button *ngIf="!unexpected_items_loaded" pButton type="button" (click)="UnexpectedItems()"
				label="Received items that were not in the initial return?"
				class="p-button-secondary p-button-text"></button>
		</div>
		<div *ngIf="unexpected_items_loaded && unexpected_items.length > 0" class="card w-full">
			<h5>Items Not Expected</h5>
			<p-dataView class="w-full" #dv [value]="joinedItens.items" [paginator]="false" layout="list">
				<ng-template let-item pTemplate="listItem">
					<div *ngIf="!item.Expeted" class="w-full">
						<div id="info-return" class="info-return flex flex-row flex-wrap gap-3 pt-5 pb-5 w-full">
							<div class="flex flex-column sm:flex-row gap-3 w-auto">
								<div id="block-img-product"
									class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
									<img [src]="item.image_url" [alt]="item.title" class="w-7rem sm:w-10rem shadow-2" />
								</div>
							</div>
							<div class="flex flex-wrap gap-3 w-auto justify-content-between">
								<div class="md:text-left flex flex-column gap-3 max-w-full">
									<span class="align-items-start justify-content-start"><b>{{item.title}}</b></span>
									<div class="flex align-items-center">
										<span class="mr-3 align-self-center md:align-self-end"><b>SKU:
											</b>{{item.SKU}}</span>
									</div>
									<div class="flex align-items-center">
										<span class="mr-3 align-self-center md:align-self-end"><b>EAN:
											</b>{{item.EAN}}</span>
									</div>
									<div class="flex align-items-center">
										<span class="mr-3 align-self-center md:align-self-end"><b>Estimated Quantity:
											</b>{{item.quantityLine !== undefined ? item.quantityLine : 0}}</span>
									</div>
									<div class="flex flex-column align-items-center">
										<form>
											<span class="mr-3 align-self-center md:align-self-end"><b>Received
													Quantity:</b></span>
											<div class="flex flex-column mt-2">
												<input type="number" min="0" pInputText id="quantity" ngModel
													name="quantity" #quantityInput="ngModel" quantity
													[ngClass]="{'exceeded-limit': isQuantityExceeded(item)}"
													[(ngModel)]="item.Quantity"
													[title]="quantityTooltip(item.Quantity, item)" [placement]="'top'"
													(change)="onQuantityChange($event, item)" />
												<span *ngIf="quantityTooltip(item.Quantity, item)!==''" class="mt-1">
													<img style="width: 20px; height: 20px; margin-right: 5px;"
														src="../../../assets/layout/images/triangle-exclamation-solidV2.svg" />
													{{quantityTooltip(item.Quantity, item)}}</span>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</p-dataView>
		</div>
	</div>
	<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
	<!--"SendReceiveItems()"-->
	<ng-template pTemplate="footer">
		<button pButton icon="pi pi-save" (click)="saveItems()" label="Save" class="p-button-outlined"></button>
	</ng-template>
</p-dialog>
<p-dialog header="{{ 'orders.orders.upload_label' | translate }}" [(visible)]="displayLabel" [modal]="true"
	[style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
	<div class=" field col-12 md:col-12">
		<label htmlFor="label">{{ 'orders.orders.return_label' | translate }} <small>*</small></label>
		<p-fileUpload name="demo[]" accept=".pdf" customUpload="true" [auto]="true" (uploadHandler)="onUpload($event)"
			(onClear)="onRemoveFile()" (onRemove)="onRemoveFile()" chooseLabel="Upload">
		</p-fileUpload>
	</div>
	<div class=" field col-12 md:col-12">
		<label htmlFor="idTracking">{{ 'orders.orders.tracking_number' | translate }}</label>
		<input type="text" pInputText [(ngModel)]="idTracking">
	</div>
	<ng-template pTemplate="content">

	</ng-template>
	<ng-template pTemplate="footer">
		<p-button icon="pi pi-check" (click)="closeDialogLabel()" label="{{ 'general.finalize' | translate }}"
			styleClass="p-button-text">
		</p-button>
		<div class="col-12 md:col-12" style="text-align: left;">
			<small>* {{ 'orders.orders.required_fields' | translate }}</small>
		</div>
	</ng-template>
</p-dialog>
<p-dialog header="{{ 'orders.returns.exchangefornewsize' | translate }}" [(visible)]="displayAcceptExchange"
	[modal]="true" [style]="{width: '500px', maxWidth: '90%'}" [draggable]="false" [resizable]="false"
	class="non-overflow">
	<ng-template pTemplate="content">
		<div id="block-exchange" class="flex flex-column gap-3">
			<span class="customer-badge status-qualified">{{selectedNotExpectedItem.quantityReceived}} {{
				'orders.returns.available' | translate }}</span>
			<div [id]="'choose-size-block-'+x" class="flex gap-3"
				*ngFor="let x of selectedNotExpectedItem.arraySizesCount">
				<div *ngIf="selectedNotExpectedItem?.quantityReceived >= 1" class="flex flex-column">
					<div class="align-items-center flex gap-3">
						<button class="item-click button-quantity-return" (click)="decrementQuantity(x)"
							[disabled]="selectedNotExpectedItem.quantityArrayNotExpectedItem[x] <= 1"
							style="cursor: pointer;">
							<i class="pi pi-minus"></i>
						</button>
						<div class="font-bold">{{selectedNotExpectedItem.quantityArrayNotExpectedItem[x]}}
						</div>
						<button class="item-click button-quantity-return" (click)="incrementQuantity(x)" [disabled]="selectedNotExpectedItem.quantityArrayNotExpectedItem[x] >= selectedNotExpectedItem.quantityReceived || 
							selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem >= selectedNotExpectedItem.quantityReceived"
							style="cursor: pointer;">
							<i class="pi pi-plus"></i>
						</button>
					</div>
				</div>
				<div class="option-return w-full flex flex-column gap-3">
					<p-dropdown appendTo="body" [options]="variantQuantities" [(ngModel)]="variantSizeSelect[x]"
						placeholder="{{ 'orders.returns.selectnewsize' | translate }}"
						optionLabel="product_variant_option2.value" [showClear]="false" [optionDisabled]="disabled"
						[disabled]="!variantQuantities" class="dropdownDialog"
						[ngClass]="{'ng-invalid ng-dirty' : submittedDialog && !selectedNotExpectedItem.variantSizeSelect[x]}"></p-dropdown>
				</div>
				<i class="pi pi-trash flex align-items-center cursor-pointer" (click)="deleteSizeSelect(x)"></i>
			</div>
			<div *ngIf="selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem < selectedNotExpectedItem.quantityReceived"
				class="flex justify-content-center">
				<p-button (onClick)="newSize()" icon="pi pi-plus" label="{{ 'orders.returns.addmore' | translate }}"
					class="button-add-more"></p-button>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<p-button icon="pi pi-check" (click)="exchangeItem()" label="{{ 'orders.returns.exchange' | translate }}"
			styleClass="p-button-text">
		</p-button>
	</ng-template>
</p-dialog>
<p-dialog header="{{ 'orders.returns.refundthisitem' | translate }}" [(visible)]="displayAcceptRefund" [modal]="true"
	[style]="{width: '500px', maxWidth: '90%'}" [draggable]="false" [resizable]="false" class="non-overflow">
	<ng-template pTemplate="content">
		<div class="flex flex-column gap-3">
			<span class="customer-badge status-qualified">{{selectedNotExpectedItem.quantityReceived}} {{
				'orders.returns.available' | translate }}</span>
			<div class="flex flex-column gap-6">
				<div class="flex gap-3">
					<div *ngIf="selectedNotExpectedItem?.quantityReceived >= 1" class="flex flex-column">
						<div class="align-items-center flex gap-3">
							<button class="item-click button-quantity-return" (click)="decrementQuantity()"
								[disabled]="selectedNotExpectedItem.quantityNotExpectedItem <= 1"
								style="cursor: pointer;">
								<i class="pi pi-minus"></i>
							</button>
							<div class="font-bold">{{selectedNotExpectedItem.quantityNotExpectedItem}}
							</div>
							<button class="item-click button-quantity-return" (click)="incrementQuantity()"
								[disabled]="selectedNotExpectedItem.quantityNotExpectedItem >= selectedNotExpectedItem.quantityReceived"
								style="cursor: pointer;">
								<i class="pi pi-plus"></i>
							</button>
						</div>
					</div>
					<div class="option-return w-full">
						<p-dropdown appendTo="body" [options]="optionsRefund" [(ngModel)]="optionSelect" class="dropdownDialog"
							placeholder="{{ 'orders.returns.selectoptionrefund' | translate }}" optionLabel="option"
							[showClear]="false"
							[ngClass]="{'ng-invalid ng-dirty' : submittedDialog && !optionSelect}"></p-dropdown>
					</div>
				</div>
				<div class="summary-block">
					<div class="flex-1 align-items-start justify-content-start" *ngIf="all_exchange == false">
						<div class="flex justify-content-between mb-2">
							<span class="flex align-items-start justify-content-start font-bold">{{
								'orders.returns.subtotal' | translate }}</span>
							<span class="flex align-items-center justify-content-end">{{subtotalRefundNotExpectedItem}}
								{{return.currency}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<p-button icon="pi pi-check" (click)="refundItem()" label="{{ 'orders.returns.refund' | translate }}"
			styleClass="p-button-text">
		</p-button>
	</ng-template>
</p-dialog>
<p-dialog header="Edit tracking" [(visible)]="editTrackingDialog" [modal]="true" [style]="{maxWidth: '90%'}"
	[style]="{width: '500px', maxWidth: '90%'}" [draggable]="false" [resizable]="false">
	<ng-template pTemplate="content">
		<div class="flex w-full gap-3 relative mb-3">
			<div class="w-full relative mb-3">
				<span for="tracking" class="flex mb-2">Tracking number</span>
				<input id="tracking" [(ngModel)]="new_tracking_id" type="text" pInputText class="p-inputtext w-full">
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<button pButton pRipple type="button" label="Cancel" class="p-button-outlined p-button-danger"
			(click)="editTrackingDialog = false"></button>
		<button pButton pRipple label="Save" type="submit" (click)="onSubmitTracking()"></button>
	</ng-template>
</p-dialog>
<p-dialog header="{{ 'orders.returns.resell_items.resell_items' | translate }}" [(visible)]="resellItemsDialog"
	[modal]="true" [style]="{maxWidth: '90%'}" [draggable]="false" [resizable]="false" class="simple-table">
	<ng-template pTemplate="content">
		<div class="flex flex-column relative gap-2"
			[ngClass]="{'max-w-full w-30rem': resellItems.return2hand.length == 0}">
			<div *ngIf="resellItems.return2hand.length == 0"
				class="w-full flex justify-content-center align-items-center">
				{{ 'orders.returns.resell_items.no_products_resell' | translate }}
			</div>
			<div *ngIf="resellItems.return2hand.length > 0" class="flex flex-column gap-3">
				<div class="p-3 bg-yellow-100 text-yellow-600 flex align-items-center gap-1">
					<i class="pi pi-info-circle"></i>
					<div>{{ 'orders.returns.resell_items.products_created_warn' | translate }}</div>
				</div>
				<p-table [value]="resellItems.return2hand" [(selection)]="selectedResellItems" dataKey="id_return_line"
					responsiveLayout="stack" class="non-overflow">
					<ng-template pTemplate="header">
						<tr>
							<th style="width: 3rem">
								<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
							</th>
							<th class="px-2">{{ 'orders.returns.resell_items.product' | translate }}</th>
							<th class="px-2">{{ 'orders.returns.resell_items.photos' | translate }}</th>
							<th class="px-2">{{ 'orders.returns.resell_items.quantity' | translate }}</th>
							<th class="px-2">{{ 'orders.returns.resell_items.condition' | translate }}</th>
							<th class="px-2">{{ 'orders.returns.resell_items.price_resell' | translate }}</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-returnline>
						<tr>
							<td style="display: table-cell; width: min-content !important;">
								<p-tableCheckbox [value]="returnline"></p-tableCheckbox>
							</td>
							<td class="px-2 h-full">
								<div class="py-3 w-15rem h-full flex gap-3 align-items-center">
									<img [src]="returnline.image_url" [alt]="returnline.title"
										class="w-3rem shadow-2" />
									<div class="h-full flex flex-column gap-2">
										<span>{{returnline.sku}}</span>
										<span>{{returnline.color}} / {{returnline.size}}</span>
										<span>{{returnline.price}} {{return.currency}}</span>
									</div>
								</div>
							</td>
							<td class="w-full px-2 gap-3">
								<div class="w-full flex flex-wrap gap-2 align-items-end">
									<div *ngFor="let photo of returnline.photos" class="relative image-uploaded">
										<img  src="{{'data:image/png;base64,' + photo}}" class="w-auto" style="height: 2.6rem;" />
										<button (click)="onRemovePhotoResell(returnline, photo)"
											class="absolute right-0 top-0 p-1 h-full w-full cursor-pointer hidden">
											<i class="pi pi-trash"></i>
										</button>
									</div>
									<p-fileUpload #photosResellUpload name="demo[]" mode="basic" accept="image/*"
										customUpload="true" multiple="multiple" [auto]="true" chooseLabel="Add photo"
										(uploadHandler)="uploadPhotosResell($event, returnline, photosResellUpload)"
										[disabled]="!selectedResellItems.includes(returnline)" class="w-auto">
									</p-fileUpload>
								</div>
							</td>
							<td class="px-2 gap-3">
								<p-inputNumber [(ngModel)]="returnline.quantityResell"
									[inputId]="returnline.quantityResell" [showButtons]="true" mode="decimal"
									suffix=" {{ 'orders.returns.resell_items.piece' | translate}}" [min]="1"
									class="table-column-full w-10rem" [max]="returnline.quantity"
									[disabled]="!selectedResellItems.includes(returnline)">
								</p-inputNumber>
							</td>
							<td class="relative px-2 gap-3">
								<p-dropdown appendTo="body" [options]="resellItems._2handcondictions" [(ngModel)]="returnline.condition"
									(onChange)="changeCondition(returnline)" class="flex table-column-full w-12rem drop-full"
									placeholder="Select a condition" optionLabel="description" [showClear]="false"
									[disabled]="!selectedResellItems.includes(returnline)"></p-dropdown>
							</td>
							<td class="px-2 w-full gap-3">
								<div class="table-column-full w-10rem flex flex-row gap-2 align-items-center">
									<p-inputNumber [(ngModel)]="returnline.resellPrice" inputId="returnline.resellPrice"
										class="w-full" [showButtons]="true" mode="currency" [currency]="return.currency"
										[disabled]="!selectedResellItems.includes(returnline)">
									</p-inputNumber>
									<i pTooltip="{{ 'orders.returns.resell_items.price_resell_explain' | translate: {price:returnline.price,
									condition:returnline.condition.description,
									resellPrice:returnline.price*returnline.condition.percentage_price/100,
									condition_percentage:returnline.condition.percentage_price} }}" class="pi pi-question-circle"></i>
								</div>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<button pButton pRipple type="button" label="{{ 'general.cancel' | translate }}"
			class="p-button-outlined p-button-danger" (click)="resellItemsDialog = false"></button>
		<button pButton pRipple label="{{ 'general.save' | translate }}" type="submit"
			(click)="submit2HandProducts()" [disabled]="resellItems.return2hand.length == 0 || selectedResellItems.length == 0"></button>
	</ng-template>
</p-dialog>