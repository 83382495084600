<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<p-toast position="top-right" [baseZIndex]="10000"></p-toast>
<div *ngIf="!loading" class="h-full">
	<p-contextMenu #cm [model]="items"></p-contextMenu>
	<p-table #dtorders [value]="ordersBrands" dataKey="id" [rowHover]="true" [columns]="selectedColumns"
		(onPage)="onPageChange($event)" (contextmenu)="onContextMenuSelect(selectedOrder)"
		[(contextMenuSelection)]="selectedOrder" [contextMenu]="cm" [paginator]="true" [resizableColumns]="true"
		[reorderableColumns]="true"
		[globalFilterFields]="['id','order_name','shipping_address.name','shipping_address.address1','shipping_address.country','tracking', 'archivedwms', 'errorwms']"
		responsiveLayout="scroll" [rows]="25" [rowsPerPageOptions]="[25,50,100]" [showCurrentPageReport]="true"
		[(selection)]="selectedOrders" exportFilename="orders" [loading]="loading"
		currentPageReportTemplate="{{ 'orders.orders.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
		<ng-template pTemplate="caption">
			<div class="flex justify-content-between gap-2 flex-wrap">
				<div class="flex flex-wrap gap-2">
					<div style="position: relative;">
						<p-calendar [showIcon]="true" [(ngModel)]="rangeDates" selectionMode="range"
							dateFormat="dd/mm/yy" (onClose)="ngOnInit()" [minDate]="minimumDate">
						</p-calendar>
						<!--<div *ngIf="isCalendarDisabled" style="position: absolute; top: 0; left: 0; /*width: calc(100% - 42px);*/ width: 100%; height: 100%; background-color: white; z-index: 100; cursor: pointer;" class="flex flex-wrap justify-content-center align-items-center" (click)="teste()">
						<div >Click to enable calendar</div>
						
					  </div>-->
					</div>

					<span class="p-input-icon-left w-full sm:w-auto">
						<i class="pi pi-search"></i>
						<input pInputText type="text" #filter
							(input)="dtorders.filterGlobal($event.target.value, 'contains')"
							placeholder="{{ 'general.search_here' | translate }}" />
					</span>

					<div *ngIf="[6,7].includes(idBrand)" class="flex align-items-center gap-1 lx:col-12">
						<p-checkbox [(ngModel)]="getFulfilled" [binary]="true" inputId="binary"
							(onChange)="changeFulfilled()">
						</p-checkbox>
						<label style="font-weight: 400;" for="fulfilled">{{ 'general.include_fulfilled_orders' |
							translate }}</label>
					</div>
					<div class="flex align-items-center gap-1 lx:col-12">
						<p-checkbox [(ngModel)]="getRTO" [binary]="true" inputId="binary"
							(onChange)="ngOnInit()">
						</p-checkbox>
						<label style="font-weight: 400;" for="fulfilled">RTO</label>
					</div>
				</div>
				<div class="flex gap-2 align-items-end">
					<button *ngIf="(idBrand == 6 ) " pButton pRipple label="{{ 'orders.orders.manifest' | translate }}"
						class="p-button-outlined" (click)="openModalManifest()">
						<i class="pi pi-print mr-2" style="font-size: 16px;"></i>

					</button>
					<button pButton pRipple label="Export" class="p-button-outlined" (click)="exportCSV()">
						<span class="p-button-icon p-button-icon-left flex"><i class="pi pi-file-excel"></i></span>
					</button>
				</div>


			</div>
			<!--<div style="padding-top: 44px;"  class="flex  align-items-end justify-content-end">
			<div style="width: 100%; height: 40px; padding-bottom: -157px !important; position: absolute !important; padding-bottom: -20px !important;
			padding-top: -20px !important; margin-bottom: -34px !important;">
				<div style="width: 100%; height: 100%;" class=" border-round flex align-items-end justify-content-end" >
                    <p-button class="sizeButt" type="button" pButton pRipple style="color: black;" icon="pi pi-bars"></p-button>
                </div> 
				<hr>
			</div>
			</div>-->
		</ng-template>
		<ng-template pTemplate="header" let-columns>

			<tr>
				<th style="width: 1rem">
					<p-tableHeaderCheckbox (click)="mainCheckboxClick()"></p-tableHeaderCheckbox>
				</th>

				<th pResizableColumn pReorderableColumn [pSortableColumn]="col.sort ? col.field : null"
					*ngFor="let col of columns" class="cursor-move">
					<div class="flex align-items-center"
						[ngClass]="{'cursor-pointer' : col.sort || col.filter, 'p-column-filter-menu': col.filter }">
						{{col.header}}
						<p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
						<i *ngIf="col.filter && activeStatus == null" class="pi pi-filter"
							(click)="openOverlay($event, op)"></i>
						<i *ngIf="col.filter && activeStatus != null" class="pi pi-filter-fill"
							(click)="openOverlay($event, op)"></i>
					</div>
				</th>
				<th style="width: 1rem; border-left: none;">
					<img src="assets/layout/images/config-icon.svg" (click)="openOverlay($event, op2)"
						class="cursor-pointer">
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-item let-columns="columns" let-lastlineTable="last">
			<div *ngIf="loadingNewItens">{{lastRow(item)}}</div>
			<ng-container *ngIf="!loadingNewItens">

				<tr [pContextMenuRow]="item" (click)="openDialog(item)" class="cursor-pointer">
					<td>
						<!--<hr *ngIf="item.order_status === 'cancelled'" class="draw-red-line"> {{teste(columns)}}-->
						<p-tableCheckbox [value]="item" (click)="checkboxClick($event)"></p-tableCheckbox>
					</td>
					<td *ngFor="let col of columns">
						<!--<hr *ngIf="item.order_status === 'cancelled' && col.field!=undefined && col.field=='order_name'" class="draw-red-line"> {{teste(col)}}-->
						<!--	<hr  [id]="'hr-' + col.field" *ngIf="item.order_status === 'cancelled'" [class]="changePosLine(item[col.field], col.field, col)" class="draw-red-line">-->

						<div *ngIf="col.field === 'date'">
							<hr *ngIf="item.order_status === 'cancelled'" class="draw-line">
							{{ item.date | date:'MMM d, h:mm a' }}
						</div>
						<!--<div class="draw-lines" *ngIf="col.field === 'financial_status' && item.financial_status==='Paid'"  >
					</div>
					<div class="draw-lineUn" *ngIf="col.field === 'financial_status' && item.financial_status==='Unpaid'"  >
					</div>-->
						<div class="draw-lines"
							*ngIf="col.field === 'financial_status' && item.financial_status==='Paid'">
							{{item.financial_status}}
						</div>
						<div class="draw-lineUn"
							*ngIf="col.field === 'financial_status' && item.financial_status==='Unpaid'">
							{{item.financial_status}}
						</div>
						<div *ngIf="col.field != 'order_name' && col.field != 'documents' && col.field !== 'date'">
							<hr *ngIf="item.order_status === 'cancelled' && col.field!= 'tracking'" class="draw-line">
							<hr *ngIf="item.order_status === 'cancelled' && col.field== 'tracking'"
								style="top: 1px; margin-top: 15px; width: 50%; height: 1.5px;" class="draw-line">
							{{item[col.field]}}
						</div>
						<a *ngIf="col.field == 'order_name'">
							<hr *ngIf="item.order_status === 'cancelled'" class="draw-line">
							{{item[col.field]}}
						</a>
						<div *ngIf="col.field == 'documents'">
							<a *ngIf="idBrand == 1" (click)="requestDocuments($event, item)">Download</a>
							<div *ngIf="idBrand != 1">Unavailable</div>
						</div>
						<div *ngIf="col.field == 'wmsStatus'">
							<!-- <div *ngIf="item.archivedwms == 1 && item.errorwms == 0">
								<i title="WMS Success" class="pi pi-exclamation-triangle text-green-600"></i>
							</div> -->
							<div *ngIf="item.archivedwms == 0 && item.errorwms == 1">
								<i title="WMS Error" class="pi pi-exclamation-triangle text-red-600"></i>
							</div>
						</div>
					</td>
					<td></td>
				</tr>

			</ng-container>
			<ng-container *ngIf="loadingNewItens">
				<ng-container *ngIf="lastItem.id== this.ordersBrandsAux[this.ordersBrandsAux.length-1].id">
					<ng-container *ngFor="let i of createRowsArr">
						<tr>
							<td style="border-color: transparent; pointer-events: none;">
								<div class="flex justify-content-center align-items-center">
									<div></div>
								</div>
							</td>
						</tr>
					</ng-container>


					<tr>
						<td style="border-color: transparent; pointer-events: none;">
							<div class="flex justify-content-center align-items-center">
								<!--//get row to show only one loading icon in page loading shopify-->
								<!--<div *ngIf="lastItem.id!= this.ordersBrandsAux[this.ordersBrandsAux.length-1].id" class="loading" style="background-image: url('');" ></div>-->
								<div *ngIf="lastItem.id== this.ordersBrandsAux[this.ordersBrandsAux.length-1].id"
									class="loading"></div>
							</div>
						</td>
					</tr>
				</ng-container>

			</ng-container>
		</ng-template>

		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="12">{{ 'general.no_data' | translate }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr>
				<td colspan="12">{{ 'general.loading' | translate }}</td>
			</tr>
		</ng-template>
	</p-table>
</div>
<p-overlayPanel #op2 (onHide)="hideOverlay()" (onShow)="showOverlay()">
	<ng-template pTemplate>
		<div class="flex flex-column gap-3">
			<h6>CUSTOMIZE COLUMNS</h6>
			<div>
				<div *ngFor="let col of colsOptional" class="field-checkbox">
					<p-checkbox name="columns" value="col" [value]="col" [(ngModel)]="selectedColumnsAux"
						[inputId]="col.field"></p-checkbox>
					<label [for]="col.field">{{col.header}}</label>
				</div>
			</div>
		</div>
		<div class="mt-6 flex">
			<p-button icon="pi pi-replay" (click)="revert($event, op2)" label="Revert to default"
				styleClass="p-button-text"></p-button>
			<p-button label="Customize" (click)="customize($event, op2)"></p-button>
		</div>
	</ng-template>
</p-overlayPanel>
<p-dialog [(visible)]="orderDialog" [style]="{width: 'auto', maxWidth: '90%', height: '100%'}" id="order_details"
	[modal]="true" [draggable]="false" [resizable]="false" [dismissableMask]="true">
	<ng-template pTemplate="content">
		<div class="flex flex-column pb-6">
			<div class="card">
				<div class="flex flex-wrap justify-content-between align-items-center mb-3">
					<div class="flex gap-2">
						<div>
							<h5>Order {{orderBrand.order_name}}</h5>
						</div>
						<div *ngIf="orderBrand.isLocalPickup" [class]="'pickup-badge local-pickup'">
							local pick-up
						</div>
						<div *ngIf="orderBrand.order_status" [class]="'order-badge status-' + orderBrand.order_status">
							{{orderBrand.order_status}}
						</div>
						<div *ngIf=" idBrand==6 || idBrand==7"
							[class]="'order-badge status-' + orderBrand.financial_status">
							{{orderBrand.financial_status}}
						</div>
						<div *ngIf="orderBrand.rto" class="labelStatus status-error-bold">
							RTO
						</div>
						<div *ngIf="orderBrand.archivedwms == 1 && orderBrand.errorwms == 0">
							<div [class]="'order-badgewms status-' + orderBrand.archivedwms">WMS Success</div>
						</div>
						<div *ngIf="orderBrand.archivedwms == 0 && orderBrand.errorwms == 1">
							<div [class]="'order-badgewms status-' + orderBrand.archivedwms">WMS Error</div>
						</div>
					</div>
					<div class="flex flex-wrap gap-2">

						<button *ngIf="(orderBrand.isLocalPickup == true && idBrand==4)" pButton pRipple
							label="Send to  warehouse" class="p-button-warehouse"
							(click)="sendWarehouse(orderBrand.id)">
							<span class="p-button-icon p-button-icon-left flex"><img
									src="assets/layout/images/box.svg"></span>
						</button>
						<button
							*ngIf="(([1, 8].includes(idBrand) && orderBrand.financial_statusAux2?.toLowerCase() != 'pending' && orderBrand.fulfillment_status?.toLowerCase() != 'fulfilled' && orderBrand.refunds?.length == 0 )) || ([6,7].includes(idBrand)  && ((orderBrand.financial_statusAux2?.toLowerCase( )!='pending' && orderBrand.fulfillment_status?.toLowerCase( )!='fulfilled' && !orderBrand.tags.includes('INVOICE')) || (orderBrand.financial_statusAux2?.toLowerCase( )!='pending' && orderBrand.fulfillment_status!=null && !orderBrand.tags.includes('INVOICE'))) && orderBrand.discount_ambar)"p
							pButton pRipple
							label="{{(([6,7].includes(idBrand) && orderBrand.financial_statusAux2?.toLowerCase( )!='pending'.toLowerCase( ) && orderBrand.fulfillment_status?.toLowerCase( )!='fulfilled'.toLowerCase( )) || ([ 1, 8].includes(idBrand) && orderBrand.label == 0  && orderBrand.financial_statusAux2?.toLowerCase( )!='pending'.toLowerCase( ) && orderBrand.fulfillment_status?.toLowerCase( )!='fulfilled'.toLowerCase( ))  ) ? 'Generate shipping label' : 'Download shipping label'}}"
							class="p-button-outlined"
							(click)="(([6,7].includes(idBrand) && orderBrand.financial_statusAux2?.toLowerCase( )!='pending'.toLowerCase( ) && orderBrand.fulfillment_status?.toLowerCase( )!='fulfilled'.toLowerCase( )) || ([ 1, 8].includes(idBrand) && orderBrand.label == 0  && orderBrand.financial_statusAux2?.toLowerCase( )!='pending'.toLowerCase( ) && orderBrand.fulfillment_status?.toLowerCase( )!='fulfilled'.toLowerCase( ))  ) ? openDialogLabel(orderBrand) : requestLabel(orderBrand.id, orderBrand.order_name)">
							<span class="p-button-icon p-button-icon-left flex"><img
									src="assets/layout/images/export-icon.svg"></span>
						</button>
						<div *ngIf="(orderBrand.isLocalPickup != true && idBrand == 4)">
							<button
								*ngIf="( [4].includes(idBrand) && ((orderBrand.financial_statusAux2?.toLowerCase( )!='pending' && orderBrand.fulfillment_status?.toLowerCase( )!='fulfilled' && orderBrand.refunds?.length == 0) )) || ([4].includes(idBrand) && (orderBrand.fulfillment_status == 'processing' && orderBrand._moloni_sent || orderBrand.fulfillment_status == 'completed'  && orderBrand._moloni_sent))"
								pButton pRipple
								label="{{(([4].includes(idBrand) && orderBrand.fulfillment_status== 'processing' && orderBrand._moloni_sent )  ) ? 'Generate shipping label' : 'Download shipping label'}}"
								class="p-button-outlined"
								(click)="(([4].includes(idBrand)&& orderBrand.fulfillment_status== 'processing' && orderBrand._moloni_sent )  ) ? openDialogLabel(orderBrand) : requestLabel(orderBrand.id, orderBrand.order_name)">
								<span class="p-button-icon p-button-icon-left flex"><img
										src="assets/layout/images/export-icon.svg"></span>
							</button>
						</div>
						<button *ngIf="(idBrand == 6 || idBrand == 7) && orderBrand.financial_status=='Paid'" pButton
							pRipple label="Invoice" class="p-button-outlined" (click)="getInvoice(orderBrand.id)">
							<span class="p-button-icon p-button-icon-left flex"><img
									src="assets/layout/images/export-icon.svg"></span>
						</button>
						<button *ngIf="(orderBrand.pod == 1 && idBrand == 1) || (orderBrand.pod != 0 && idBrand==4)"
							pButton pRipple label="POD" class="p-button-outlined"
							(click)="requestPOD(orderBrand.id, orderBrand.order_name)">
							<span class="p-button-icon p-button-icon-left flex"><img
									src="assets/layout/images/export-icon.svg"></span>
						</button>
						<button
							*ngIf="[1].includes(idBrand) == true || (orderBrand._moloni_sent != undefined && idBrand==4)"
							pButton pRipple label="Proforma" class="p-button-outlined"
							(click)="requestProforma(orderBrand.id, orderBrand.order_name, orderBrand._moloni_sent)">
							<span class="p-button-icon p-button-icon-left flex"><img
									src="assets/layout/images/export-icon.svg"></span>
						</button>

					</div>
				</div>
				<div class="grid">
					<div class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>Order ID:</b> {{orderBrand.id}}</p>
					</div>
					<div class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>{{ 'orders.orders.name' | translate }}:</b> {{orderBrand.shipping_address.name}}
						</p>
					</div>
					<div class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>{{ 'orders.orders.email' | translate }}:</b> <a href="mailto:{{orderBrand.email}}"
								(click)="$event.stopPropagation()"> {{orderBrand.email}}</a>
							<p-button (click)="copiedToclipboard()" icon="pi pi-copy"
								styleClass="h-auto w-auto p-0 text-color p-button-rounded p-button-text" ngxClipboard
								[cbContent]="orderBrand.email"></p-button>
						</p>
					</div>
					<div class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>{{ 'orders.orders.date' | translate }}:</b> {{orderBrand.date | date:'MMM d, h:mm a'}}</p>
					</div>
					<div class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>{{ 'orders.orders.address' | translate }}:</b>
							{{orderBrand.shipping_address.address1}}</p>
					</div>
					<div class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>{{ 'orders.orders.zipcode' | translate }}:</b> {{orderBrand.shipping_address.zip}}
						</p>
					</div>
					<div class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>{{ 'orders.orders.city' | translate }}:</b> {{orderBrand.shipping_address.city}}</p>
					</div>
					<div class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>{{ 'orders.orders.country' | translate }}:</b>
							{{orderBrand.shipping_address.country}}</p>
					</div>
					<div class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>{{ 'orders.orders.phone' | translate }}:</b> {{orderBrand.shipping_address.phone}}</p>
					</div>
					<div class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>{{ 'orders.orders.carrier' | translate }}:</b> {{orderBrand.carrier}}</p>
					</div>
					<div class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>{{ 'orders.orders.tracking' | translate }}:</b> <a href="{{orderBrand.carrier_link}}"
								target="_blank"> {{orderBrand.tracking}}</a></p>
					</div>
					<div *ngIf="orderBrand.payment_gateway_names.length>0" class="col-12 sm:col-6 lg:col-2.5 pr-0">
						<p><b>{{ 'orders.orders.payment_method' | translate }}:</b>
							{{orderBrand.payment_gateway_names.join(', ')}}</p>
					</div>

				</div>
			</div>
			<div>
				<div class="returns-summary flex flex-column gap-3">
					<div class="w-full flex flex-column md:flex-row gap-3">
						<div class="returns-block card" [ngClass]="{'w-full' : return?.order_name?.includes('exc.')}">
							<h5>Items</h5>
							<div class="flex align-items-start justify-content-center">
								<p-dataView class="w-full" [value]="orderBrand.line_items" [paginator]="false"
									layout="list">
									<ng-template let-orderline pTemplate="listItem">
										<div class="col-12">
											<div class="flex flex-column md:flex-row pt-3 pb-3 w-full">
												<div>
													<img [src]="orderline.image_link" [alt]="orderline.sku" width="110"
														class="my-4 md:my-0 shadow-2 mr-5" />
												</div>
												<div class="flex flex-column gap-2">
													<div><b>{{orderline.title}}</b></div>
													<div>{{orderline.sku}}</div>
													<div>{{orderline.variant_title}}</div>
													<div>{{orderline.price_set.presentment_money.amount}}
														{{orderline.price_set.presentment_money.currency_code}}</div>
													<div>x{{orderline.quantity}}</div>
												</div>
											</div>
										</div>
									</ng-template>
								</p-dataView>
							</div>
						</div>
						<div class="card summary-block">
							<div class="flex-1 align-items-start justify-content-start">
								<div class="flex justify-content-between flex-wrap mb-3">
									<span class="flex align-items-center justify-content-center font-bold text-xl">{{
										'orders.returns.summary' | translate }}</span>
								</div>
								<div class="flex justify-content-between flex-wrap mb-2">
									<span class="flex align-items-start justify-content-start">{{
										'orders.returns.subtotal' | translate }}</span>
									<span class="flex align-items-center justify-content-end">{{orderBrand.subtotal}}
										{{orderBrand.current_subtotal_price_set.presentment_money.currency_code}}</span>
								</div>
								<div class="flex justify-content-between flex-wrap mb-2">
									<span class="flex align-items-start justify-content-start">{{
										'orders.returns.discount' | translate }}</span>
									<span
										class="flex align-items-center justify-content-end">-{{orderBrand.totalDiscount}}
										{{orderBrand.current_subtotal_price_set.presentment_money.currency_code}}</span>
								</div>
								<div class="flex justify-content-between flex-wrap mb-2">
									<span class="flex align-items-start justify-content-start">{{
										'orders.returns.shippingcost' | translate }}</span>
									<span
										class="flex align-items-center justify-content-end">{{orderBrand.shipping_total}}
										{{orderBrand.current_subtotal_price_set.presentment_money.currency_code}}</span>
								</div>
								<div class="flex justify-content-between flex-wrap mt-3 mb-2">
									<span class="flex align-items-start justify-content-start font-bold">{{
										'orders.orders.total' | translate }}</span>
									<span class="flex align-items-center justify-content-end">{{orderBrand.total}}
										{{orderBrand.current_subtotal_price_set.presentment_money.currency_code}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</p-dialog>


<p-dialog [(visible)]="labelDialog" [style]="{width: 'auto', maxWidth: '90%', height: 'auto', minWidth: '30%'}"
	id="label_ambar" [modal]="true" [draggable]="false" [resizable]="false" [dismissableMask]="true"
	(onHide)="labelOnHide()">
	<ng-template pTemplate="content">
		<div class="col-12 md:col-12">
			<h4>{{ 'orders.orders.Get_your_label' | translate }}</h4>
			<div class="card">
				<div class="flex flex-wrap justify-content-center mb-3 mt-4">
					<div>
						<div class="col-12 flex">
							<div class="w-full flex-column mb-3">
								<h6 for="current_password" class="flex mb-2">{{ 'orders.orders.number_boxes' | translate
									}}</h6>
								<input pInputText pInputText style="height: 36px;" type="number" id="decimalInput"
									step="1" min="0" required [(ngModel)]="inputNBoxes"
									[ngClass]="{'ng-invalid ng-dirty' : generatedLabel && (!inputNBoxes || inputNBoxes <= 0)}">
								<small *ngIf="generatedLabel && (!inputNBoxes || inputNBoxes <= 0)"
									class="p-error absolute flex gap-1 mt-2"><img
										src="assets/layout/images/error-icon.svg">{{
									'personal_area.marketplace.required_field' | translate }}</small>
							</div>
						</div>
						<div class="col-12 flex">
							<div class="w-full flex-column mb-3">
								<h6 for="current_password" class="flex mb-2">{{ 'orders.orders.insert_weight'
									| translate }}</h6>
								<input pInputText pInputText style="height: 36px;" type="number" id="decimalInput"
									step="1" min="0" required [(ngModel)]="inputWeight"
									[ngClass]="{'ng-invalid ng-dirty' : generatedLabel && (!inputWeight || inputWeight <= 0)}">
								<small *ngIf="generatedLabel && (!inputWeight || inputWeight <= 0)"
									class="p-error absolute flex gap-1 mt-2"><img
										src="assets/layout/images/error-icon.svg">{{
									'personal_area.marketplace.required_field' | translate }}</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex flex-wrap justify-content-between">
			<div class="flex justify-content-start mb-3 mr-3">
				<button class="close buttLabel" type="button" pButton pRipple icon="pi pi-times"
					label="{{ 'orders.orders.cancel' | translate }}" (click)="labelOnHide()"></button>
			</div>
			<div class="flex mb-3" style="justify-content: flex-end;">
				<button class="buttLabel" type="button" pButton pRipple icon="pi pi-check"
					label="{{ 'orders.orders.send' | translate }}"
					(click)="generateLabel(orderBrand.id, orderBrand.order_name)"></button>
			</div>
		</div>

	</ng-template>
</p-dialog>





<p-dialog [(visible)]="manifestDialog" [style]="{width: 'auto', maxWidth: '40%', height: 'auto', minWidth: '30%'}"
	id="manifest_ambar" [modal]="true" [draggable]="false" [resizable]="false" [dismissableMask]="true">
	<ng-template pTemplate="content">
		<div class="col-12 md:col-12 mb-2">
			<h6 class="flex flex-wrap justify-content-center">{{ 'orders.orders.manifest_dialog' | translate }}</h6>

		</div>
		<div class="flex ">

			<div style="width: 49%;" class=" mb-3 mr-2">
				<button style="width: 100%;" class="close buttLabel" type="button" pButton pRipple icon="pi pi-times"
					label="{{ 'orders.orders.no' | translate }}" (click)="manifestDialog=false"></button>
			</div>
			<div class="flex mb-3 ml-2" style="justify-content: flex-end; width: 49%;">
				<button style="width: 100%;" class="buttLabel" type="button" pButton pRipple icon="pi pi-check"
					label="{{ 'orders.orders.yes' | translate }}" (click)="downloadManifest()"></button>
			</div>
		</div>

	</ng-template>
</p-dialog>