import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { PurchaseOrdersComponent } from './purchase_orders.component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FileUploadModule,
        InputTextModule,
        TableModule,
        ToolbarModule,
        DropdownModule,
        BreadcrumbModule,
        TranslateModule,
        DialogModule,
        ToastModule,
        SidebarModule,
        AutoCompleteModule,
        SelectButtonModule
    ],
    declarations: [
        PurchaseOrdersComponent
    ],
    exports: [
        PurchaseOrdersComponent
    ],
    providers: [
        MessageService
    ]

})
export class PurchaseOrdersModule { }
