import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from '../../app.main.component';

@Component({
  selector: 'pages-footer',
  templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

    constructor(
        public appMain: AppMainComponent
    ) { }

  ngOnInit() {
  }

}
