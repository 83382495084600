import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root',
})
export class ReturnService {
    constructor(private http: HttpClient) { }

    async getReturns(idBrand: any, dateI: any, dateF: any) {
        let data = await this.http
            .get<any>(
                AUTH_API +
                'return?id_brand=' + idBrand + '&inicial_date=' +
                dateI +
                '&final_date=' +
                dateF
            )
            .toPromise();
        return data;
    }

    async getAllReturns() {
        let data = await this.http.get<any>(AUTH_API + 'return/allReturns').toPromise();
        return data;
    }

    async getTotalAndPendingReturns(idBrand: any, id_status: any) {
        let data = await this.http.get<any>(AUTH_API + 'return/totalAndPendingReturns?id_brand=' + idBrand + '&id_status=' + id_status).toPromise();
        return data;
    }

    async getReturnStatus() {
        let data = await this.http
            .get<any>(AUTH_API + 'return/returnstatus')
            .toPromise();
        return data;
    }

    async getReturnLines(id_return: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'return/returnlines?id_return=' + id_return)
            .toPromise();
        return data;
    }

    async putReturnLinesStatus(lines: any) {
        let data = await this.http
            .put<any>(AUTH_API + 'return/linesStatus', lines)
            .toPromise();
        return data;
    }

    async postCommentReturn(comments: any) {
        let data = await this.http
            .post<any>(AUTH_API + 'return/commentsPost', comments)
            .toPromise();
        return data;
    }


    async getCommentReturn(id_return: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'return/comments?id_return=' + id_return)
            .toPromise();
        return data;
    }

    async deleteCommentReturn(comment: any) {
        let result = await this.http
            .delete<any>(AUTH_API+'return/comments?id_comment='+comment.id_comment)
            .toPromise();
        return result;
    }

    async postEmailReturnLabel(return_data: any) {
        let data = await this.http
            .post<any>(AUTH_API + 'return/emailreturnlabel', return_data)
            .toPromise();
        return data;
    }

    async getReturnLabel(id_return: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'return/returnlabel?id_return=' + id_return)
            .toPromise();
        return data;
    }

    async putReturnStatus(id_status: any) {
        let data = await this.http
            .put<any>(AUTH_API + 'return/returnstatus', id_status)
            .toPromise();
        return data;
    }

    async getReturnLineImages(id_return: any, id_return_line: any) {
        let data = await this.http
            .get<any>(
                AUTH_API +
                'return/returnlineimages?id_return=' +
                id_return +
                '&id_return_line=' +
                id_return_line
            )
            .toPromise();
        return data;
    }

    async putReturnFinalize(request_data: any) {
        let data = await this.http
            .put<any>(AUTH_API + 'return/returnstatus', request_data)
            .toPromise();
        return data;
    }

    async putReturnWMS(request_data: any) {
        let data = await this.http
            .post<any>(AUTH_API + 'return/resendWMS', request_data)
            .toPromise();
        return data;
    }

    async getOrderExchangeDetails(order_id_exc: any) {
        let data = await this.http.get<any>(AUTH_API + 'return/orderexchangedetail?order_id_exc=' + order_id_exc).toPromise();
        return data;
    }

    async getOrderDetails(order_id: any, return_id: any) {
        let data = await this.http.get<any>(AUTH_API + 'return/orderdetails?order_id=' + order_id + '&return_id=' + return_id).toPromise();
        return data;
    }

    async postReturnReceiveItems(receive_items: any) {
        let data = await this.http.post<any>(AUTH_API + 'return/returnReceive', receive_items).toPromise();
        return data;
    }

    async putReturnStatusToDone(id_return: any) {
        let data = await this.http.put<any>(AUTH_API + 'return/updateReturnStatusToDone', id_return).toPromise();
        return data;
    }

    async getAllCountriesReturnLabelAuto() {
        let data = await this.http.get<any>(AUTH_API + 'return/getAllCountriesReturnLabelAuto').toPromise();
        return data;
    }

    async addCountriesReturnLabelAuto(countries: any) {
        let data = await this.http.post<any>(AUTH_API + 'return/addCountriesReturnLabelAuto', countries).toPromise();
        return data;
    }

    async addReturnLabel(data_request: any) {
        let data = await this.http.put<any>(AUTH_API + 'return/addReturnLabel', data_request).toPromise();
        return data;
    }

    async updateTrackingId(data_request: any) {
        let data = await this.http.put<any>(AUTH_API + 'return/updateTrackingId', data_request).toPromise();
        return data;
    }

    async getVariantQuantity(product_id: any, variant_id: any) {
        let data = await this.http.get<any>(AUTH_API + 'return/variantquantity?product_id=' + product_id + '&variant_id=' + variant_id).toPromise();
        return data;
    }

    async getOptionsReturn() {
        let data = await this.http.get<any>(AUTH_API + 'return/optionsReturn').toPromise();
        return data;
    }

    async returnUnexpectedRefund(body) {
        let data = await this.http.post<any>(AUTH_API + 'return/returnUnexpectedRefund', body).toPromise();
        return data;
    }

    async returnUnexpectedExchange(body) {
        let data = await this.http.post<any>(AUTH_API + 'return/returnUnexpectedExchange', body).toPromise();
        return data;
    }

    async getAllCountriesReturnExchange() {
        let data = await this.http.get<any>(AUTH_API + 'return/getAllCountriesReturnExchange').toPromise();
        return data;
    }

    async getAllCountriesReturn2Hand() {
        let data = await this.http.get<any>(AUTH_API + 'return/getAllCountriesReturn2Hand').toPromise();
        return data;
    }

    async getAllCountriesReturnRepair() {
        let data = await this.http.get<any>(AUTH_API + 'return/getAllCountriesReturnRepair').toPromise();
        return data;
    }

    async updateRefundTotal(data_request: any) {
        let data = await this.http.put<any>(AUTH_API + 'return/refundTotal', data_request).toPromise();
        return data;
    }

    async getTotalPendingReturn() {
        let data = await this.http.get<any>(AUTH_API + 'dashBoard/totalPendingReturns').toPromise();
        return data;
    }

    async getTotalStatusReturn(initial_date, final_date) {
        let data = await this.http.get<any>(AUTH_API + 'dashBoard/totalStatusReturn?inicial_date=' + initial_date + '&final_date=' + final_date).toPromise();
        return data;
    }

    async getTotalReasonReturn(finalized, initial_date, final_date) {
        let data = await this.http.get<any>(AUTH_API + 'dashBoard/totalReasonReturn?inicial_date=' + initial_date + '&final_date=' + final_date + '&finalized=' + finalized).toPromise();
        return data;
    }

    async getBestProducts(finalized, initial_date, final_date) {
        let data = await this.http.get<any>(AUTH_API + 'dashBoard/bestProducts?inicial_date=' + initial_date + '&final_date=' + final_date + '&finalized=' + finalized).toPromise();
        return data;
    }

    async getTotalPaymentReturns(finalized, initial_date, final_date) {
        let data = await this.http.get<any>(AUTH_API + 'dashBoard/totalPaymentReturns?inicial_date=' + initial_date + '&final_date=' + final_date + '&finalized=' + finalized).toPromise();
        return data;
    }

    async cancelReturn(data_request) {
        let data = await this.http.put<any>(AUTH_API + 'return/cancelReturn', data_request).toPromise();
        return data;
    }

    async archiveReturn(data_request) {
        let data = await this.http.put<any>(AUTH_API + 'return/archiveReturn', data_request).toPromise();
        return data;
    }

    async getHistoryJournalReturns(idOrder: any) {
        let data = await this.http.get<any>(AUTH_API + 'return/getHistoryJournalReturns?id_order=' + idOrder).toPromise();
        return data;
    }

    async getResellItems(idReturn: any){
        let data = await this.http.get<any>(AUTH_API + 'return/2handVerification?id_return=' + idReturn).toPromise();
        return data;
    }

    async postResellItems(resellItems: any){
        let data = await this.http.post<any>(AUTH_API + 'return/2handVerification', resellItems).toPromise();
        return data;
    }
}
