import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ContextMenu } from 'primeng/contextmenu';


import { Router } from '@angular/router';

import { AppMainComponent } from 'src/app/app.main.component';

import { ProductService } from '../../../services/product/product.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'pages-list-product',
    templateUrl: './list_product.component.html',
    styleUrls: ['./list_product.component.scss'],
})
export class ListProductComponent implements OnInit {

    currency: any = JSON.parse(localStorage.getItem('currency')!) ? JSON.parse(localStorage.getItem('currency')!) : "EUR";

    link_2hand = window.sessionStorage.getItem("2hand_link");

    totalRecords: any = 200;

    products: any[] = [];

    productsAux: any[] = [];

    product: any = null;

    loading: boolean = true;

    visibleSidebar: boolean = false;

    // items: any[] = [
    //     { label: 'Set as active' },
    //     { label: 'Delete products' },
    //     { separator: true },
    //     { label: 'Add tags' },
    //     { label: 'Remove tags' },
    //     { separator: true },
    //     { label: 'Add to collection(s)' },
    //     { label: 'Remove from collection(s)' }
    // ];

    items: any[] = [];

    cols: any[] = [
        { field: 'title', header: 'Product Name', sort: true, filter: false },
        { field: 'category_description', header: 'Category', sort: true, filter: false },
        { field: 'status', header: 'Status 2HD', sort: true, filter: false },
        { field: 'listing', header: 'Listings', sort: true, filter: false }
    ];

    colsOptional: any[] = [
        { field: 'Genre', header: 'Genre', sort: true, filter: false },
        // { field: 'Country_Code', header: 'Country Code', sort: true, filter: false },
        { field: 'collection_name', header: 'Collection Name ', sort: true, filter: false }
    ];;

    colsSideBar: any[] = [
        { field: 'sku', header: 'SKU', sort: true },
        { field: 'ean', header: 'EAN', sort: true },
        { field: 'product_variant_option1_description', header: 'Color', sort: true },
        { field: 'product_variant_option2_description', header: 'Size', sort: true },
        { field: 'price', header: 'Price', sort: true },
    ];

    colsListing: any[] = [
        { field: 'listing_id', header: 'Listing', sort: true },
        { field: 'customer_id', header: 'Costumer', sort: true },
        { field: 'created_at', header: 'Created at', sort: true },
        { field: 'price', header: 'Price', sort: true },
    ];

    _selectedColumnsAux: any[];

    selectedColumns: any[];

    selectedProducts: any[] = [];

    displayModal: boolean = false;

    panel1: boolean = true;

    panel2: boolean = false;

    selectedListing: any;

    offers = [
        {
            "offer_id": 6,
            "customer_id": "Margarida Sá",
            "listing_id": 3,
            "price": 10,
            "accepted": 0,
            "created_at": "2023-01-25T15:03:40.000Z",
            "updated_at": null,
            "created_by": null,
            "updated_by": null
        },
        {
            "offer_id": 7,
            "customer_id": "Alice Maria",
            "listing_id": 7,
            "price": 24,
            "accepted": 0,
            "created_at": "2023-01-26T11:23:19.000Z",
            "updated_at": null,
            "created_by": null,
            "updated_by": null
        },
        {
            "offer_id": 8,
            "customer_id": "Ricardo Ferreira",
            "listing_id": 6,
            "price": 24,
            "accepted": 1,
            "created_at": "2023-01-26T14:21:14.000Z",
            "updated_at": null,
            "created_by": null,
            "updated_by": null
        }
    ]

    colsOffers: any[];

    activeStatus: any;

    active_description = ['Active', 'Inactive']

    search_table_products: string = localStorage.getItem('search_table_products');

    resellItemsDialog: boolean = false;

    selectedResellItems: any = [];

    options2Hand: any = [];

    @ViewChild('dtproducts') table: Table;

    @ViewChild('filter') filter: ElementRef;

    @ViewChild('cm') contextMenu!: ContextMenu;

    sample_conditionSave: boolean = true;

    constructor(
        private app: AppMainComponent,
        private productService: ProductService,
        private router: Router,
        private messageService: MessageService,
        private translateService: TranslateService
    ) { }

    async ngOnInit() {
        this.loading = true;
        this.offers.map((offer: any) => {
            offer.accepted_description = offer.accepted == 0 ? 'Accepted' : 'Rejected'
            offer.created_at = this.app.getDateOnFormatString(offer.created_at)
        })

        this.selectedColumns = this.cols
        this._selectedColumnsAux = []

        this.colsOffers = [
            { field: 'offer_id', header: 'Identifier', sort: true },
            { field: 'customer_id', header: 'Customer', sort: true },
            { field: 'created_at', header: 'Created at', sort: false },
            { field: 'price', header: 'Price', sort: false },
            { field: 'accepted_description', header: 'Accepted', sort: false },
        ];

        this.loadProductsList()

        // setTimeout(() => {
        //     this.filter.nativeElement.value = this.search_table_products;
        //     this.table.filterGlobal(this.search_table_products, 'contains')
        // })
    }

    async loadProductsList() {
        await this.productService.getProducts()
            .then(async products => {
                this.products = products
                this.products.map(product => {
                    product.status = product.id_status == 1 ? 'Active' : 'Inactive'

                    product.listing = product.listings;
                })

                this.productsAux = products;
                this.products = products;

                this.loading = false;
            });
    }

    async changeAll2HandStatus() {
        var selectionAux = this.table._selection
        this.loading = true
        const status = await this.productService.put2HandOptions(selectionAux)
        .then((response) => {
            return response.status
        })
        if(status == 200){
            this.loadProductsList()
        }
    }

    async change2HandStatus(data:any) {
        const products = []
        products.push(data)
        const status = await this.productService.put2HandOptions(products)
        .then((response) => {
            return response.status
        })
        return status;
    }

    async onContextMenuSelectProduct(data : any) {        
        this.translateService.stream('list.list').subscribe((val) => {
            this.items = [
                {
                    label: data.status == "Inactive" ? val.setAsActive : val.setAsInactive,
                    icon: 'pi pi-check-circle',
                    command: async () => {
                        this.loading = true
                        const status = await this.change2HandStatus(data)
                        if(status == 200){
                            this.loadProductsList()
                        }
                    }
                }
            ]
        });
    }

    filterTable() {
        this.products = this.productsAux.filter(product => product.id_status == this.activeStatus)
    }

    clearFilter(event, op) {
        this.activeStatus = null
        this.products = this.productsAux
        op.hide(event)
    }

    openOverlay(event, op) {
        event.stopPropagation();
        op.toggle(event)
    }

    showOverlay() {
        this.app.overlayActive = true
    }

    hideOverlay() {
        this.app.overlayActive = false
    }

    openPanel2(listing) {
        this.panel1 = false
        this.panel2 = true
        this.selectedListing = listing
        document.getElementsByClassName('p-sidebar-content')[0].scrollTop = 0
    }

    closePanel2() {
        this.panel2 = false
        this.panel1 = true
    }

    selectedProductsCount() {
        if (this.selectedProducts.length == 0) {
            return 'all'
        }
        return this.selectedProducts.length
    }

    checkboxClick(e) {
        e.stopPropagation()
        var x = document.getElementsByClassName('p-checkbox-box')
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedProducts.length > 0) {
            x[0].classList.add('p-highlight')
            y[0].classList.add('pi')
            y[0].classList.add('pi-minus')
        } else {
            x[0].classList.remove('p-highlight')
            y[0].classList.remove('pi')
            y[0].classList.remove('pi-minus')
        }
    }

    mainCheckboxClick() {
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedProducts.length == this.productsAux.length) {
            y[0].classList.remove('pi-minus')
        }
    }

    @Input() get selectedColumnsAux(): any[] {
        return this._selectedColumnsAux;
    }

    set selectedColumnsAux(val: any[]) {
        //restore original order
        this._selectedColumnsAux = this.colsOptional.filter(col => val.includes(col));
    }

    async openSideBar(product) {
        document.body.classList.add('overflow-hidden')
        this.visibleSidebar = true;
        this.product = await this.productService.getProductsById(product.product_id);
        this.product.images = [{ id: this.product.variants[0].product_variant_option1_id, photo: this.product.variants[0].photos[0]?.photo }]
        this.product.variants.map(variant => {
            var aux = this.product.images.map(img => img.id)
            if (!aux.includes(variant.product_variant_option1_id)) {
                this.product.images.push({ id: variant.product_variant_option1_id, photo: variant.photos[0].photo })
            }
        })
        this.product.listings.map(listing => {
            if (listing.photo == null) {
                listing.photo = this.product.variants.find(variant => variant.product_variant_id = listing.product_variant_id).photos[0].photo
            }
        })
    }

    closeSideBar() {
        document.body.classList.remove('overflow-hidden')
        this.panel1 = true
        this.panel2 = false
        this.product = null
    }

    customize(event, op) {
        this.selectedColumns = this.cols.concat(this._selectedColumnsAux)
        this.app.overlayActive = false
        op.hide(event)
    }

    revert(event, op) {
        this.selectedColumns = this.cols
        this._selectedColumnsAux = this.cols
        this.app.overlayActive = false
        op.hide(event)
    }

    async exportCSV() {
        var selectionAux = this.table._selection
        if (this.table._selection.length == 0) {
            if (this.table.filteredValue) {
                this.table._selection = this.table.filteredValue
            } else {
                this.table._selection = this.table._value
            }
        }
        await this.table.exportCSV({ selectionOnly: true })
        this.table._selection = selectionAux
    }

    async resellItems() {
        this.selectedResellItems = []
        this.options2Hand = await this.productService.get2HandOptions()
        this.options2Hand.comment = "";
        this.product.variants.map(variant => {
            variant.photosResell = [];
            variant.condition = this.options2Hand._2handcondictions[0];
            variant.condition_id = this.options2Hand._2handcondictions[0].condition_id;
            variant.quantityResell = 1;
            variant.resellPrice = Number(variant.price) * this.options2Hand._2handcondictions[0].percentage_price / 100
        })
        this.resellItemsDialog = true
    }

    uploadPhotosResell(event: any, productline: any, photosResellUpload) {
        for (let file of event.files) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                var photo = e.target.result.split(',')[1]
                productline.photosResell.push(photo)
            }
            reader.readAsDataURL(file);
        }
        photosResellUpload.clear()
    }
    onRemovePhotoResell(productline: any, photoResell: any) {
        productline.photosResell = productline.photosResell.filter(photo => photo !== photoResell)
    }



    async textAreaChange() {
        for (let i = 0; i < this.selectedResellItems.length; i++) {
            if (this.selectedResellItems[i].condition.sample_condition == 1 && (this.selectedResellItems[i].comment == undefined || this.selectedResellItems[i].comment.trim() == "")) {
                this.sample_conditionSave = false;
                return;
            }
            this.sample_conditionSave = true;
        }
    }

    textAreaCommentPlaceHolder(productline: any) {
        if (productline.condition.sample_condition == 1) {
            return "Write a comment (mandatory)"
        }
        else {
            return "Write a comment..."
        }
    }

    changeCondition(productline: any) {
        productline.condition_id = productline.condition.condition_id
        productline.resellPrice = Number(productline.price) * productline.condition.percentage_price / 100;
        if (productline.condition.sample_condition == 1 && productline.comment != undefined && productline.comment != "") this.sample_conditionSave = true;
    }
    async submit2HandProducts() {
        const sellProducts: any = []
        this.selectedResellItems.map(item => {
            const sellProduct = {
                sku: item.sku,
                quantitysell: item.quantityResell,
                condition_id: item.condition_id,
                sellPrice: item.resellPrice,
                photos: item.photosResell,
                comment: item.comment,
                sampleCondition: item.condition.sample_condition,
                description: item.condition.description

            }
            sellProducts.push(sellProduct)
        })
        await this.productService.addResellProducts({ sellProducts: sellProducts }).then(
            result => {
                this.resellItemsDialog = false;
                this.messageService.add({ key: 'tst2', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("products.list_product.resellItems") })
                this.openSideBar(this.product)
            },
            error => {
                this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: error.error.Error })
            }
        )
    }
}