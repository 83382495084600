import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth/auth.service'
import { PermissionsService } from './services/permissions/permissions.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(
        private permissionsService: PermissionsService,
        private authService: AuthService,
        private router: Router) {
    };
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
       
        let isLoggedIn = false;
        isLoggedIn = this.authService.isAuthenticated();
        let isPermission = this.permissionsService.checkPermissions([next.data.permission])

        if (isLoggedIn && isPermission) {
            return true
        } else if (isLoggedIn && !isPermission) {
            this.router.navigate(['pages/notfound']);
           return true
        } else {
            this.router.navigate(['login']);
            return false
        }
    }
}
