<p-scrollTop [threshold]="200"></p-scrollTop>
<div class="layout-wrapper"
    [ngClass]="{'layout-overlay':isOverlay(),
                                'layout-static':isStatic(),
                                'layout-theme-light':!config.dark,
                                'layout-theme-dark':config.dark,
                                'layout-overlay-sidebar-active': overlayMenuActive,
                                'layout-static-sidebar-inactive': staticMenuInactive,
                                'p-ripple-disabled': !config.ripple, 'p-input-filled': config.inputStyle === 'filled'}">
    <pages-topbar></pages-topbar>
    <pages-leftbar></pages-leftbar>

    <!-- <div class="layout-sidebar">        
        <pages-menu></pages-menu>
    </div> -->

    <div class="layout-main-container" (click)="staticMenuInactive = true">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
        <!-- <pages-footer></pages-footer> -->
    </div>
    <!-- <pages-config></pages-config> -->
    <div *ngIf="overlayActive" class="layout-mask p-component-overlay"></div>
    <div *ngIf="menuActiveMobile" class="layout-mask-mobile p-component-overlay"></div>
    </div>