import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PurchaseOrdersService } from 'src/app/services/purchase_orders/purchase_orders.service';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { MessageService } from 'primeng/api';
import { PurchaseOrder } from 'src/app/api/po';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Supplier } from 'src/app/api/supplier';
import { CountryService } from 'src/app/services/country/country.service';
import { SupplierService } from 'src/app/services/supplier/supplier.service';

@Component({
    templateUrl: './purchase_order.component.html',
    styles: [
        `
            :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
                position: -webkit-sticky;
                position: sticky;
                top: 0px;
            }

            @media screen and (max-width: 64em) {
                :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
                    top: 0px;
                }
            }
        `,
    ],
})
export class POComponent implements OnInit {
    pos: PurchaseOrder[];

    aux: PurchaseOrder[];

    expandedRows = {};

    isExpanded: boolean = false;

    cols: any[] = [
        { field: 'POnumber', header: 'ID' },
        { field: 'sku', header: 'SKU' },
        { field: 'ean', header: 'EAN' },
        { field: 'name', header: 'name' },
        { field: 'pname', header: 'pname' },
        { field: 'color', header: 'Color' },
        { field: 'size', header: 'Size' },
    ];

    exportColumns: any[];

    sortOptions: any[];

    selectedOption: any;

    breadcumbItems: MenuItem[] = [];

    loading: Boolean;

    loadingSubTables: Boolean;

    productModels: any;

    product: any = {
        pname: "Draped Shoulder Long Sleeve Tee",
        image: "https://prdbisa.blob.core.windows.net/prodctimages/sku/001-02563-0003-002.jpeg?sp=r&st=2022-07-06T14:14:20Z&se=2999-07-06T22:14:20Z&spr=https&sv=2021-06-08&sr=c&sig=JdzGbIVnzO6wnKplwJqZGhlHj3JXe6i2CYdeHhhf2ys%3D"
    }

    createSupplierDialog: boolean = false;

    submitted: boolean = false;

    supplierTransfer: any = [
        { name: 'Inactive', code: 0 },
        { name: 'Active', code: 1 }
    ];

    newSupplier: Supplier = {
        codigo_wms: '',
        name: '',
        country_code: '',
        nif: null,
        address: '',
        city: '',
        codigo_postal: '',
        phone: null,
        email: '',
        transfer: ''
    };

    zipcodeRegex: RegExp = /(.*?)/;

    nameError: boolean = false;

    countries: any[] = [];

    displayDialog: boolean = false;

    @ViewChild('dt1') table: Table;

    @ViewChild('filter') filter: ElementRef;

    constructor(
        private purchaseOrdersService: PurchaseOrdersService,
        private countryService: CountryService,
        private supplierService: SupplierService,
        private messageService: MessageService,
        private router: Router,
        private readonly translateService: TranslateService
    ) { }

    async ngOnInit() {
        //load data to translate
        await this.translateService.get('dummyTranslation').toPromise().then();

        this.translateService.stream('general.menu').subscribe((val) => {
            this.breadcumbItems = [
                { label: '', icon: 'pi pi-home' },
                { label: val.purchaseOrders.value },
                { label: val.purchaseOrders.items.consult_po },
            ];
        });

        this.loading = true;

        this.countryService.getCountries()
            .then((countries) => {
                this.countries = countries;
            });

        await this.purchaseOrdersService.getPOnumb().then((pos) => {
            const purchase_orders = [];
            pos.map(function (po) {
                let purchase_order: any = {
                    POnumber: po.POnumber,
                    open: false,
                    Status: po.StatusPOdesc,
                    orders: null,
                    totalOrdered: null,
                    totalReceived: null
                };
                purchase_orders.push(purchase_order);
            });

            this.pos = purchase_orders;
            this.aux = purchase_orders;
            this.loading = false;
        }).catch((error) => {
            this.loading = false;
            this.messageService.add({ key: 'tst', severity: 'error', summary: error.error });
        });
    }

    itemClicked(event) {
        if (event.item.label === '') {
            this.router.navigate(['main']);
        }
    }

    async expandItem(po, itemOpen) {
        this.productModels = [];
        if (itemOpen == 1) {
            po.open = !po.open;
        }
        if (po.orders == null) {
            this.loadingSubTables = true;
            await this.purchaseOrdersService
                .getPO(po.POnumber)
                .then((prods) => {
                    var totalOrdered = 0;
                    var totalReceived = 0;
                    for (var prod of prods) {
                        var totalProdOrdered = prod.products.reduce(getTotalProdOrdered, 0);
                        function getTotalProdOrdered(total, item) {
                            return total + item.qtyDyn;
                        }
                        var totalProdReceived = prod.products.reduce(getTotalProdReceived, 0);
                        function getTotalProdReceived(total, item) {
                            return total + item.qtyRec;
                        }
                        prod.totalProdOrdered = totalProdOrdered
                        prod.totalProdReceived = totalProdReceived
                        totalOrdered += totalProdOrdered
                        totalReceived += totalProdReceived
                        this.productModels.push({ label: prod.name, value: prod.name });
                    }
                    po.orders = prods;
                    po.totalOrdered = totalOrdered;
                    po.totalReceived = totalReceived;
                });
        }
        this.loadingSubTables = false;
    }

    async expandProd(po) {
        po.open = !po.open;
        if (po.orders == null) {
            this.loadingSubTables = true;
            await this.purchaseOrdersService
                .getPO(po.POnumber)
                .then((prods) => {
                    po.orders = prods;
                });
        }
        this.loadingSubTables = false;
    }

    async refreshPos() {
        await this.ngOnInit();
    }

    async exportExcel() {
        let posExcel: any = []
        let pos = this.pos.filter(po => po.open == true)
        for (var po of pos) {
            for (var order of po.orders) {
                for (var prod of order.products) {
                    var poExcel = {
                        PoNumber: po.POnumber,
                        TotalPoOrdered: po.totalOrdered,
                        TotalPoReceived: po.totalReceived,
                        Model: order.name,
                        TotalModelOrdered: order.totalProdOrdered,
                        TotalModelReceived: order.totalProdReceived,
                        SKU: prod.SKU,
                        EAN: prod.EAN,
                        Color: prod.color,
                        Size: prod.size,
                        ProdOrdered: prod.qtyDyn,
                        ProdReceived: prod.qtyRec,
                    }
                    posExcel.push(poExcel);
                }
            }
        }

        import("xlsx").then(async xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(posExcel);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "purchase_orders");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    openDisplay(product) {
        this.product = product;
        this.displayDialog = true;
    }

    clear(table: Table) {
        table.clear();
        this.filter.nativeElement.value = '';
    }

    createSupplier() {
        this.submitted = true
        this.nameError = false

        if (this.newSupplier.name && this.newSupplier.email && this.newSupplier.codigo_wms && this.newSupplier.phone && this.newSupplier.nif && this.newSupplier.address && this.newSupplier.city && this.newSupplier.codigo_postal && this.newSupplier.country_code && this.validateEmail(this.newSupplier.email) && this.validatePhone(this.newSupplier.phone) && this.validateVAT(this.newSupplier.nif) && this.validateZipcode(this.newSupplier.codigo_postal)) {
            this.supplierService.postSupplier(this.newSupplier).subscribe(
                data => {
                    this.hideDialog()
                },
                error => {
                    this.nameError = true
                })
        }
    }

    hideDialog() {
        this.createSupplierDialog = false;
        this.submitted = false;
        this.newSupplier = {
            codigo_wms: '',
            name: '',
            country_code: '',
            nif: null,
            address: '',
            city: '',
            codigo_postal: '',
            phone: null,
            email: '',
            transfer: ''
        };
    }

    validateEmail(email) {
        var emailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailRegex.test(email)) {
            return true
        } else {
            return false
        }
    }

    validatePhone(phone) {
        var phoneRegex: RegExp = /^9[1236]{1}[0-9]{7}$|^2[1-9]{1}[0-9]{7}$/;
        if (phoneRegex.test(phone)) {
            return true
        } else {
            return false
        }
    }

    validateVAT(vat) {
        var vatRegex: RegExp = /^[0-9]{9}$/;
        if (vatRegex.test(vat)) {
            return true
        } else {
            return false
        }
    }

    validateZipcode(zipcode) {
        if (this.zipcodeRegex.test(zipcode)) {
            return true
        } else {
            return false
        }
    }

    async createZipRegex() {
        this.newSupplier.codigo_postal = null
        await this.countryService.getCountrByCode(this.newSupplier.country_code)
            .then((country) => {
                this.zipcodeRegex = new RegExp(country.country_ziprule);
            });
    }
}
