import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

const AUTH_API = environment.url;
const PERMISSION_KEY = 'auth-permission';
const SECRET_KEY = 'YourSecretKeyForEncryption&Descryption';

const PERMISSIONS_TYPE = {
    createOperation: 1,
    turnManager: 2,
    createUser: 3,
    managePermissions: 4,
    consultOrders: 5,
    correctOrders: 6,
    consultProducts: 7,
    importProducts: 8,
    addSuppliers: 9,
    deleteSuppliers: 10,
    consultSuppliers: 11,
    enterProductReceipt: 12,
    consultProductReception: 13,
    brandDetails: 14,
    alterarPassword: 15,
    consultPO: 16,
    consultReturn: 17,
    consult2hand: 18,
    consultRepair: 19,
    consult2handOrder: 18,
    consultAbandonedBags:18,
    consultAdmin2Hand:20,
    consultRecycle: 21
};

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    permissions = [];

    constructor(private http: HttpClient) { }

    public getPermissionsType() {
        return PERMISSIONS_TYPE;
    }

    public savePermissions(permissions: Array<object>): void {

        const data = JSON.stringify(permissions);
        const cryptoJS = CryptoJS.AES.encrypt(data, SECRET_KEY.trim()).toString();

        window.sessionStorage.removeItem(PERMISSION_KEY);
        window.sessionStorage.setItem(PERMISSION_KEY, cryptoJS);
    }

    public getPermissions() {
        let result = window.sessionStorage.getItem(PERMISSION_KEY);
        let cryptoJS = CryptoJS.AES.decrypt(result, SECRET_KEY.trim()).toString(
            CryptoJS.enc.Utf8
        );
        return JSON.parse(cryptoJS);
    }

    async getAllPermissions() {
        let data = await this.http
            .get<any>(AUTH_API + 'permission/GetAll')
            .toPromise();
        return data;
    }

    async getGroupsPermissions() {
        let data = await this.http
            .get<any>(AUTH_API + 'group/GetGroupPermissions')
            .toPromise();
        return data;
    }

    public checkPermissionsMenu(permissionMenu: Array<number>) {
        const permissionsUser = this.getPermissions();
        var permissionTrue = null;
        for (var id_permission of permissionMenu) {
            permissionTrue = permissionsUser.find(permission => permission.id_permission == id_permission)
            if (permissionTrue) {
                return true
            }
        }
        return false
    }

    public checkPermissionsSubmenu(id_permission: number) {
        const permissionsUser = this.getPermissions();
        var permissionTrue = null;
        permissionTrue = permissionsUser.find(permission => permission.id_permission == id_permission)
        if (permissionTrue == undefined) {
            return false
        }
        return true
    }

    public checkPermissions(array: Array<number>) {
        const data = this.getPermissions();
        let i: number = 0;
        while (i < data.length) {

            let j: number = 0;

            while (j < array.length) {
                if (data[i].id_permission == array[j]) {
                    return true;
                }
                j++;
            }

            i++;
        }
        return false;
    }
}
