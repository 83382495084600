import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { InputMaskModule } from 'primeng/inputmask';
import { CommonModule } from '@angular/common';
import { MyAccountComponent } from './my_account.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { KeyFilterModule } from 'primeng/keyfilter';

import {BreadcrumbModule} from 'primeng/breadcrumb';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TranslateModule } from '@ngx-translate/core';
import { SplitButtonModule } from 'primeng/splitbutton';
import { PasswordModule } from 'primeng/password';

@NgModule({
    imports: [
        ButtonModule,
        SplitButtonModule,
        CommonModule,
        DialogModule,
        ReactiveFormsModule,
        TableModule,
        ToastModule,
        FormsModule,
        KeyFilterModule,
        InputTextModule,
        InputMaskModule,
        FileUploadModule,
        DropdownModule,
        MessageModule,
        BreadcrumbModule,
        InputSwitchModule,
        CheckboxModule,
        MultiSelectModule,
        ToggleButtonModule,
        TranslateModule,
        PasswordModule
    ],
    declarations: [
        MyAccountComponent
    ],
    exports: [
        MyAccountComponent
    ],
    providers: [MessageService]

})
export class MyAccountModule { }
