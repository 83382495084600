<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<div *ngIf="!loading" class="h-full">
	<p-contextMenu #cm [model]="items" class="dwnldLabelRecycle" ></p-contextMenu> 
	<p-table #dtrecycles [value]="recycles" dataKey="id_recycle" [columns]="selectedColumns" [rowHover]="true"
		(contextmenu)="onContextMenuSelectProduct(selectedSubmission)" [(contextMenuSelection)]="selectedSubmission" [contextMenu]="cm"
		[paginator]="true" [globalFilterFields]="['idInterno', 'order_name', 'customer', 'country']" [rows]="25"
		[rowsPerPageOptions]="[25,50,100]" [showCurrentPageReport]="true" [resizableColumns]="true"
		[reorderableColumns]="true" responsiveLayout="scroll" [responsive]="true" [(selection)]="selectedRecycles"
		exportFilename="recycles" [loading]="loading" 
		currentPageReportTemplate="{{ 'products.list_product.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
		<ng-template pTemplate="caption">
			<div class="flex justify-content-between gap-2 flex-wrap">
				<div class="flex flex-column w-full sm:w-auto sm:flex-row gap-2">
					<p-calendar [showIcon]="true" [(ngModel)]="rangeDates" selectionMode="range" dateFormat="dd/mm/yy"
						(onClose)="ngOnInit()" [minDate]="minimumDate"></p-calendar>
					<div class="flex gap-2 w-full sm:w-auto">
						<span class="p-input-icon-left w-full sm:w-auto">
							<i class="pi pi-search"></i>
							<input pInputText type="text" #filter
								(input)="dtrecycles.filterGlobal($event.target.value, 'contains')"
								placeholder="{{ 'general.search_here' | translate }}" />
						</span>
						<div (click)="ngOnInit()" class="flex align-items-center cursor-pointer"
							style="color: #6c757d;">
							<i class="pi pi-refresh"></i>
						</div>
					</div>
				</div>
				<div class="flex gap-2">
					<button pButton pRipple label="Export" class="p-button-outlined" (click)="exportCSV()">
						<span class="p-button-icon p-button-icon-left flex"><i class="pi pi-file-excel"></i></span>
					</button>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th style="width: 1rem">
					<p-tableHeaderCheckbox (click)="mainCheckboxClick()"></p-tableHeaderCheckbox>
				</th>
				<th pResizableColumn pReorderableColumn [pSortableColumn]="col.sort ? col.field : null"
					*ngFor="let col of columns" class="cursor-move">
					<div class="flex align-items-center"
						[ngClass]="{'cursor-pointer' : col.sort || col.filter, 'p-column-filter-menu': col.filter }">
						{{col.header}}
						<p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
						<i *ngIf="col.filter && activeStatus == null" class="pi pi-filter"
							(click)="openOverlay($event, op)"></i>
						<i *ngIf="col.filter && activeStatus != null" class="pi pi-filter-fill"
							(click)="openOverlay($event, op)"></i>
					</div>
				</th>
				<th style="width: 1rem; border-left: none;">
					<img src="assets/layout/images/config-icon.svg" (click)="openOverlay($event, op2)"
						class="cursor-pointer">
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-item let-columns="columns" let-ri="rowIndex">
			<tr [pContextMenuRow]="item" (click)="openSideBar(item)" class="cursor-pointer">
				<td>
					<p-tableCheckbox [value]="item" (click)="checkboxClick($event)"></p-tableCheckbox>
				</td>
				<td *ngFor="let col of columns">
					<div class="flex w-min"
						[ngClass]="col.field == 'recycle_status' ? 'recycle-badge status-' + item.IDStatus : ''">
						<div *ngIf="col.field === 'created_at'">
							{{ item.created_at | date:'MMM d, h:mm a' }}
						</div>
						<div *ngIf="col.field != 'idInterno' && col.field!== 'created_at'">
							{{item[col.field]}}
						</div>
						<a *ngIf="col.field == 'idInterno'">
							{{item[col.field]}}
						</a>
						<div *ngIf="col.field == 'items'">
							<div *ngIf="item[col.field] == 1">&nbsp;item</div>
							<div *ngIf="item[col.field] !== 1">&nbsp;items</div>
						</div>
						<div *ngIf="col.field == 'amount'">
							&nbsp;{{item.currency}}
						</div>
					</div>
				</td>
				<td></td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="11">{{ 'general.no_data' | translate }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr>
				<td colspan="11">{{ 'general.loading' | translate }}</td>
			</tr>
		</ng-template>
	</p-table>
</div>
<p-overlayPanel #op2 (onHide)="hideOverlay()" (onShow)="showOverlay()">
	<ng-template pTemplate>
		<div class="flex flex-column gap-3">
			<h6>CUSTOMIZE COLUMNS</h6>
			<div>
				<div *ngFor="let col of colsOptional" class="field-checkbox">
					<p-checkbox name="columns" value="col" [value]="col" [(ngModel)]="selectedColumnsAux"
						[inputId]="col.field"></p-checkbox>
					<label [for]="col.field">{{col.header}}</label>
				</div>
			</div>
		</div>
		<div class="mt-6 flex">
			<p-button icon="pi pi-replay" (click)="revert($event, op2)" label="Revert to default"
				styleClass="p-button-text"></p-button>
			<p-button label="Customize" (click)="customize($event, op2)"></p-button>
		</div>
	</ng-template>
</p-overlayPanel>

<p-sidebar [(visible)]="visibleSidebar" position="right" [baseZIndex]="10000" (onHide)="hideSideBar()"
	(click)="menuShow = false" [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false"
	class="relative">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="hideSideBar()"></button>
		</div>
	</div>
	<div *ngIf="!recycle || loadingSidebar" class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div>
	<div *ngIf="recycle && !loadingSidebar" class="flex flex-column pb-6">
		<div class="card">
			<div class="flex justify-content-between mb-3">
				<div class="flex gap-2">
					<div>
						<h5>Recycle ID | {{recycle.id}}</h5>
					</div>
				</div>
			</div>

			<div class="grid">
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>Submitted On: {{recycle.created_at}}</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>Customer: {{recycle.customer}}</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>{{ 'orders.returns.email' | translate }}:
						<a href="mailto:{{recycle.EmailAddress}}" (click)="$event.stopPropagation()">{{recycle.EmailAddress}}</a>
					</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-8">
					<p>{{ 'orders.returns.address' | translate }}: {{recycle.customerAddress}}</p>
				</div>
				<div *ngIf="recycle.tracking_number_1 != null" class="col-12 sm:col-6 md:col-6 lg:col-4 pr-0">
					<p>Tracking Rec.:
						<a href="{{recycle.track_link}}{{recycle.tracking_number_1}}" (click)="$event.stopPropagation()"
							target="_blank" style="word-break: break-word;">{{recycle.tracking_number_1}}</a>
					</p>
				</div>

			</div>
		</div>
		<!-- lines -->
		<div>
			<div class="recycle-summary flex flex-column gap-3">
				<div class="w-full flex flex-column lg:flex-row gap-3">
					<div class="card w-full lg:w-8">
						<h5>Questions Answers</h5>
						<div class="flex align-items-start justify-content-center">
							<div class="w-full">
								<div id="info-recycle"
									class="info-recycle flex flex-row flex-wrap gap-2 pt-5 pb-5 w-full justify-content-between">
									<div class="flex flex-column sm:flex-row gap-3 w-full">
										<div class="flex flex-wrap gap-3 w-full justify-content-between">
											<div class="md:text-left flex flex-column gap-3 max-w-full">
												<span class="align-items-start justify-content-start">
													<b>Recompensamos os nossos membros com conteúdo exclusivo! Em que tipo de recompensa estás mais interessado?</b>
												</span>
												<div class="flex align-items-center">
													<span class="mr-3 align-self-center md:align-self-end">{{recycle.question_answer}}</span>
												</div><br>
												<span class="align-items-start justify-content-start">
													<b>Quais marcas de moda combinam com teu estilo?
													</b>
												</span>
												<div class="flex align-items-center">
													<span class="mr-3 align-self-center md:align-self-end">{{recycle.brands_answer}}</span>
												</div><br>
												<span class="align-items-start justify-content-start">
													<b>Qual é o destino que dás às tuas roupas no final da sua vida útil?</b>
												</span>
												<div class="flex align-items-center">
													<span class="mr-3 align-self-center md:align-self-end">{{recycle.other_methods_answer}}</span>
												</div><br>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card w-full lg:w-5">
						<div class="flex-1 align-items-start justify-content-start">
							<div>
								<div class="flex justify-content-between mb-4">
									<span
										class="flex align-items-center justify-content-center font-bold text-xl">{{'recycle.submissions.pickup_info'
										| translate }}</span>
								</div>
								<div class="flex justify-content-between mb-3">
									<span
										class="flex align-items-start justify-content-start">{{'recycle.submissions.pickup_id'
										| translate }}</span>
									<span
										class="flex align-items-center justify-content-end">{{recycle.recycle_collect_id}}</span>
								</div>
								<div class="flex justify-content-between mb-3">
									<span
										class="flex align-items-start justify-content-start">{{'recycle.submissions.pickup_name'
										| translate}}</span>
									<span
										class="flex align-items-center justify-content-end">{{recycle.recycle_collect_name}}</span>
								</div>
								<div class="flex justify-content-between mb-3">
									<span
										class="flex align-items-start justify-content-start">{{'recycle.submissions.pickup_address'
										| translate}}</span>
									<span
										class="flex align-items-center justify-content-end">{{recycle.recycle_collect_street}}</span>
								</div>
								<div class="flex justify-content-between mb-3">
									<span
										class="flex align-items-start justify-content-start">{{'recycle.submissions.pickup_zip'
										| translate}}</span>
									<span
										class="flex align-items-center justify-content-end">{{recycle.recycle_collect_zipcode}}</span>
								</div>
								<div class="flex justify-content-between mb-3">
									<span
										class="flex align-items-start justify-content-start">{{'recycle.submissions.tracking'
										| translate}}</span>
									<a href="{{recycle.track_link}}{{recycle.tracking_number}}" (click)="$event.stopPropagation()"
										target="_blank" style="word-break: break-word;">{{recycle.tracking_number}}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="card w-full lg:w-5">
			<div class="flex-1 align-items-start justify-content-start">
				<div>
					<div class="flex justify-content-between mb-4">
						<span
							class="flex align-items-center justify-content-center font-bold text-xl">Recolha Info</span>
					</div>
					<div class="flex justify-content-between mb-3">
						<span
							class="flex align-items-start justify-content-start">Endereço de Recolha: </span>
						<span
							class="flex align-items-center justify-content-end"></span>
					</div>
					<div class="flex justify-content-between mb-3">
						<span
							class="flex align-items-start justify-content-start">Hora de Recolha: </span>
						<span
							class="flex align-items-center justify-content-end"></span>
					</div>
					<div class="flex justify-content-between mb-3">
						<span
							class="flex align-items-start justify-content-start">Dia de Recolha: </span>
						<span
							class="flex align-items-center justify-content-end"></span>
					</div>
					<div class="flex justify-content-between mb-3">
						<span
							class="flex align-items-start justify-content-start">Observações: </span>
						<span
							class="flex align-items-center justify-content-end"></span>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</p-sidebar>

<!--histórico sidebar-->
<p-sidebar [(visible)]="isHistoryTrue" position="right" [baseZIndex]="10001" (onHide)="hideSidebarHistory()"
	(click)="menuShow = false" [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false"
	class="relative">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="hideSidebarHistory()"></button>
		</div>
	</div>

	<div *ngIf="!dataHistory.length && dataHistory[0]!=='undefined'"
		class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div>
	<div *ngIf=" dataHistory[0]=='undefined'" class="flex justify-content-center align-items-center min-h-screen">
		<p>No history avaliable</p>
	</div>
	<div *ngIf="isHistoryTrue && dataHistory && dataHistory.length && dataHistory[0]!=='undefined'">
		<div class="card-title" style="padding-top: 50px; padding-bottom: 50px;">History</div>
		<p-timeline [value]="dataHistory" class="w-full timeline">
			<ng-template pTemplate="content" let-event>
				{{dateTimeLine(event)}}
				<br>
				{{IDStatusDescriptionTimeLine(event)}}
			</ng-template>
		</p-timeline>
	</div>
</p-sidebar>