import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root'
})
export class CountryService {

    constructor(private http: HttpClient) { }

    async getCountries() {

        let data = await this.http.get<any>(AUTH_API + 'country/getCountries').toPromise();
        return data;
    } 

    async getCountrByCode(countryCode) {

        let data = await this.http.get<any>(AUTH_API + 'country/getCountry?country_code=' + countryCode).toPromise();
        return data[0];
    } 
}
