import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { CommonModule } from '@angular/common';
import { BrandsComponent } from './brands.component';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MessageService } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { TabViewModule } from 'primeng/tabview';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { ColorPickerModule } from 'primeng/colorpicker';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { EditorModule } from 'primeng/editor';
import { DropdownModule } from 'primeng/dropdown';
import { ChipsModule } from 'primeng/chips';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { KeyFilterModule } from 'primeng/keyfilter';
import { SliderModule } from 'primeng/slider';
import { SidebarModule } from 'primeng/sidebar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PasswordModule } from 'primeng/password';
import { CarouselModule } from 'primeng/carousel';


@NgModule({
    imports: [
        CommonModule,
        TableModule,
        CalendarModule, // see icons in table ..........!!!!!!!
        ButtonModule,
        SelectButtonModule,
        MultiSelectModule,
        InputTextModule,
        ContextMenuModule,
        FormsModule,
        ReactiveFormsModule,
        ToastModule,
        TabViewModule,
        BreadcrumbModule,
        TranslateModule,
        FileUploadModule,
        HttpClientModule,
        ColorPickerModule,
        InputTextareaModule,
        AngularEditorModule,
        EditorModule,
        DropdownModule,
        ChipsModule,
        CheckboxModule,
        InputNumberModule,
        DialogModule,
        DividerModule,
        KeyFilterModule,
        SliderModule,
        SidebarModule,
        AutoCompleteModule,
        PasswordModule,
        CarouselModule,
    ],
    declarations: [
        BrandsComponent
    ],
    exports: [
        BrandsComponent
    ],
    providers: [
        MessageService
    ]

})
export class BrandsModule { }
