import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Supplier } from 'src/app/api/supplier';
import { CountryService } from 'src/app/services/country/country.service';
import { SupplierService } from '../../../services/supplier/supplier.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'pages-list-edit-supplier',
    templateUrl: './list_edit_supplier.component.html',
    styleUrls: ['./list_edit_supplier.component.sass']
})
export class ListEditSupplierComponent implements OnInit {

    ccEmailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    ccPhoneRegex: RegExp = /^9[1236]{1}[0-9]{7}$|^2[1-9]{1}[0-9]{7}$/;

    ccNIFRegex: RegExp = /^[0-9]{9}$/;

    ccRegex: RegExp = /(.*?)/;

    regex_example: string = "";

    countries: any[];

    suppliers: Supplier[];

    suppliersAux: Supplier[];

    idSuppliers: any[];

    supplier: Supplier;

    supplierDialog: boolean;

    submitted: boolean;

    selectedCountry: any;

    loading: boolean;

    valCheck: string;

    fieldsError: boolean;

    breadcumbItems: MenuItem[] = [];

    @ViewChild('dt') table: Table;

    @ViewChild('filter') filter: ElementRef;

    constructor(
        private supplierService: SupplierService,
        private countryService: CountryService,
        private messageService: MessageService,
        private router: Router,
        private readonly translateService: TranslateService
    ) { }

    async ngOnInit() {

        //load data to translate
        await this.translateService.get('dummyTranslation').toPromise().then();

        this.translateService.stream('general.menu').subscribe(val => {
            this.breadcumbItems = [
                { label: '', icon: 'pi pi-home' },
                { label: val.suppliers.value },
                { label: val.suppliers.items.consult_suppliers }
            ]
        });

        this.loading = true;
        let suppliersAux = [];

        this.supplierService.getAllSuppliers()
            .then(async suppliers => {
                await this.countryService.getCountries()
                    .then(data => {
                        this.countries = data;
                    })

                for (let supplier of suppliers) {

                    const sup: Supplier = {
                        id: supplier.id_supplier,
                        codigo_wms: supplier.codigo_wms,
                        name: supplier.name,
                        country_code: supplier.country_code,
                        nif: supplier.nif,
                        address: supplier.address,
                        city: supplier.city,
                        codigo_postal: supplier.codigo_postal,
                        phone: supplier.phone,
                        email: supplier.email,
                        transfer: supplier.transfer
                    }
                    suppliersAux.push(sup);
                }
                this.suppliersAux = suppliersAux;
                this.suppliers = suppliersAux;

                this.loading = false;
            });
    }

    itemClicked(event) {
        if (event.item.label === '') {
            this.router.navigate(['main']);
        }
    }

    async editSupplier(supplier: Supplier) {
        if (supplier.transfer == 1) {
            this.valCheck = this.translateService.instant("primeng.accept")
        } else {
            this.valCheck = this.translateService.instant("primeng.reject")
        }
        this.selectedCountry = null;
        let countryExists = this.countries.filter(e => e.country_code == supplier.country_code)
        if (countryExists.length != 0) {
            this.selectedCountry = supplier.country_code;
            await this.countryService.getCountrByCode(supplier.country_code)
                .then((country) => {
                    if (country != null) {
                        this.ccRegex = new RegExp(country.country_ziprule);
                        this.regex_example = country.country_ziperror;
                    }
                });
        }
        this.supplier = { ...supplier };
        this.supplierDialog = true;
    }

    hideDialog() {
        this.supplierDialog = false;
        this.submitted = false;
    }

    saveSupplier() {

        this.submitted = true;
        const invalidFields = document.getElementsByClassName("ng-invalid ng-dirty");
        let fieldsError = false;
        if (invalidFields.length != 0) {
            fieldsError = true;
        }
        if (!fieldsError) {

            let data = {
                id_supplier: this.supplier.id,
                name: this.supplier.name,
                country_code: this.selectedCountry,
                nif: this.supplier.nif,
                address: this.supplier.address,
                city: this.supplier.city,
                codigo_postal: this.supplier.codigo_postal,
                phone: this.supplier.phone,
                email: this.supplier.email,
                codigo_wms: this.supplier.codigo_wms
            }

            this.supplierService.putSupplier(data).subscribe(
                data => {
                    this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant("suppliers.success"), detail: this.translateService.instant("suppliers.update_success") });
                },
                error => {
                    this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("suppliers.error_update") });
                });

            const index = this.suppliers.findIndex(item => item.id === this.supplier.id);
            this.suppliers[index] = this.supplier;
            this.suppliers[index].country_code = this.selectedCountry;

            this.suppliers = [...this.suppliers];
            this.supplierDialog = false;
        } else {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: this.translateService.instant("suppliers.warning"), detail: this.translateService.instant("suppliers.warning_update") });
        }
    }

    async createZipRegex(supplier) {
        supplier.codigo_postal = null;
        await this.countryService.getCountrByCode(this.selectedCountry)
            .then((country) => {
                this.ccRegex = new RegExp(country.country_ziprule);
                this.regex_example = country.country_ziperror;
            });
    }

    clear(table: Table) {
        table.clear();
        this.filter.nativeElement.value = '';
    }
}