<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<div *ngIf="!loading" class="h-full">
	<p-table #dtusers [value]="users" [columns]="selectedColumns" dataKey="username" [rowHover]="true"
		[paginator]="true" [globalFilterFields]="['username','email']" [rows]="25" [rowsPerPageOptions]="[25,50,100]"
		[showCurrentPageReport]="true" [resizableColumns]="true" [reorderableColumns]="true" responsiveLayout="scroll"
		[(selection)]="selectedUsers" exportFilename="users" class="non-overflow"
		currentPageReportTemplate="{{ 'suppliers.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
		<ng-template pTemplate="caption">
			<div class="flex justify-content-between gap-2 flex-wrap">
				<div>
					<span class="p-input-icon-left w-full sm:w-auto">
						<i class="pi pi-search"></i>
						<input pInputText type="text" #filter
							(input)="dtusers.filterGlobal($event.target.value, 'contains')"
							placeholder="{{ 'general.search_here' | translate }}" />
					</span>
				</div>
				<div class="flex gap-2">
					<button pButton pRipple label="Export" class="p-button-outlined" (click)="exportCSV()">
						<span class="p-button-icon p-button-icon-left flex"><i class="pi pi-file-excel"></i></span>
					</button>
					<button pButton pRipple icon="pi pi-plus" label="Add one user" class="p-button-secundary"
						(click)="createUserDialog = true"></button>
					<!-- <p-splitButton label="Add one user" icon="pi pi-plus" [model]="this.items" class="p-button-outlined"
						(click)="userDialog = true">
						<span class="p-button-icon p-button-icon-left flex"><img
								src="assets/layout/images/plus-icon.svg"></span>
					</p-splitButton> -->
					<!-- <button pButton pRipple label="Manage role permissions" class="p-button-secundary">
						<span class="p-button-icon p-button-icon-left flex"><img
								src="assets/layout/images/users-icon.svg"></span>
					</button> -->
				</div>

			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th style="width: 1rem">
					<p-tableHeaderCheckbox (click)="mainCheckboxClick()"></p-tableHeaderCheckbox>
				</th>
				<th pResizableColumn pReorderableColumn [pSortableColumn]="col.sort ? col.field : null"
					*ngFor="let col of columns" class="cursor-move">
					<div class="flex align-items-center"
						[ngClass]="{'cursor-pointer' : col.sort || col.filter, 'p-column-filter-menu': col.filter }">
						{{col.header}}
						<p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
						<i *ngIf="col.filter && activeStatus == null" class="pi pi-filter"
							(click)="openOverlay($event, op)"></i>
						<i *ngIf="col.filter && activeStatus != null" class="pi pi-filter-fill"
							(click)="openOverlay($event, op)"></i>
					</div>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-item let-columns="columns">
			<tr class="cursor-pointer" (click)="editUser(item)">
				<td>
					<p-tableCheckbox [value]="item" (click)="checkboxClick($event)"></p-tableCheckbox>
				</td>
				<td *ngFor="let col of columns">
					<div *ngIf="col.field != 'username' && col.field != 'active'" class="flex align-items-center gap-1">
						<div *ngIf="col.field == 'active_description'" class="status-{{item[col.field]}}"></div>
						{{item[col.field]}}
					</div>
					<a *ngIf="col.field == 'username'">
						{{item[col.field]}}
					</a>
					<div *ngIf="col.field == 'active'" class="flex align-items-center gap-1">
						<div class="status-{{item.active}}"></div>
						<p-dropdown appendTo="body" *ngIf="col.field == 'active'" [options]="userStatus" (click)="stopOthersEvents($event)"
							(onChange)="updateUserStatus(item)" [(ngModel)]="item.active" optionLabel="name"
							optionValue="code" class="p-dropdown-text"></p-dropdown>
					</div>
				</td>
				<!-- <td></td> -->
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="8">{{ 'general.no_data' | translate }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr>
				<td colspan="8">{{ 'general.loading' | translate }}</td>
			</tr>
		</ng-template>
	</p-table>
</div>
<!-- <p-dialog [(visible)]="createUserDialog" [style]="{width: '740px', maxWidth: '90%', maxHeight: '100%', margin: '2rem'}"
	id="user_details" header="Register user" [modal]="true" [draggable]="false" [resizable]="false" class="p-fluid"
	(onHide)="hideDialog()">
	<ng-template pTemplate="content">
		<div class="flex flex-column gap-3">
			<div *ngIf="submitted && usernameError">
				<p-messages severity="error" [closable]="true">
					<ng-template pTemplate>
						<img src="assets/layout/images/error-icon.svg" width="22" />
						<div class="ml-2">Error creating user. A user with username <b>{{newUser.username}}</b> already
							exists.</div>
					</ng-template>
				</p-messages>
			</div>
			<div class="flex flex-row gap-3">
				<div class="w-6 flex flex-column gap-3">
					<div class="relative mb-3">
						<h6 for="username" class="flex mb-2"><small class="p-error">*</small>Username</h6>
						<input id="username" [(ngModel)]="newUser.username" type="text" pInputText
							class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && !newUser.username}">
						<small *ngIf="submitted && !newUser.username" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
					</div>
					<div class="relative mb-3">
						<h6 for="email" class="flex mb-2"><small class="p-error">*</small>Email</h6>
						<input id="email" [(ngModel)]="newUser.email" type="text" pInputText class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && (!newUser.email || !validateEmail(newUser.email))}">
						<small *ngIf="submitted && !newUser.email" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
						<small *ngIf="submitted && newUser.email && !validateEmail(newUser.email)"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Invalid
							email</small>
					</div>
					<div class="relative mb-3">
						<h6 for="confirm_email" class="flex mb-2"><small class="p-error">*</small>Confirm email</h6>
						<input id="confirm_email" [(ngModel)]="confirm_email" type="text" pInputText
							class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && (!confirm_email || !validateEmail(confirm_email) || !matchEmails())}">
						<small *ngIf="submitted && !confirm_email" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
						<small *ngIf="submitted && confirm_email && !validateEmail(confirm_email)"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Invalid
							email</small>
						<small *ngIf="submitted && confirm_email && validateEmail(confirm_email) && !matchEmails()"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Emails
							doesn't match</small>
					</div>
				</div>
				<div class="w-6 flex flex-column gap-3">
					<div class="relative mb-3">
						<h6 for="password" class="flex mb-2"><small class="p-error">*</small>Password</h6>
						<p-password id="password" [(ngModel)]="newUser.password" [toggleMask]="true" [feedback]="false"
							styleClass="w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && (!newUser.password || !validatePassword(newUser.password))}"></p-password>
						<small *ngIf="submitted && !newUser.password" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
						<small *ngIf="submitted && newUser.password && !validatePassword(newUser.password)"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Invalid
							password</small>
					</div>
					<div class="relative mb-3">
						<h6 for="confirm_password" class="flex mb-2"><small class="p-error">*</small>Confirm password
						</h6>
						<p-password id="confirm_password" [(ngModel)]="confirm_password" [toggleMask]="true"
							[feedback]="false" styleClass="w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && (!confirm_password || !validatePassword(confirm_password) || !matchPasswords())}"></p-password>
						<small *ngIf="submitted && !confirm_password" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
						<small *ngIf="submitted && confirm_password && !validatePassword(confirm_password)"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Invalid
							password</small>
						<small
							*ngIf="submitted && confirm_password && validatePassword(confirm_password) && !matchPasswords()"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Passwords
							doesn't match</small>
					</div>
					<div class="relative mb-3">
						<h6 for="group" class="flex mb-2">Group</h6>
						<p-multiSelect [options]="groups_permissions" [(ngModel)]="selectedGroup"
							(onChange)="selectPermissions()" defaultLabel="Select group permissions" [filter]="false"
							[showToggleAll]="true" class="multiselect-custom">
							<ng-template let-value pTemplate="selectedItems">
								<div class="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2"
									*ngFor="let option of selectedGroup">
									<div>{{ 'users.groups_description.' + option.id_group | translate }}</div>
								</div>
								<div *ngIf="!selectedGroup || selectedGroup.length === 0">
									{{ 'users.select_group_permissions' | translate }}
								</div>
							</ng-template>
							<ng-template let-group pTemplate="item">
								<div class="flex align-items-centerc">
									<div>{{ 'users.groups_description.' + group.id_group | translate }}</div>
								</div>
							</ng-template>
						</p-multiSelect>
					</div>
				</div>
			</div>
			<div class="relative w-full">
				<h6 for="permissions" class="flex mb-2"><small class="p-error">*</small>Permissions</h6>
				<div class=" grid">
					<div *ngFor="let permission of permissions" class=" field-checkbox col-6 md:col-3">
						<p-checkbox name="{{permission.id_permission}}" value="{{permission.id_permission}}"
							[value]="permission.id_permission" [(ngModel)]="selectedPermissions"
							[inputId]="permission.id_permission"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && selectedPermissions.length == 0}"
							(click)="validatePermission(permission.id_permission, $event)"></p-checkbox>
						<label [for]="permission.id_permission">{{ 'users.permissions_description.' +
							permission.id_permission | translate }}</label>
					</div>
				</div>
				<small *ngIf="submitted && selectedPermissions.length == 0"
					class="p-error absolute flex gap-1 mt-1"><img src="assets/layout/images/error-icon.svg">Required
					field</small>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<div class="flex justify-content-between">
			<div class="w-full text-left"><img src="assets/layout/images/info-icon.svg" class="mr-1">
				<small>Your password must be at least 8 characters, can’t begin or end with a space and include at
					least one: lower case letter, uppercase letter, number and special character.</small>
			</div>
			<div class="flex flex-nowrap">
				<button pButton pRipple type="button" label="Cancel" class="p-button-outlined p-button-danger"
					(click)="hideDialog()"></button>
				<button pButton pRipple label="Submit" type="submit" (click)="createUser()"></button>
			</div>
		</div>
	</ng-template>
</p-dialog> -->
<p-dialog [(visible)]="createUserDialog" [style]="{width: '740px', maxWidth: '90%', maxHeight: '100%', margin: '2rem'}"
	id="user_details" header="Invite a new user" [modal]="true" [draggable]="false" [resizable]="false" class="p-fluid"
	(onHide)="hideDialog()">
	<ng-template pTemplate="content">
		<div class="flex flex-column gap-3">
			<div *ngIf="submitted && usernameError">
				<p-messages severity="error" [closable]="true">
					<ng-template pTemplate>
						<img src="assets/layout/images/error-icon.svg" width="22" />
						<div class="ml-2">Error creating user. A user with username <b>{{newUser.username}}</b> already
							exists.</div>
					</ng-template>
				</p-messages>
			</div>
			<div class="flex flex-row gap-3">
				<div class="w-full flex flex-column gap-3">
					<div class="relative mb-3">
						<h6 for="email" class="flex mb-2"><small class="p-error">*</small>Email</h6>
						<input id="email" [(ngModel)]="newUser.email" type="text" pInputText class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && (!newUser.email || !validateEmail(newUser.email))}">
						<small *ngIf="submitted && !newUser.email" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
						<small *ngIf="submitted && newUser.email && !validateEmail(newUser.email)"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Invalid
							email</small>
					</div>
				</div>
			</div>
			<div class="relative w-full">
				<h6 for="permissions" class="flex mb-2"><small class="p-error">*</small>Permissions</h6>
				<div class=" grid">
					<div *ngFor="let permission of permissions" class=" field-checkbox col-6 md:col-3">
						<p-checkbox name="{{permission.id_permission}}" value="{{permission.id_permission}}"
							[value]="permission.id_permission" [(ngModel)]="selectedPermissions"
							[inputId]="permission.id_permission"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && selectedPermissions.length == 0}"
							(click)="validatePermission(permission.id_permission, $event)"></p-checkbox>
						<label [for]="permission.id_permission">{{ 'users.permissions_description.' +
							permission.id_permission | translate }}</label>
					</div>
				</div>
				<small *ngIf="submitted && selectedPermissions.length == 0"
					class="p-error absolute flex gap-1 mt-1"><img src="assets/layout/images/error-icon.svg">Required
					field</small>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<div class="flex justify-content-end">
			<div class="flex flex-nowrap">
				<button pButton pRipple type="button" label="Cancel" class="p-button-outlined p-button-danger"
					(click)="hideDialog()"></button>
				<button pButton pRipple label="Submit" type="submit" (click)="createUserInvite()"></button>
			</div>
		</div>
	</ng-template>
</p-dialog>
<p-dialog [(visible)]="editUserDialog" [style]="{width: '650px', margin: '0 2rem'}" id="user_details"
	header="{{ 'users.user_details' | translate }}" [modal]="true" [draggable]="false" [resizable]="false"
	class="p-fluid">
	<ng-template pTemplate="content">
		<div class="p-fluid p-formgrid grid">
			<div class="field col-12 md:col-6">
				<label for="username">{{ 'users.username' | translate }}</label>
				<input type="text" pInputText disabled id="username" [(ngModel)]="user.username" required>
			</div>
			<div class="field col-12 md:col-6">
				<label for="email">Email</label>
				<input type="text" pInputText disabled id="email" [(ngModel)]="user.email" required>
			</div>
			<div class="field col-2 md:col-2">
				<label for="active">{{ 'users.status' | translate }}</label>
				<p-dropdown appendTo="body" [options]="userStatus" [(ngModel)]="user.active" optionLabel="name" optionValue="code"
					class="p-dropdown-text"></p-dropdown>
			</div>
			<div class="field col-10 md:col-10">
				<label for="group">{{ 'users.group' | translate }}</label>
				<p-multiSelect [options]="groups_permissions" [(ngModel)]="selectedGroup"
					(onChange)="selectPermissions()" defaultLabel="Select group permissions" [filter]="false"
					[showToggleAll]="true" class="multiselect-custom">
					<ng-template let-value pTemplate="selectedItems">
						<div class="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2"
							*ngFor="let option of selectedGroup">
							<div>{{option.description}}</div>
						</div>
						<div *ngIf="!selectedGroup || selectedGroup.length === 0">
							{{ 'users.select_group_permissions' | translate }}
						</div>
					</ng-template>
					<ng-template let-group pTemplate="item">
						<div class="flex align-items-centerc">
							<div>{{ 'users.groups_description.' + group.id_group | translate }}</div>
						</div>
					</ng-template>
				</p-multiSelect>
			</div>
			<div class="field col-12 md:col-12">
				<label for="permissions">{{ 'users.permissions' | translate }} <small>*</small></label>
				<div class=" grid">
					<div *ngFor="let permission of permissions" class="field-checkbox col-6 md:col-4">
						<p-checkbox name="{{permission.id_permission}}" value="{{permission.id_permission}}"
							[value]="permission.id_permission" [(ngModel)]="selectedPermissions"
							[inputId]="permission.id_permission" required
							[ngClass]="{'ng-invalid ng-dirty' : submitted && !selectedPermissions}"
							(click)="validatePermission(permission.id_permission)"></p-checkbox>
						<label [for]="permission.id_permission">{{ 'users.permissions_description.' +
							permission.id_permission | translate }}</label>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<button pButton pRipple type="button" label="Cancel" class="p-button-outlined p-button-danger"
			(click)="editUserDialog = false"></button>
		<button pButton pRipple label="Save" type="submit" class="p-button-text" (click)="saveUser()"></button>
	</ng-template>
</p-dialog>
<p-dialog [(visible)]="userDialog" [style]="{width: '720px', margin: '0 2rem'}" id="user_details" header="Register user"
	[modal]="true" [draggable]="false" [resizable]="false" class="p-fluid" (onHide)="hideDialog()">
	<ng-template pTemplate="content">
		<div class="flex flex-row gap-3">
			<div class="w-6 flex flex-column gap-3">
				<div class="relative mb-3">
					<h6 for="first_name" class="flex mb-2"><small class="p-error">*</small>First name</h6>
					<input id="first_name" [(ngModel)]="newUser.first_name" type="text" pInputText
						class="p-inputtext w-full"
						[ngClass]="{'ng-invalid ng-dirty' : submitted && !newUser.first_name}">
					<small *ngIf="submitted && !newUser.first_name" class="p-error absolute flex gap-1 mt-1"><img
							src="assets/layout/images/error-icon.svg">Required field</small>
				</div>
				<div class="relative mb-3">
					<h6 for="last_name" class="flex mb-2"><small class="p-error">*</small>Last name</h6>
					<input id="last_name" [(ngModel)]="newUser.last_name" type="text" pInputText
						class="p-inputtext w-full"
						[ngClass]="{'ng-invalid ng-dirty' : submitted && !newUser.last_name}">
					<small *ngIf="submitted && !newUser.last_name" class="p-error absolute flex gap-1 mt-1"><img
							src="assets/layout/images/error-icon.svg">Required field</small>
				</div>
				<div class="relative mb-3">
					<h6 for="username" class="flex mb-2"><small class="p-error">*</small>Username</h6>
					<input id="username" [(ngModel)]="newUser.username" type="text" pInputText
						class="p-inputtext w-full" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newUser.username}">
					<small *ngIf="submitted && !newUser.username" class="p-error absolute flex gap-1 mt-1"><img
							src="assets/layout/images/error-icon.svg">Required field</small>
				</div>
				<div class="relative mb-3">
					<h6 for="password" class="flex mb-2"><small class="p-error">*</small>Password</h6>
					<p-password id="password" [(ngModel)]="newUser.password" [toggleMask]="true" [feedback]="false"
						styleClass="w-full"
						[ngClass]="{'ng-invalid ng-dirty' : submitted && (!newUser.password || !validatePassword(newUser.password))}"></p-password>
					<small *ngIf="submitted && !newUser.password" class="p-error absolute flex gap-1 mt-1"><img
							src="assets/layout/images/error-icon.svg">Required field</small>
					<small *ngIf="submitted && newUser.password && !validatePassword(newUser.password)"
						class="p-error absolute flex gap-1 mt-1"><img src="assets/layout/images/error-icon.svg">Invalid
						password</small>
				</div>
				<div class="relative mb-3">
					<h6 for="confirm_password" class="flex mb-2"><small class="p-error">*</small>Confirm password</h6>
					<p-password id="confirm_password" [(ngModel)]="confirm_password" [toggleMask]="true"
						[feedback]="false" styleClass="w-full"
						[ngClass]="{'ng-invalid ng-dirty' : submitted && (!confirm_password || !validatePassword(confirm_password) || !matchPasswords())}"></p-password>
					<small *ngIf="submitted && !confirm_password" class="p-error absolute flex gap-1 mt-1"><img
							src="assets/layout/images/error-icon.svg">Required field</small>
					<small *ngIf="submitted && confirm_password && !validatePassword(confirm_password)"
						class="p-error absolute flex gap-1 mt-1"><img src="assets/layout/images/error-icon.svg">Invalid
						password</small>
					<small
						*ngIf="submitted && confirm_password && validatePassword(confirm_password) && !matchPasswords()"
						class="p-error absolute flex gap-1 mt-1"><img
							src="assets/layout/images/error-icon.svg">Passwords
						doesn't match</small>
				</div>
			</div>
			<div class="w-6 flex flex-column gap-3">
				<div class="relative mb-3">
					<h6 for="email" class="flex mb-2"><small class="p-error">*</small>Email</h6>
					<input id="email" [(ngModel)]="newUser.email" type="text" pInputText class="p-inputtext w-full"
						[ngClass]="{'ng-invalid ng-dirty' : submitted && (!newUser.email || !validateEmail(newUser.email))}">
					<small *ngIf="submitted && !newUser.email" class="p-error absolute flex gap-1 mt-1"><img
							src="assets/layout/images/error-icon.svg">Required field</small>
					<small *ngIf="submitted && newUser.email && !validateEmail(newUser.email)"
						class="p-error absolute flex gap-1 mt-1"><img src="assets/layout/images/error-icon.svg">Invalid
						email</small>
				</div>
				<div class="relative mb-3">
					<h6 for="confirm_email" class="flex mb-2"><small class="p-error">*</small>Confirm email</h6>
					<input id="confirm_email" [(ngModel)]="confirm_email" type="text" pInputText
						class="p-inputtext w-full"
						[ngClass]="{'ng-invalid ng-dirty' : submitted && (!confirm_email || !validateEmail(confirm_email) || !matchEmails())}">
					<small *ngIf="submitted && !confirm_email" class="p-error absolute flex gap-1 mt-1"><img
							src="assets/layout/images/error-icon.svg">Required field</small>
					<small *ngIf="submitted && confirm_email && !validateEmail(confirm_email)"
						class="p-error absolute flex gap-1 mt-1"><img src="assets/layout/images/error-icon.svg">Invalid
						email</small>
					<small *ngIf="submitted && confirm_email && validateEmail(confirm_email) && !matchEmails()"
						class="p-error absolute flex gap-1 mt-1"><img src="assets/layout/images/error-icon.svg">Emails
						doesn't match</small>
				</div>
				<div class="flex flex-row gap-1 mb-3">
					<div class="relative w-8">
						<h6 for="work_phone" class="flex mb-2">Work phone</h6>
						<p-inputMask mask="999 999 999" [(ngModel)]="newUser.work_phone"></p-inputMask>
					</div>
					<div class="relative w-4">
						<h6 for="extension" class="flex mb-2">Extension</h6>
						<input id="extension" [(ngModel)]="newUser.extension" type="text" pInputText
							class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && !newUser.extension}">
						<small *ngIf="submitted && !newUser.extension" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
					</div>
				</div>
				<div class="relative mb-3">
					<h6 for="timezone" class="flex mb-2"><small class="p-error">*</small>Timezone</h6>
					<input id="timezone" [(ngModel)]="newUser.timezone" type="text" pInputText
						class="p-inputtext w-full" [ngClass]="{'ng-invalid ng-dirty' : submitted && !newUser.timezone}">
					<small *ngIf="submitted && !newUser.timezone" class="p-error absolute flex gap-1 mt-1"><img
							src="assets/layout/images/error-icon.svg">Required field</small>
				</div>
				<div class="relative mb-3">
					<h6 for="role" class="flex mb-2"><small class="p-error">*</small>Role</h6>
					<p-dropdown appendTo="body" [options]="roles" [(ngModel)]="newUser.role" optionLabel="name" placeholder=" "
						[ngClass]="{'ng-invalid ng-dirty' : submitted && !newUser.role}"></p-dropdown>
					<small *ngIf="submitted && !newUser.role" class="p-error absolute flex gap-1 mt-1"><img
							src="assets/layout/images/error-icon.svg">Required field</small>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<div class="flex justify-content-between">
			<div class="w-full text-left"><img src="assets/layout/images/info-icon.svg" class="mr-1">
				<small>Your password must be at least 8 characters, can’t begin or end with a space and include at
					least one: lower case letter, uppercase letter, number and special character.</small>
			</div>
			<div class="flex flex-nowrap">
				<button pButton pRipple type="button" label="Cancel" class="p-button-outlined p-button-danger"
					(click)="hideDialog()"></button>
				<button pButton pRipple label="Submit" type="submit" (click)="createUser()"></button>
			</div>
		</div>
	</ng-template>
</p-dialog>
<p-overlayPanel #op>
	<ng-template pTemplate>
		<div class="field-radiobutton">
			<p-radioButton name="activeStatus" value="1" [(ngModel)]="activeStatus" inputId="1"
				(click)="filterTable()"></p-radioButton>
			<label for="1">Active</label>
		</div>
		<div class="field-radiobutton">
			<p-radioButton name="activeStatus" value="0" [(ngModel)]="activeStatus" inputId="0"
				(click)="filterTable()"></p-radioButton>
			<label for="0">Inactive</label>
		</div>
		<button pButton pRipple type="button" label="Clear" class="p-button-text p-0" [disabled]="activeStatus == null"
			(click)="clearFilter($event, op)"></button>
	</ng-template>
</p-overlayPanel>