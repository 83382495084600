import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root'
})
export class OrdersBrandService {

    constructor(private http: HttpClient) { }

    async getOrdersByDate(dateI, dateF, page, numberRows, getFulfilled, getRTO){
        let info={
            dateI, dateF, page, numberRows, getFulfilled, getRTO
        }

        //let data = await this.http.get<any>(AUTH_API + 'order/date/?inicial_date='+ dateI +'&final_date='+ dateF).toPromise();
        let data = await this.http.put<any>(AUTH_API + 'order/orderDate',info ).toPromise();
        return data;
    }

    async getReturnsByDate(dateI, dateF){

        let data = await this.http.get<any>(AUTH_API + 'return/date/?inicial_date='+ dateI +'&final_date='+ dateF).toPromise();
        return data;
    }

    putOrder(order){
        
        let data = this.http.put<any>(AUTH_API + 'order', order);
        return data;
    }

    async getCountryByCode(codeCountry){

        let data = await this.http.get<any>(AUTH_API + 'country/?countryCode='+ codeCountry).toPromise();
        return data;
    }

    async getOrderBrandLabel(id_order: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'order/orderbrandlabel?id_order=' + id_order)
            .toPromise();
        return data;
    }

    async getOrderBrandPOD(id_order: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'order/orderbrandpod?id_order=' + id_order)
            .toPromise();
        return data;
    }

    async getOrderBrandProforma(id_order: any, moloni_sent: any) {
        let data = await this.http
            .get<any>(AUTH_API + 'order/orderbrandproforma?id_order=' + id_order + '&document_id=' + moloni_sent)
            .toPromise();
        return data;
    }

    async postRTO(id_order: any){

        let data = await this.http.post<any>(AUTH_API + 'order/createrto?id_order=' + id_order, null).toPromise();
        return data;
    }

    async wareHouse(id_order:any){
        try {
        let data = await this.http.post<any>(AUTH_API + 'order/localpickup?id_order=' + id_order, null).toPromise();
        return data;
        } catch (error) {
            console.log(error);
            return {value:null, error_:error};
        }
    }

    async getInvoice(id_order:any){
        
    const data = await this.http.get<any>(AUTH_API + 'order/invoice?id_order=' + id_order).toPromise();
    return data;
    }

    async getGenerateLabel(id_order:any, weight:any, packages:any){
        let data = await this.http.get<any>(AUTH_API + 'order/generatelabel?id_order='+id_order+'&weight='+weight+'&packages='+packages).toPromise();
        return data;
    }

    async getManifestAmbar(){
        let data = await this.http.get<any>(AUTH_API + 'order/manifestAmbar').toPromise();
        return data;
    }
}
