import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';

import { Table } from 'primeng/table';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { User } from 'src/app/api/user';
import { UserService } from 'src/app/services/user/user.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TokenStorageService } from 'src/app/services/token-storage/token-storage.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'pages-my-account',
    templateUrl: './my_account.component.html',
    styleUrls: ['./my_account.component.sass']
})
export class MyAccountComponent implements OnInit {

    displayModal: boolean = false;

    first_name: string = null;

    last_name: string = null;

    email: string = null;

    timezone: string = null;

    extension: string = null;

    work_phone: string = null;

    current_password: string;

    new_password: string;

    confirm_password: string;

    password_match: boolean = true;

    current_password_error: boolean = false;

    submitted: boolean = false;

    constructor(
        private tokenService: TokenStorageService,
        private userService: UserService,
        private authService: AuthService,
        private router: Router,
    ) { }

    async ngOnInit() {
    }

    openDisplayModal() {
        this.displayModal = true;
        this.current_password = null, this.new_password = null, this.confirm_password = null;
    }
    async submitPassword() {
        this.submitted = true;

        if (this.new_password == this.confirm_password && this.new_password.length <= 30) {
            this.password_match = true;
            let passwordUpdateUser = {
                data: {
                    old_password: this.current_password,
                    password: this.new_password
                }
            }
            this.userService.putPasswordUser(passwordUpdateUser).subscribe(
                data => {
                    this.displayModal = false;
                    this.current_password_error = false;
                    this.password_match = true;
                },
                error => {
                    this.current_password_error = true;
                })
        } else {
            this.password_match = false;
        }
    }

    validatePassword() {
        var passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?()/=",.;:_\\])[A-Za-z\d#$@!%&*?()/=",.;:_\\]{8,}$/;
        if (passwordRegex.test(this.new_password)) {
            return true
        } else {
            return false
        }
    }

    logOut() {
        this.authService.signOut().subscribe(() => {
            this.router.navigate(['login']);
        });
        this.tokenService.signOut();
    }
}