import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Return } from 'src/app/api/return';
import { AppMainComponent } from 'src/app/app.main.component';
import { BrandService } from 'src/app/services/brands/brand.service';
import { ReturnService } from 'src/app/services/return/return.service';

@Component({
    selector: 'pages-returns',
    templateUrl: './returns.component.html',
    styleUrls: ['returns.component.scss'],
})
export class ReturnsComponent implements OnInit {
    pipe = new DatePipe('en-US');

    rangeDates: Date[] = [
        new Date(new Date().setDate(new Date().getDate() - 31)),
        new Date(),
    ];

    dateIAux: string;

    dateFAux: string;

    allReturnsAux: any = [];

    returnsAux: any;

    returns: any;

    return: any;

    submitted: boolean;

    loading: boolean = true;

    loadingSidebar: boolean = false;

    loadingManualReveive: boolean = false;

    returnsStatus: any;

    statusOptions: any = [];

    paymentOptions: any = [];

    idBrand: any;

    statusReturn: any;

    selectedReturns: any[] = [];

    selectedReturn: any;

    visibleSidebar: boolean = false;

    cols: any = [];

    colsOptional: any = [];

    selectedColumns: any = [];

    _selectedColumnsAux: any = [];

    editTrackingDialog: boolean = false;

    show_IBAN: boolean = false;

    dataHistory: any[] = [];

    isHistoryTrue: boolean = false;

    loadingHistory: boolean = false;

    historyIcons = [
        { id: [0], icon: "pi pi-shopping-cart" },
        { id: [1], icon: "pi pi-clock" },
        { id: [16], icon: "pi pi-times" },
        { id: [2, 9], icon: "pi pi-check" },
        { id: [3], icon: "pi pi-spin pi-spinner" },
        { id: [98], icon: "pi pi-inbox" },
        { id: [99], icon: "pi pi-ban" }
    ]

    dropdown_options: any = [
        {
            id: 'resend_email',
            label: 'Resend Email Received',
            icon: 'pi pi-check',
            command: () => {
                this.EmailReturnLabel();
            },
            disabled: false,
            visible: true
        },
        {
            id: 'wms',
            label: 'Resend to WMS',
            icon: 'pi pi-box',
            command: () => {
                this.ReturnWms();
            },
            disabled: false,
            visible: true
        },
        {
            id: 'label',
            label: 'Download Labels',
            icon: 'pi pi-file',
            command: () => {
                this.ReturnLabel();
            },
            disabled: false,
            visible: true
        },
        {
            id: 'refunded',
            label: 'Refunded',
            icon: 'pi pi-check-circle',
            command: () => {
                this.refunded();
            },
            disabled: false,
            visible: true
        },
        {
            id: 'history_',
            label: 'History',
            icon: 'pi pi-history',
            command: () => {
                this.callHistory();
            },
            disabled: false,
            visible: true
        },
        {
            separator: true
        },
        {
            id: 'editreturn',
            label: 'Edit Return',
            icon: 'pi pi-pencil',
            command: () => {
                this.editReturn();
                window.location.reload();
            },
            disabled: true,
            visible: true
        },
        {
            id: 'editTracking',
            label: 'Edit Tracking',
            icon: 'pi pi-pencil',
            command: () => {
                this.openEditTrackingDialog();
            },
            disabled: false,
            visible: true
        },
        {
            separator: true
        },
        {
            id: 'cancelReturn',
            label: 'Cancel Return',
            icon: 'pi pi-times',
            command: () => {
                this.cancelReturn();
            },
            disabled: false,
            visible: true
        },
        {
            id: 'archiveReturn',
            label: 'Archive Return',
            icon: 'pi pi-inbox',
            command: () => {
                this.archiveReturn();
            },
            disabled: false,
            visible: true
        },
        {
            id: 'resellItems',
            label: 'Resell items',
            icon: 'pi pi-inbox',
            command: () => {
                this.openResellItemsDialog();
            },
            disabled: true,
            visible: false
        }
    ];

    displayAcceptRefund: boolean = false;

    optionsRefund: any;

    optionSelect: any;

    displayAcceptExchange: boolean = false;

    variantSizeSelect: any = [];

    selectedNotExpectedItem: any;

    quantityArrayNotExpectedItem: any = [1];

    quantityNotExpectedItem: number = 1;

    subtotalRefundNotExpectedItem: number = 0;

    totalRefundNotExpectedItem: number = 0;

    submittedDialog: boolean = false;

    arraySizesCount = [0]

    totalQuantitySelectedNotExpectedItem: number = 1;

    hasExchange: any = [];

    notExpectedOptions: any = [
        {
            id: 'acceptExchange',
            label: 'Accept Exchange',
            icon: 'pi pi-pencil',
            command: () => {
                this.acceptExchange();;
            },
            disabled: false
        },
        {
            id: 'acceptRefund',
            label: 'Accept Refund',
            icon: 'pi pi-pencil',
            command: () => {
                this.acceptRefund();
            },
            disabled: false
        }
    ];

    carrierLink = new Map([
        [
            'DHL Parcel',
            'https://clientesparcel.dhl.es/LiveTrackingN/ModifyMyShipment/',
        ],
        [
            'DHL Express',
            'https://www.dhl.com/us-en/home/tracking.html?tracking-id=',
        ],
        ['DPD', 'https://dpd.pt/track-and-trace?reference='],
    ]);

    subtotal: string = '0';

    processing_fee: number = 0;

    refund_total: number = 0;

    approved = [];

    refused = [];

    line_images: any = [];

    refund_items: number = 0;

    order_exc_detail: any = {};

    order_detail_receive: any = [];

    order_items: any = [];

    all_exchange = false;

    submittedLabel = false;

    displayLabel = false;

    displayTracking = false;

    labelUpload = []

    labelAuto = true;

    idTracking: any;

    selectedItemReceive = "0";

    selectedItemAdd = false;

    displayReceive = false;

    expected_items: any = [];

    unexpected_items: any = [];

    unexpected_items_loaded = false;

    received_temp_item: any = {};

    received_items: any = [];

    variantQuantities: any;

    itemsToExchangeCount: number = 0;

    itemsApprovedCount: number = 0;

    itemsRejectedCount: number = 0;

    menuShow: boolean = false;

    comment: string = '';

    comments: Comment[] = [];

    items = [
        { label: 'Update', icon: 'pi pi-refresh' },
        { label: 'Delete', icon: 'pi pi-times' },
        { label: 'Angular.io', icon: 'pi pi-info' },
        { separator: true },
        { label: 'Setup', icon: 'pi pi-cog' }
    ];

    new_tracking_id = null;

    updateLine: boolean = false;

    @ViewChild('dtreturns') table: Table;

    @ViewChild('filter') filter: ElementRef;

    maxLength: number = 256;

    remainingChars: number = 256;

    resellItems: any;

    resellItemsDialog: boolean = false;

    selectedResellItems: any[] = [];

    newResellPrice: number = 25;

    conditions: any[] = [{ id: 0, description: 'New with tags' }, { id: 1, description: 'Excellent' }]

    constructor(
        private app: AppMainComponent,
        private readonly translateService: TranslateService,
        private messageService: MessageService,
        private returnService: ReturnService,
        private brandService: BrandService,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private fb: FormBuilder
    ) { }

    userComments: string = window.sessionStorage.getItem('username');

    async submitComment(): Promise<void> {
        if (this.comment.length > 0) {
            let newComment: Comment = {
                author: window.sessionStorage.getItem('username'),
                text: this.comment,
                date: this.pipe.transform(Date(), 'yyyy-MM-dd HH:mm:ss'),
                id_return: this.selectedReturn.idInterno,
                id_user: Number(window.sessionStorage.getItem("id_user"))
            };

            await this.returnService.postCommentReturn(newComment);

            this.comments = await this.returnService.getCommentReturn(this.selectedReturn.idInterno);
            this.formateDateComments(this.comments)
            this.comment = '';
            this.remainingChars = this.maxLength;
        }
    }

    copiedToclipboard() {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Copied to clipboard.' });
    }

    formateDateComments(comment: Comment[]) {
        this.comments = comment.map(comment => {
            return {
                ...comment,
                date: this.app.getDateOnFormatString(comment.date)
            };
        });
    }

    async initComments(id_return: number) {
        this.loadingSidebar = true;
        this.comments = await this.returnService.getCommentReturn(id_return);
        this.formateDateComments(this.comments);
        this.loadingSidebar = false;
    }

    async deleteComment(comment: any) {
        // comment.id_return= this.selectedReturn.idInterno;
        //comment.date = this.pipe.transform(comment.date, 'yyyy-MM-dd HH:mm:ss');
        await this.returnService.deleteCommentReturn(comment);
        this.comments = await this.returnService.getCommentReturn(this.selectedReturn.idInterno);
        this.formateDateComments(this.comments);
    }

    disableDelComment(comment: any): boolean {
        if (window.sessionStorage.getItem("id_user") == comment.id_user) return false;
        return true;
    }

    async callHistory() {
        document.getElementsByTagName('body')[0].classList.add('fixed')
        this.IsHistoryTrue();
        await this.getHistoryJournalReturns(this.return.id_return)
    }

    async initReturns() {
        this.loading = true
        this.cols = [
            { field: 'idInterno', header: 'Subm. ID', sort: true, filter: false }
        ]

        this.colsOptional = [
            { field: 'order_name', header: 'Order', sort: true, filter: false },
            { field: 'customer', header: 'Customer', sort: true, filter: false },
            { field: 'country', header: 'Country', sort: true, filter: false },
            { field: 'items', header: 'Items', sort: true, filter: false },
            { field: 'refund_total', header: 'Refund Total', sort: true, filter: false },
            { field: 'dataCriacao', header: 'Request Date', sort: true, filter: false },
            { field: 'returnStatus', header: 'Status', sort: true, filter: false },
            { field: 'wmsStatus', header: 'WMS Status', sort: true, filter: false }
        ];
        this.selectedColumns = this.cols.concat(this.colsOptional)
        this._selectedColumnsAux = this.cols.concat(this.colsOptional)

        this.idBrand = this.brandService.getBrand();

        await this.returnService.getReturnStatus().then((status) => {
            this.returnsStatus = status;
            for (let returnStatus of status) {
                this.statusOptions.push({
                    label: returnStatus.description,
                    value: returnStatus.description,
                });
            }
        });

        const dateI = this.pipe.transform(this.rangeDates[0], 'yyyy-MM-dd');
        let dateF;
        if (this.rangeDates[1] == null) {
            dateF = dateI;
        } else {
            dateF = this.pipe.transform(this.rangeDates[1], 'yyyy-MM-dd');
        }

        //if (dateI != this.dateIAux || dateF != this.dateFAux || refresh) {
        await this.returnService
            .getReturns(this.idBrand, dateI, dateF)
            .then((returns) => {
                this.returnsAux = [];
                var paymentOptions = Array.from(new Set(returns.map(({ payment_description }) => payment_description)));
                paymentOptions.map(payment => {
                    this.paymentOptions.push({ value: payment, label: payment })
                })
                returns.map((ret) => {

                    const orderReturn: any = {
                        idInterno: ret.id_return,
                        IDStatus: ret.IDStatus,
                        returnStatusID: ret.IDStatus,
                        returnStatus: this.returnsStatus.find((r: any) => r.IDStatus == ret.IDStatus).description,
                        orderID: ret.order_id,
                        items: ret.line_items.length,
                        customer: ret.email,
                        country: ret.country,
                        returnPaymentMethod: ret.payment_description,
                        refund_total: Number(ret.refund_total),
                        currency: ret.currency,
                        name: ret.first_name + ' ' + ret.last_name,
                        //dataCriacao: this.app.getDateOnFormatString(ret.started_at),
                        dataCriacao: ret.started_at,
                        order_name: ret.order_name,
                        archived: ret.archived,
                        error: ret.error,
                    };
                    this.returnsAux.push(orderReturn);
                    
                });
                var sortingArr = [1, 49, 53, 4, 52, 0, 55, 13, 51, 9, 99, 98, 16]
                this.returns = this.returnsAux.sort(
                    (a, b) =>
                        sortingArr.indexOf(a.IDStatus) - sortingArr.indexOf(b.IDStatus) ||
                        Number(b.idInterno) - Number(a.idInterno)
                );
            });
        //}
        this.dateIAux = dateI;
        this.dateFAux = dateF;
        this.loading = false;

        const orderStatusFields =
            document.getElementsByClassName('order-status');
        const arr = Array.from(orderStatusFields);
        arr.map((orderStatusField) => {
            orderStatusField.removeAttribute('disabled');
        });

    }

    async ngOnInit() {
        this.dataHistory = [];
        await this.initReturns();
    }

    filterTable(e) {
        var returnStatus = this.statusReturn[e['element'].index];
        var returnsFilters = this.allReturnsAux.filter((e) => e.returnStatus == returnStatus);
        function arrayEquals(a, b) {
            return Array.isArray(a) &&
                Array.isArray(b) &&
                a.length === b.length &&
                a.every((val, index) => val === b[index]);
        }

        var filterEquals = arrayEquals(this.returns, returnsFilters);
        if (filterEquals) {
            this.returns = this.returnsAux
        } else {
            this.returns = returnsFilters
        }
    }

    newReturn() {
        const returncenter_url = window.sessionStorage.getItem("returncenter_url");

        if (returncenter_url == undefined) {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: this.translateService.instant('orders.returns.noreturncenter_url'),
            });

            return;
        }

        window
            .open(returncenter_url, '_blank')
            .focus();
    }

    async filterReturnsByStatus(returnStatus) {
        var returnsAux = [];
        await this.returnService
            .getAllReturns()
            .then((returns) => {
                returns.map((ret) => {
                    if (ret.IDStatus != 99 && ret.IDStatus != 98) {
                        const orderReturn: Return = {
                            idInterno: ret.id_return,
                            IDStatus: ret.IDStatus,
                            returnStatusID: ret.IDStatus,
                            returnStatus: this.returnsStatus.find((r: any) => r.IDStatus == ret.IDStatus).description,
                            orderID: ret.order_id,
                            customer: ret.email,
                            country: ret.country,
                            returnPaymentMethod: ret.payment_description,
                            refund_total: Number(ret.refund_total),
                            currency: ret.currency,
                            dataCriacao: ret.started_at,
                            order_name: ret.order_name,
                            archived: ret.archived,
                            error: ret.error,
                        };
                        returnsAux.push(orderReturn);
                    }
                });

                const orderStatusFields =
                    document.getElementsByClassName('order-status');

                const arr = Array.from(orderStatusFields);
                arr.map((orderStatusField) => {
                    if (orderStatusField.id == returnStatus) {
                        if (!orderStatusField.classList.contains('active')) {
                            orderStatusField.classList.add('active');
                        } else {
                            orderStatusField.classList.remove('active');
                        }
                    } else {
                        orderStatusField.classList.remove('active');
                    }
                });

                var orderStatusField = document.getElementById(returnStatus);
                if (orderStatusField.classList.contains('active')) {
                    if (returnStatus == 'total') {
                        this.returns = returnsAux.sort(
                            (a, b) =>
                                Number(a.IDStatus) - Number(b.IDStatus) ||
                                Number(a.idInterno) - Number(b.idInterno)
                        );
                    } else if (returnStatus == 'pending') {
                        this.returns = returnsAux.filter(
                            (e) => e.returnStatusID == 1
                        );
                    }
                } else {
                    this.returns = this.returnsAux;
                }
            });
    }

    async updateLineStatus(IDStatus: any, return_id: any, return_line_id: any) {
        this.updateLine = true
        let line = {
            IDStatus:
                IDStatus == 1
                    ? 2
                    : IDStatus == 49
                        ? 50
                        : IDStatus == 50
                            ? 49
                            : 1,
            id_return: return_id,
            id_return_line: return_line_id,
        };

        await this.returnService.putReturnLinesStatus(line)
            .then(async (returnList) => {
                {
                    await this.returnLines(return_id);
                    this.updateLine = false
                    var returnAux = this.return.lines.find(ret => ret.id_return_line == return_line_id)
                    if (line.IDStatus == 50 || line.IDStatus == 2) {
                        this.itemsApprovedCount = this.itemsApprovedCount + returnAux.quantity
                        this.itemsRejectedCount = this.itemsRejectedCount - returnAux.quantity
                    } else if (line.IDStatus == 1 || line.IDStatus == 49) {
                        this.itemsApprovedCount = this.itemsApprovedCount - returnAux.quantity
                        this.itemsRejectedCount = this.itemsRejectedCount + returnAux.quantity
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async refreshReturns() {
        await this.ngOnInit();
    }

    clear(table: Table) {
        table.clear();
        this.filter.nativeElement.value = '';
    }


    openOverlay(event, op) {
        event.stopPropagation();
        op.toggle(event)
    }

    showOverlay() {
        this.app.overlayActive = true
    }

    hideOverlay() {
        this.app.overlayActive = false
    }

    @Input() get selectedColumnsAux(): any[] {
        return this._selectedColumnsAux;
    }

    set selectedColumnsAux(val: any[]) {
        //restore original order
        this._selectedColumnsAux = this.colsOptional.filter(col => val.includes(col));
    }

    customize(event, op) {
        this.selectedColumns = this.cols.concat(this._selectedColumnsAux)
        this.app.overlayActive = false
        op.hide(event)
    }

    revert(event, op) {
        this.selectedColumns = this.cols.concat(this.colsOptional)
        this._selectedColumnsAux = this.cols.concat(this.colsOptional)
        this.app.overlayActive = false
        op.hide(event)
    }

    async openSideBar(selectedReturn) {
        await this.initComments(selectedReturn.idInterno);
        this.menuShow = false
        this.return = null
        this.visibleSidebar = true
        this.selectedReturn = selectedReturn
        document.getElementsByTagName('body')[0].classList.add('fixed')
        await this.returnLines(selectedReturn.idInterno)
        var linesStatus = this.return.lines.map(line => line.IDStatus)
        this.itemsApprovedCount = linesStatus.filter(status => [2, 50, 51, 52, 53, 54, 55, 57].includes(status)).length
        this.itemsRejectedCount = linesStatus.filter(status => [1, 49, 56].includes(status)).length
        this.itemsToExchangeCount = linesStatus.length
    }

    hideSideBar() {
        this.visibleSidebar = false;
        document.getElementsByTagName('body')[0].classList.remove('fixed');
        this.comment = ''
    }

    hideSidebarHistory() {
        this.isHistoryTrue = false;
        // document.getElementsByTagName('body')[0].classList.remove('fixed');
    }

    openMenu(event) {
        event.stopPropagation()
        this.menuShow = !this.menuShow
    }

    async returnLines(id_return: any) {
        this.show_IBAN = false;
        await this.returnService.getReturnLines(id_return).then(
            async (returnline) => {
                this.return = returnline.data;
                this.return.order_created_at = this.app.getDateOnFormatString(this.return.order_created_at)
                this.return.carrier_link = this.carrierLink.get('DHL Parcel');

                if (this.return.lines.length > 0) {
                    // check if all lines are exchange
                    this.all_exchange = true;
                    for (let line of this.return.lines) {
                        if (![49, 50, 51, 52, 53, 54, 55, 56, 57, 16, 11, 12, 10, 9].includes(line.IDStatus)) {
                            this.all_exchange = false;
                            break;
                        }
                        if (line.IDStatus == 9 && !line.return_reason_text.includes('(EXC)')) {
                            this.all_exchange = false;
                            break;
                        }
                    }

                    // check for extra lines received from WMS service endpoint
                    this.return.linesExtra = [];
                    for (let line of this.return.lines) {
                        if ([14].includes(line.IDStatus)) {
                            line.variantSizeSelect = [];
                            line.arraySizesCount = [0];
                            line.availableQuantityNotExpectedItemAux = line.quantityReceived
                            line.totalQuantitySelectedNotExpectedItem = 1
                            line.quantityArrayNotExpectedItem = [1]
                            line.quantityNotExpectedItem = 1
                            line.notExpectedOptions = [
                                {
                                    id: 'acceptExchange',
                                    label: 'Accept Exchange',
                                    icon: 'pi pi-pencil',
                                    command: () => {
                                        this.acceptExchange();;
                                    },
                                    disabled: false
                                },
                                {
                                    id: 'acceptRefund',
                                    label: 'Accept Refund',
                                    icon: 'pi pi-pencil',
                                    command: () => {
                                        this.acceptRefund();
                                    },
                                    disabled: false
                                }
                            ]
                            this.return.linesExtra.push(line);
                        }
                    }
                }

                // receive items manualy
                if (this.return.logistic_op == "") {

                    let receive_exist = false;
                    for (let options of this.dropdown_options) {
                        if (options.id == 'receive') {
                            receive_exist = true;
                            break;
                        }
                    }

                    if (!receive_exist) {
                        this.dropdown_options.push({
                            id: 'receive',
                            label: 'Receive (manual)',
                            icon: 'pi pi-arrow-circle-down',
                            command: () => {
                                this.ReturnReceive();
                            },
                            disabled: false,
                            visible: true
                        })
                    }
                }
                this.CalculateTotals();
                this.options_disable();
            }
        ).catch((error) => {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: error.error,
            });
        });
    }

    filterHistory() {
        const timeThreshold = 30; // time limit in seconds
        const filteredHistory = this.dataHistory.filter((item, index) => {
            if (index === 0) {
                // remove second duplicate item
                return true;
            }
            const currentItemTime = new Date(item.change_timestamp);
            const prevItemTime = new Date(this.dataHistory[index - 1].change_timestamp);
            const timeDifference = (prevItemTime.getTime() - currentItemTime.getTime()) / 1000;
            return timeDifference > timeThreshold;
        });
        this.dataHistory = filteredHistory;
        this.dataHistory.map(a => {
            a.icon = this.historyIcons.find(icons => icons.id.includes(a.IDStatus_new))?.icon
        })
    }

    async getHistoryJournalReturns(id_return) {
        this.loadingHistory = true
        this.dataHistory = [];
        await this.returnService.getHistoryJournalReturns(id_return)
            .then(async (response) => {
                this.dataHistory = response
                if (this.dataHistory.length > 0) {
                    this.filterHistory();
                }
                this.loadingHistory = false
            })
            .catch((error) => {
                this.loadingHistory = false
                this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant('general.error'), detail: this.translateService.instant('orders.returns.editreturnerror') });
            });
    }

    IsHistoryTrue(): void {
        this.isHistoryTrue = !this.isHistoryTrue;
    }

    IDStatusDescriptionTimeLine(event: any): string {
        if (event.change_type == "INSERT")
            return event.description_new;
        if (event.change_type == "UPDATE")
            return event.description_new;
        return event.description_old;
    }

    dateTimeLine(event: any): string {
        return this.app.getDateOnFormatString(event.change_timestamp);
    }

    CalculateTotals() {
        // calculate total refund items, calculate Subtotal,
        this.refund_total = 0;
        this.refund_items = 0;
        this.subtotal = '0';

        for (let line of this.return.lines) {
            if (![49, 50, 51, 52, 53, 54, 55, 56, 57, 1, 16, 11, 12, 10, 14].includes(line.IDStatus) && ![13, 9].includes(this.return.IDStatus)) {
                this.refund_items = this.refund_items + 1;
                this.subtotal = (
                    Number(this.subtotal) + Number(line.total)
                ).toFixed(2);
            } else if (![49, 50, 51, 52, 53, 54, 55, 56, 57, 1, 4, 16, 11, 12, 10, 14].includes(line.IDStatus) && [13, 9].includes(this.return.IDStatus)) {
                this.refund_items = this.refund_items + 1;
                this.subtotal = (
                    Number(this.subtotal) + Number(line.total)
                ).toFixed(2);
            }
        }
        if (Number(this.subtotal) > 0) {
            var fee = Number(this.return.fee_total) ? Number(this.return.fee_total) : 0
            this.refund_total = Number((Number(this.subtotal) + Number(this.return.shipping_cost) - fee).toFixed(2))
        }
    }
    refundTotal() {
        if ([13, 9].includes(this.return.IDStatus)) {
            return this.return.refund_total
        } else {
            return this.refund_total
        }
    }

    options_disable() {
        // options disability
        const received: any = [0, /*9,*/ 13, 15, 17, 51, 55, 57];
        const refunded: any = [13, 15, 51, 55];
        const resendable: any = [2, 3, 50];
        const notApproved: any = [1, 49];
        const cancelledOrArchived: any = [98, 99];
        const rejected: any = [16, 56];
        const errorWMS: any = [10, 54];
        const approved_received: any = [13, 51];

        for (let option of this.dropdown_options) {
            if (option.id == "history_") continue;
            if (this.return.IDStatus == 9) {
                if (option.id == "resellItems") {
                    option.disabled = false;
                    option.visible = true;
                } else {
                    option.disabled = true;
                    option.visible = false;
                    continue;
                }
            }
            option.disabled = false;
            option.visible = true;
            if (option.id == "resellItems") {
                if (this.return.IDStatus == 13 || this.return.IDStatus == 9) {
                    option.disabled = false;
                    option.visible = true;
                } else {
                    option.disabled = true;
                    option.visible = false;
                }
            }
            if (option.id == 'editreturn') {
                option.disabled = true;
            }
            if (resendable.includes(this.return.IDStatus) || notApproved.includes(this.return.IDStatus) || rejected.includes(this.return.IDStatus)) {
                if (option.id == 'wms' || option.id == 'label' || option.id == 'resend_email' || option.id == 'receive') {
                    option.disabled = true;
                    continue;
                }
            } else if (errorWMS.includes(this.return.IDStatus)) {
                if (option.id == 'resend_email' || option.id == 'editreturn' || option.id == 'receive') {
                    option.disabled = true;
                    continue;
                }
            } else if (approved_received.includes(this.return.IDStatus)) {
                if (option.id == 'wms' || option.id == 'resend_email' || option.id == 'editreturn' || option.id == 'receive') {
                    option.disabled = true;
                    continue;
                }
            } else {
                if (option.id == 'wms') {
                    option.disabled = true;
                    continue;
                }
            }

            if (this.return.exchange_flag == 1) {
                if (option.id == 'refunded') {
                    option.disabled = true;
                    continue;
                }
            } else {
                if (!refunded.includes(this.return.IDStatus)) {
                    if (option.id == 'refunded') {
                        option.disabled = true;
                        continue;
                    }
                }
            }

            if (window.sessionStorage.getItem("wms") == "false") {
                if (option.id == 'wms') {
                    option.visible = false;
                    continue;
                }
            }

            if (cancelledOrArchived.includes(this.return.IDStatus)) {
                option.disabled = true
                if (option.id == "label") {
                    option.disabled = false
                }
                if (option.id == 'cancelReturn' || option.id == 'archiveReturn') {
                    option.visible = false
                }
            }

            if (received.includes(this.return.IDStatus)) {
                if (option.id == 'cancelReturn' || option.id == 'archiveReturn') {
                    option.visible = false;
                    continue;
                }
            }
        }
    }
    // if (resendable.includes(this.return.IDStatus)) {
    //     for (let options of this.dropdown_options) {
    //         if (options.id == 'wms' || options.id == 'label' || options.id == 'resend_email' || options.id == 'receive') {
    //             options.disabled = true;
    //             continue;
    //         }
    //     }
    // } else if (notApproved.includes(this.return.IDStatus)) {
    //     for (let options of this.dropdown_options) {
    //         if (options.id == 'wms' || options.id == 'label' || options.id == 'resend_email' || options.id == 'receive') {
    //             options.disabled = true;
    //             continue;
    //         }
    //     }
    // } else if (this.return.IDStatus == 16 || this.return.IDStatus == 56) {
    //     for (let options of this.dropdown_options) {
    //         if (options.id == 'wms' || options.id == 'label' || options.id == 'resend_email' || options.id == 'receive') {
    //             options.disabled = true;
    //             continue;
    //         }
    //         if (options.id == 'editreturn') {
    //             options.disabled = false;
    //             continue;
    //         }
    //     }
    // } else if (this.return.IDStatus == 10 || this.return.IDStatus == 54) {
    //     for (let options of this.dropdown_options) {
    //         if (options.id == 'wms' || options.id == 'label') {
    //             options.disabled = false;
    //             continue;
    //         }
    //         if (options.id == 'resend_email' || options.id == 'editreturn' || options.id == 'receive') {
    //             options.disabled = true;
    //             continue;
    //         }
    //     }
    // } else if (this.return.IDStatus == 13 || this.return.IDStatus == 51) {
    //     for (let options of this.dropdown_options) {
    //         if (options.id == 'wms' || options.id == 'resend_email' || options.id == 'editreturn' || options.id == 'receive') {
    //             options.disabled = true;
    //             continue;
    //         }
    //         if (options.id == 'label') {
    //             options.disabled = false;
    //             continue;
    //         }
    //     }
    // }
    // else {
    //     for (let options of this.dropdown_options) {
    //         if (options.id == 'wms') {
    //             options.disabled = true;
    //             continue;
    //         }
    //         if (options.id == 'label' || options.id == 'resend_email' || options.id == 'receive') {
    //             options.disabled = false;
    //             continue;
    //         }
    //     }
    // }

    // if (this.return.exchange_flag == 1) {
    //     this.dropdown_options.find(option => option.id == 'refunded').visible = false
    // } else {
    //     this.dropdown_options.find(option => option.id == 'refunded').visible = true
    //     if (refunded.includes(this.return.IDStatus)) {
    //         this.dropdown_options.find(option => option.id == 'refunded').disabled = false
    //     } else {
    //         this.dropdown_options.find(option => option.id == 'refunded').disabled = true
    //     }
    // }

    // // remove WMS call if no wms 
    // if (window.sessionStorage.getItem("wms") == "true") {
    //     this.dropdown_options.find(option => option.id == 'wms').visible = true
    // }
    // else if (window.sessionStorage.getItem("wms") == "false") {
    //     this.dropdown_options.find(option => option.id == 'wms').visible = false
    // }

    // if (cancelledOrArchived.includes(this.return.IDStatus)) {
    //     for (let option of this.dropdown_options) {
    //         // option.disabled = true
    //         if (option.id == "label") {
    //             option.disabled = false
    //         }
    //         if (option.id == 'cancelReturn' || option.id == 'archiveReturn') {
    //             option.visible = false
    //         }
    //     }
    // }

    // if (received.includes(this.return.IDStatus)) {
    //     this.dropdown_options.find(option => option.id == 'cancelReturn').visible = false
    //     this.dropdown_options.find(option => option.id == 'archiveReturn').visible = false
    // }

    EmailReturnLabel() {
        this.messageService.add({ key: 'tst', severity: 'info', summary: this.translateService.instant('orders.returns.waitemail'), detail: this.translateService.instant('orders.returns.emailsending'), sticky: true });
        let return_data = {
            id_return: this.return.id_return,
        };

        this.returnService.postEmailReturnLabel(return_data)
            .then((email) => {
                if (email.statusCode == '10000') {
                    this.messageService.clear();
                    this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.success'), detail: this.translateService.instant('orders.returns.emailsent') });
                }
            })
            .catch((error) => {
                this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant('general.error'), detail: this.translateService.instant('orders.returns.emailnotsent') });
            });
    }

    ReturnLabel() {
        this.loadingSidebar = true;
        this.returnService.getReturnLabel(this.return.id_return)
            .then((label) => {
                {
                    if (label.Label != undefined) {
                        const source = `data:application/pdf;base64,${label.Label}`;
                        const link = document.createElement('a');
                        link.href = source;
                        link.download = `${this.return.order_name}.pdf`;
                        link.click();
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        this.loadingSidebar = false;
    }

    async editReturn() {
        let editStatus;

        if (this.return.IDStatus === 16) {
            editStatus = 1;
        } else if (this.return.IDStatus === 56) {
            editStatus = 49;
        }

        let request_data = {
            id_return: this.return.id_return,
            IDStatus: editStatus,
        };

        await this.returnService.putReturnFinalize(request_data)
            .then(async (response) => {
                {
                    if (response.statusCode == 10000) {
                        this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.success'), detail: this.translateService.instant('orders.returns.editreturn') });
                    }
                    this.options_disable();
                }
            })
            .catch((error) => {
                this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.error'), detail: this.translateService.instant('orders.returns.editreturnerror') });
            });
    }

    async ReturnFinalize() {
        var countriesLabelAuto = await this.returnService.getAllCountriesReturnLabelAuto();
        if (this.return.pre_return == 0 && countriesLabelAuto.find(e => e.country_code == this.return.country) == undefined && !this.submittedLabel) {
            this.idTracking = null;
            this.labelAuto = false;
            this.openDialogLabel();
            setTimeout(() => {
                document.getElementsByClassName('p-fileupload')[0]["style"].border = 'none';
            });
        } else {
            this.loadingSidebar = true;
            //check lines to return status
            let statusreturn;
            let rejected = true;
            for (const rline of this.return.lines) {
                if (rline.IDStatus == 2) {
                    statusreturn = 2;
                    break;
                } else if (rline.IDStatus == 50) {
                    statusreturn = 50;
                    continue;
                }
            }

            for (const rline of this.return.lines) {
                if (rline.IDStatus == 2) {
                    rejected = false;
                    break;
                } else if (rline.IDStatus == 50) {
                    rejected = false;
                    break;
                }
            }

            if (rejected) {
                for (const rline of this.return.lines) {
                    if (rline.IDStatus == 1) {
                        statusreturn = 16;
                        break;
                    } else if (rline.IDStatus == 49) {
                        statusreturn = 56;
                        continue;
                    }
                }
            }

            let request_data = {
                id_return: this.return.id_return,
                IDStatus: statusreturn,
                labelAuto: this.labelAuto,
                hasLabel: Boolean(this.return.pre_return)
            };
            await this.returnService.putReturnFinalize(request_data)
                .then(async (response) => {
                    {
                        if (response.statusCode == 10000) {
                            this.messageService.add({
                                key: 'tst',
                                severity: 'success',
                                summary:
                                    this.translateService.instant(
                                        'general.success'
                                    ),
                                detail: this.translateService.instant('orders.returns.finalized'),
                            });
                        }
                        this.returnLines(this.return.id_return).then(() => {
                            if (
                                this.return.IDStatus == 10 ||
                                this.return.IDStatus == 54
                            ) {
                                this.messageService.add({
                                    key: 'tst',
                                    severity: 'warn',
                                    summary:
                                        this.translateService.instant(
                                            'general.error'
                                        ),
                                    detail: this.translateService.instant('orders.returns.wms_not_insert')
                                });
                            }
                        });
                        var refundTotal_data = {
                            id_return: request_data.id_return,
                            refund_total: this.refund_total
                        }
                        this.returnService.updateRefundTotal(refundTotal_data);
                        this.options_disable();
                        this.ngOnInit();
                    }
                })
                .catch((error) => {
                    if (error.error.message == 'emailerror') {
                        this.messageService.add({
                            key: 'tst',
                            severity: 'success',
                            summary:
                                this.translateService.instant('general.success'),
                            detail: this.translateService.instant('orders.returns.wms_not_insert')
                        });
                        this.messageService.add({
                            key: 'tst',
                            severity: 'warn',
                            summary: this.translateService.instant('general.error'),
                            detail: this.translateService.instant('orders.returns.emailnotsent')
                        });
                    }
                });
            this.loadingSidebar = false;
            this.submittedLabel = false;
        }
    }

    async ReturnFinalizeExchange() {
        this.loadingSidebar = true;
        var lines = []
        for (var x of this.return.linesExtra) {
            var i = 0;
            for (var y of x.quantityArrayNotExpectedItem) {
                var line = {
                    id_return_line: x.id_return_line,
                    quantity: y,
                    EAN: x.variantSizeSelect[i].ean,
                    SKU: x.variantSizeSelect[i].sku,
                    variant_id: x.variantSizeSelect[i].variant_id
                }
                i++
                lines.push(line)
            }
        }
        var body = {
            id_return: this.return.id_return,
            lines: lines
        }

        await this.returnService.returnUnexpectedExchange(body).then(
            data => {
                this.loadingSidebar = false;
                this.ngOnInit();
                this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.success'), detail: this.translateService.instant('orders.returns.newretturncreated') });
            },
            error => {
                this.loadingSidebar = false;
                this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant('general.error'), detail: this.translateService.instant('orders.returns.exchange_not_possible') });
            }
        )

        await this.returnLines(this.return.id_return);
    }

    onUpload(event) {
        document.getElementsByClassName('p-fileupload')[0]["style"].border = 'none';
        if (document.getElementsByClassName('p-fileupload-files').length > 0) {
            document.getElementsByClassName('p-fileupload-files')[0]["style"].display = 'block';
        }
        for (let file of event.files) {
            this.labelUpload.push(file);
        }
        this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
    }

    openDialogLabel() {
        this.displayLabel = true;
        setTimeout(() => {
            if (document.getElementsByClassName('p-fileupload-files').length > 0) {
                document.getElementsByClassName('p-fileupload-files')[0]["style"].display = 'none';
            }
        }, 100);
    }

    onRemoveFile() {
        this.labelUpload.pop();
        document.getElementsByClassName('p-fileupload') ? document.getElementsByClassName('p-fileupload')[0]["style"].border = 'none' : null;
    }

    closeDialogLabel() {
        if (this.labelUpload.length > 0) {
            var file = this.labelUpload[0];
            var filebase64;
            let fileReader64 = new FileReader();
            fileReader64.readAsBinaryString(file);
            fileReader64.onload = async () => {
                let binaryData: any = fileReader64.result;
                filebase64 = window.btoa(binaryData);
                var data_request = {
                    id_return: this.return.id_return,
                    label: filebase64,
                    idTracking: this.idTracking?.replace(/\s+/g, "")
                }
                await this.returnService.addReturnLabel(data_request).then(
                    (data) => {
                        this.ReturnFinalize();
                    },
                    (error) => {
                        this.messageService.add({
                            key: 'tst',
                            severity: 'warn',
                            summary: this.translateService.instant('general.error'),
                            detail: this.translateService.instant('orders.returns.label_error')
                        });
                    }
                );
            };
            this.submittedLabel = true
            this.displayLabel = false
        } else {
            document.getElementsByClassName('p-fileupload')[0]["style"].border = '1px solid red';
            document.getElementsByClassName('p-fileupload')[0]["style"].borderRadius = '6px';
            this.messageService.add({ key: 'tst', severity: 'warn', summary: this.translateService.instant("general.warn"), detail: this.translateService.instant("users.warning_update") });
        }
    }

    ReturnWms() {
        let request_data = {
            id_return: this.return.id_return,
        };

        this.returnService.putReturnWMS(request_data)
            .then(async (response) => {
                if (response.statusCode == '10000') {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary:
                            this.translateService.instant('general.success'),
                        detail: this.translateService.instant('orders.returns.updated_WMS'),
                    });
                } else if (response.statusCode == '10001') {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: this.translateService.instant('general.error'),
                        detail: this.translateService.instant('orders.returns.error_updating_WMS')
                    });
                }
                this.returnLines(this.return.id_return);
                this.options_disable();
            })
            .catch((error) => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: this.translateService.instant('general.error'),
                    detail: this.translateService.instant('orders.returns.error_updating_WMS')
                });
            });
    }

    async refunded() {
        this.loadingSidebar = true;
        let request_data = {
            id_return: this.return.id_return,
        };
        await this.returnService.putReturnStatusToDone(request_data).then(
            async (data) => {
                let id_return = this.return.id_return; // this.route.snapshot.paramMap.get('id_return');
                this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.success'), detail: this.translateService.instant('orders.returns.refunded') });
                await this.returnLines(id_return);
            },
            (error) => {
                this.loadingSidebar = false;
                this.messageService.add({
                    key: 'tst',
                    severity: 'warn',
                    summary: this.translateService.instant('general.error'),
                    detail: this.translateService.instant('orders.returns.error_refunded')
                });
            }
        );

        this.loadingSidebar = false;
    }

    async onImagesClick(id_return: any, id_return_line: any) {
        // reset images log
        this.line_images = [];

        // get images
        await this.returnService.getReturnLineImages(
            id_return,
            id_return_line
        ).then(async (lineimages) => {
            for (let lineimage of lineimages) {
                this.line_images.push(lineimage);
            }
        });

        for (let return_line of this.return.lines) {
            if (
                return_line.id_return == id_return &&
                return_line.id_return_line == id_return_line
            ) {
                return_line.images_data = this.line_images;
            }
        }
    }

    async getOrderExchangeDetails(order_id_exc: any, op: any, event: any) {
        //this.order_exc_detail = {};
        await this.returnService.getOrderExchangeDetails(order_id_exc).then(
            async (orderExchange) => {
                this.order_exc_detail = orderExchange;
                this.order_exc_detail.exc_lines.map(exc_line => {
                    exc_line.name_aux = exc_line.name.substring(0, exc_line.name.lastIndexOf(' - '));
                })
            }
        );
        op.toggle(event);
    }

    //menu de confirmação do recieve quando inserimos um valor maior que o esperado
    joinedItens: any = [];
    private hasExceededLimit = false;
    @ViewChildren('quantityInput', { read: ElementRef }) quantityInputs: QueryList<ElementRef>;
    confirm() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to save? The quantity of the product inserted is greater than what was expected.',
            accept: () => {
                this.SendReceiveItemsV2();
            },
            reject: () => {
                return;
            }
        });
    }

    async checkExceededLimit() {
        this.hasExceededLimit = false;
        this.quantityInputs.forEach(input => {
            if (input.nativeElement.classList.contains('exceeded-limit')) {
                this.hasExceededLimit = true;
                return;
            }
        });
    }

    //poe a quantidade inicial do recieve sempre a 0
    onQuantityChange(event: any, item: any) {
        let newValue = event.target.value.trim() === '' || isNaN(event.target.value) || event.target.value < 0 ? 0 : event.target.value;
        if (event.target.value.trim() === '') {
            newValue = 0;
        }
        item.Quantity = parseInt(newValue);
    }

    isQuantityExceeded(item: any): boolean {
        var orderItem = this.order_items.items.find(i => i.SKU == item.SKU)
        if (parseInt(item.Quantity) > orderItem.Quantity /*|| itemQuantity===''*/) {
            this.checkExceededLimit();
            return true;
        }
        else {
            this.checkExceededLimit();
            return false;
        }
    }

    quantityTooltip(itemQuantity: string, item: any): string {
        var orderItem = this.order_items.items.find(i => i.SKU == item.SKU)
        if (parseInt(itemQuantity) > orderItem.Quantity) {
            return item.lastValue = 'Expected quantity smaller than inserted';
        }
        if (parseInt(itemQuantity) < 0) {
            item.lastValue = 'Add a positive value';
            return item.lastValue;
        }
        if (parseInt(itemQuantity) < orderItem.Quantity) {
            if (item.lastValue == 'Add a positive value' && itemQuantity != '0') {
                return item.lastValue = '';
            }

            if (item.lastValue == 'Expected quantity smaller than inserted') {
                return item.lastValue = '';
            }

        }
        if (parseInt(itemQuantity) == orderItem.Quantity) {
            return item.lastValue = '';
        }
        /* if (itemQuantity === '') {
             return 'Adicione um valor';
         }*/
        return typeof item.lastValue === 'undefined' ? item.lastValue = '' : item.lastValue;
    }

    resetQuantity() {
        this.joinedItens.items.forEach(item => {
            item.Quantity = 0;
        });
    }

    addQuantityLine(orderData, lines) {
        const expectedItems = orderData.items.filter(item => item.Expeted === true);
        for (const line of lines) {
            let item = expectedItems.find(item => item.SKU === line.sku && typeof line.quantityNotExpectedItem === 'undefined');
            if (!item) continue;
            item.quantityLine = line.quantity;
        }

    }


    async ReturnReceive() {

        // reset data
        this.expected_items = [];
        this.unexpected_items = [];
        this.unexpected_items_loaded = false;
        this.received_temp_item = {};
        this.received_items = [];
        this.selectedItemReceive = "0";
        this.selectedItemAdd = false;


        // get items from order for autocomplete 
        await this.returnService.getOrderDetails(this.return.order_id, this.return.id_return).then(
            async (orderData) => {

                this.addQuantityLine(orderData, this.return.lines)

                this.order_items = orderData;
                this.joinedItens = JSON.parse(JSON.stringify(this.order_items));

                this.resetQuantity();

                // organize data in groups
                for (let orderLine of orderData.items) {
                    if (orderLine.Expeted) {
                        this.expected_items.push({
                            "label": orderLine.SKU, "value": orderLine.id
                        });
                    } else {
                        this.unexpected_items.push({
                            "label": orderLine.SKU, "value": orderLine.id
                        });
                    }
                }

                this.order_detail_receive = [{
                    label: "Expected", value: "expected",
                    items: this.expected_items
                }
                ];

                if (this.unexpected_items.length == 0)
                    this.unexpected_items_loaded = true;

            }
        )

        this.displayReceive = true;
    }



    UnexpectedItems() {
        this.unexpected_items_loaded = true; // hide message

        this.order_detail_receive = [{
            label: "Expected", value: "expected",
            items: this.expected_items
        },
        {
            label: "Unexpected", value: "unexpected",
            items: this.unexpected_items
        }
        ];

        this.messageService.add({
            key: 'tst',
            severity: 'success',
            summary:
                this.translateService.instant('orders.returns.list_items_updated'),
            detail: this.translateService.instant('orders.returns.add_unexpeted_items')
        });
    }

    ChooseItemReceive(event: any) {

        this.received_temp_item = {}
        // auto field inputs
        for (let item of this.order_items.items) {
            if (item.id == event.value) {
                this.received_temp_item = {
                    "id": item.id,
                    "sku": item.SKU,
                    "ean": item.EAN,
                    "quantity": item.Quantity
                }
                this.selectedItemAdd = true
                break;
            }
        }
    }

    AddReceiveItem() {
        for (let item of this.order_items.items) {
            if (item.id == this.received_temp_item.id) {

                for (let received_item of this.received_items) {
                    if (received_item.id == this.received_temp_item.id) {


                        received_item.quantity = parseInt(received_item.quantity) + parseInt(this.received_temp_item.quantity);

                        this.received_temp_item = {};
                        this.selectedItemAdd = false
                        this.selectedItemReceive = "0";

                        return;
                    }
                }

                this.received_items.push({
                    "id": this.received_temp_item.id,
                    "sku": this.received_temp_item.sku,
                    "ean": this.received_temp_item.ean,
                    "quantity": this.received_temp_item.quantity,
                    "quantity_expected": item.Expeted == false ? 0 : item.Quantity
                })
                break;
            }
        }

        this.received_temp_item = {};
        this.selectedItemAdd = false
        this.selectedItemReceive = "0";
    }

    removeReceiveItem(item: any) {
        this.received_items = this.received_items.filter(function (value: any) {
            return value.id != item.id
        })
    }


    async SendReceiveItemsV2() {
        let send_data: any = [];

        this.loadingManualReveive = true;

        for (let index = 0; index < this.joinedItens.items.length; index++) {
            if (this.joinedItens.items[index].Quantity == 0) continue;
            send_data.push({
                "product_code": this.joinedItens.items[index].SKU,
                "product_ean": this.joinedItens.items[index].EAN,
                "quantity_exception": this.order_items.items[index].Expeted == false ? 0 : this.order_items.items[index].Quantity,
                "quantity": this.joinedItens.items[index].Quantity,
            })
        }
        if (send_data.length == 0) {
            this.displayReceive = false;
            return;
        }
        let data = {
            "process_type": "Devolução de Cliente",
            "warehouse_date": "",
            "warehouse_id": 0,
            "original_id": this.order_items.original_id,
            "logistic_op": "",
            "external_number": "",
            "items": send_data
        }
        await this.returnService.postReturnReceiveItems(data).then(async (returnresponse) => {
            this.returnLines(this.return.id_return);
            this.displayReceive = false;
            this.loadingManualReveive = false;
        }).catch((error) => {
            this.loadingManualReveive = false;
            console.log(error);
        });

    }

    saveItems() {
        if (this.hasExceededLimit) {
            this.confirm();
            return;
        }

        this.SendReceiveItemsV2();

    }

    async SendReceiveItems() {
        let send_data: any = [];

        for (let received_item of this.received_items) {
            send_data.push({
                "product_code": received_item.sku,
                "product_ean": received_item.ean,
                "quantity_exception": received_item.quantity_expected,
                "quantity": received_item.quantity
            })
        }

        let data = {
            "process_type": "Devolução de Cliente",
            "warehouse_date": "",
            "warehouse_id": 0,
            "original_id": this.order_items.original_id,
            "logistic_op": "",
            "external_number": "",
            "items": send_data
        }

        await this.returnService.postReturnReceiveItems(data).then(async (returnresponse) => {
            this.returnLines(this.return.id_return);
            this.displayReceive = false;
        })
            .catch((error) => {
                console.log(error);
            });
    }

    onSubmitTracking(): void {
        this.return.tracking_id = this.return.tracking_id.replace(/\s+/g, "")
        var data = {
            id_return: this.return.id_return,
            idTracking: this.new_tracking_id
        }

        this.returnService.updateTrackingId(data)
        this.editTrackingDialog = false
        this.return.tracking_id = this.new_tracking_id
        this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.success'), detail: this.translateService.instant('orders.returns.updated_tracking') });
    }

    async acceptExchange() {
        this.displayAcceptExchange = true;
        this.submittedDialog = false;
        if (this.selectedNotExpectedItem.arraySizesCount.length == 0) {
            this.variantSizeSelect = []
            this.selectedNotExpectedItem.arraySizesCount = [0]
            this.selectedNotExpectedItem.quantityArrayNotExpectedItem = [1]
            this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem = 1
            this.checkIfHasExchange()
        }
        if (this.selectedNotExpectedItem.variantSizeSelect.length >= 1) {
            this.newSize()
        }

        this.variantQuantities = await this.returnService.getVariantQuantity(this.selectedNotExpectedItem.id_product, this.selectedNotExpectedItem.variant_id)
        for (var variantQnt of this.variantQuantities) {
            if (variantQnt.quantity == 0) {
                variantQnt.disabled = true
            } else {
                variantQnt.disabled = false
            }
        }
    }

    decrementQuantity(index?: number) {
        if (index !== undefined) {
            this.selectedNotExpectedItem.quantityArrayNotExpectedItem[index]--;
        } else {
            this.selectedNotExpectedItem.quantityNotExpectedItem--;
            this.subtotalRefundNotExpectedItem -= Number(this.selectedNotExpectedItem.price)
        }
        this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem--;
    }

    incrementQuantity(index?: number) {
        if (index !== undefined) {
            this.selectedNotExpectedItem.quantityArrayNotExpectedItem[index]++;
        } else {
            this.selectedNotExpectedItem.quantityNotExpectedItem++;
            this.subtotalRefundNotExpectedItem += Number(this.selectedNotExpectedItem.price)
        }
        this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem++;
    }

    async acceptRefund() {
        this.displayAcceptRefund = true;
        this.submittedDialog = false;
        this.optionSelect = null;
        this.selectedNotExpectedItem.quantityNotExpectedItem = 1
        this.subtotalRefundNotExpectedItem = Number(this.selectedNotExpectedItem.price)
        this.optionsRefund = await this.returnService.getOptionsReturn();
        this.optionsRefund = this.optionsRefund.filter(option => !option.option.includes('(EXC)'))
    }

    async refundItem() {
        this.submittedDialog = true;
        if (this.optionSelect) {
            this.loadingSidebar = true;
            var body = {
                id_return: this.selectedNotExpectedItem.id_return,
                id_return_line: this.selectedNotExpectedItem.id_return_line,
                quantity: this.selectedNotExpectedItem.quantityNotExpectedItem,
                option_id: this.optionSelect.option_id
            }

            await this.returnService.returnUnexpectedRefund(body)
            this.selectedNotExpectedItem.availableQuantityNotExpectedItemAux = this.selectedNotExpectedItem.availableQuantityNotExpectedItemAux - this.quantityNotExpectedItem
            this.displayAcceptRefund = false;
            await this.returnLines(this.return.id_return);
            this.loadingSidebar = false
            this.checkDisabled()
        } else {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: this.translateService.instant("general.warn"), detail: this.translateService.instant("users.warning_update") });
        }
    }

    exchangeItem() {
        this.submittedDialog = true;
        if (this.variantSizeSelect[0] == null) {
            this.selectedNotExpectedItem.variantSizeSelect = []
            this.variantSizeSelect = []
            this.hasExchange = []
            this.return.linesExtra.map(line => this.hasExchange.concat(line.variantSizeSelect))
        }
        if (this.variantSizeSelect.length > 0 && this.variantSizeSelect.length == this.selectedNotExpectedItem.arraySizesCount.length) {
            var variantSizeSelect = this.variantSizeSelect
            this.selectedNotExpectedItem.variantSizeSelect = variantSizeSelect
            this.loadingSidebar = true;
            var qnt = this.selectedNotExpectedItem.quantityArrayNotExpectedItem.reduce((partialSum, a) => partialSum + a, 0);
            this.displayAcceptExchange = false;
            this.selectedNotExpectedItem.availableQuantityNotExpectedItemAux = this.selectedNotExpectedItem.quantityReceived - qnt
            this.checkIfHasExchange()
            setTimeout(() => {
                this.loadingSidebar = false
            })
            this.checkDisabled()
        } else {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: this.translateService.instant("general.warn"), detail: this.translateService.instant("users.warning_update") });
        }
    }

    newSize() {
        this.selectedNotExpectedItem.arraySizesCount.push(this.selectedNotExpectedItem.arraySizesCount[this.selectedNotExpectedItem.arraySizesCount.length - 1] + 1)
        this.selectedNotExpectedItem.quantityArrayNotExpectedItem.push(1)
        this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem++;
    }

    deleteSizeSelect(x: number, returnline?: any) {
        if (returnline) {
            this.selectedNotExpectedItem = returnline
            this.selectedNotExpectedItem.availableQuantityNotExpectedItemAux += this.selectedNotExpectedItem.quantityArrayNotExpectedItem[x]
            this.checkDisabled();
        }
        if (returnline || this.selectedNotExpectedItem.arraySizesCount.length > 1) {
            this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem = this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem - this.selectedNotExpectedItem.quantityArrayNotExpectedItem[x];
            this.selectedNotExpectedItem.variantSizeSelect.splice(x, 1)
            this.variantSizeSelect.splice(x, 1)
            this.selectedNotExpectedItem.quantityArrayNotExpectedItem.splice(x, 1)
            this.selectedNotExpectedItem.arraySizesCount = Array.from(Array(this.selectedNotExpectedItem.arraySizesCount.length - 1).keys())
        }
        this.checkIfHasExchange()
    }

    checkDisabled() {
        if (this.selectedNotExpectedItem.availableQuantityNotExpectedItemAux <= 0) {
            this.selectedNotExpectedItem.notExpectedOptions.map(option => option.disabled = true)
        } else {
            this.selectedNotExpectedItem.notExpectedOptions.map(option => option.disabled = false)
        }
    }

    checkIfHasExchange() {
        this.return.linesExtra.map(line => {
            this.hasExchange = [];
            this.hasExchange = this.hasExchange.concat(line.variantSizeSelect)
        })
    }

    async cancelReturn() {
        this.loadingSidebar = true
        var data = {
            id_return: this.return.id_return
        }
        await this.returnService.cancelReturn(data)
        var ret = this.returns.find(ret => ret.idInterno == this.return.id_return)
        ret.IDStatus = 99
        ret.returnStatus = "Cancelled"
        this.return.IDStatus = 99
        this.return.returnStatus = "Cancelled"

        this.loadingSidebar = false
    }

    async archiveReturn() {
        this.loadingSidebar = true
        var data = {
            id_return: this.return.id_return
        }
        this.returnService.archiveReturn(data)
        var ret = this.returns.find(ret => ret.idInterno == this.return.id_return)
        ret.IDStatus = 98
        ret.returnStatus = "Archived"
        this.return.IDStatus = 98
        this.return.returnStatus = "Archived"

        this.loadingSidebar = false
    }

    async openResellItemsDialog() {
        this.selectedResellItems = []
        this.getResellItems()
        this.resellItemsDialog = true;
    }
    async getResellItems() {
        this.resellItems = await this.returnService.getResellItems(this.return.id_return)
        this.resellItems.return2hand.map(item => {
            item.photos = [];
            item.condition = this.resellItems._2handcondictions[0];
            item.condition_id = this.resellItems._2handcondictions[0].condition_id;
            item.quantityResell = 1;
            item.resellPrice = item.price * this.resellItems._2handcondictions[0].percentage_price / 100
        })
    }
    changeCondition(returnline: any) {
        returnline.condition_id = returnline.condition.condition_id
        returnline.resellPrice = returnline.price * returnline.condition.percentage_price / 100
    }
    uploadPhotosResell(event: any, returnline: any, photosResellUpload) {
        for (let file of event.files) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                var photo = e.target.result.split(',')[1]
                returnline.photos.push(photo)
            }
            reader.readAsDataURL(file);
        }
        photosResellUpload.clear()
    }
    onRemovePhotoResell(returnline: any, photoResell: any) {
        returnline.photos = returnline.photos.filter(photo => photo !== photoResell)
    }
    async submit2HandProducts() {
        const resellItems = {
            id_return: this.return.id_return,
            resellProducts: this.selectedResellItems
        }
        await this.returnService.postResellItems(resellItems).then(
            result => {
                this.getResellItems()
            }
        )

    }

    checkboxClick(e) {
        e.stopPropagation()
        var x = document.getElementsByClassName('p-checkbox-box')
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedReturns.length > 0) {
            x[0].classList.add('p-highlight')
            y[0].classList.add('pi')
            y[0].classList.add('pi-minus')
        } else {
            x[0].classList.remove('p-highlight')
            y[0].classList.remove('pi')
            y[0].classList.remove('pi-minus')
        }
    }

    mainCheckboxClick() {
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedReturns.length == this.returnsAux.length) {
            y[0].classList.remove('pi-minus')
        }
    }

    async exportCSV() {
        var selectionAux = this.table._selection
        if (this.table._selection.length == 0) {
            if (this.table.filteredValue) {
                this.table._selection = this.table.filteredValue
            } else {
                this.table._selection = this.table._value
            }
        }
        await this.table.exportCSV({ selectionOnly: true })
        this.table._selection = selectionAux
    }

    openEditTrackingDialog() {
        this.new_tracking_id = this.return.tracking_id
        this.editTrackingDialog = true
    }

    set_show_IBAN() {
        this.show_IBAN = !this.show_IBAN;
    }

    set_clipboard() {
        navigator.clipboard.writeText(this.return.mb_return_payment);
        this.messageService.add({ key: 'tst', severity: 'info', summary: "Info", detail: "Copied to CLipboard!" });
    }

}


interface Comment {
    author: string;
    text: string;
    date: string;
    id_return: number;
    id_user?: number;
    comment_id?: number;
}
