<p-toast key="tst" [baseZIndex]="99999"></p-toast>
<p-table #dt1 [value]="suppliers" dataKey="email" [loading]="loading" [rowHover]="true"
	styleClass="p-datatable-gridlines overflow-x" [paginator]="true"
	[globalFilterFields]="['name','codigo_wms','email','phone','nif','address','codigo_postal','city','country_code']"
	responsiveLayout="stack" [rows]="10" [rowsPerPageOptions]="[10,25,50]" [showCurrentPageReport]="true"
	currentPageReportTemplate="{{ 'suppliers.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
	<ng-template pTemplate="caption">
		<div class="flex justify-content-end gap-2 sm:flex-row">
			<button pButton class="p-button-outlined mb-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
			<span class="p-input-icon-left search-100 mb-2">
				<i class="pi pi-search"></i>
				<input pInputText type="text" #filter (input)="dt1.filterGlobal($event.target.value, 'contains')"
					placeholder="{{ 'general.search_here' | translate }}" class="w-full" />
			</span>
		</div>
	</ng-template>
	<ng-template pTemplate="header">
		<tr>
			<th>{{ 'suppliers.name' | translate }}
				<p-columnFilter type="text" field="name" display="menu"
					placeholder="{{ 'suppliers.search_name' | translate }}">
				</p-columnFilter>
			</th>
			<th>{{ 'suppliers.code' | translate }}
				<p-columnFilter type="text" field="codigo_wms" display="menu"
					placeholder="{{ 'suppliers.search_code' | translate }}"></p-columnFilter>
			</th>
			<th>{{ 'suppliers.email' | translate }}
				<p-columnFilter type="text" field="email" display="menu"
					placeholder="{{ 'suppliers.search_email' | translate }}">
				</p-columnFilter>
			</th>
			<th>{{ 'suppliers.vat' | translate }}
				<p-columnFilter type="text" field="nif" display="menu"
					placeholder="{{ 'suppliers.search_vat' | translate }}">
				</p-columnFilter>
			</th>
			<th>{{ 'suppliers.address' | translate }}
				<p-columnFilter type="text" field="address" display="menu"
					placeholder="{{ 'suppliers.search_address' | translate }}"></p-columnFilter>
			</th>
			<th>{{ 'suppliers.zipcode' | translate }}
				<p-columnFilter type="text" field="codigo_postal" display="menu"
					placeholder="{{ 'suppliers.search_zipcode' | translate }}"></p-columnFilter>
			</th>
			<th>{{ 'suppliers.city' | translate }}
				<p-columnFilter type="text" field="city" display="menu"
					placeholder="{{ 'suppliers.search_city' | translate }}">
				</p-columnFilter>
			</th>
			<th>{{ 'suppliers.country' | translate }}
				<p-columnFilter type="text" field="country_code" display="menu"
					placeholder="{{ 'suppliers.search_country' | translate }}"></p-columnFilter>
			</th>
			<th style="width: auto"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-item>
		<tr style="border: 1px solid #dee2e6; border-bottom: none;">
			<td style="min-width: auto;">
				<span class="p-column-title"><b>{{ 'suppliers.name' | translate }}</b></span>
				{{item.name}}
			</td>
			<td style="min-width: auto;">
				<span class="p-column-title"><b>{{ 'suppliers.code' | translate }}</b></span>
				{{item.codigo_wms}}
			</td>
			<td style="min-width: auto;">
				<span class="p-column-title"><b>{{ 'suppliers.email' | translate }}</b></span>
				{{item.email}}
			</td>
			<td style="min-width: auto;">
				<span class="p-column-title"><b>{{ 'suppliers.vat' | translate }}</b></span>
				{{item.nif}}
			</td>
			<td style="min-width: auto;">
				<span class="p-column-title"><b>{{ 'suppliers.address' | translate }}</b></span>
				{{item.address}}
			</td>
			<td style="min-width: auto;">
				<span class="p-column-title"><b>{{ 'suppliers.zipcode' | translate }}</b></span>
				{{item.codigo_postal}}
			</td>
			<td style="min-width: auto;">
				<span class="p-column-title"><b>{{ 'suppliers.city' | translate }}</b></span>
				{{item.city}}
			</td>
			<td style="min-width: auto;">
				<span class="p-column-title"><b>{{ 'suppliers.country' | translate }}</b></span>
				{{item.country_code}}
			</td>
			<td>
				<span class="p-column-title"></span>
				<button pButton pRipple icon="pi pi-pencil" id="btn-seemore-{{item.id}}"
					class="p-button-rounded p-button-text" (click)="editSupplier(item)"></button>
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="8">{{ 'general.no_data' | translate }}</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="loadingbody">
		<tr>
			<td colspan="8">{{ 'general.loading' | translate }}</td>
		</tr>
	</ng-template>
</p-table>
<p-dialog [(visible)]="supplierDialog" [style]="{width: '650px', margin: '0 2rem'}" id="supplier_details"
	header="{{ 'suppliers.supplier_details' | translate }}" [modal]="true" [draggable]="false" [resizable]="false"
	class="p-fluid">
	<ng-template pTemplate="content">
		<div class="p-fluid p-formgrid grid">
			<div class="field col-12 md:col-6">
				<label for="name">{{ 'suppliers.name' | translate }} <small>*</small></label>
				<input type="text" pInputText id="name" [(ngModel)]="supplier.name" required autofocus
					[ngClass]="{'ng-invalid ng-dirty' : (!supplier.name)}">
			</div>
			<div class="col-12 md:col-6">
				<form #form="ngForm">
					<div class="field">
						<label for="email">{{ 'suppliers.email' | translate }} <small>*</small></label>
						<input type="text" pInputText id="email" name="email" [(ngModel)]="supplier.email"
							autocomplete="off" [pKeyFilter]="ccEmailRegex" [pValidateOnly]="true" required autofocus
							[ngClass]="{'ng-invalid ng-dirty' : (!supplier.email) || (supplier.email && form.invalid)}">
					</div>
				</form>
			</div>
			<div class="field col-12 md:col-4">
				<label for="codigo_wms">{{ 'suppliers.wms_code' | translate }}</label>
				<input type="string" disabled pInputText id="codigo_wms" [(ngModel)]="supplier.codigo_wms" required
					autofocus [ngClass]="{'ng-invalid ng-dirty' : (!supplier.codigo_wms)}">
			</div>
			<div class="col-12 md:col-4" *ngIf="selectedCountry === 'PT'">
				<form #form="ngForm">
					<div class="field">
						<label htmlFor="phone">{{ 'suppliers.phone' | translate }} <small>*</small></label>
						<input id="phone" type="number" name="phone" [(ngModel)]="supplier.phone" pInputText
							[pKeyFilter]="ccPhoneRegex" [pValidateOnly]="true"
							[ngClass]="{'ng-invalid ng-dirty' : (!supplier.phone) || (form.invalid)}">
					</div>
				</form>
			</div>
			<div class="field col-12 md:col-4" *ngIf="selectedCountry !== 'PT'">
				<label htmlFor="phone">{{ 'suppliers.phone' | translate }} <small>*</small></label>
				<input pInputText type="number" pInputText required [(ngModel)]="supplier.phone">
			</div>
			<div class="col-12 md:col-4" *ngIf="selectedCountry === 'PT'">
				<form #form="ngForm">
					<div class="field">
						<label htmlFor="nif">{{ 'suppliers.vat' | translate }} <small>*</small></label>
						<input id="nif" type="number" name="nif" [(ngModel)]="supplier.nif" pInputText
							[pKeyFilter]="ccNIFRegex" [pValidateOnly]="true"
							[ngClass]="{'ng-invalid ng-dirty' : (!supplier.nif) || (form.invalid)}">
					</div>
				</form>
			</div>
			<div class="field col-12 md:col-4" *ngIf="selectedCountry !== 'PT'">
				<label htmlFor="nif">{{ 'suppliers.vat' | translate }} <small>*</small></label>
				<input pInputText type="number" pInputText required [(ngModel)]="supplier.nif">
			</div>
			<div class="field col-12 md:col-12">
				<label for="address">{{ 'suppliers.address' | translate }} <small>*</small></label>
				<input type="text" pInputText id="address" [(ngModel)]="supplier.address" required autofocus
					[ngClass]="{'ng-invalid ng-dirty' : (!supplier.address)}">
			</div>
			<div class="field col-12 md:col-6">
				<label for="city">{{ 'suppliers.city' | translate }} <small>*</small></label>
				<input type="text" pInputText id="city" [(ngModel)]="supplier.city" required autofocus
					[ngClass]="{'ng-invalid ng-dirty' : (!supplier.city)}">
			</div>
			<div class="field col-12 md:col-3">
				<label for="country_code">{{ 'suppliers.country' | translate }} <small>*</small></label>
				<p-dropdown appendTo="body" id="dropdown-country-options" [options]="countries" [(ngModel)]="selectedCountry"
					placeholder="{{ 'suppliers.select_country' | translate }}" optionLabel="country_name"
					optionValue="country_code" required (onChange)="createZipRegex(supplier)"
					[ngClass]="{'ng-invalid-drop' : (!selectedCountry)}"></p-dropdown>
			</div>
			<div class="col-12 md:col-3" *ngIf="selectedCountry">
				<form #form="ngForm">
					<div class="field">
						<label htmlFor="codigo_postal">{{ 'suppliers.zipcode' | translate }} <small>*</small></label>
						<input id="codigo_postal" type="text" name="codigo_postal" [(ngModel)]="supplier.codigo_postal"
							required pInputText [pKeyFilter]="ccRegex" [pValidateOnly]="true"
							[(placeholder)]="regex_example"
							[ngClass]="{'ng-invalid ng-dirty' : (!supplier.codigo_postal) || (form.invalid)}">
					</div>
				</form>
			</div>
			<div class="field col-12 md:col-3" *ngIf="!selectedCountry">
				<label for="codigo_postal">{{ 'suppliers.zipcode' | translate }} <small>*</small></label>
				<input type="text" pInputText disabled id="codigo_postal" [(ngModel)]="regex_example"
					class="ng-invalid ng-dirty">
			</div>
			<div class="col-12 md:col-12" style="display: inline-flex; gap: 0.5rem;">
				<p style="margin: 0;">{{ 'suppliers.transfer_supplier' | translate }}</p>
				<p style="margin: 0;"><strong>{{valCheck}}</strong></p>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<button pButton pRipple label="{{ 'general.cancel' | translate }}" icon="pi pi-times" class="p-button-text"
			(click)="hideDialog()"></button>
		<button pButton pRipple label="{{ 'general.save' | translate }}" type="submit" icon="pi pi-check"
			class="p-button-text" (click)="saveSupplier()"></button>
		<div class="col-12 md:col-12" style="text-align: left;">
			<small>* {{ 'suppliers.required_fields' | translate }}</small>
		</div>
	</ng-template>
</p-dialog>