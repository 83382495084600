import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root'
})
export class PackingListService {

    constructor(private http: HttpClient) { }

    async getPackingListNumbers() {

        let data = await this.http.get<any>(AUTH_API + 'packing/getPackingListNumbers').toPromise();
        return data;
    }

    async getPackingList() {

        let data = await this.http.get<any>(AUTH_API + 'packing/getPackingList').toPromise();
        return data;
    }

    async postPackingInsert(data:any) {

        let result = await this.http.post<any>(AUTH_API + 'packing/insert', data).toPromise();
        return result;
    }

    async getPOsInsertLine(poNumber) {

        let data = await this.http.get<any>(AUTH_API + 'packing/getPOsInsertLine?poNumber=' + poNumber).toPromise();
        return data;
    }

    async postPOsInsertLine(po) {

        let data = await this.http.post<any>(AUTH_API + 'packing/postPOsInsertLine', po).toPromise();
        return data;
    }

    async postPOsValidateExcelLines(listPos) {
        // body to teste only
        let data = await this.http.post<any>(AUTH_API + 'packing/PostPOsValidateExcelLines', listPos).toPromise();
        return data;
    }
}
