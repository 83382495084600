import { Component, Input, OnInit } from '@angular/core';
import { AppMainComponent } from '../../app.main.component';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { BrandService } from '../../services/brands/brand.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'pages-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.sass'],
})
export class MainMenuComponent implements OnInit {
    model: any[];
    brandSettings:any;
    accept2Hand:boolean;
    acceptrepair: boolean;
    acceptreturn:boolean;
    acceptrecycle:boolean;


    constructor(
        public appMain: AppMainComponent,
        public permissions: PermissionsService,
        public brands: BrandService,
        private readonly translateService: TranslateService
    ) { }

   /* enableDashboard(data:any):boolean{//no permissions, 2hand, repair and return, no dashboard
        if(!this.accept2Hand && !this.acceptrepair && !this.acceptreturn && data=='Dashboard'){
            return false;
        }
        else{
            return true;
        }
    }*/

    async ngOnInit() {
        this.brandSettings = JSON.parse(window.sessionStorage.getItem("brands_permissions"));
        this.accept2Hand = Boolean(this.brandSettings["2hand"]);
        this.acceptrepair = Boolean(this.brandSettings["repair"]);
        this.acceptreturn = Boolean(this.brandSettings["returns"]);
        this.acceptrecycle = Boolean(this.brandSettings["recycle"]);
        const permissionsType = this.permissions.getPermissionsType();
        this.appMain.staticMenuInactive = true;
        this.translateService.stream('general.menu').subscribe((val) => {
            this.model = [
                {
                    permission: this.permissions.checkPermissionsMenu([
                        permissionsType.brandDetails
                    ]),
                    items: [
                        {
                            label: 'Dashboard',
                            icon: 'assets/layout/images/home-icon.svg',
                            permission: this.permissions.checkPermissionsSubmenu(
                                permissionsType.brandDetails
                            )&& (this.accept2Hand || this.acceptrepair || this.acceptreturn || this.acceptrecycle),
                            routerLink: ['/main'],
                        }
                    ],
                },
                {
                    permission: this.permissions.checkPermissionsMenu([
                        permissionsType.consultReturn, permissionsType.consult2hand
                    ]),
                    items: [
                        {
                            label: 'Submissions',
                            icon: 'assets/layout/images/submission-icon.svg',
                            items: [
                                {
                                    label: 'Returns',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.consultReturn) && this.acceptreturn,
                                    routerLink: ['returns'],
                                },
                                {
                                    label: 'Second Hand',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.consult2hand)&& this.accept2Hand,
                                    routerLink: ['2hand'],
                                },
                                {
                                    label: 'Repairs',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.consultRepair) && this.acceptrepair,
                                    routerLink: ['repair'],
                                },
                                {
                                    label: 'Recycle',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.consultRecycle) && this.acceptrecycle,
                                    routerLink: ['recycle'],
                                }
                            ]
                        }
                    ]
                },
                {
                    permission: this.permissions.checkPermissionsMenu([
                        permissionsType.consultProducts,
                    ]),
                    items: [
                        {
                            label: 'Products',
                            icon: 'assets/layout/images/product-icon.svg',
                            items: [
                                {
                                    label: 'List All',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.consultProducts,
                                    ),
                                    routerLink: ['list_product'],
                                },
                                // {
                                //     label: 'Collections',
                                //     permission: this.permissions.checkPermissionsSubmenu(
                                //         permissionsType.brandDetails,
                                //     ),
                                // },
                                {
                                    label: 'Receptions',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.consultProductReception,
                                    ),
                                    routerLink: ['purchase_orders']
                                },
                                // {
                                //     label: 'Inventory',
                                //     permission: this.permissions.checkPermissions([
                                //         permissionsType.brandDetails,
                                //     ]),
                                //     routerLink: ['list_product']
                                // }
                            ]
                        }
                    ]
                },
                // {
                //     permission: this.permissions.checkPermissions([
                //         permissionsType.consultSuppliers,
                //     ]),
                //     items: [
                //         {
                //             label: 'Customers',
                //             icon: 'assets/layout/images/costumer-icon.svg',
                //             permission: this.permissions.checkPermissionsSubmenu(
                //                 permissionsType.consultSuppliers,
                //             ),
                //             routerLink: ['list_edit_supplier'],
                //         }
                //     ],
                // },
                {
                    permission: this.permissions.checkPermissionsMenu([
                        permissionsType.consultOrders,
                        permissionsType.consultPO,
                    ]),
                    items: [
                        {
                            label: 'Orders',
                            icon: 'assets/layout/images/order-icon.svg',
                            items: [
                                {
                                    label: 'B2C Sales Orders',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.consultOrders,
                                    ),
                                    routerLink: ['orders_brands']
                                },
                                {
                                    label: '2Hand Orders',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.consult2handOrder,
                                    ),
                                    routerLink: ['2hand_orders']

                                },
                                {
                                    label: 'Purchase Orders',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.consultPO,
                                    ),
                                    routerLink: ['purchaseOrder']
                                }
                                ,
                                {
                                    label: 'Abandoned Shopping Bags',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.consultAbandonedBags,
                                    ),
                                    routerLink: ['abandoned_shopping_bags']

                                },
                              
                                // {
                                //     label: '2HD Sales Orders',
                                //     permission: this.permissions.checkPermissionsSubmenu(
                                //         permissionsType.brandDetails,
                                //     ),
                                //     routerLink: ['returns']
                                // }
                            ]
                        }
                    ]
                },
                {
                    permission: this.permissions.checkPermissionsMenu([
                        permissionsType.brandDetails,
                        permissionsType.createUser,
                    ]),
                    items: [
                        {
                            label: 'Settings',
                            icon: 'assets/layout/images/setting-icon.svg',
                            items: [
                                {
                                    label: 'Brand Settings',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.brandDetails,
                                    ),
                                    routerLink: ['brands']
                                },
                                {
                                    label: 'Users and Permissions',
                                    permission: this.permissions.checkPermissionsSubmenu(
                                        permissionsType.createUser
                                    ),
                                    routerLink: ['list_edit_user']
                                },
                                // {
                                //     label: 'Shippings',
                                //     permission: this.permissions.checkPermissionsSubmenu(
                                //         permissionsType.brandDetails,
                                //     ),
                                //     routerLink: ['returns']
                                // }
                            ]
                        }
                    ]
                },
                {
                    permission: this.permissions.checkPermissionsMenu([
                        permissionsType.brandDetails,
                        permissionsType.createUser,
                        permissionsType.createUser,
                    ])
                }
            ];
        });
    }

    onKeydown(event: KeyboardEvent) {
        const nodeElement = <HTMLDivElement>event.target;
        if (event.code === 'Enter' || event.code === 'Space') {
            nodeElement.click();
            event.preventDefault();
        }
    }
}
