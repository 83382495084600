import { ChangeDetectorRef, Component, HostListener, Injectable, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuService } from '../../service/app.menu.service';
import { AppMainComponent } from '../../app.main.component';
import { TokenStorageService } from 'src/app/services/token-storage/token-storage.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { BrandService } from 'src/app/services/brands/brand.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: '[pages-menuitem]',
    templateUrl: './menuitem.component.html',
    styleUrls: ['menuitem.component.scss'],
    host: {
        '[class.active-menuitem]': 'active',
    },
    animations: [
        trigger('children', [
            state('void', style({
                height: '0px'
            })),
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => visibleAnimated, visibleAnimated => void',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})

export class MenuItemComponent implements OnInit, OnDestroy {

    inside = false;
    @HostListener("click")
    clicked() {
        this.inside = true;
    }
    @HostListener("document:click")
    clickedOut() {
        this.active = this.inside
            ? this.active
            : false;
        this.inside = false;
    }

    @Input() item: any;

    @Input() index: number;

    @Input() root: boolean;

    @Input() parentKey: string;

    active: boolean = false;

    menuSourceSubscription: Subscription;

    menuResetSubscription: Subscription;

    key: string;

    current_password: string;

    new_password: string;

    confirm_password: string;

    password_match: boolean = true;

    current_password_error: boolean = false;

    submitted: boolean = false;

    displayModal: boolean = false;

    constructor(
        public app: AppMainComponent,
        public router: Router,
        private menuService: MenuService,
        private tokenService: TokenStorageService,
        private authService: AuthService,
        private userService: UserService,
        private tokenStorage: TokenStorageService,
        private permissions: PermissionsService,
        private brandService: BrandService,
        private readonly translateService: TranslateService
    ) {
        this.menuSourceSubscription = this.menuService.menuSource$.subscribe(key => {
            // deactivate current active menu
            if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
                this.active = false;
            }
        });

        this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
            this.active = false;
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(params => {
                if (this.item.routerLink) {
                    this.updateActiveStateFromRoute();
                } else {
                    this.active = false;
                }
            });
    }

    ngOnInit() {
        if (this.item.routerLink) {
            this.updateActiveStateFromRoute();
        }

        this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
    }

    updateActiveStateFromRoute() {
        this.active = this.router.isActive(this.item.routerLink[0], this.item.items ? false : true);
        if (this.router.url == '/main/' + this.item.routerLink[0]) {
            this.active = true
        }
    }
    
    itemClick(event: Event) {
        event.stopPropagation();

        // avoid processing disabled items
        if (this.item.disabled) {
            event.preventDefault();
            return;
        }

        // notify other items
        this.menuService.onMenuStateChange(this.key);

        // execute command
        if (this.item.command) {
            this.item.command({ originalEvent: event, item: this.item });
        }

        this.app.staticMenuInactive = true;

        // toggle active state
        if (this.item.items) {
            this.app.staticMenuInactive = false;
            this.active = !this.active;
        } else {
            // activate item
            this.active = true;

            // hide overlay menus
            this.app.menuActiveMobile = false;

            if (this.app.isDesktop() && this.app.isOverlay()) {
                this.app.menuInactiveDesktop = true;
            }
        }

        if (this.item.label == "Change password") {
            this.displayModal = true
            this.current_password = null, this.new_password = null, this.confirm_password = null
        } else if (this.item.label == "Log out") {
            this.logOut()
        } else if (this.item.brand) {
            this.changeBrand({ id_brand: this.item.id_brand })
        }
    }

    ngOnDestroy() {
        if (this.menuSourceSubscription) {
            this.menuSourceSubscription.unsubscribe();
        }

        if (this.menuResetSubscription) {
            this.menuResetSubscription.unsubscribe();
        }
    }

    async submitPassword() {
        this.submitted = true;

        if (this.new_password == this.confirm_password && this.new_password.length <= 30) {
            this.password_match = true;
            let passwordUpdateUser = {
                data: {
                    old_password: this.current_password,
                    password: this.new_password
                }
            }
            this.userService.putPasswordUser(passwordUpdateUser).subscribe(
                data => {
                    this.displayModal = false;
                    this.current_password_error = false;
                    this.password_match = true;
                },
                error => {
                    this.current_password_error = true;
                })
        } else {
            this.password_match = false;
        }
    }

    validatePassword() {
        var passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?()/=",.;:_\\-])[A-Za-z\d#$@!%&*?()/=",.;:_\\-]{8,}$/;
        if (passwordRegex.test(this.new_password)) {
            return true
        } else {
            return false
        }
    }

    logOut() {
        this.authService.signOut().subscribe(() => {
            this.router.navigate(['login']);
        });
        this.tokenService.signOut();
    }

    async changeBrand(id_brand) {
        await (await this.authService.changeBrand(id_brand)).subscribe(async (data) => {
            this.permissions.savePermissions(data.User.permissions);
            this.brandService.saveBrand(data.User.id_brand);
            this.translateService.use(data.User.language_code.match(/gb|pt/) ? data.User.language_code : 'gb')
            this.tokenStorage.saveToken(data.User['Bearer Token']);

            const accept= await this.brandService.checkIfBrandAccept();
            window.sessionStorage.setItem("brands_permissions", JSON.stringify(accept));
            window.sessionStorage.setItem("language_code", data.User.language_code);
            window.sessionStorage.setItem("returncenter_url", data.User.returncenter_url);
            window.sessionStorage.setItem("2hand_link", data.User["2hand_link"]);
            window.sessionStorage.setItem("shop_domain", data.User.shop_domain);
            window.sessionStorage.setItem("wms", (data.User.logistic_op != undefined ? "true" : "false"));
            window.sessionStorage.setItem("id_token", data.User.id_token);
            window.sessionStorage.setItem("username", data.User.username);
            window.sessionStorage.setItem("id_user", data.User.id_user);
            window.sessionStorage.setItem("user_details", JSON.stringify(data.User));
            window.location.reload();
        });
    }
}
