import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//import { HttpHeaders } from '@angular/common/http';

//import { Product } from '../../api/product';

//import { Observable } from 'rxjs';

import { AppConfig } from '../../api/appconfig';

import { Subject } from 'rxjs';

import { ConnectableObservable } from "rxjs";
import { Subscription } from 'rxjs';
import { Observable, of, pipe } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators'


import { share } from 'rxjs/operators';

@Injectable()
export class LoginService {

    someDataObservable: Observable<any>;

    //errorMessage;

    config: AppConfig = {
        theme: 'lara-light-indigo',
        dark: false,
        inputStyle: 'outlined',
        ripple: true
    };

    //result: Observable<any>;

    private configUpdate = new Subject<AppConfig>();

    configUpdate$ = this.configUpdate.asObservable();

    //currentURL = '';

    //data: Observable<any>;

    result;

    constructor(private http: HttpClient) {
    }

    updateConfig(config: AppConfig) {
        this.config = config;
        this.configUpdate.next(config);
    }

    getConfig() {
        return this.config;
    }

    //postLogin(data: object) {

    //    return this.http.post<any>(this.appService.url() + 'users/login', { 'data': data });

    //}

    //async getAll() {

    //    return this.http.get<any>(this.url() + 'group/GetAll', this.headers())
    //        .toPromise()
    //        //.then(res => res.data as Product[])
    //        .then(data => data);

    //}

    /*
  ngOnInit() {          
    this.http.post<Article>('https://reqres.in/api/posts', { title: 'Angular POST Request Example' }).subscribe(data => {
        this.postId = data.id;
    })
}

...

interface Article {
    id: number;
    title: string;
}
}
      */



}

