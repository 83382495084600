import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Supplier } from 'src/app/api/supplier';
import { CountryService } from 'src/app/services/country/country.service';
import { SupplierService } from 'src/app/services/supplier/supplier.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'pages-create-supplier',
    templateUrl: './create_supplier.component.html',
    styleUrls: ['./create_supplier.component.sass'],
})
export class CreateSupplierComponent implements OnInit {

    selectedCountry: any;

    countries = [];

    ccRegex: RegExp = /(.*?)/;

    ccEmailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    ccPhoneRegex: RegExp = /^9[1236]{1}[0-9]{7}$|^2[1-9]{1}[0-9]{7}$/;

    ccNIFRegex: RegExp = /^[0-9]{9}$/;

    regex_example: string = "";

    loadingCreate: boolean;

    country_code: string;

    name: string;

    email: string;

    codigo_wms: string;

    phone: number;

    nif: number;

    address: string;

    city: string;

    zip: string;

    codigo_postal: string;

    isError: boolean;

    submitted: boolean = false;

    reactiveForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.pattern(this.ccEmailRegex)]),
        codigo_wms: new FormControl('', [Validators.required]),
        phone: new FormControl('', [Validators.required]),
        nif: new FormControl('', [Validators.required]),
        address: new FormControl('', [Validators.required]),
        city: new FormControl('', [Validators.required]),
        selectedCountry: new FormControl('',),
        zip: new FormControl('', [Validators.required, Validators.pattern(this.ccRegex)]),
        transfer: new FormControl(false, [Validators.required])
    });

    stateOptions = [];

    breadcumbItems: MenuItem[] = [];

    constructor(
        private countryService: CountryService,
        private supplierService: SupplierService,
        private messageService: MessageService,
        private router: Router,
        private readonly translateService: TranslateService
    ) {
    }

    async ngOnInit() {

        //load data to translate
        await this.translateService.get('dummyTranslation').toPromise().then();

        this.translateService.stream('general.menu').subscribe(val => {
            this.breadcumbItems = [
                { label: '', icon: 'pi pi-home' },
                { label: val.suppliers.value },
                { label: val.suppliers.items.add_supplier }
            ]
        });

        this.translateService.stream('primeng').subscribe(val => {
            this.stateOptions = [
                { label: val.accept, value: true },
                { label: val.reject, value: false }
            ]
        });

        this.submitted = false;
        this.countryService.getCountries()
            .then((countries) => {
                this.countries = countries;
            });
    }

    itemClicked(event) {
        if (event.item.label === '') {
            this.router.navigate(['main']);
        }
    }

    async createZipRegex() {
        this.reactiveForm.get('zip').setValue(null);
        await this.countryService.getCountrByCode(this.selectedCountry)
            .then((country) => {
                this.ccRegex = new RegExp(country.country_ziprule);
                this.regex_example = country.country_ziperror;
            });
    }

    onSubmit() {
        this.isError = false;
        this.submitted = true;

        let transfer = 0;
        const value = this.reactiveForm.get('transfer').value;
        if (value == true) {
            transfer = 1;
        }

        this.country_code = this.reactiveForm.get('selectedCountry').value
        this.codigo_wms = this.reactiveForm.get('codigo_wms').value
        this.nif = this.reactiveForm.get('nif').value
        this.address = this.reactiveForm.get('address').value
        this.city = this.reactiveForm.get('city').value
        this.codigo_postal = this.reactiveForm.get('zip').value
        this.phone = this.reactiveForm.get('phone').value
        this.email = this.reactiveForm.get('email').value
        this.name = this.reactiveForm.get('name').value

        let supplier: Supplier = {
            codigo_wms: this.reactiveForm.get('codigo_wms').value,
            name: this.reactiveForm.get('name').value,
            country_code: this.reactiveForm.get('selectedCountry').value,
            nif: this.reactiveForm.get('nif').value,
            address: this.reactiveForm.get('address').value,
            city: this.reactiveForm.get('city').value,
            codigo_postal: this.reactiveForm.get('zip').value,
            phone: this.reactiveForm.get('phone').value,
            email: this.reactiveForm.get('email').value,
            transfer: transfer
        }

        const invalidFields = document.getElementsByClassName("ng-invalid");
        let fieldsError = false;
        if (invalidFields.length != 0) {
            fieldsError = true;
        }
        if (!fieldsError) {

            this.loadingCreate = true;
            
            this.supplierService.postSupplier(supplier).subscribe(
                data => {
                    this.isError = false;
                    this.selectedCountry = null;
                    this.reactiveForm.get('transfer').setValue(false)
                    this.loadingCreate = false;
                    this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("suppliers.create_success") });
                },
                error => {
                    this.reactiveForm.get('selectedCountry').setValue(this.country_code)
                    this.reactiveForm.get('codigo_wms').setValue(this.codigo_wms)
                    this.reactiveForm.get('nif').setValue(this.nif)
                    this.reactiveForm.get('name').setValue(this.name)
                    this.reactiveForm.get('address').setValue(this.address)
                    this.reactiveForm.get('city').setValue(this.city)
                    this.reactiveForm.get('zip').setValue(this.codigo_postal)
                    this.reactiveForm.get('phone').setValue(this.phone)
                    this.reactiveForm.get('email').setValue(this.email)
                    this.reactiveForm.get('transfer').setValue(value)
                    this.loadingCreate = false;
                    this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("suppliers.create_error_name") });
                })
        } else {
            this.isError = true;
            this.messageService.add({ key: 'tst', severity: 'warn', summary: this.translateService.instant("suppliers.warning"), detail: this.translateService.instant("suppliers.warning_update") });
        }
    }
}
