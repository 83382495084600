import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from "primeng/fileupload";
import { TableModule } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { InsertReceptionComponent } from './insert_reception.component';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        ButtonModule,
        CommonModule,
        DialogModule,
        ReactiveFormsModule,
        TableModule,
        ToastModule,
        FormsModule,
        InputTextModule,
        FileUploadModule,
        DropdownModule,
        MessageModule,
        SelectButtonModule,
        BreadcrumbModule,
        AutoCompleteModule,
        TranslateModule
    ],
    declarations: [
        InsertReceptionComponent
    ],
    exports: [
        InsertReceptionComponent
    ],
    providers: [
        MessageService
    ]
})

export class InsertReceptionModule { }
