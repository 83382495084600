<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
    <div class="loading"></div>
</div>

<div *ngIf="!loading">
    <div class="grid m-4" style="justify-content: center !important;">
        <div class="col-12 sm:col-12 col-8 md:col-6">
            <div class="card-shadow" style="min-height: 220px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.repairs.total_number_of_repairs' | translate }}</h5>
                    </div>
                    <div class="bg-purple-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-database text-purple-500 text-xl"></i>
                    </div>
                </div>
                <div>
                    <h2 style="margin-top: 4rem;" class="flex justify-content-center align-items-center">
                        {{dashStructAux.nRepairs}}</h2>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-6">
            <div class="card-shadow" style="min-height: 220px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.repairs.total_value_of_repairs' | translate }}</h5>
                    </div>
                    <div class="bg-yellow-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-euro text-yellow-500 text-xl"></i>
                    </div>
                </div>
                <h2 style="margin-top: 4rem;" class="flex justify-content-center mb-3">{{dashStructAux.tValueRepairs && dashStructAux.tValueRepairs !== 'NaN' ?
                    dashStructAux.tValueRepairs : '- '}}€</h2>
            </div>
        </div>

        <div class="col-12 md:col-12">
            <div class="card-shadow" style="min-height: 166.59px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.repairs.most_repaired_product' | translate }}</h5>
                    </div>
                    <div class="bg-orange-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-cog text-orange-500 text-xl"></i>
                    </div>
                </div>
                <div *ngIf="dashStruct.mRProducts!=null">
                    <div>
                        <div class="flex  flex-wrap justify-content-between mb-3">
                            <div>
                                <h6 class="flex justify-content-start mb-3">{{dashStruct.mRProducts.title}}</h6>
                                <h6 class="flex justify-content-start mb-3">Times Repaired:
                                    {{dashStruct.mRProducts.nRepairs}} </h6>
                                <h8 class="flex justify-content-start mb-3">SKU: {{dashStruct.mRProducts.sku}}</h8>
                                <h8 class="flex justify-content-start mb-3">Color: {{dashStruct.mRProducts.color}}</h8>
                                <h8 class="flex justify-content-start mb-3">Size: {{dashStruct.mRProducts.size}} </h8>

                            </div>
                            <img [src]="dashStruct.mRProducts.photo" [alt]="dashStruct.mRProducts.title"
                                style="max-height: 180px;" />
                        </div>
                    </div>
                    <hr>
                    <div class="flex flex-wrap align-items-center" style="justify-content: space-evenly !important;">
                        <h6 style="margin-right: 1rem;">Services:</h6>
                        <div *ngIf="dashStruct.mRProducts!=null">
                            <div style="line-height: 25px;" class=" justify-content-center">
                                <div *ngFor="let description of  dashStruct.mRProducts.sDescriptions">
                                    <h6>{{description.sDescription}} </h6>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 md:col-12">
            <div class="card-shadow" style="min-height: 166.59px;">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3 block">{{ 'orders.repairs.most_popular_service' | translate }}</h5>
                    </div>
                    <div class="bg-pink-100 border-round flex align-items-center justify-content-center"
                        style="width: 2.5rem; height: 2.5rem;">
                        <i class="pi pi-heart text-pink-500 text-xl"></i>
                    </div>
                </div>
                <div *ngIf="dashStruct.mPService!=null">
                    <h6 class="flex flex-wrap align-items-center mb-3">Service: {{dashStruct.mPService.description}}
                    </h6>
                    <h6 class="flex flex-wrap align-items-center mb-3">Times Used: {{dashStruct.mPService.nServices}}
                    </h6>
                    <div>
                        <hr>
                        <div *ngIf=" dashStruct.mPService!=null">
                            <div *ngFor="let p of  dashStruct.mPService.products; let i = index">
                                <div class="flex  flex-wrap justify-content-between mb-3">
                                    <div>
                                        <h6 class="flex justify-content-start mb-3">{{p.title}}</h6>
                                        <h8 class="flex justify-content-start mb-3">SKU: {{p.sku}}</h8>
                                        <h8 class="flex justify-content-start mb-3">Color: {{p.color}}</h8>
                                        <h8 class="flex justify-content-start mb-3">Size: {{p.size}} </h8>
                                    </div>
                                    <img [src]="p.photo" [alt]="p.title" style="max-height: 180px;" />
                                </div>
                                <hr *ngIf="dashStruct.mPService.products.length-1!== i" class="mb-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>