import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { InputMaskModule } from 'primeng/inputmask';
import { CommonModule } from '@angular/common';
import { ListProductComponent } from './list_product.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { KeyFilterModule } from 'primeng/keyfilter';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DividerModule } from 'primeng/divider';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarModule } from 'primeng/sidebar';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CarouselModule } from 'primeng/carousel';
import { TabViewModule } from 'primeng/tabview';
import { AvatarModule } from 'primeng/avatar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ContextMenuModule } from 'primeng/contextmenu';


@NgModule({
    imports: [
        ButtonModule,
        CommonModule,
        DialogModule,
        ReactiveFormsModule,
        TableModule,
        ToastModule,
        FormsModule,
        KeyFilterModule,
        InputTextModule,
        InputMaskModule,
        FileUploadModule,
        DropdownModule,
        MessageModule,
        BreadcrumbModule,
        DividerModule,
        TranslateModule,
        SidebarModule,
        MultiSelectModule,
        CheckboxModule,
        OverlayPanelModule,
        RadioButtonModule,
        SplitButtonModule,
        SelectButtonModule,
        CarouselModule,
        AvatarModule,
        InputNumberModule,
        InputTextareaModule,
        ContextMenuModule
    ],
    declarations: [
        ListProductComponent
    ],
    exports: [
        ListProductComponent
    ],
    providers: [MessageService]

})
export class ListProductModule { }
