import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root',
})
export class PurchaseOrdersService {
    constructor(private http: HttpClient) {}

    async getAllPOsByIdBrand() {
        let data = await this.http
            .get<any>(AUTH_API + 'packing/getPOnumbers')
            .toPromise();
        return data;
    }

    async getPOsInfoByPO(po_number) {
        let data = await this.http
            .get<any>(
                AUTH_API +
                    'packing/getPurchaseOrderByNumber?POnumber=' +
                    po_number
            )
            .toPromise();
        return data;
    }

    async getPurchaseOrderByIDPackingList(id) {
        let data = await this.http
            .get<any>(
                AUTH_API +
                    'packing/getPurchaseOrderByIDPackingList?IDPackingList=' +
                    id
            )
            .toPromise();
        return data;
    }

    async getPOnumb() {
        let data = await this.http
            .get<any>(AUTH_API + 'purchaseOrder/getPOnumb')
            .toPromise();
        return data;
    }

    async getPO(po_number) {
        let data = await this.http
            .get<any>(
                AUTH_API +
                    'purchaseOrder/getPurchaseOrder?purchaseOrder=' +
                    po_number
            )
            .toPromise();
        return data;
    }
}
