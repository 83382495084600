<div class="flex gap-3 p-6">
	<div class="flex flex-column w-6 gap-3">
		<div class="w-full flex gap-3">
			<div class="w-6 flex-column">
				<h6 for="email1" class="flex mb-2">First Name</h6>
				<input id="email1" [(ngModel)]="first_name" type="text" placeholder="First Name" pInputText
					class="p-inputtext w-full">
			</div>
			<div class="w-6 flex-column">
				<h6 for="email1" class="flex mb-2">Last Name</h6>
				<input id="email1" [(ngModel)]="last_name" type="text" placeholder="Last Name" pInputText
					class="p-inputtext w-full">
			</div>
		</div>
		<div class="w-full flex">
			<div class="flex-column">
				<h6 for="email1" class="flex mb-2">Email</h6>
				<input id="email1" [(ngModel)]="email" type="text" placeholder="Email address" pInputText
					class="p-inputtext w-full">
			</div>
		</div>
		<div class="w-full flex">
			<div class="flex-column">
				<h6 for="email1" class="flex mb-2">Timezone</h6>
				<input id="email1" [(ngModel)]="timezone" type="text" placeholder="Timezone" pInputText
					class="p-inputtext w-full">
			</div>
		</div>
		<div class="w-full flex gap-3">
			<div class="w-8 flex-column">
				<h6 for="email1" class="flex mb-2">Work Phone</h6>
				<input id="email1" [(ngModel)]="work_phone" type="text" placeholder="Work Phone" pInputText
					class="p-inputtext w-full">
			</div>
			<div class="w-4 flex-column">
				<h6 for="email1" class="flex mb-2">Extension</h6>
				<!-- <input id="email1" [(ngModel)]="username" type="text" placeholder="Email address" pInputText
					class="p-inputtext w-full" (keyup.enter)="onSubmit()"
					[ngClass]="{'ng-invalid ng-dirty' : submitted && !username}"> -->
			</div>
		</div>
		<div>
			<button pButton pRipple type="button" label="Change Password" class="p-button-outlined p-button-danger"
				(click)="openDisplayModal()"></button>
		</div>
		<div>
			<button pButton pRipple type="button" label="Log Out" (click)="logOut()"></button>
		</div>
	</div>
	<div class="w-6" style="background-color: azure;">

	</div>
</div>
<p-dialog header="Change Password" [(visible)]="displayModal" [modal]="true" [style]="{width: '400px', maxWidth: '90%'}"
	[draggable]="false" [resizable]="false">
	<div class="grid">
		<div class="col-12 flex flex-column">
			<div>Your password must be at least 8 characters, can’t begin or end with a space and include at least one:
			</div>
			<div class="flex flex-column">
				<div class="flex gap-1">
					<div class="col-6">-Lower case letter</div>
					<div class="col-6">-Number</div>
				</div>
				<div class="flex gap-1">
					<div class="col-6">-Uppercase letter</div>
					<div class="col-6">-Special character</div>
				</div>
			</div>
		</div>
		<div class="col-12 flex">
			<div class="w-full flex-column mb-3">
				<h6 for="current_password" class="flex mb-2">Current password</h6>
				<p-password id="current_password" [(ngModel)]="current_password" [toggleMask]="false" [feedback]="false"
					styleClass="w-full"
					[ngClass]="{'ng-invalid ng-dirty' : submitted && current_password_error}"></p-password>
				<small *ngIf="submitted && current_password_error" class="p-error absolute flex gap-1 mt-2"><img
						src="assets/layout/images/error-icon.svg">Invalid password</small>
			</div>
		</div>
		<div class="col-12 flex">
			<div class="w-full flex-column mb-3">
				<h6 for="new_password" class="flex mb-2">New password</h6>
				<p-password id="new_password" [(ngModel)]="new_password" [toggleMask]="true" [feedback]="false"
					styleClass="w-full"
					[ngClass]="{'ng-invalid ng-dirty' : submitted && (!validatePassword() || new_password.length > 30)}"></p-password>
				<small *ngIf="submitted && !validatePassword()" class="p-error absolute flex gap-1 mt-2"><img
						src="assets/layout/images/error-icon.svg">Invalid password</small>
				<small *ngIf="submitted && new_password.length > 30" class="p-error absolute flex gap-1 mt-2"><img
						src="assets/layout/images/error-icon.svg">Password
					must be less than 30 characters</small>
			</div>
		</div>
		<div class="col-12 flex">
			<div class="w-full flex-column mb-3">
				<h6 for="confirm_password" class="flex mb-2">Confirm new password</h6>
				<p-password id="confirm_password" [(ngModel)]="confirm_password" [toggleMask]="true" [feedback]="false"
					styleClass="w-full"
					[ngClass]="{'ng-invalid ng-dirty' : submitted && (!validatePassword() || confirm_password.length > 30)}"></p-password>
				<small *ngIf="submitted && !validatePassword()" class="p-error absolute flex gap-1 mt-2"><img
						src="assets/layout/images/error-icon.svg">Invalid password</small>
				<small *ngIf="submitted && validatePassword() && confirm_password.length <= 30 && !password_match"
					class="p-error absolute flex gap-1 mt-2"><img src="assets/layout/images/error-icon.svg">Passwords
					doesn't match</small>
				<small *ngIf="submitted && confirm_password.length > 30" class="p-error absolute flex gap-1 mt-2"><img
						src="assets/layout/images/error-icon.svg">Password
					must be less than 30 characters</small>
			</div>
		</div>
	</div>
	<ng-template pTemplate="footer">
		<p-button (onClick)="submitPassword()" label="Change password" styleClass="w-full"
			[disabled]="!current_password || !new_password || !confirm_password || !validatePassword()"></p-button>
	</ng-template>
</p-dialog>