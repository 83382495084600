<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<div *ngIf="!loading" class="grid m-4">
	<!-- <div class="col-12 md:col-12">
		<div class="card-shadow h-full flex flex-column gap-3">
			<a *ngIf="totalPending > 0" href="#/main/returns">
				<div class="w-full wmsReturnStatus status-warning">
					{{ 'orders.returns.pending_returns_count' | translate: {totalPending:totalPending} }}
				</div>
			</a>
			<div class="mb-0">
				<p> {{ 'orders.orders.orders_between' | translate }}:</p>
				<div class="flex gap-3 align-items-center">
					<p-calendar [showIcon]="true" [(ngModel)]="rangeDates" selectionMode="range" dateFormat="dd/mm/yy"
						(onClose)="ngOnInit()" [minDate]="minimumDate"></p-calendar>
					<div class="flex gap-1 align-items-center">
						<p-checkbox [(ngModel)]="checked" [binary]="true" inputId="binary"
							(onChange)="ngOnInit()"></p-checkbox>
						<label for="binary">{{ 'orders.returns.only_finalized' | translate }}</label>
					</div>
				</div>
			</div>
		</div>
	</div> -->
	<div class="col-12 md:col-5">
		<div class="card-shadow">
			<div class="flex flex-wrap justify-content-between mb-3">
				<h5 class="mb-3">{{ 'orders.returns.returns_status' | translate }}</h5>
				<div class="bg-pink-100 border-round flex align-items-center justify-content-center"
					style="width: 2.5rem; height: 2.5rem;">
					<i class="pi pi-exclamation-circle text-pink-500 text-xl"></i>
				</div>
			</div>
			<div class="flex flex-column align-items-center justify-content-center"
				style="width: 100% !important; height: 100%;">
				<p-chart type="pie" [data]="pieDataStatus" height="350px"
					[style]="{'width': '90%', 'maxWidth': '350px'}" responsive="true"></p-chart>
			</div>

		</div>
	</div>
	<div class="col-12 md:col-7">
		<div class="card-shadow">
			<div class="flex flex-wrap justify-content-between mb-3">
				<h5 class="mb-3">{{ 'orders.returns.returns_options' | translate }}</h5>
				<div class="bg-blue-100 border-round flex align-items-center justify-content-center"
					style="width: 2.5rem; height: 2.5rem;">
					<i class="pi pi-bars text-blue-500 text-xl"></i>
				</div>
			</div>
			<div class="flex flex-column align-items-center justify-content-center"
				style="width: 100% !important; height: 100% !important; ">
				<p-chart type="bar" [data]="barDataOptions" height="350px" [style]="{'width': '90%', 'height': '100%'}"
					responsive="true" [options]="barOptions"></p-chart>
			</div>
		</div>
	</div>
	<div class="col-12 md:col-12">
		<div class="card-shadow">
			<div class="flex flex-wrap justify-content-between mb-3">
				<h5 class="mb-3">{{ 'orders.returns.returns_payment' | translate }}</h5>
				<div class="bg-yellow-100 border-round flex align-items-center justify-content-center"
					style="width: 2.5rem; height: 2.5rem;">
					<i class="pi pi-euro text-yellow-500 text-xl"></i>
				</div>
			</div>
			<div
				class="flex flex-column lg:flex-row align-items-start lg:align-items-center md:justify-content-between mb-4">
				<div>
					<span class="font-medium mr-2 mb-1 md:mb-0">Total returned</span>
				</div>
				<div
					class="mt-2 md:mt-0 flex align-items-center justify-content-between w-full lg:justify-content-end lg:w-auto">
					<div class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" [ngStyle]="{height: '8px'}">
						<div class="bg-green h-full" [ngStyle]="{width: calculate(totalPaymentReturns)}"></div>
						<div class="absolute" style="font-size: 10px;">{{calculate(totalPaymentReturns)}}</div>
					</div>
					<span class="text-green ml-3 font-medium"
						style="width: 5rem; text-align: end;">{{totalPaymentReturns}}€</span>
				</div>
			</div>
			<div *ngFor="let payment of paymentReturns">
				<div
					class="flex flex-column lg:flex-row align-items-start lg:align-items-center md:justify-content-between mb-4">
					<div>
						<span class="mr-2 mb-1 sm:ml-3 md:mb-0">{{payment.name}}</span>
					</div>
					<div
						class="mt-2 md:mt-0 flex align-items-center justify-content-between w-full lg:justify-content-end lg:w-auto">
						<div class="sm:ml-3 surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
							[ngStyle]="{height: '8px'}">
							<div class="bg-green h-full" [ngStyle]="{width: calculate(payment.value)}"></div>
							<div class="absolute" style="font-size: 10px;">{{calculate(payment.value)}}</div>
						</div>
						<span class="text-green ml-3 font-medium"
							style="width: 5rem; text-align: end;">{{payment.value}}€</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-12 lg:col-7">
		<div class="card-shadow">
			<div class="flex flex-wrap justify-content-between mb-3">
				<h5 class="mb-3">{{ 'orders.returns.most_returned_products' | translate }}</h5>
				<div class="bg-purple-100 border-round flex align-items-center justify-content-center"
					style="width: 2.5rem; height: 2.5rem;">
					<i class="pi pi-history text-purple-500 text-xl"></i>
				</div>
			</div>
			<div class="flex flex-column align-items-center justify-content-center"
				style="width: 100% !important; height: 100%;">
				<p-chart type="bar" [data]="barDataBestProducts" height="350px"
					[style]="{'width': '90%', 'height': '100%'}" responsive="true" [options]="stackedOptions"></p-chart>
			</div>
		</div>
	</div>
	<div class="col-12 lg:col-5 flex flex-column-reverse lg:flex-column">
		<div class="card-shadow">
			<div class="flex flex-column sm:flex-row justify-content-between mb-3">
				<div class="flex flex-column w-full">
					<div class="flex justify-content-between mb-3">
						<h5 class="mb-3 flex flex-wrap justify-content-between mb-3">{{
							'orders.returns.n1_returned_product' | translate: {totalReturn:totalReturn} }}</h5>
						<div class="bg-orange-100 border-round flex flex-wrap align-items-center justify-content-center"
							style="width: 2.5rem; height: 2.5rem; min-width: 2.5rem;">
							<i class="pi pi-star text-orange-500 text-xl"></i>
						</div>
					</div>
					<div class="flex flex-column gap-3 h-full">
						<div class="font-medium"><b>{{productSelect.product_id}}</b></div>
						<div class="flex flex-wrap gap-3 w-full justify-content-between">
							<div *ngIf="productSelect.Image_url != 'null'">
								<img [src]="productSelect.Image_url" [alt]="productSelect.ProductName"
									height={{heightImg}} />
							</div>
							<div id="block-products-returns" class="w-full md:w-8 lg:w-full flex flex-column gap-2">
								<div class="grid nested-grid justify-content-end border-bottom-1 border-300"
									*ngFor="let colors_products of productSelect.Colors_products">
									<div class="col-3">
										<div style="overflow-wrap: break-word;" class="font-bold">{{colors_products.color}}</div>
									</div>
									<div class="col-9" *ngFor="let product of colors_products.products">
										<div class="grid">
											<div class="col-8">
												<div>{{product.name}}</div>
											</div>
											<div class="col-2">
												<div>{{product.option_2_value}}</div>
											</div>
											<div class="col-2">
												<div>{{product.value}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>