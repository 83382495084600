<div class="grid">
	<div class="col-12">
		<p-breadcrumb [style]="{background: 'none' , border: 'none'}" [model]="breadcumbItems"
			(onItemClick)="itemClicked($event)"></p-breadcrumb>
		<div class="card">
			<h5>{{ 'orders.returns.return_details' | translate }} {{return.id_return}}</h5>
			<div class="grid my-4 align-items-center">
				<div class="col-12 md:col-10 lg:col-10 align-items-center justify-content-center">
					<div class="grid">
						<div class="col-12 sm:col-6 md:col-6 lg:col-4">
							<p><b>{{ 'orders.returns.order_name' | translate }}:</b> {{return.order_name}}</p>
						</div>
						<div class="col-12 sm:col-6 md:col-6 lg:col-4">
							<p><b>{{ 'orders.returns.return_created' | translate }}:</b> {{return.started_at}}</p>
						</div>
						<div class="col-12 sm:col-6 md:col-6 lg:col-4">
							<p><b>{{ 'orders.returns.email' | translate }}:</b> {{return.email}}</p>
						</div>
						<div class="col-12 sm:col-6 md:col-6 lg:col-4">
							<p><b>{{ 'orders.returns.address' | translate }}:</b> {{return.address1}}
								{{return.address2}} {{return.city}} {{return.zipcode}} {{return.province}}
								{{return.country}}</p>
						</div>
						<div class="col-12 sm:col-6 md:col-6 lg:col-4">
							<p><b>{{ 'orders.returns.by' | translate }}:</b> {{return.first_name}} {{return.last_name}}
							</p>
						</div>
						<div class="col-12 sm:col-6 md:col-6 lg:col-4">
							<p><b>{{ 'orders.returns.payment_description' | translate }}:</b>
								{{return.payment_description}}</p>
						</div>
						<div class="col-12 sm:col-6 md:col-6 lg:col-4">
							<p><b>{{ 'orders.returns.status' | translate }}:</b> {{return.returnStatus}}</p>
						</div>
						<div class="col-12 sm:col-6 md:col-6 lg:col-4">
							<p><b>{{ 'orders.orders.tracking_number' | translate }}:</b>
								<span *ngIf="!displayTracking && !return.track_link"> {{return.tracking_id}}</span>
								<a *ngIf="return.track_link  && !displayTracking"
									href="{{return.track_link}}{{return.tracking_id}}" class="ml-2" target="_blank"
									style="word-break: break-word;">{{return.tracking_id}}</a>
								<span *ngIf="displayTracking" class="p-input-icon-right">
									<i class="pi pi-check" (click)="onSubmitTracking()"></i>
									<input type="text" pInputText [(ngModel)]="return.tracking_id" class="ml-2"
										(keypress)="onSubmitTracking($event)" required />
								</span>
							</p>
						</div>
					</div>
				</div>
				<div class="col-12 md:col-2 lg:col-2 flex align-items-start justify-content-end align-self-start"
					*ngIf="return.IDStatus !== 9">
					<div>
						<button class="p-button-raised p-button-secondary p-button-text" type="button" pButton
							icon="pi pi-cog" label="Actions" (click)="menu.toggle($event)"></button>
						<p-menu #menu [popup]="true" [model]="dropdown_options"></p-menu>
					</div>
				</div>
			</div>
		</div>
		<div>
			<div class="returns-summary flex flex-column gap-3">
				<div class="w-full flex flex-column md:flex-row gap-3">
					<div class="returns-block card" [ngClass]="{'w-full' : return?.order_name?.includes('exc.')}">
						<h5>{{ 'orders.returns.return_items' | translate }}</h5>
						<div class="flex align-items-start justify-content-center">
							<p-dataView class="w-full" #dv [value]="return.lines" [paginator]="false" layout="list">
								<ng-template let-returnline pTemplate="listItem">
									<div class="w-full">
										<div id="info-return"
											class="info-return flex flex-row gap-2 p-3 pt-5 pb-5 w-full justify-content-between"
											*ngIf="returnline.IDStatus !== 14">
											<div class="flex flex-column sm:flex-row gap-3 w-full">
												<div id="block-img-product"
													class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
													<img [src]="returnline.Image_url" [alt]="returnline.productname"
														class="w-7rem sm:w-10rem shadow-2" />
												</div>
												<div class="md:text-left flex flex-column gap-3 max-w-full">
													<span
														class="align-items-start justify-content-start"><b>{{returnline.ProductName}}</b></span>
													<div class="flex align-items-center">
														<span class="mr-3 align-self-center md:align-self-end"><b> Sku:
															</b>
															{{returnline.sku}}</span>
														<span class="align-self-center md:align-self-end"><b> {{
																'orders.returns.order' | translate }}: </b>
															{{return.order_name}}</span>
													</div>
													<div class="flex align-items-center">
														<span class="mr-3 align-self-center md:align-self-end"><b> {{
																'orders.returns.quantity' | translate }}: </b>
															{{returnline.quantity}}</span>
														<span class="mr-3 align-self-center md:align-self-end"><b> {{
																'orders.returns.received' | translate }}: </b>
															{{returnline.quantityReceived}}</span>
													</div>
													<div *ngIf="[49,50,51].includes(returnline.IDStatus) == false">
														<span class="align-items-start justify-content-start"><b>
																{{'orders.returns.price' | translate }}:
															</b>
															{{returnline.total}}{{return.currency}}</span>
													</div>
													<div *ngIf="returnline.product_variant_option1.value != null"><span
															class="align-items-start justify-content-start"><b>
																{{returnline.product_variant_option1.key}}: </b>
															{{returnline.product_variant_option1.value}}</span></div>
													<div *ngIf="returnline.product_variant_option2.value != null"><span
															class="align-items-start justify-content-start"><b>
																{{returnline.product_variant_option2.key}}: </b>
															{{returnline.product_variant_option2.value}}</span></div>
													<div *ngIf="returnline.product_variant_option3.value != null"><span
															class="align-items-start justify-content-start"><b>
																{{returnline.product_variant_option3.key}}: </b>
															{{returnline.product_variant_option3.value}}</span></div>
													<div
														*ngIf="[49,50,51].includes(returnline.IDStatus) == false && returnline.return_reason_id">
														<span class="align-items-start justify-content-start"><b> {{
																'orders.returns.reason' | translate }}: </b>
															{{returnline.return_reason_text}}</span>
													</div>
													<div
														*ngIf="[49,50,51].includes(returnline.IDStatus) == false && returnline.return_reason_note">
														<span class="align-items-start justify-content-start"><b> {{
																'orders.returns.note' | translate }}: </b>
															{{returnline.return_reason_note}}</span>
													</div>
													<div *ngIf="[49,50,51,53,57].includes(returnline.IDStatus) == true">
														<p-button label="Order Exchange {{return.order_name_exc}}"
															styleClass="p-button-link p-0"
															(click)="getOrderExchangeDetails( return.order_id_exc , op, $event)">
														</p-button>
													</div>

													<div
														*ngIf="(returnline.images > 0 && returnline.images_data?.length == undefined)">
														<p-button
															(click)="onImagesClick(returnline.id_return,returnline.id_return_line)"
															label="{{ 'orders.returns.images' | translate }}"
															[badge]="returnline.images"
															styleClass="p-button-outlined p-button-secondary">
														</p-button>
													</div>

													<div *ngIf="returnline.images_data?.length > 0 ">
														<p-dataView [value]="returnline.images_data">
															<ng-template let-linesImages pTemplate="listItem">
																<div class="m-2">
																	<p-image [src]="linesImages.image" width="100"
																		[preview]="true">
																	</p-image>
																</div>
															</ng-template>
														</p-dataView>
													</div>
												</div>
											</div>
											<div id="block-approve-or-no"
												class="flex flex-column gap-3 block-approve-or-no"
												style="width: min-content;">
												<div *ngIf="return.IDStatus !== 1"
													class="text-align-center flex gap-1 flex-wrap justify-content-start sm:justify-content-end">
													<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end md:flex-nowrap"
														*ngIf="returnline.IDStatus === 13 || returnline.IDStatus === 9">
														<div
															class="wmsReturnStatus status-approved w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.approved' | translate }}</div>
														<div
															class="wmsReturnStatus status-received w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.received' | translate }}</div>
													</div>
													<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
														*ngIf="returnline.IDStatus === 4">
														<div
															class="wmsReturnStatus status-approved w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.approved' | translate }}</div>
														<div
															class="wmsReturnStatus status-notreceived w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.notreceived' | translate }}</div>
													</div>
													<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
														*ngIf="(returnline.IDStatus === 53 || returnline.IDStatus === 52)">
														<div
															class="wmsReturnStatus status-approved w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.exchangeapproved' | translate }}</div>
														<div
															class="wmsReturnStatus status-notreceived w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.notreceived' | translate }}</div>
													</div>
													<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
														*ngIf="returnline.IDStatus === 51">
														<div
															class="wmsReturnStatus status-approved w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.exchangeapproved' | translate }}</div>
														<div
															class="wmsReturnStatus status-approved w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.received' | translate }}</div>
													</div>
													<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
														*ngIf="returnline.IDStatus === 55">
														<div
															class="wmsReturnStatus status-approved w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.exchangeapproved' | translate }}</div>
														<div
															class="wmsReturnStatus status-parcial w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.parcialreceived' | translate }}</div>
													</div>
													<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
														*ngIf="returnline.IDStatus === 15">
														<div
															class="wmsReturnStatus status-approved w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.approved' | translate }}</div>
														<div
															class="wmsReturnStatus status-parcial w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.parcialreceived' | translate }}</div>
													</div>
													<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
														*ngIf="returnline.IDStatus === 17">
														<div
															class="wmsReturnStatus status-notreceived w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.itemnotexpected' | translate }} </div>
														<div
															class="wmsReturnStatus status-approved w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.approved' | translate }}</div>
														<div
															class="wmsReturnStatus status-approved w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.received' | translate }}</div>
													</div>
													<div class="flex gap-2 flex-wrap justify-content-start sm:justify-content-end"
														*ngIf="returnline.IDStatus === 14">
														<div
															class="wmsReturnStatus status-unexpected w-min sm:w-full lg:w-auto">
															{{
															'orders.returns.unexpected' | translate }} </div>
													</div>
													<div *ngIf="returnline.IDStatus === 2"
														class="wmsReturnStatus status-approved w-min sm:w-full lg:w-auto">
														{{
														'orders.returns.approved'
														| translate }}</div>
													<div *ngIf="returnline.IDStatus === 1"
														class="wmsReturnStatus status-notapproved w-min sm:w-full lg:w-auto">
														{{
														'orders.returns.notapproved' | translate }}</div>
													<div *ngIf="returnline.IDStatus === 16"
														class="wmsReturnStatus status-notapproved w-min sm:w-full lg:w-auto">
														{{
														'orders.returns.rejected' | translate }}</div>
													<div *ngIf="returnline.IDStatus === 50"
														class="wmsReturnStatus status-approved w-min sm:w-full lg:w-auto">
														{{
														'orders.returns.exchangeapproved' | translate }}</div>
													<div *ngIf="returnline.IDStatus === 49"
														class="wmsReturnStatus status-notapproved w-min sm:w-full lg:w-auto">
														{{
														'orders.returns.notexchangeapproved' | translate }}</div>
													<div *ngIf="returnline.IDStatus === 56"
														class="wmsReturnStatus status-notapproved w-min sm:w-full lg:w-auto">
														{{
														'orders.returns.rejectedexchange' | translate }}</div>
												</div>
												<div *ngIf="(return.IDStatus === 1 || return.IDStatus === 49)"
													class="flex justify-content-end">
													<div>{{returnline.returnStatus}}</div>
													<div
														*ngIf="[1,49].includes(returnline.IDStatus) ; else elseLineStatus">
														<button
															(click)="updateLineStatus(returnline.IDStatus, returnline.id_return, returnline.id_return_line)"
															pButton
															[ngStyle]="{'background-color':'var(--green-200)','color':'var(--gray-900)', 'border':'var(--yellow-200)'}"
															icon="pi pi-check-circle"
															label="{{ 'orders.returns.approve' | translate }}"
															iconPos="right" class="mb-2"></button>
													</div>
													<ng-template #elseLineStatus>
														<button
															(click)="updateLineStatus(returnline.IDStatus, returnline.id_return, returnline.id_return_line)"
															pButton
															[ngStyle]="{'background-color':'var(--pink-200)','color':'var(--gray-900)', 'border':'var(--yellow-200)'}"
															icon="pi pi-check-circle"
															label="{{ 'orders.returns.reject' | translate }}"
															iconPos="right" class="mb-2"></button>
													</ng-template>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</p-dataView>
						</div>
					</div>
					<div class="card summary-block">
						<div class="flex-1 align-items-start justify-content-start">
							<div *ngIf="!all_exchange">
								<div class="flex justify-content-between mb-3">
									<span class="flex align-items-center justify-content-center font-bold text-xl">{{
										'orders.returns.summary' | translate }}</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start font-bold">{{
										'orders.returns.subtotal' | translate }}</span>
									<span
										class="flex align-items-center justify-content-end">{{subtotal}}{{return.currency}}</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start font-bold">{{
										'orders.returns.shippingcost' | translate }}</span>
									<span
										class="flex align-items-center justify-content-end">{{return.shipping_cost}}{{return.currency}}</span>
								</div>
								<div class="flex justify-content-between flex-wrap mb-2">
									<span class="flex align-items-start justify-content-start font-bold">{{
										'orders.returns.processingfee' | translate }}</span>
									<span *ngIf="(all_exchange == true)"
										class="flex align-items-center justify-content-end">0.00{{return.currency}}</span>
									<span *ngIf="(all_exchange == false)"
										class="flex align-items-center justify-content-end">
										<div *ngIf="return.fee_total !== '0.00'">-</div>
										{{return.fee_total}}{{return.currency}}
									</span>
								</div>
								<div class="flex justify-content-between flex-wrap mb-2">
									<span class="flex align-items-start justify-content-start font-bold">{{
										'orders.returns.refundtotal' | translate }}</span>
									<span
										class="flex align-items-center justify-content-end">{{refund_total}}{{return.currency}}</span>
								</div>
							</div>
							<div *ngIf="all_exchange">
								<div class="flex justify-content-between mb-3">
									<span class="flex align-items-center justify-content-center font-bold text-xl">{{
										'orders.returns.summary' | translate }}</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start font-bold">Items to
										exchange</span>
									<span class="flex align-items-center justify-content-end">{{itemsToExchangeCount}}
										items</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span class="flex align-items-start justify-content-start font-bold">Approved
										items</span>
									<span class="flex align-items-center justify-content-end">{{itemsApprovedCount}}
										items</span>
								</div>
								<div class="flex justify-content-between flex-wrap mb-2">
									<span class="flex align-items-start justify-content-start font-bold">Rejected
										items</span>
									<span class="flex align-items-center justify-content-end">{{itemsRejectedCount}}
										items</span>
								</div>
							</div>
							<!-- <p-divider *ngIf="(return.IDStatus === 1 || return.IDStatus === 49)" layout="horizontal">
						</p-divider> -->
							<button *ngIf="(return.IDStatus === 1 || return.IDStatus === 49)" (click)="ReturnFinalize()"
								pButton class="w-full button-secundary mt-3 mb-2"
								label="{{ 'general.finalize' | translate }}"></button>
							<div *ngIf="loading"
								class="p-datatable-loading-overlay p-component-overlay ng-star-inserted"
								style="z-index: 1000; display: flex; justify-content: center; align-items: center;">
								<i class="p-datatable-loading-icon pi pi-spin pi-spinner"></i>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="lg:col-1" *ngIf="all_exchange == false">
					<p-divider class="divider-line" layout="vertical"></p-divider>
				</div> -->
				<!-- Extra lines -->
				<div class="w-full flex flex-column md:flex-row gap-3 ">
					<div class="card w-full flex gap-2" *ngIf="return.linesExtra?.length > 0">
						<div class="w-full">
							<div class="flex gap-3">
								<div class="returns-block">
									<h5>{{ 'orders.returns.itemsnotexpected' | translate }}</h5>
								</div>
								<div *ngIf="return.IDStatus == 13 || return.IDStatus == 51" class="hidden md:flex"
									style="padding: 0 21px">
									<h5>{{ 'orders.returns.newexchangeorder' | translate }}</h5>
								</div>
							</div>
							<div class="flex gap-2">
								<div class="flex flex-column align-items-start justify-content-center w-full">
									<p-dataView class="w-full" #dv [value]="return.linesExtra" [paginator]="false"
										layout="list">
										<ng-template let-returnline pTemplate="listItem">
											<div class="w-full flex flex-column md:flex-row gap-2">
												<div id="info-return"
													class="returns-block info-return flex flex-row p-3 pt-5 pb-5 justify-content-between"
													*ngIf="returnline.IDStatus === 14">
													<div class="flex flex-column sm:flex-row gap-3">
														<div id="block-img-product"
															class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
															<img [src]="returnline.Image_url"
																[alt]="returnline.productname"
																class="w-7rem sm:w-10rem shadow-2" />
														</div>
														<div class="md:text-left flex flex-column gap-3 w-max">
															<span
																class="align-items-start justify-content-start"><b>{{returnline.ProductName}}</b></span>
															<div class="flex align-items-center">
																<span
																	class="mr-3 align-self-center md:align-self-end"><b>
																		Sku:
																	</b>
																	{{returnline.sku}}</span>
																<span class="align-self-center md:align-self-end"><b> {{
																		'orders.returns.order' | translate }}: </b>
																	{{return.order_name}}</span>
															</div>
															<div class="flex align-items-center">
																<span
																	class="mr-3 align-self-center md:align-self-end"><b>
																		{{
																		'orders.returns.quantity' | translate }}: </b>
																	{{returnline.quantity}}</span>
																<span
																	class="mr-3 align-self-center md:align-self-end"><b>
																		{{
																		'orders.returns.received' | translate }}: </b>
																	{{returnline.quantityReceived}}</span>
															</div>
															<div><span
																	class="align-items-start justify-content-start"><b>
																		{{ 'orders.returns.price' | translate }}: </b>
																	{{returnline.price}} {{return.currency}}</span>
															</div>
															<div
																*ngIf="returnline.product_variant_option1.value != null">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option1.key}}: </b>
																	{{returnline.product_variant_option1.value}}</span>
															</div>
															<div
																*ngIf="returnline.product_variant_option2.value != null">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option2.key}}: </b>
																	{{returnline.product_variant_option2.value}}</span>
															</div>
															<div
																*ngIf="returnline.product_variant_option3.value != null">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option3.key}}: </b>
																	{{returnline.product_variant_option3.value}}</span>
															</div>
														</div>
													</div>
													<div id="block-approve-or-no"
														class="flex flex-column gap-3 w-min sm:w-full block-approve-or-no">
														<div *ngIf="!(return.IDStatus == 13 || return.IDStatus == 51)"
															class="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end">
															<div *ngIf="return.IDStatus !== 1"
																style="text-align: center;">
																<div class="flex gap-2">
																	<div class="wmsReturnStatus status-notreceived">{{
																		'orders.returns.itemnotexpected' | translate }}
																	</div>
																</div>
															</div>
														</div>
														<div *ngIf="return.IDStatus == 13 || return.IDStatus == 51"
															class="flex justify-content-end">
															<button
																class="p-button-raised p-button-secondary p-button-text"
																type="button" pButton icon="pi pi-cog" label="Actions"
																(click)="menu.toggle($event)"
																(click)="selectedNotExpectedItem = returnline"></button>
															<p-menu #menu [popup]="true"
																[model]="selectedNotExpectedItem?.notExpectedOptions">
															</p-menu>
														</div>
													</div>
												</div>
												<div *ngIf="(return.IDStatus == 13 || return.IDStatus == 51) && returnline.variantSizeSelect.lenght != 0"
													class="line-div flex align-items-center justify-content-center">
													<button pButton pRipple type="button" icon="pi pi-sort-alt"
														class="p-button-rounded" style="cursor: auto;"></button>
												</div>
												<div *ngIf="return.IDStatus == 13 || return.IDStatus == 51"
													class="select-sizes-block flex flex-column gap-3 justify-content-between">
													<div *ngFor="let sizeSelect of returnline.variantSizeSelect; let i = index"
														class="flex justify-content-between">
														<div class="md:text-left flex flex-column gap-3 w-max">
															<div class="flex align-items-center">
																<span
																	class="mr-3 align-self-center md:align-self-end"><b>
																		Sku:
																	</b>
																	{{sizeSelect?.sku}}</span>
															</div>
															<div class="flex align-items-center">
																<span
																	class="mr-3 align-self-center md:align-self-end"><b>
																		{{
																		'orders.returns.quantity' | translate }}: </b>
																	{{returnline.quantityArrayNotExpectedItem[i]}}</span>
															</div>
															<div><span
																	class="align-items-start justify-content-start"><b>
																		{{sizeSelect?.product_variant_option2.key}}:
																	</b>
																	{{sizeSelect?.product_variant_option2.value}}</span>
															</div>
														</div>
														<i class="pi pi-trash flex align-items-center cursor-pointer"
															(click)="deleteSizeSelect(i, returnline)"></i>
													</div>
												</div>
											</div>
										</ng-template>
									</p-dataView>
									<div class="w-full flex justify-content-end" *ngIf="hasExchange.length > 0">
										<div class="summary-block pl-0 md:pl-3">
											<button pButton (click)="ReturnFinalizeExchange()"
												label="{{ 'orders.returns.finalizeexchange' | translate }}"
												class="w-full button-secundary mt-3 mb-2"></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div>
			<p-toast key="tst" [baseZIndex]="99999"></p-toast>
		</div>
		<div>
			<p-overlayPanel #op [showCloseIcon]="true" styleClass="w-5">
				<ng-template pTemplate>
					<h5 [class]="'product-badge status-' + order_exc_detail.financial_status.toLowerCase()"><b> {{
							'orders.returns.orderstate' | translate }}: </b>
						<span>{{order_exc_detail.financial_status}}</span>
					</h5>

					<!-- <div class="col-12 lg:col-8 flex align-items-start justify-content-center"> -->
					<p-virtualScroller [value]="order_exc_detail.exc_lines" scrollHeight="300px">
						<ng-template pTemplate="ListItem" let-exc_lines>
							<div class="col-12">
								<div class="md:flex-row p-0 w-full">
									<div><img [src]="exc_lines.image_url" [alt]="exc_lines.productname"
											class="my-4 md:my-0 w-9 md:w-10rem shadow-2 mr-5" /></div>
									<div class="md:text-left w-full">
										<div class="font-bold text-2xl">{{exc_lines.name}}</div>
										<div class="my-1"><span class="align-items-start justify-content-start"><b>
													Sku: </b> {{exc_lines.sku}}</span></div>
										<div class="my-1"><span class="align-items-start justify-content-start"><b>
													{{ 'orders.returns.quantity' | translate }}: </b>
												{{exc_lines.quantity}}</span></div>
										<div *ngIf="exc_lines.product_variant_option1.value != null" class="my-1"><span
												class="align-items-start justify-content-start"><b>
													{{exc_lines.product_variant_option1.key}} : </b>
												{{exc_lines.product_variant_option1.value}}</span></div>
										<div *ngIf="exc_lines.product_variant_option2.value != null" class="my-1"><span
												class="align-items-start justify-content-start"><b>
													{{exc_lines.product_variant_option2.key}} : </b>
												{{exc_lines.product_variant_option2.value}}</span></div>
										<div *ngIf="exc_lines.product_variant_option3.value != null" class="my-1"><span
												class="align-items-start justify-content-start"><b>
													{{exc_lines.product_variant_option3.key}} : </b>
												{{exc_lines.product_variant_option3.value}}</span></div>
										<!-- <div class="my-1"><span class="align-items-start justify-content-start"><b>
													{{ 'orders.returns.size' | translate }}: </b>
												{{exc_lines.size}}</span></div>
										<div class="my-1"><span class="align-items-start justify-content-start"><b>
													{{ 'orders.returns.color' | translate }} : </b>
												{{exc_lines.color}}</span></div> -->
									</div>
								</div>
							</div>
						</ng-template>
					</p-virtualScroller>
				</ng-template>
			</p-overlayPanel>
		</div>
		<div>
			<p-dialog header="Manually Receive Items" [(visible)]="displayReceive" modal="false" showEffect="fade"
				[breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{'width': '90%','min-height': '90%'}">
				<div class="grid">
					<div class="col-12 md:col-4 lg:col-2">
						<p-dropdown appendTo="body" [options]="order_detail_receive" [(ngModel)]="selectedItemReceive"
							placeholder="Select a Item" [group]="true" (onChange)="ChooseItemReceive($event)">
							<ng-template let-group pTemplate="group">
								<div class="flex align-items-center">
									<span>{{group.label}}</span>
								</div>
							</ng-template>
						</p-dropdown>
					</div>
					<div class="col-12 md:col-8 lg:col-10">
						<button *ngIf="!unexpected_items_loaded" pButton type="button" (click)="UnexpectedItems()"
							label="Received items that were not in the initial return?"
							class="p-button-secondary p-button-text"></button>
					</div>
				</div>
				<div *ngIf="selectedItemAdd" class="p-fluid p-formgrid grid mt-3">
					<div class="field col-12 md:col-3">
						<span class="p-float-label">
							<input type="text" pInputText id="sku" [(ngModel)]="received_temp_item.sku" />
							<label for="inputtext">SKU</label>
						</span>
					</div>
					<div class="field col-12 md:col-3">
						<span class="p-float-label">
							<input type="text" pInputText id="ean" [(ngModel)]="received_temp_item.ean" />
							<label for="inputtext">EAN</label>
						</span>

					</div>
					<div class="field col-12 md:col-3">
						<span class="p-float-label">
							<input type="text" pInputText id="quantity" [(ngModel)]="received_temp_item.quantity" />
							<label for="inputtext">Quantity</label>
						</span>

					</div>
					<div class="field col-12 md:col-2">
						<button pButton pRipple class="mb-2" label="Add" icon="pi pi-plus"
							(click)="AddReceiveItem()"></button>
					</div>
				</div>
				<div class="mt-2">
					<p-table #dtreceive [value]="received_items" [rowHover]="true"
						styleClass="p-datatable-gridlines overflow-x" [paginator]="false" responsiveLayout="stack">
						<ng-template pTemplate="header">
							<tr>
								<th>
									<div class="flex justify-content-between align-items-center">SKU</div>
								</th>
								<th>
									<div class="flex justify-content-between align-items-center">EAN</div>
								</th>
								<th>
									<div class="flex justify-content-between align-items-center">Quantity Return</div>
								</th>
								<th>
									<div class="flex justify-content-between align-items-center">Quantity Receive</div>
								</th>
								<th></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-item>
							<tr [pContextMenuRow]="item"
								style="border: 1px solid #dee2e6; border-bottom: none;cursor: pointer;">
								<td style="min-width: auto;">
									<span class="p-column-title"><b>SKU</b></span>
									{{item.sku}}
								</td>
								<td style="min-width: auto;">
									<span class="p-column-title"><b>EAN</b></span>
									{{item.ean}}
								</td>
								<td style="min-width: auto;">
									<span class="p-column-title"><b>Quantity</b></span>
									{{item.quantity_expected}}
								</td>
								<td style="min-width: auto;">
									<span class="p-column-title"><b>Quantity Receive</b></span>
									{{item.quantity}}
								</td>
								<td class="p-0" style="min-width: auto ;text-align: center; ">
									<button pButton pRipple type="button" icon="pi pi-trash"
										class="p-button-rounded p-button-danger p-button-text"
										(click)="removeReceiveItem(item)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="8">{{ 'general.no_data' | translate }}</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="loadingbody">
							<tr>
								<td colspan="8">{{ 'general.loading' | translate }}</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
				<ng-template pTemplate="footer">
					<button pButton icon="pi pi-save" (click)="SendReceiveItems()" label="Save"
						class="p-button-outlined"></button>
				</ng-template>
			</p-dialog>
		</div>
	</div>
</div>
<p-dialog header="{{ 'orders.orders.upload_label' | translate }}" [(visible)]="displayLabel" [modal]="true"
	[style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
	<div class=" field col-12 md:col-12">
		<label htmlFor="label">{{ 'orders.orders.return_label' | translate }} <small>*</small></label>
		<p-fileUpload name="demo[]" accept=".pdf" customUpload="true" [auto]="true" (uploadHandler)="onUpload($event)"
			(onClear)="onRemoveFile()" (onRemove)="onRemoveFile()" chooseLabel="Upload">
		</p-fileUpload>
	</div>
	<div class=" field col-12 md:col-12">
		<label htmlFor="idTracking">{{ 'orders.orders.tracking_number' | translate }}</label>
		<input type="text" pInputText [(ngModel)]="idTracking">
	</div>
	<ng-template pTemplate="content">

	</ng-template>
	<ng-template pTemplate="footer">
		<p-button icon="pi pi-check" (click)="closeDialogLabel()" label="{{ 'general.finalize' | translate }}"
			styleClass="p-button-text">
		</p-button>
		<div class="col-12 md:col-12" style="text-align: left;">
			<small>* {{ 'orders.orders.required_fields' | translate }}</small>
		</div>
	</ng-template>
</p-dialog>
<p-dialog header="{{ 'orders.returns.exchangefornewsize' | translate }}" [(visible)]="displayAcceptExchange"
	[modal]="true" [style]="{width: '500px', maxWidth: '90%'}" [draggable]="false" [resizable]="false"
	class="non-overflow">
	<ng-template pTemplate="content">
		<div id="block-exchange" class="flex flex-column gap-3">
			<span class="customer-badge status-qualified">{{selectedNotExpectedItem.quantityReceived}} {{
				'orders.returns.available' | translate }}</span>
			<div [id]="'choose-size-block-'+x" class="flex gap-3"
				*ngFor="let x of selectedNotExpectedItem.arraySizesCount">
				<div *ngIf="selectedNotExpectedItem?.quantityReceived >= 1" class="flex flex-column">
					<div class="align-items-center flex gap-3">
						<button class="item-click button-quantity-return" (click)="decrementQuantity(x)"
							[disabled]="selectedNotExpectedItem.quantityArrayNotExpectedItem[x] <= 1"
							style="cursor: pointer;">
							<i class="pi pi-minus"></i>
						</button>
						<div class="font-bold">{{selectedNotExpectedItem.quantityArrayNotExpectedItem[x]}}
						</div>
						<button class="item-click button-quantity-return" (click)="incrementQuantity(x)" [disabled]="selectedNotExpectedItem.quantityArrayNotExpectedItem[x] >= selectedNotExpectedItem.quantityReceived || 
							selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem >= selectedNotExpectedItem.quantityReceived"
							style="cursor: pointer;">
							<i class="pi pi-plus"></i>
						</button>
					</div>
				</div>
				<div class="option-return w-full flex flex-column gap-3">
					<p-dropdown appendTo="body" [options]="variantQuantities" [(ngModel)]="variantSizeSelect[x]"
						placeholder="{{ 'orders.returns.selectnewsize' | translate }}"
						optionLabel="product_variant_option2.value" [showClear]="false" [optionDisabled]="disabled"
						[disabled]="!variantQuantities" class="dropdownDialog"
						[ngClass]="{'ng-invalid ng-dirty' : submittedDialog && !selectedNotExpectedItem.variantSizeSelect[x]}"></p-dropdown>
				</div>
				<i class="pi pi-trash flex align-items-center cursor-pointer" (click)="deleteSizeSelect(x)"></i>
			</div>
			<div *ngIf="selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem < selectedNotExpectedItem.quantityReceived"
				class="flex justify-content-center">
				<p-button (onClick)="newSize()" icon="pi pi-plus" label="{{ 'orders.returns.addmore' | translate }}"
					class="button-add-more"></p-button>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<p-button icon="pi pi-check" (click)="exchangeItem()" label="{{ 'orders.returns.exchange' | translate }}"
			styleClass="p-button-text">
		</p-button>
	</ng-template>
</p-dialog>
<p-dialog header="{{ 'orders.returns.refundthisitem' | translate }}" [(visible)]="displayAcceptRefund" [modal]="true"
	[style]="{width: '500px', maxWidth: '90%'}" [draggable]="false" [resizable]="false" class="non-overflow">
	<ng-template pTemplate="content">
		<div class="flex flex-column gap-3">
			<span class="customer-badge status-qualified">{{selectedNotExpectedItem.quantityReceived}} {{
				'orders.returns.available' | translate }}</span>
			<div class="flex flex-column gap-6">
				<div class="flex gap-3">
					<div *ngIf="selectedNotExpectedItem?.quantityReceived >= 1" class="flex flex-column">
						<div class="align-items-center flex gap-3">
							<button class="item-click button-quantity-return" (click)="decrementQuantity()"
								[disabled]="selectedNotExpectedItem.quantityNotExpectedItem <= 1"
								style="cursor: pointer;">
								<i class="pi pi-minus"></i>
							</button>
							<div class="font-bold">{{selectedNotExpectedItem.quantityNotExpectedItem}}
							</div>
							<button class="item-click button-quantity-return" (click)="incrementQuantity()"
								[disabled]="selectedNotExpectedItem.quantityNotExpectedItem >= selectedNotExpectedItem.quantityReceived"
								style="cursor: pointer;">
								<i class="pi pi-plus"></i>
							</button>
						</div>
					</div>
					<div class="option-return w-full">
						<p-dropdown appendTo="body" [options]="optionsRefund" [(ngModel)]="optionSelect"
							class="dropdownDialog" placeholder="{{ 'orders.returns.selectoptionrefund' | translate }}"
							optionLabel="option" [showClear]="false"
							[ngClass]="{'ng-invalid ng-dirty' : submittedDialog && !optionSelect}"></p-dropdown>
					</div>
				</div>
				<div class="summary-block">
					<div class="flex-1 align-items-start justify-content-start" *ngIf="all_exchange == false">
						<div class="flex justify-content-between mb-2">
							<span class="flex align-items-start justify-content-start font-bold">{{
								'orders.returns.subtotal' | translate }}</span>
							<span class="flex align-items-center justify-content-end">{{subtotalRefundNotExpectedItem}}
								{{return.currency}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<p-button icon="pi pi-check" (click)="refundItem()" label="{{ 'orders.returns.refund' | translate }}"
			styleClass="p-button-text">
		</p-button>
	</ng-template>
</p-dialog>