import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { User } from 'src/app/api/user';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { BrandService } from 'src/app/services/brands/brand.service';
import { UserService } from 'src/app/services/user/user.service';
import { ClipboardService } from 'ngx-clipboard';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'pages-create-user',
    templateUrl: './create_user.component.html',
    styleUrls: ['./create_user.component.sass'],
})
export class CreateUserComponent implements OnInit {

    permissions: any;

    groups_permissions: any;

    ccRegex: RegExp = /(.*?)/;

    ccEmailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    regex_example: string = "";

    loadingCreate: boolean;

    id_brand: number;

    username: string;

    email: string;

    password: string;

    selectedGroup: any;

    selectedPermissions: number[] = null;

    isError: boolean;

    submitted: boolean = false;

    reactiveForm = new FormGroup({
        username: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.pattern(this.ccEmailRegex)]),
        password: new FormControl('', [Validators.required]),
        selectedGroup: new FormControl('',),
        selectedPermissions: new FormControl('',)
    });

    breadcumbItems: MenuItem[];

    constructor(
        private readonly translateService: TranslateService,
        private clipboardApi: ClipboardService,
        private userService: UserService,
        private brandService: BrandService,
        private permissionsService: PermissionsService,
        private messageService: MessageService,
        private router: Router
    ) {
    }

    async ngOnInit() {
        await this.translateService.get('dummyTranslation').toPromise().then();

        this.translateService.stream('general.menu').subscribe(val => {
            this.breadcumbItems = [
                { label: '', icon: 'pi pi-home' },
                { label: val.users.value },
                { label: val.users.items.add_user }
            ]
        });

        this.id_brand = this.brandService.getBrand();
        this.permissions = await this.permissionsService.getPermissions();
        var groups_permissions = await this.permissionsService.getGroupsPermissions();
        this.groups_permissions = groups_permissions.groups_permissions;
        this.groups_permissions.map(group => {
            group.id_permission = group.id_permission.map(str => {
                return Number(str);
            });
        })
        var x = document.getElementsByClassName("pi-copy")[0]
        var y = document.getElementsByTagName("p-password")[0].firstChild
        y.appendChild(x);
    }

    itemClicked(event) {
        if (event.item.label === '') {
            this.router.navigate(['main']);
        }
    }

    onSubmit() {
        this.isError = false;
        this.submitted = true;

        this.email = this.reactiveForm.get('email').value
        this.username = this.reactiveForm.get('username').value

        let user: User = {
            email: this.reactiveForm.get('email').value,
            username: this.reactiveForm.get('username').value,
            password: this.password,
            id_brand: this.id_brand,
            active: 1,
            permissions: this.selectedPermissions
        }

        const invalidFields = document.getElementsByClassName("ng-invalid");
        let fieldsError = false;
        if (invalidFields.length != 0) {
            fieldsError = true;
        }

        if (!fieldsError) {
            this.userService.postUser(user).subscribe(
                data => {
                    this.isError = false;
                    this.loadingCreate = false;
                    this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("users.create_success") });
                },
                error => {
                    this.reactiveForm.get('email').setValue(this.email)
                    this.reactiveForm.get('username').setValue(this.username)
                    this.reactiveForm.get('selectedPermissions').setValue(this.selectedPermissions)

                    this.loadingCreate = false;
                    this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("users.create_error_name") });
                })
        } else {
            this.isError = true;
            this.messageService.add({ key: 'tst', severity: 'warn', summary: this.translateService.instant("general.warn"), detail: this.translateService.instant("users.warning_update")});
        }
    }

    selectPermissions() {
        this.selectedPermissions = [];
        for (var group of this.selectedGroup) {
            this.selectedPermissions = this.selectedPermissions.concat(group.id_permission)
        }
        var x = document.getElementsByTagName("p-checkbox")
        var y = Array.from(x);
        y.map(a => a.classList.add("ng-dirty"))
    }

    validatePermission(permission) {
        if (permission == 5) {
            this.selectedPermissions = this.selectedPermissions.filter(perm => perm != 6)
        } else if (permission == 7) {
            this.selectedPermissions = this.selectedPermissions.filter(perm => perm != 8)
        } else if (permission == 11) {
            this.selectedPermissions = this.selectedPermissions.filter(perm => perm != 9 && perm != 10)
        } else if (permission == 13) {
            this.selectedPermissions = this.selectedPermissions.filter(perm => perm != 12)
        }

        if (permission == 6) {
            this.selectedPermissions = this.selectedPermissions.concat([5])
        } else if (permission == 8) {
            this.selectedPermissions = this.selectedPermissions.concat([7])
        } else if (permission == 9 || permission == 10) {
            this.selectedPermissions = this.selectedPermissions.concat([11])
        } else if (permission == 12) {
            this.selectedPermissions = this.selectedPermissions.concat([13])
        }
    }

    copyPassword() {
        this.clipboardApi.copyFromContent(this.password ? this.password : " ")
    }

    generatePassword() {
        var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var especialChars = "!@#$%^&*()"
        var lowerChars = "abcdefghijklmnopqrstuvwxyz"
        var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
        var password = "";

        var randomEspecialChars = Math.floor(Math.random() * especialChars.length)
        var randomLowerChars = Math.floor(Math.random() * lowerChars.length)
        var randomUpperChars = Math.floor(Math.random() * upperChars.length)

        password = especialChars.substring(randomEspecialChars, randomEspecialChars + 1) +
            lowerChars.substring(randomLowerChars, randomLowerChars + 1) +
            upperChars.substring(randomUpperChars, randomUpperChars + 1) +
            Math.floor(Math.random() * 9)

        for (var i = 0; i <= 4; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }
        this.password = [...password].sort(() => Math.random() - .5).join('');

    }
}
