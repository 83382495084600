<p-toast key="tst" [baseZIndex]="99999"></p-toast>
<!-- <div *ngIf="appMain.menuActiveMobile" class="absolute flex align-items-center justify-content-center right-0"
    style="z-index: 1000; width: 5rem; height: 5rem;">
    <i class="pi pi-times"></i>
</div> -->
<div class="layout-topbar" (click)="appMain.toggleMenu($event)"
    [ngClass]="{'layout-topbar-menu-mobile-inactive': !appMain.menuActiveMobile}">
    <div class="w-full h-full">
        <a class="layout-topbar-logo" [routerLink]="['/main']">
            <!-- <img *ngIf="appMain.staticMenuInactive && appMain.isDesktop()"
                src="assets/layout/images/logo-small.svg">
                *ngIf="!appMain.staticMenuInactive || (appMain.menuActiveMobile && !appMain.isDesktop())"  -->
            <img src="assets/layout/images/logo.svg">
        </a>
        <div class="layout-menu-block flex flex-column justify-content-between"
            style="height: calc(100% - 81px); overflow-y: auto;">
            <pages-menu></pages-menu>
            <div class="w-full" style="position: sticky; bottom: 0; background-color: transparent; padding-top: 20px;">
                <div *ngIf="appMain.isDesktop()" class="layout-menu-container">
                    <ul class="layout-menu" role="menu">
                        <li pages-menu class="layout-menuitem-category" *ngFor="let item of model; let i = index;"
                            [item]="item" [index]="i" [root]="true" role="none">
                            <div *ngIf="item.permission">
                                <div class="layout-menuitem-root-text" [attr.aria-label]="item.label">{{item.label}}
                                </div>
                                <ul role="menu">
                                    <li pages-menuitem *ngFor="let child of item.items" [item]="child" [index]="i"
                                        role="none">
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- <ul class="layout-menu flex w-full">
                    <li>
                        <a href="#">
                            <div class="icon">
                                <img *ngIf="appMain.staticMenuInactive" src="assets/layout/images/left-icon.svg">
                                <img *ngIf="!appMain.staticMenuInactive" src="assets/layout/images/left-icon.svg"
                                    style="transform: rotate(180deg);">
                            </div>
                            <span>Close</span>
                        </a>
                    </li>
                </ul> -->
            </div>
        </div>
    </div>
</div>
<p-toast key="tst2" [baseZIndex]="99999"></p-toast>