import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { CreateSupplierComponent } from './create_supplier.component';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        ButtonModule,
        CommonModule,
        ReactiveFormsModule,
        ToastModule,
        FormsModule,
        DropdownModule,
        InputTextModule,
        KeyFilterModule,
        MessagesModule,
        MessageModule,
        TableModule,
        DialogModule,
        InputMaskModule,
        FileUploadModule,
        MultiSelectModule,
        ReactiveFormsModule,
        CheckboxModule,
        SelectButtonModule,
        BreadcrumbModule,
        TranslateModule
    ],
    declarations: [
        CreateSupplierComponent
    ],
    exports: [
        CreateSupplierComponent
    ],
    providers: [MessageService]

})
export class CreateSupplierModule { }
