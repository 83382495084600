import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { RecycleService } from 'src/app/services/recycle/recycle.service';

@Component({
    selector: 'pages-dash-recycle',
    templateUrl: './dash-recycle.component.html',
    styleUrls: ['dash-recycle.component.scss'],
})
export class DashRecycleComponent implements OnInit {
    pipe = new DatePipe('en-US');

    recycleBrands: any;

    recycleMethods: any;

    pieDataBrands: any;

    pieDataMethods: any;

    productSelect: any = {
        product_id: "",
        Image_url: "",
        ProductName: "",
        Color: "",
        SKUs: []
    }

    colors: any = [
        '#29a87c',
        '#ff3a67',
        '#f8a72d',
        '#0096d6',
        '#64c2e7',
        '#47CB9A',
        '#152f50',
        '#877f7d'
    ]

    colorsHover: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#7E57C2',
        '#FFA726',
        '#00bb7e',
        '#495057',
        '#66BB6A'
    ]

    heightImg: number = 180;

    loading: boolean = true;

    @ViewChild('dt') table: Table;

    @ViewChild('filter') filter: ElementRef;

    constructor(
        private recycleService: RecycleService,
        private readonly translateService: TranslateService
    ) { }

    async ngOnInit() {
        this.recycleBrands = await this.recycleService.getTotalBrandsRecycle();
        this.recycleMethods = await this.recycleService.getTotalMethodsRecycle();

        this.loading = false;

        this.createPieBrandsDate();
        this.createPieMethodsDate();
    }

    async createPieBrandsDate() {
        const brandName = this.recycleBrands.map(brand => brand.name);
        const brandValue = this.recycleBrands.map(brand => brand.value);

        this.pieDataBrands = {
            labels: brandName,
            datasets: [
                {
                    data: brandValue,
                    backgroundColor: this.colors
                }
            ]
        };
    }

    async createPieMethodsDate() {
        const methodName = this.recycleMethods.map(method => method.name);
        const methodValue = this.recycleMethods.map(method => method.value);

        this.pieDataMethods = {
            labels: methodName,
            datasets: [
                {
                    data: methodValue,
                    backgroundColor: this.colors
                }
            ]
        };
    }
}
