import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { AppMainComponent } from 'src/app/app.main.component';
import { BrandService } from 'src/app/services/brands/brand.service';
import { SecHandService } from 'src/app/services/2hand/2hand.service';
import TuiImageEditor from 'tui-image-editor';
import FileSaver from 'file-saver'; 
import 'fabric';
import { Dialog } from 'primeng/dialog';

@Component({
    selector: 'pages-2hand',
    templateUrl: './2hand.component.html',
    styleUrls: ['2hand.component.scss'],
})
export class SecHandComponent implements OnInit {

    pipe = new DatePipe('en-US');

    rangeDates: Date[] = [
        new Date(new Date().setDate(new Date().getDate() - 31)),
        new Date(),
    ];

    some(a:any){
        console.log(a);
    }

    all2HandsAux: any = [];

    _2handsAux: any;

    loading: boolean = true;

    loadingSidebar: boolean = false;

    idBrand: any;

    selected2Hands: any[] = [];

    selected2Hand: any;

    visibleSidebar: boolean = false;

    updateLine: boolean = false;

    cols: any = [];

    colsOptional: any = [];

    selectedColumns: any = [];

    _selectedColumnsAux: any = [];

    _2hands: any = [];

    _2hand: any;

    menuShow: boolean = false;

    itemsApprovedCount: number = 0;

    itemsRejectedCount: number = 0;

    itemsNotApprovedCount: number = 0;
    
    isHistoryTrue:boolean=false;

    dataHistory:any[]=[];

    carrierLink = new Map([
        [
            'DHL Parcel',
            'https://clientesparcel.dhl.es/LiveTrackingN/ModifyMyShipment/',
        ],
        [
            'DHL Express',
            'https://www.dhl.com/us-en/home/tracking.html?tracking-id=',
        ],
        ['DPD', 'https://dpd.pt/track-and-trace?reference='],
    ]);

    dropdown_options: any = [
    {
        id: 'history_',
        label: 'History',
        icon: 'pi pi-history',
        command: () => {
            this.callHistory();
        },
        disabled: false,
        visible: true
    },
]


    @ViewChild('dt2hand') table: Table;

    @ViewChild('filter') filter: ElementRef;

    @ViewChild('tuiImageEditor') tuiImageEditor: ElementRef;

    openImageEditor: boolean = false;

    tuiImageEditorInstance: TuiImageEditor = null;

    actualImage: any;

    @ViewChild('dialoImageEditor') dialoImageEditor: Dialog;
    
    loading_: boolean = false;

    constructor(
        private app: AppMainComponent,
        private readonly translateService: TranslateService,
        private messageService: MessageService,
        private secHandService: SecHandService,
        private brandService: BrandService,
        private route: ActivatedRoute,
    ) { }
    
    async ngOnInit() {
        this.dataHistory=[];
        try {
            this.loading = true;
            this.cols = [
                { field: 'product_child_id', header: 'Subm. ID', sort: true, filter: false }
            ]

            this.colsOptional = [
                { field: 'order_name', header: 'Order', sort: true, filter: false },
                { field: 'customer', header: 'Customer', sort: true, filter: false },
                { field: 'country', header: 'Country', sort: true, filter: false },
                { field: 'items', header: 'Items', sort: true, filter: false },
                { field: 'total', header: 'Total', sort: true, filter: false },
                { field: 'date', header: 'Request Date', sort: true, filter: false },
                { field: 'product_child_message_status', header: 'Status', sort: true, filter: false }
            ];
            this.selectedColumns = this.cols.concat(this.colsOptional)
            this._selectedColumnsAux = this.cols.concat(this.colsOptional)
            this.idBrand = this.brandService.getBrand();

            const dateI = this.pipe.transform(this.rangeDates[0], 'yyyy-MM-dd');
            let dateF;
            if (this.rangeDates[1] == null) {
                dateF = dateI;
            } else {
                dateF = this.pipe.transform(this.rangeDates[1], 'yyyy-MM-dd');
            }

            // change data from db
            await this.secHandService.get2Hands("", "").then((_2hands) => {// inicial phase witout dates
                // reset data
                this._2hands = [];

                _2hands.map((_2h) => {
                    let _2handsAux = _2h;

                    _2handsAux.date = _2handsAux.date;//this.app.getDateOnFormatString(_2handsAux.date);
                    _2handsAux.dates = _2handsAux.date;
                    this._2hands.push(_2handsAux);
                })
            })
            this.loading = false;
        } catch (error) {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: this.translateService.instant('Something went wrong loading 2hands list!'),
            });
        }

    }


    async callHistory() {
        document.getElementsByTagName('body')[0].classList.add('fixed')
        this.IsHistoryTrue();
    }


    IsHistoryTrue():void{
        this.isHistoryTrue=!this.isHistoryTrue;
    }

    hideSidebarHistory(){
        this.isHistoryTrue=false;
    }

    dateTimeLine(event:any):string{
        return this.app.getDateOnFormatString(event.change_timestamp);
    }

    IDStatusDescriptionTimeLine(event:any):string{
        if(event.change_type == "INSERT")
            return event.description_new;
        if(event.change_type == "UPDATE")
            return event.description_new;
        return event.description_old;
    }

    openOverlay(event, op) {
        event.stopPropagation();
        op.toggle(event)
    }

    showOverlay() {
        this.app.overlayActive = true
    }

    hideOverlay() {
        this.app.overlayActive = false
    }

    @Input() get selectedColumnsAux(): any[] {
        return this._selectedColumnsAux;
    }

    async openSideBar(selected2Hand) {
        this.menuShow = false;
        this._2hand = null;
        this.visibleSidebar = true;
        this.selected2Hand = selected2Hand;
        document.getElementsByTagName('body')[0].classList.add('fixed');
        await this.SechandLines(selected2Hand.product_child_id);
        var linesStatus = this._2hand.lines.map(line => line.IDStatus);
        this.itemsApprovedCount = linesStatus.filter(status => [2].includes(status)).length;
        this.itemsRejectedCount = linesStatus.filter(status => [3].includes(status)).length;
        this.itemsNotApprovedCount = linesStatus.filter(status => [1].includes(status)).length;
        this._2hand.order_created_at = selected2Hand.date;

       await this.get2HandJournal(selected2Hand.product_child_id);
    }

    async get2HandJournal(product_child_id: any) {
        this.dataHistory = [];
        try {
            const history = await this.secHandService.get2HandJournal(product_child_id);
            if ( history.length > 0) {
                this.dataHistory = history;
            } else {
                this.dataHistory = ['undefined'];
            }
        } catch (error) {
            this.dataHistory = ['undefined'];
        }

    }

    hideSideBar() {
        this.visibleSidebar = false;
        document.getElementsByTagName('body')[0].classList.remove('fixed')
    }

    async SechandLines(product_child_id: any) {
        await this.secHandService.get2HandLines(product_child_id).then((_2hand) => {
            //forçar so o valor para quando for usado é para tirar
            //if(_2hand && _2hand[0]) {_2hand[0].lines[0].retail_price="20.00"}
            this._2hand = (_2hand.length > 0 ? _2hand[0] : {});   
        })
        this._2hand.carrier_link = this.carrierLink.get('DHL Parcel');

    }

    checkboxClick(e) {
        e.stopPropagation()
        var x = document.getElementsByClassName('p-checkbox-box')
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selected2Hands.length > 0) {
            x[0].classList.add('p-highlight')
            y[0].classList.add('pi')
            y[0].classList.add('pi-minus')
        } else {
            x[0].classList.remove('p-highlight')
            y[0].classList.remove('pi')
            y[0].classList.remove('pi-minus')
        }
    }

    mainCheckboxClick() {
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selected2Hands.length == this._2handsAux.length) {
            y[0].classList.remove('pi-minus')
        }
    }

    async updateLineStatus(product_child_id_status: any, product_child_id: any) {
        this.updateLine = true
        let line = {
            "product_child_id_status": (product_child_id_status == 1 ? 2 : (product_child_id_status == 2 ? 1 : product_child_id_status)),
            "product_child_id": product_child_id,
            "customer_id": this._2hand.id_customer,
            "price": this._2hand.lines[0].total
        };

        await this.secHandService.put2HandLinesStatus(line).then(async (_2handList) => {
            this.updateLine = false;
            if (product_child_id_status == 1) { // hide if btn approve item
                this.hideSideBar();
                return;
            }
            await this.SechandLines(product_child_id);
            
            
        }).catch((error) => {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: this.translateService.instant('Something went wrong updating 2hand !'),
            })
        });

        await this.get2HandJournal(product_child_id);

    }

    async Reject2Hand() {
        this.loadingSidebar = true;

        let line = {
            "product_child_id_status": 3,
            "product_child_id": this._2hand.product_child_id,
            "customer_id": this._2hand.id_customer,
            "price": 0
        };

        await this.secHandService.reject2hand(line).then(async (_2handList) => {
            await this.SechandLines(this._2hand.product_child_id);
            this.loadingSidebar = false;
            // this.ngOnInit();
        }).catch((error) => {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: this.translateService.instant('Something went wrong rejecting 2hand !'),
            })
        });;

        this.loadingSidebar = false
    }

    async exportCSV() {
        var selectionAux = this.table._selection
        if (this.table._selection.length == 0) {
            if (this.table.filteredValue) {
                this.table._selection = this.table.filteredValue
            } else {
                this.table._selection = this.table._value
            }
        }
        await this.table.exportCSV({ selectionOnly: true })
        this.table._selection = selectionAux
    }


    async toProductOnline(productID:any){
        try {
        
      await this.secHandService.getLinkProductOnlineStore(productID).then((link:any)=>{
        window.open(link.data, '_blank');
      }).catch((error:any)=>{
        console.log(error)
      });
          
    } catch (error) {
        console.log(error)
    }

    }

    async imageEditor(image: any) {
        this.actualImage = image;
        this.openImageEditor = true;
        this.dialoImageEditor.maximize();
    }


    async openEditor() {
        const lastSlashIndex = this.actualImage.blob.lastIndexOf('/');
        const fileNameWithExtension = this.actualImage.blob.substring(lastSlashIndex + 1);

        // get dot of the extension
        const lastDotIndex = fileNameWithExtension.lastIndexOf('.');
        //get name
        const name = fileNameWithExtension.substring(0, lastDotIndex);

        this.tuiImageEditorInstance = new TuiImageEditor(this.tuiImageEditor.nativeElement, {
            includeUI: {
                loadImage: {
                    path: this.actualImage.blob,
                    name: name,
                },
                menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'mask', 'filter'],

                uiSize: {
                    width: "100%",
                    height: "100%",

                },
                menuBarPosition: "bottom",
                //theme: this.customTheme

            },
            cssMaxWidth: document.documentElement.clientWidth,
            cssMaxHeight: document.documentElement.clientHeight,
            selectionStyle: {
                cornerSize: 20,
                rotatingPointOffset: 70
            }
        });

        await this.createSaveButton();

    }

    async createSaveButton() {
        //remove header title
        const headerTitle = this.tuiImageEditor.nativeElement.querySelector(".tui-image-editor-header-logo");
        headerTitle.removeChild(headerTitle.childNodes[1]);
        //add button Save
        const buttonSave = document.createElement('div');
        buttonSave.innerHTML = 'Save';
        buttonSave.style.cssText = 'background-color: #fff;border: 1px solid #ddd;color: #222;font-family: \'Noto Sans\', sans-serif; font-size: 12px; width: 120px; height: 40px; line-height: 40px; outline: none; border-radius: 20px; font-weight: bold; cursor: pointer; vertical-align: middle; letter-spacing: .3px; text-align: center; ';

        buttonSave.addEventListener('click', async () => {
            this.loading_ = true;
            // Force a re-render immediately after setting loading_ to true
            setTimeout(() => {
                this.saveImage();
            }, 0);
        });
        headerTitle.appendChild(buttonSave);

    }


    async saveImage() {

        let cloudinaryResponse: any = {};
        cloudinaryResponse.url = {};

        try {
            // get modified image in base string
            const modifiedImageDataURL = this.tuiImageEditorInstance.toDataURL();
            // get last slash
            const lastSlashIndex = this.actualImage.blob.lastIndexOf('/');
            //get name and extension image
            const fileNameWithExtension = this.actualImage.blob.substring(lastSlashIndex + 1);

            // get dot of the extension
            const lastDotIndex = fileNameWithExtension.lastIndexOf('.');
            //get name image
            const name = fileNameWithExtension.substring(0, lastDotIndex);
            // get extension
            //const extension = fileNameWithExtension.substring(lastDotIndex + 1); 

            const uploadIndex = this.actualImage.blob.indexOf("/upload/");
            const secondSlashIndex = this.actualImage.blob.indexOf("/", uploadIndex + "/upload/".length + 1);
            const dotIndex = this.actualImage.blob.lastIndexOf(".");
            const publicId = this.actualImage.blob.substring(secondSlashIndex + 1, dotIndex);
            /*const lastSlashIndexAux = publicId.lastIndexOf("/");
            const folder = publicId.substring(0, lastSlashIndexAux) +"/";*/


            const data = {
                photo: modifiedImageDataURL,
                name: name,
                existingPublicId: publicId,
                //folder:folder,
                childID: this.selected2Hand.product_child_id,
                pos: this.actualImage.position
            }

            cloudinaryResponse = await this.secHandService.putCloudinaryImage(data);
            this.actualImage.blob = cloudinaryResponse.url;
            this.loading_ = false;

        } catch (error) {
            console.log(error);
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: 'Failed to save image',
                detail: this.translateService.instant(error),
            })
            this.loading_ = false;

        }
        this.messageService.add({
            key: 'tst',
            severity: 'success',
            summary: 'Image Saved',
            detail: this.translateService.instant('Imaged Saved'),
        })
        this.openImageEditor = false;
    }

    destroyImageEditor() {
        this.tuiImageEditorInstance.destroy()
        this.actualImage = null;
    }

    //for white theme
    /*  customTheme = {
                 // common
         'common.bi.image': '',
         'common.bisize.width': '251px',
         'common.bisize.height': '21px',
         'common.backgroundImage': 'none',
         'common.backgroundColor': '#ffffff', 
         'common.border': '0px',
 
         // header
         'header.backgroundImage': 'none',
         'header.backgroundColor': 'transparent',
         'header.border': '0px',
 
         // load button
         'loadButton.backgroundColor': '#fff',
         'loadButton.border': '1px solid #ddd',
         'loadButton.color': '#222',
         'loadButton.fontFamily': 'NotoSans, sans-serif',
         'loadButton.fontSize': '12px',
 
         // download button
         'downloadButton.backgroundColor': '#fdba3b',
         'downloadButton.border': '1px solid #fdba3b',
         'downloadButton.color': '#fff',
         'downloadButton.fontFamily': 'NotoSans, sans-serif',
         'downloadButton.fontSize': '12px',
 
         // icons default
         'menu.normalIcon.color': '#8a8a8a',
         'menu.activeIcon.color': '#555555',
         'menu.disabledIcon.color': '#434343',
         'menu.hoverIcon.color': '#e9e9e9',
         'submenu.normalIcon.color': '#8a8a8a',
         'submenu.activeIcon.color': '#e9e9e9',
 
         'menu.iconSize.width': '24px',
         'menu.iconSize.height': '24px',
         'submenu.iconSize.width': '32px',
         'submenu.iconSize.height': '32px',
 
         // submenu primary color
         'submenu.backgroundColor': '#ffffff',
         'submenu.partition.color': '#858585',
 
         // submenu labels
         'submenu.normalLabel.color': '#858585',
         'submenu.normalLabel.fontWeight': 'lighter',
         'submenu.activeLabel.color': '#fff',
         'submenu.activeLabel.fontWeight': 'lighter',
 
         // checkbox style
         'checkbox.border': '1px solid #ccc',
         'checkbox.backgroundColor': '#fff',
 
         // rango style
         'range.pointer.color': '#fff',
         'range.bar.color': '#666',
         'range.subbar.color': '#d1d1d1',
 
         'range.disabledPointer.color': '#414141',
         'range.disabledBar.color': '#282828',
         'range.disabledSubbar.color': '#414141',
 
         'range.value.color': '#fff',
         'range.value.fontWeight': 'lighter',
         'range.value.fontSize': '11px',
         'range.value.border': '1px solid #353535',
         'range.value.backgroundColor': '#151515',
         'range.title.color': '#fff',
         'range.title.fontWeight': 'lighter',
 
         // colorpicker style
         'colorpicker.button.border': '1px solid #ffffff', 
         'colorpicker.title.color': '#ffffff', 
         
     };*/

}