<div class="grid">
    <div class="col-12">
        <p-breadcrumb [style]="{background: 'none' , border: 'none'}" [model]="breadcumbItems"
            (onItemClick)="itemClicked($event)"></p-breadcrumb>
        <div class="flex justify-content-between flex-column sm:flex-row" style="width: 100%;">
            <div class="upload-block">
                <div class="card" style="height: 100%;">
                    <p-fileUpload name="myfile[]" accept=".xlsx" customUpload="true" [auto]="true"
                        (uploadHandler)="onBeforeUpload($event)" (onClear)="onRemoveFile()" (onRemove)="onRemoveFile()"
                        chooseLabel="{{ 'reception.load_file' | translate }}">
                        <ng-template pTemplate="toolbar">
                            <button pButton pRipple id="btnExcelSample" icon="pi pi-book"
                                (click)="downloadSampleExcel()" class="e-btn p-button-success" pTooltip="XLS Template"
                                tooltipPosition="bottom"></button>
                        </ng-template>
                    </p-fileUpload>
                    <p-toast key="tst" [baseZIndex]="99999"></p-toast>
                </div>
            </div>
            <div class="extra-block">
                <div class="card">
                    <p-dropdown appendTo="body" [options]="suppliers" [(ngModel)]="selectedSupplier" [style]="{'width': '100%'}"
                        optionLabel="name" (onChange)="editIdFile()"></p-dropdown>
                    <div *ngIf="!selectedSupplier">
                        <p style="color: #F59E0B; margin-top: 0.5rem"><i class="pi pi-exclamation-triangle"></i>
                            {{ 'reception.action_error' | translate }}</p>
                    </div>
                </div>
                <div class="card" *ngIf="selectedSupplier" style="margin: 0">
                    <h5 style="word-break: break-all;">{{idFile}}</h5>
                    <div id="transfer-block" class="field"
                        [style]="{'display': 'inline-flex', 'gap': '0.5rem', 'justify-content': 'space-between', 'align-items': 'center', 'width': '100%'}">
                        <p style="margin: 0 !important">{{ 'reception.transfer_submit' | translate }}</p>
                        <p-selectButton [style]="{'display': 'flex', 'justify-content': 'flex-end', 'flex': '0 0 auto'}"
                            [options]="stateOptions" [(ngModel)]="transfer" optionLabel="label" optionValue="value"
                            (onChange)="changeSuppliersArray()"></p-selectButton>
                    </div>
                    <button pButton pRipple label="{{ 'reception.submit_file' | translate }}"
                        [style]="{'width':'100%', 'overflow': 'visible'}" [disabled]="purchaseOrders.length == 0"
                        (click)="onSubmit()"></button>
                    <div *ngIf="loadingUpload" class="p-datatable-loading-overlay p-component-overlay ng-star-inserted"
                        style="z-index: 1000; display: flex; justify-content: center; align-items: center;">
                        <i class="p-datatable-loading-icon pi pi-spin pi-spinner"></i>
                    </div>
                </div>
                <div class="card" *ngIf="!selectedSupplier" style="margin: 0">
                    <div id="transfer-block" class="field"
                        [style]="{'display': 'inline-flex', 'gap': '0.5rem', 'justify-content': 'space-between', 'align-items': 'center', 'width': '100%'}">
                        <p style="margin: 0 !important">{{ 'reception.transfer_submit' | translate }}</p>
                        <p-selectButton [style]="{'display': 'flex', 'justify-content': 'flex-end', 'flex': '0 0 auto'}"
                            [options]="stateOptions" [(ngModel)]="transfer" optionLabel="label" optionValue="value"
                            (onChange)="changeSuppliersArray()"></p-selectButton>
                    </div>
                    <button disabled pButton pRipple label="{{ 'reception.submit_file' | translate }}"
                        [style]="{'width':'100%', 'overflow': 'visible'}" (click)="onSubmit()"></button>
                    <div *ngIf="loadingUpload" class="p-datatable-loading-overlay p-component-overlay ng-star-inserted"
                        style="z-index: 1000; display: flex; justify-content: center; align-items: center;">
                        <i class="p-datatable-loading-icon pi pi-spin pi-spinner"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" style="padding: 0; margin-top: 1rem;">
            <div class="card" [ngStyle]="{'padding-left': errorOrWarnField ? '2rem' : ''}">
                <div *ngIf="deletedRows > 0" class="field col-12 md:col-12" style="padding: 0;">
                    <p-message severity="warn"
                        text="{{ 'reception.error_lines_deleted' | translate: {deletedRows:deletedRows} }}"></p-message>
                </div>
                <p-table #dt1 [value]="purchaseOrders" dataKey="id_line" editMode="row" [rowHover]="true"
                    styleClass="p-datatable-gridlines overflow-x" [paginator]="true" [loading]="loading"
                    responsiveLayout="stack"
                    [globalFilterFields]="['id_po','SKU','ProductName','EAN','Color','Size','Quantity']" [rows]="10"
                    [rowsPerPageOptions]="[10,25,50]" [showCurrentPageReport]="true"
                    currentPageReportTemplate="{{ 'reception.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
                    <ng-template pTemplate="caption">
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <div class="flex gap-2 sm:flex-row">
                                <button pButton pRipple label="{{ 'general.new' | translate }}" icon="pi pi-plus"
                                    class="search-100 mb-2" (click)="openNew()"></button>
                                <button pButton pRipple id="btnExcelSubmit" icon="pi pi-file-excel"
                                    (click)="exportExcel()" class="e-btn p-button-success mb-2" pTooltip="XLS"
                                    tooltipPosition="bottom" [disabled]="purchaseOrders.length == 0"></button>
                            </div>
                            <div class="flex gap-2 sm:flex-row">
                                <button pButton class="p-button-outlined mb-2" icon="pi pi-filter-slash"
                                    (click)="clear(dt1)"></button>
                                <span class="p-input-icon-left search-100 mb-2">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" #filter
                                        (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{ 'general.search_here' | translate }}" class="w-full" />
                                </span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>
                                <div class="flex justify-content-between align-items-center">
                                    {{ 'reception.po_number' | translate }}
                                    <p-columnFilter type="text" field="id_po" display="menu"
                                        placeholder="{{ 'reception.search_po_number' | translate }}">
                                    </p-columnFilter>
                                </div>
                            </th>
                            <th>
                                <div class="flex justify-content-between align-items-center">
                                    {{ 'reception.SKU' | translate }}
                                    <p-columnFilter type="text" field="SKU" display="menu"
                                        placeholder="{{ 'reception.search_SKU' | translate }}">
                                    </p-columnFilter>
                                </div>
                            </th>
                            <th>
                                <div class="flex justify-content-between align-items-center">
                                    {{ 'reception.name' | translate }}
                                    <p-columnFilter type="text" field="ProductName" display="menu"
                                        placeholder="{{ 'reception.search_name' | translate }}"></p-columnFilter>
                                </div>
                            </th>
                            <th>
                                <div class="flex justify-content-between align-items-center">
                                    {{ 'reception.EAN' | translate }}
                                    <p-columnFilter type="text" field="EAN" display="menu"
                                        placeholder="{{ 'reception.search_EAN' | translate }}">
                                    </p-columnFilter>
                                </div>
                            </th>
                            <th style="width: 10rem">
                                <div class="flex justify-content-between align-items-center">
                                    {{ 'reception.color' | translate }}
                                    <p-columnFilter type="text" field="Color" display="menu"
                                        placeholder="{{ 'reception.search_color' | translate }}"></p-columnFilter>
                                </div>
                            </th>
                            <th>
                                <div class="flex justify-content-between align-items-center">
                                    {{ 'reception.size' | translate }}
                                    <p-columnFilter type="text" field="Size" display="menu"
                                        placeholder="{{ 'reception.search_size' | translate }}"></p-columnFilter>
                                </div>
                            </th>
                            <th>
                                <div class="flex justify-content-between align-items-center">
                                    {{ 'reception.quantity' | translate }}
                                    <p-columnFilter type="numeric" field="Quantity" display="menu"
                                        placeholder="{{ 'reception.search_quantity' | translate }}"></p-columnFilter>
                                </div>
                            </th>
                            <th style="width: 8rem;"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-po let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="po"
                            [ngClass]="{'error-line' : !po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check)}"
                            [ngStyle]="{'background': !po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check) ? '#ffe7e6' : 'transparent'}"
                            title="{{ !po.SKU_check && !po.EAN_check ? this.translateService.instant('reception.no_sku_no_ean' , {sku: po.SKU, ean: po.EAN}) : 
                                !po.SKU_EAN_check ? this.translateService.instant('reception.sku_ean' , {sku: po.SKU, ean: po.EAN}) : 
                                !po.SKU_check && po.EAN_check ? this.translateService.instant('reception.no_sku_ean' , {sku: po.SKU, ean: po.EAN}) :
                                po.SKU_check && !po.EAN_check ? this.translateService.instant('reception.sku_no_ean' , {sku: po.SKU, ean: po.EAN}) :
                                ''}}">
                            <td>
                                <span class="p-column-title"><b>PO Number</b></span>
                                <i *ngIf="(po.SKU_EAN_check && (!po.SKU_check || !po.EAN_check))"
                                    class="pi pi-exclamation-triangle"
                                    style="position: absolute; left: -20px; color: #cc8925;"></i>
                                <i *ngIf="(!po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check))"
                                    class="pi pi-times-circle"
                                    style="position: absolute; left: -20px; color: #ff5757"></i>
                                <i *ngIf="(!po.SKU_EAN_check && (!po.SKU_check || !po.EAN_check))"
                                    class="pi pi-times-circle"
                                    style="position: absolute; left: -20px; color: #ff5757"></i>
                                {{po.id_po}}
                            </td>
                            <td>
                                <span class="p-column-title"><b>SKU</b></span>
                                <p-cellEditor *ngIf="!po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check)">
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="po.SKU" required
                                            [ngClass]="{'ng-invalid' : error}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{po.SKU}}
                                    </ng-template>
                                </p-cellEditor>
                                <div *ngIf="!(!po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check))">
                                    {{po.SKU}}
                                </div>
                            </td>
                            <td>
                                <span class="p-column-title"><b>Name</b></span>
                                {{po.ProductName}}
                            </td>
                            <td>
                                <span class="p-column-title"><b>EAN</b></span>
                                <p-cellEditor *ngIf="!po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check)">
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="po.EAN" required
                                            [ngClass]="{'ng-invalid' : error}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{po.EAN}}
                                    </ng-template>
                                </p-cellEditor>
                                <div *ngIf="!(!po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check))">
                                    {{po.EAN}}
                                </div>
                            </td>
                            <td>
                                <span class="p-column-title"><b>Color</b></span>
                                {{po.Color}}
                            </td>
                            <td>
                                <span class="p-column-title"><b>Size</b></span>
                                {{po.Size}}
                            </td>
                            <td style="width: 10rem;">
                                <span class="p-column-title"><b>Quantity</b></span>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" [(ngModel)]="po.Quantity" min="0" required>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{po.Quantity}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td style="width: 8rem;">
                                <span class="p-column-title"></span>
                                <div style="text-align:center; display: flex; ">
                                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow
                                        icon="pi pi-pencil" (click)="onRowEditInit(po)"
                                        class="p-button-rounded p-button-text"></button>
                                    <button *ngIf="!editing" pButton pRipple type="button" pInitDeleteRow
                                        icon="pi pi-trash" (click)="deleteProduct(po, ri)"
                                        class="p-button-rounded p-button-text"></button>
                                    <button id="btn-save-order" *ngIf="editing" pButton pRipple type="button"
                                        pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(po)"
                                        class="p-button-rounded p-button-text p-button-success mr-2"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow
                                        icon="pi pi-times" (click)="onRowEditCancel(po, ri)"
                                        class="p-button-rounded p-button-text p-button-danger"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="11">{{ 'general.no_data' | translate }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="loadingbody">
                        <tr>
                            <td colspan="11">{{ 'general.loading' | translate }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <p-dialog [(visible)]="purchaseOrderDialog" id="new-product" [style]="{width: '650px'}"
                header="Product Details" [modal]="true" [draggable]="false" [resizable]="false" class="p-fluid">
                <ng-template pTemplate="content">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <label for="id_po">{{ 'reception.po_number' | translate }} <small>*</small></label>
                            <p-autoComplete [(ngModel)]="purchaseOrder.id_po" required [suggestions]="filteredPoNumber"
                                (completeMethod)="filterField($event, 'poNumber')" [dropdown]="true"
                                (ngModelChange)="checkFields(purchaseOrder, 'poNumber')"
                                [ngClass]="{'ng-invalid ng-dirty' : (submitted && !purchaseOrder.id_po) || fieldPoNumberError}">
                                <ng-template let-poNumber pTemplate="item">
                                    {{poNumber}}
                                </ng-template>
                            </p-autoComplete>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="sku">{{ 'reception.SKU' | translate }} <small>*</small></label>
                            <p-autoComplete [(ngModel)]="purchaseOrder.SKU" required [suggestions]="filteredSKU"
                                (completeMethod)="filterField($event, 'SKU')" [dropdown]="true"
                                (ngModelChange)="checkFields(purchaseOrder, 'SKU')"
                                [ngClass]="{'ng-invalid ng-dirty' : (submitted && !purchaseOrder.SKU) || fieldSKUError}">
                                <ng-template let-SKU pTemplate="item">
                                    {{SKU}}
                                </ng-template>
                            </p-autoComplete>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="ean">{{ 'reception.EAN' | translate }} <small>*</small></label>
                            <p-autoComplete [(ngModel)]="purchaseOrder.EAN" required [suggestions]="filteredEAN"
                                (completeMethod)="filterField($event, 'EAN')" [dropdown]="true"
                                (ngModelChange)="checkFields(purchaseOrder, 'EAN')"
                                [ngClass]="{'ng-invalid ng-dirty' : (submitted && !purchaseOrder.EAN) || fieldEANError}">
                                <ng-template let-EAN pTemplate="item">
                                    {{EAN}}
                                </ng-template>
                            </p-autoComplete>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="Quantity">{{ 'reception.quantity' | translate }} <small>*</small></label>
                            <input id="Quantity" pInputText type="number" [(ngModel)]="purchaseOrder.Quantity" min="0"
                                required [ngClass]="{'ng-invalid ng-dirty' : submitted && !purchaseOrder.Quantity}">
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="footer">
                    <button pButton pRipple label="{{ 'general.cancel' | translate }}" icon="pi pi-times"
                        class="p-button-text" (click)="purchaseOrderDialog = false"></button>
                    <button pButton pRipple label="{{ 'general.save' | translate }}" icon="pi pi-check"
                        class="p-button-text" (click)="createPurchaseOrder()"></button>
                    <div class="col-12 md:col-12" style="text-align: left;">
                        <small>* {{ 'reception.required_fields' | translate }}</small>
                    </div>
                </ng-template>
            </p-dialog>
            <p-dialog [(visible)]="deleteProductDialog" header="Confirm" [modal]="true" [draggable]="false"
                [resizable]="false" [style]="{width:'450px'}">
                <div class="flex align-items-center justify-content-center">
                    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                    <span *ngIf="purchaseOrder">{{ 'reception.delete_line' | translate }}
                        <b>{{purchaseOrder.SKU}}</b>?</span>
                </div>
                <ng-template pTemplate="footer">
                    <button pButton pRipple icon="pi pi-times" class="p-button-text"
                        label="{{ 'primeng.reject' | translate }}" (click)="deleteProductDialog = false"></button>
                    <button pButton pRipple icon="pi pi-check" class="p-button-text"
                        label="{{ 'primeng.accept' | translate }}" (click)="confirmDelete()"></button>
                </ng-template>
            </p-dialog>
        </div>
    </div>
</div>