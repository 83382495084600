<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<div *ngIf="!loading" class="h-full">
	<p-table #dt1 id="pltable" [value]="pos" dataKey="POnumber" [rowHover]="true" [paginator]="true"
		[globalFilterFields]="['POnumber']" [rows]="25" [rowsPerPageOptions]="[25,50,100]"
		[showCurrentPageReport]="true" responsiveLayout="scroll" [responsive]="true"
		currentPageReportTemplate="{{ 'orders.orders.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
		<ng-template pTemplate="caption">
			<div class="flex justify-content-between gap-2 flex-wrap">
				<span class="p-input-icon-left w-full sm:w-auto">
					<i class="pi pi-search"></i>
					<input pInputText type="text" #filter (input)="dt1.filterGlobal($event.target.value, 'contains')"
						placeholder="{{ 'reception.search_PO' | translate }}"
						class="p-inputtext p-component p-eleme[(ngModel)]=nt w-full" />
				</span>
				<div class="flex gap-2">
					<button pButton pRipple label="Export" class="p-button-outlined" (click)="exportExcel()">
						<span class="p-button-icon p-button-icon-left flex"><i class="pi pi-file-excel"></i></span>
					</button>
					<button pButton pRipple label="Add supplier" class="p-button-secundary"
						(click)="createSupplierDialog = true">
						<span class="p-button-icon p-button-icon-left flex"><img
								src="assets/layout/images/desktop-icon.svg"></span>
					</button>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th></th>
				<th>PO Number</th>
				<th pSortableColumn="totalOrdered">
					<div class="flex align-items-center">
						{{ 'purchase_order.Ordered' | translate }}
						<p-sortIcon field="totalOrdered"></p-sortIcon>
					</div>
				</th>
				<th pSortableColumn="totalReveived">
					<div class="flex align-items-center">
						{{ 'purchase_order.Received' | translate }}
						<p-sortIcon field="totalReveived"></p-sortIcon>
					</div>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-po let-rowIndex="rowIndex" let-expanded="expanded">
			<tr>
				<td style="width: 2rem;">
					<button type="button" pButton pRipple [pRowToggler]="po"
						class="p-button-text p-button-rounded p-button-plain"
						[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
						(click)="expandItem(po, 1)"></button>
				</td>
				<td><b>{{po.POnumber}}</b></td>
				<td>{{po.totalOrdered}}</td>
				<td>{{po.totalReceived}}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="rowexpansion" let-po>
			<tr>
				<th colspan="5">
					<p-table #dt2 id="potable" [value]="po.orders" rowGroupMode="subheader" [rowHover]="true"
						sortMode="single" responsiveLayout="scroll" dataKey="name" [loading]="loadingSubTables">
						<ng-template pTemplate="header">
			<tr>
				<th></th>
				<th>{{ 'purchase_order.ProductModels' | translate }}
					<p-columnFilter field="name" matchMode="equals" display="menu">
						<ng-template pTemplate="filter" let-value let-filter="filterCallback">
							<p-dropdown appendTo="body" [ngModel]="value" [options]="productModels" (onChange)="filter($event.value)"
								placeholder="{{ 'general.any' | translate }}" [style]="{'min-width': '12rem'}">
								<ng-template let-option pTemplate="item">
									{{option.label}}
								</ng-template>
							</p-dropdown>
						</ng-template>
					</p-columnFilter>
				</th>
				<th pSortableColumn="totalProdOrdered">
					<div class="flex align-items-center">
						{{ 'purchase_order.Ordered' | translate }}
						<p-sortIcon field="totalProdOrdered"></p-sortIcon>
					</div>
				</th>
				<th pSortableColumn="totalProdReceived">
					<div class="flex align-items-center">
						{{'purchase_order.Received' | translate }}
						<p-sortIcon field="totalProdReceived"></p-sortIcon>
					</div>
				</th>
				<th></th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-order let-rowIndex="rowIndex" let-expanded="expanded">
			<tr>
				<td style="width: 2rem;">
					<button type="button" pButton pRipple [pRowToggler]="order"
						class="p-button-text p-button-rounded p-button-plain"
						[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
						(click)="expandItem(po, 0)"></button>
				</td>
				<td><b>{{order.name}}</b></td>
				<td>{{order.totalProdOrdered}}</td>
				<td>{{order.totalProdReceived}}</td>
				<td style="width: 10%;">
					<p-image [src]="order.products.length == 1 ? order.products[0].image :  order.products[1].image"
						width="30px" [preview]="true" class="shadow-4"></p-image>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="rowexpansion" let-order>
			<tr>
				<th colspan="8">
					<p-table #dt3 [value]="order.products" rowGroupMode="subheader" sortMode="single"
						responsiveLayout="stack" responsive="true" dataKey="pname" [loading]="loadingSubTables">
						<ng-template pTemplate="header">
			<tr>
				<th pSortableColumn="SKU">
					<div class="flex align-items-center">
						{{ 'purchase_order.SKU' | translate }}
						<p-sortIcon field="SKU"></p-sortIcon>
					</div>
				</th>
				<th pSortableColumn="EAN">
					<div class="flex align-items-center">
						{{ 'purchase_order.EAN' | translate }}
						<p-sortIcon field="EAN"></p-sortIcon>
					</div>
				</th>
				<th>{{ 'purchase_order.Color' | translate }}
				</th>
				<th>{{ 'purchase_order.Size' | translate }}
				</th>
				<th pSortableColumn="qtyDyn">
					<div class="flex align-items-center">
						{{ 'purchase_order.Ordered' | translate }}
						<p-sortIcon field="qtyDyn"></p-sortIcon>
					</div>
				</th>
				<th pSortableColumn="qtyRec">
					<div class="flex align-items-center">
						{{ 'purchase_order.Received' | translate }}
						<p-sortIcon field="qtyRec"></p-sortIcon>
					</div>
				</th>
				<th style="width: 10%;">{{ 'purchase_order.Image' | translate }}
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-product let-expanded="expanded">
			<tr>
				<td><span class="p-column-title">SKU</span>{{product.SKU}}</td>
				<td><span class="p-column-title">EAN</span>{{product.EAN}}</td>
				<td><span class="p-column-title">Color</span>{{product.color}}</td>
				<td><span class="p-column-title">Size</span>{{product.size}}</td>
				<td><span class="p-column-title">Qty Encomendada</span>{{product.qtyDyn}}</td>
				<td><span class="p-column-title">Qty Recebida</span>{{product.qtyRec}}</td>
				<td style="width: 10%;">
					<p-image [src]="product.image" width="30px" [preview]="true" class="shadow-4"></p-image>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="8">{{ 'general.no_data' | translate }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr>
				<td colspan="8">{{ 'general.loading' | translate }}</td>
			</tr>
		</ng-template>
	</p-table>

	</th>
	</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="8">{{ 'general.no_data' | translate }}</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="loadingbody">
		<tr>
			<td colspan="8">{{ 'general.loading' | translate }}</td>
		</tr>
	</ng-template>
	</p-table>
	</th>
	</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td colspan="8">{{ 'general.no_data' | translate }}</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="loadingbody">
		<tr>
			<td colspan="8">{{ 'general.loading' | translate }}</td>
		</tr>
	</ng-template>
	</p-table>
	<p-dialog [header]="product.SKU" [(visible)]="displayDialog" [style]="{width: '650px', margin: '0 2rem'}"
		modal="false" showEffect="fade">
		<div class="grid">
			<div class="col-12 md:col-6">
				<img [src]="product.image" [alt]="product.pname" width="100%" height="100%" />
			</div>
		</div>
		<ng-template pTemplate="footer">
			<button pButton icon="pi pi-check" (click)="displayDialog=false" label="Ok"
				class="p-button-outlined"></button>
		</ng-template>
	</p-dialog>
</div>
<p-dialog [(visible)]="createSupplierDialog"
	[style]="{width: '740px', maxWidth: '90%', maxHeight: '100%', margin: '2rem'}" id="user_details"
	header="Register supplier" [modal]="true" [draggable]="false" [resizable]="false" class="p-fluid"
	(onHide)="hideDialog()">
	<ng-template pTemplate="content">
		<div class="flex flex-column gap-3">
			<div *ngIf="submitted && nameError">
				<p-messages severity="error" [closable]="true">
					<ng-template pTemplate>
						<img src="assets/layout/images/error-icon.svg" width="22" />
						<div class="ml-2">Error creating supplier. A supplier with name <b>{{newSupplier.name}}</b>
							already exists.</div>
					</ng-template>
				</p-messages>
			</div>
			<div class="flex flex-column gap-3">
				<div class="w-full flex gap-3">
					<div class="w-6 relative mb-3">
						<h6 for="name" class="flex mb-2"><small class="p-error">*</small>Name</h6>
						<input id="name" [(ngModel)]="newSupplier.name" type="text" pInputText
							class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && !newSupplier.name}">
						<small *ngIf="submitted && !newSupplier.name" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
					</div>
					<div class="w-6 relative mb-3">
						<h6 for="email" class="flex mb-2"><small class="p-error">*</small>Email</h6>
						<input id="email" [(ngModel)]="newSupplier.email" type="text" pInputText
							class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && (!newSupplier.email || !validateEmail(newSupplier.email))}">
						<small *ngIf="submitted && !newSupplier.email" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
						<small *ngIf="submitted && newSupplier.email && !validateEmail(newSupplier.email)"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Invalid
							email</small>
					</div>
				</div>
				<div class="w-full flex gap-3">
					<div class="w-4 relative mb-3">
						<h6 for="codigo_wms" class="flex mb-2"><small class="p-error">*</small>WMS Code</h6>
						<input id="codigo_wms" [(ngModel)]="newSupplier.codigo_wms" type="text" pInputText
							class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && !newSupplier.codigo_wms}">
						<small *ngIf="submitted && !newSupplier.codigo_wms"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
					</div>
					<div class="w-4 relative mb-3">
						<h6 for="phone" class="flex mb-2"><small class="p-error">*</small>Phone</h6>
						<input id="phone" [(ngModel)]="newSupplier.phone" type="number" pInputText
							class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && (!newSupplier.phone || !validatePhone(newSupplier.phone))}">
						<small *ngIf="submitted && !newSupplier.phone" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
						<small *ngIf="submitted && newSupplier.phone && !validatePhone(newSupplier.phone)"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Invalid
							phone number</small>
					</div>
					<div class="w-4 relative mb-3">
						<h6 for="nif" class="flex mb-2"><small class="p-error">*</small>VAT</h6>
						<input id="nif" [(ngModel)]="newSupplier.nif" type="number" pInputText
							class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && (!newSupplier.nif || !validateVAT(newSupplier.nif))}">
						<small *ngIf="submitted && !newSupplier.nif" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
						<small *ngIf="submitted && newSupplier.nif && !validateVAT(newSupplier.nif)"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Invalid
							VAT</small>
					</div>
				</div>
				<div class="w-full flex">
					<div class="w-full relative mb-3">
						<h6 for="address" class="flex mb-2"><small class="p-error">*</small>Address</h6>
						<input id="address" [(ngModel)]="newSupplier.address" type="text" pInputText
							class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && !newSupplier.address}">
						<small *ngIf="submitted && !newSupplier.address" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
					</div>
				</div>
				<div class="w-full flex gap-3">
					<div class="w-6 relative mb-3">
						<h6 for="city" class="flex mb-2"><small class="p-error">*</small>City</h6>
						<input id="city" [(ngModel)]="newSupplier.city" type="text" pInputText
							class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && !newSupplier.city}">
						<small *ngIf="submitted && !newSupplier.city" class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
					</div>
					<div class="w-3 relative mb-3">
						<h6 for="country" class="flex mb-2"><small class="p-error">*</small>Country</h6>
						<p-dropdown appendTo="body" [options]="countries" [(ngModel)]="newSupplier.country_code"
							placeholder="{{ 'suppliers.select_country' | translate }}" [style]="{'minWidth':'100%'}"
							optionLabel="country_name" optionValue="country_code" (onChange)="createZipRegex()"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && !newSupplier.country_code}"></p-dropdown>
						<small *ngIf="submitted && !newSupplier.country_code"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
					</div>
					<div class="w-3 relative mb-3">
						<h6 for="codigo_postal" class="flex mb-2"><small class="p-error">*</small>Zipcode</h6>
						<input id="codigo_postal" [(ngModel)]="newSupplier.codigo_postal" type="text" pInputText
							class="p-inputtext w-full"
							[ngClass]="{'ng-invalid ng-dirty' : submitted && (!newSupplier.codigo_postal || !validateZipcode(newSupplier.codigo_postal))}">
						<small *ngIf="submitted && !newSupplier.codigo_postal"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Required field</small>
						<small
							*ngIf="submitted && newSupplier.codigo_postal && !validateZipcode(newSupplier.codigo_postal)"
							class="p-error absolute flex gap-1 mt-1"><img
								src="assets/layout/images/error-icon.svg">Invalid
							zipcode</small>
					</div>
				</div>
				<div class="w-full flex ">
					<div class="w-full relative flex gap-2 align-items-center mb-3">
						<h6 for="transfer">Can this supplier make transfers?</h6>
						<p-dropdown appendTo="body" [options]="supplierTransfer" [(ngModel)]="newSupplier.transfer" optionLabel="name"
							optionValue="code" class="p-dropdown-text"></p-dropdown>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<div class="flex justify-content-end">
			<div class="flex flex-nowrap">
				<button pButton pRipple type="button" label="Cancel" class="p-button-outlined p-button-danger"
					(click)="hideDialog()"></button>
				<button pButton pRipple label="Submit" type="submit" (click)="createSupplier()"></button>
			</div>
		</div>
	</ng-template>
</p-dialog>