import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { UserService } from '../../services/user/user.service'

import { AppMainComponent } from '../../app.main.component';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'pages-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.sass']
})
export class TopBarComponent implements OnInit {

    model: any[];

    countryFlag: any;

    constructor(
        public permissions: PermissionsService,
        public appMain: AppMainComponent,
        private translateService: TranslateService,
        private userService: UserService,
    ) { }

    ngOnInit() {
        this.countryFlag = this.translateService.currentLang == 'en' ? 'gb' : this.translateService.currentLang;
        const permissionsType = this.permissions.getPermissionsType();
        var user_details = JSON.parse(sessionStorage.getItem('user_details'))
        var brands_list = user_details?.brands_list.filter(brand => brand.id_brand != user_details.id_brand)
        var items = null
        if (brands_list.length > 0) {
            items = []
            brands_list.map(brand => {
                items.push({
                    label: brand.brand_name,
                    brand: true,
                    id_brand: brand.id_brand,
                    permission: true
                })
            })
        }
        var actual_brand = user_details.brands_list.find(brand => brand.id_brand == user_details.id_brand)
        this.model = [
            {
                permission: this.permissions.checkPermissions([
                    permissionsType.brandDetails,
                    permissionsType.createUser,
                    permissionsType.createUser,
                ]),
                items: [
                    {
                        label: actual_brand.brand_name,
                        icon: 'assets/layout/images/store-icon.svg',
                        items: items
                    },
                    {
                        label: 'My account',
                        icon: 'assets/layout/images/user.png',
                        items: [
                            {
                                label: 'Change password',
                                permission: this.permissions.checkPermissions([
                                    permissionsType.brandDetails,
                                ]),
                                icon: 'assets/layout/images/password-icon.svg'
                            },
                            {
                                label: 'Log out',
                                permission: this.permissions.checkPermissions([
                                    permissionsType.brandDetails,
                                ]),
                                icon: 'assets/layout/images/log-out-icon.svg'
                            }
                        ]
                    }
                ]
            },
        ]
    }

    changeLang() {
        let lang = this.translateService.currentLang == 'gb' ? 'pt' : 'gb'
        this.translateService.use(lang);
        this.countryFlag = lang;
        window.sessionStorage.setItem("language_code", lang);
        this.userService.putLangUser(lang);
    }
}
