<div class="all" style="position: relative; height: 100vh; ">
	<div class="sidebar-button">
		<!--<i class="pi pi-times" style="position: relative; font-size: 20rem;">
			<span style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: black; opacity: 0; "></span>
		  </i>-->
		<!--<button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded" style="z-index: 10005" style=" background-color: black; border: transparent; color: white;  font-size: 20rem"
		(click)="closeSideBar()"></button>-->
		<i class="btnX" (click)="closeSideBar()">
			<img class="btnIMG" src="../../../assets/layout/images/circle-xmark-solidV2.svg" />
			<span style="position: absolute; "></span>
		</i>
	</div>
	<p-sidebar [(visible)]="settingSidebar" position="bottom" [baseZIndex]="10000" (onHide)="closeSideBar()"
		[showCloseIcon]="false" [style]="{height: '85%'}">
		<div class="p-sidebar-header" style="padding: 1.25rem 0">

		</div>
		<p-tabView #tv styleClass="tabview-custom" [scrollable]="true" (onChange)="getTags_($event)">
			<p-tabPanel header="Header I">
				<ng-template pTemplate="header">
					<span>{{ 'personal_area.details' | translate }}</span>
				</ng-template>
				<div class="mt-3 flex flex-column md:flex-row gap-3" *ngIf="brands && brands.length > 0">
					<div class="w-full md:w-5 flex flex-column gap-3">
						<div class="w-full card mb-0">
							<div class="flex justify-content-start align-items-center gap-2">
								<i class="pi pi-id-card" style="font-size: 20px;"></i>
								<h6>General</h6>
							</div>
							<div class="mt-3">
								<div class="col-12 flex justify-content-between mb-2">
									<h6>{{ 'personal_area.name' | translate }}</h6>
									<div>{{brands[0].brand_name}}</div>
								</div>
								<div class="col-12 flex justify-content-between mb-2">
									<h6>{{ 'personal_area.created_at' | translate }}</h6>
									<div>{{brands[0].created_at}}</div>
								</div>
							</div>
						</div>
						<div class="w-full card">
							<div class="flex justify-content-start align-items-center gap-2">
								<i class="pi pi-cog" style="font-size: 20px;"></i>
								<h6>{{ 'personal_area.integrations' | translate }}</h6>
							</div>
							<div class="mt-3">
								<div class="col-12 flex justify-content-between mb-2">
									<h6>{{ 'personal_area.dynamic_id' | translate }}</h6>
									<div *ngIf="brands[0].dynamics_id != null" class="uppercase text-green-600">{{
										'general.yes' | translate }}</div>
									<div *ngIf="brands[0].dynamics_id == null" class="uppercase p-error">{{ 'general.no'
										|
										translate }}</div>
								</div>
								<div *ngIf="brands[0].shopify_api == 1"
									class="col-12 flex justify-content-between mb-2">
									<h6>{{ 'personal_area.shopify_api' | translate }}</h6>
									<div class="uppercase text-green-600">{{
										'general.yes' | translate }}</div>
								</div>
								<div *ngIf="brands[0].woocommerce == 1"
									class="col-12 flex justify-content-between mb-2">
									<h6>{{ 'personal_area.woocommerce_api' | translate }}</h6>
									<div class="uppercase text-green-600">{{
										'general.yes' | translate }}</div>
								</div>
								<div class="col-12 flex justify-content-between mb-2">
									<h6>{{ 'personal_area.account_wms' | translate }}</h6>
									<div *ngIf="brands[0].wms_account == 1" class="uppercase text-green-600">{{
										'general.yes' | translate }}</div>
									<div *ngIf="brands[0].wms_account != 1" class="uppercase p-error">{{ 'general.no' |
										translate }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="w-full md:w-7 card">
						<div class="flex justify-content-start align-items-center gap-2">
							<i class="pi pi-home" style="font-size: 20px;"></i>
							<h6>{{ 'personal_area.warehouse.warehouse' | translate }}</h6>
						</div>
						<div class="mt-3 grid">
							<div class="col-12 sm:col-6 flex-column mb-2">
								<h6 for="company_name" class="flex justify-content-start mb-2">{{
									'personal_area.warehouse.company_name' | translate }}</h6>
								<input id="company_name" [(ngModel)]="warehouse.company_name" type="text"
									placeholder="Company name" pInputText class="p-inputtext w-full">
							</div>
							<div class="col-12 sm:col-6 flex-column mb-2">
								<h6 for="name" class="flex justify-content-start mb-2">{{ 'personal_area.warehouse.name'
									| translate }}</h6>
								<input id="name" [(ngModel)]="warehouse.name" type="text" placeholder="Name" pInputText
									class="p-inputtext w-full">
							</div>
							<div class="col-12 sm:col-6 flex-column mb-2">
								<h6 for="phone" class="flex justify-content-start mb-2">{{
									'personal_area.warehouse.phone' | translate }}</h6>
								<input id="phone" [(ngModel)]="warehouse.phone" type="text" placeholder="Phone"
									pInputText class="p-inputtext w-full">
							</div>
							<div class="col-12 sm:col-6 flex-column mb-2">
								<h6 for="email" class="flex justify-content-start mb-2">Email</h6>
								<input id="email" [(ngModel)]="warehouse.email" type="text" placeholder="Email"
									pInputText class="p-inputtext w-full">
							</div>
							<div class="col-12">
								<div class="w-full flex-column mb-2">
									<h6 for="address" class="flex justify-content-start mb-2">{{
										'personal_area.warehouse.address' | translate }}</h6>
									<input id="address" [(ngModel)]="warehouse.address" type="text"
										placeholder="Address" pInputText class="p-inputtext w-full">
								</div>
							</div>
							<div class="col-12 sm:col-6 flex-column mb-2">
								<h6 for="zipcode" class="flex justify-content-start mb-2">{{
									'personal_area.warehouse.zipcode' | translate }}</h6>
								<input id="zipcode" [(ngModel)]="warehouse.zipcode" type="text" placeholder="Zipcode"
									pInputText class="p-inputtext w-full">
							</div>
							<div class="col-12 sm:col-6 flex-column mb-2">
								<h6 for="city" class="flex justify-content-start mb-2">{{ 'personal_area.warehouse.city'
									| translate }}</h6>
								<input id="city" [(ngModel)]="warehouse.city" type="text" placeholder="City" pInputText
									class="p-inputtext w-full">
							</div>
							<div class="col-12 sm:col-4 flex-column mb-2">
								<h6 for="country" class="flex justify-content-start mb-2">{{
									'personal_area.warehouse.country' | translate }}</h6>
								<p-dropdown appendTo="body" id="dropdown-country-options"
									[options]="countries_warehouse" class="drop-full" [(ngModel)]="selectedCountry"
									placeholder="{{ 'suppliers.select_country' | translate }}"
									optionLabel="country_name" optionValue="country_code" required
									(onChange)="teste($event)"></p-dropdown>
							</div>
							<div class="col-12 sm:col-4 flex-column mb-2" *ngIf="id_brand == 1">
								<h6 for="eori" class="flex justify-content-start mb-2">{{ 'personal_area.warehouse.EORI'
									| translate }}</h6>
								<input id="eori" [(ngModel)]="warehouse.eori" type="text" placeholder="EORI" pInputText
									class="p-inputtext w-full">
							</div>
							<div class="col-12 sm:col-4 flex-column mb-2" *ngIf="id_brand == 1">
								<h6 for="uk_vat" class="flex justify-content-start mb-2">{{
									'personal_area.warehouse.UK_VAT' | translate }}</h6>
								<input id="uk_vat" [(ngModel)]="warehouse.uk_vat" type="text" placeholder="UK VAT"
									pInputText class="p-inputtext w-full">
							</div>
						</div>
						<div class="w-full flex justify-content-end">
							<button pButton pRipple label="{{ 'general.save' | translate }}" type="submit"
								icon="pi pi-check" class="p-button-raised" (click)="onSubmitWarehouse()"></button>
						</div>
					</div>
				</div>
			</p-tabPanel>

			<p-tabPanel header="Header II">
				<ng-template pTemplate="header">
					<span>{{ 'personal_area.webhooks' | translate }}</span>
				</ng-template>
				<p-toast key="tst" [baseZIndex]="99999"></p-toast>
				<div class="card">
					<p-table #dt1 [value]="webhooks" dataKey="id" [loading]="loadingwebhooks" [rowHover]="true"
						[paginator]="false" responsiveLayout="stack" onStateSave
						(onEditComplete)="onEditComplete($event)" class="simple-table">
						<ng-template pTemplate="header">
							<tr>
								<th>
									<div class="flex justify-content-between align-items-center">
										{{ 'personal_area.id' | translate }}
									</div>
								</th>
								<th>
									<div class="flex justify-content-between align-items-center">
										{{ 'personal_area.endpoint' | translate }}
									</div>
								</th>
								<th>
									<div class="flex justify-content-between align-items-center">
										{{ 'personal_area.topic' | translate }}
									</div>
								</th>
								<th>
									<div class="flex justify-content-between align-items-center">
										{{ 'personal_area.state' | translate }}
									</div>
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-po>
							<tr>
								<td style="min-width: auto;">
									<span class="p-column-title"><b>{{ 'personal_area.id' | translate
											}}</b></span>
									{{po.id_shopify_webhook}}
								</td>
								<td pEditableColumn [pEditableColumn]="po" [pEditableColumnField]="'address'">
									<span class="p-column-title"><b>{{ 'personal_area.endpoint' | translate
											}}</b></span>
									<p-cellEditor class="text-right">
										<ng-template pTemplate="input">
											<input pInputText type="text" [(ngModel)]="po.address" style="width: 100%">
										</ng-template>
										<ng-template pTemplate="output" style="width: 20%">
											{{po.address}}
										</ng-template>
									</p-cellEditor>
								</td>
								<td style="min-width: auto;">
									<span class="p-column-title"><b>{{ 'personal_area.topic' | translate
											}}</b></span>
									{{po.topic}}
								</td>
								<td>
									<span class="p-column-title"><b>{{ 'personal_area.state' | translate
											}}</b></span>
									<div class="flex">
										<button pButton pRipple
											icon="pi {{po.flag ? 'pi-minus-circle' : 'pi-plus-circle'}}"
											id="btn-seemore-{{po.id_webhook}}"
											class="{{po.flag ? 'p-button-danger' : 'p-button-success'}}  mr-2"
											(click)="changeWebhookSatus(po)"></button>
									</div>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="9">{{ 'general.no_data' | translate }}</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="loadingbody">
							<tr>
								<td colspan="9">{{ 'general.loading' | translate }}</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</p-tabPanel>

			<p-tabPanel header="Header III" *ngIf="permissionReturn && accepreturn">
				<div *ngIf="loadingBrandReturn" class="flex justify-content-center align-items-center min-h-screen">
					<div class="loading"></div>
				</div>
				<ng-template pTemplate="header">
					<span>{{ 'personal_area.returns' | translate }}</span>
				</ng-template>
				<p-toast key="tst2" [baseZIndex]="99999"></p-toast>
				<div class="flex flex-column gap-3">
					<div class="card">
						<div class="flex justify-content-start align-items-center gap-2">
							<i class="pi pi-cog" style="font-size: 20px;"></i>
							<h6>{{ 'personal_area.page_setup' |
								translate }}</h6>
						</div>
						<div class="mt-3 grid">
							<div class="col-12 md:col-6 flex-column mb-2">
								<h6 for="background_image" class="flex justify-content-start mb-2">{{
									'personal_area.background' |
									translate }}</h6>
								<div class="flex gap-3">
									<img src="data:image/png;base64,{{brandSetup?.background}}"
										class="w-auto max-w-10rem" />
									<p-fileUpload #backupload mode="basic" name="myfile[]" accept="image/*"
										customUpload="true" [auto]="true" class="upload-images"
										chooseIcon="pi pi-replay"
										(uploadHandler)="onFileBackgroundUpload($event, backupload)"
										chooseLabel="{{ 'personal_area.change' | translate }}">
									</p-fileUpload>
								</div>
							</div>
							<div class="col-12 md:col-6 flex-column mb-2">
								<h6 for="logo" class="flex justify-content-start mb-2">{{ 'personal_area.logo' |
									translate }}</h6>
								<div class="flex gap-3">
									<img src="data:image/png;base64,{{brandSetup?.logo}}"
										class="w-auto max-w-10rem max-h-5rem" />
									<p-fileUpload #logoupload mode="basic" name="myfile[]" accept="image/*"
										customUpload="true" [auto]="true" class="upload-images"
										chooseIcon="pi pi-replay" (uploadHandler)="onFileLogoUpload($event, logoupload)"
										chooseLabel="{{ 'personal_area.change' | translate }}">
									</p-fileUpload>
								</div>
							</div>
							<div class="col-12 md:col-4 flex-column mb-2">
								<h6 for="font" class="flex justify-content-start mb-2">{{ 'personal_area.font' |
									translate }}</h6>
								<p-dropdown appendTo="body" [options]="return_fonts" [(ngModel)]="selectedFont"
									[style]="{'minWidth':'100%'}" optionLabel="name_font" optionValue="id_font">
								</p-dropdown>
							</div>
							<div class="col-12 md:col-4 flex-column mb-2">
								<h6 for="color" class="flex justify-content-start mb-2">{{ 'personal_area.color' |
									translate }}</h6>
								<div class="flex gap-1">
									<p-colorPicker [(ngModel)]="color"></p-colorPicker>
									<div class="col" style="padding: 0;">
										<input pInputText type="text" pInputText required
											[ngClass]="{'ng-invalid ng-dirty' : submitted && !color}"
											[(ngModel)]="color">
									</div>
								</div>
							</div>
							<div class="col-12 md:col-4 flex-column mb-2">
								<h6 for="limit_days" class="flex justify-content-start mb-2">{{
									'personal_area.days_limit' |
									translate }}</h6>
								<p-inputNumber [(ngModel)]="limit_days" [showButtons]="true" mode="decimal"
									suffix=" days" [min]="1"
									[ngClass]="{'ng-invalid ng-dirty' : submitted && !limit_days}">
								</p-inputNumber>
							</div>
							<div class="col-12 md:col-6 flex-column mb-2">
								<h6 for="noreturn_tags" class="flex justify-content-start mb-2">{{
									'personal_area.noreturn_tags' |
									translate }}</h6>
								<div class="w-full">
									<div #container class="w-full" style="display:inline-block;position:relative">
										<p-chips class="w-full"
											pTooltip="{{ 'personal_area.noreturn_hint' | translate }}"
											[(ngModel)]="noreturn_tags" separator=","></p-chips>
									</div>
								</div>
							</div>
							<div class="col-12 md:col-6 flex-column mb-2">
								<h6 for="noexchange_tags" class="flex justify-content-start mb-2">{{
									'personal_area.noexchange_tags' |
									translate }}</h6>
								<div class="w-full">
									<p-chips class="w-full" pTooltip="{{ 'personal_area.noreturn_hint' | translate }}"
										[(ngModel)]="noexchange_tags" separator=","></p-chips>
								</div>
							</div>
							<div class="col-12 md:col-6 flex-column mb-2">
								<h6 for="price_range" class="flex justify-content-start mb-2">{{
									'personal_area.price_range' | translate }}*: {{secondHanderange[0] + '%- ' +
									secondHanderange[1]}}%</h6>
								<div class="py-2 w-full">
									<p-slider [(ngModel)]="secondHanderange" [range]="true" (onChange)="0"></p-slider>
								</div>
								<small>* {{ 'personal_area.prince_range_message' | translate }}</small>
							</div>
							<div class="col-12 md:col-6 flex-column mb-2">
								<h6 for="proforma" class="flex justify-content-start mb-2">{{
									'personal_area.proforma_shipping' | translate }}</h6>
								<div class="flex gap-2 align-items-center">
									<label htmlFor="shipping">{{'personal_area.shipping' | translate }}?</label>
									<p-checkbox [(ngModel)]="proforma_shipping" [binary]="true">
									</p-checkbox>
								</div>
							</div>
						</div>
						<div class="w-full flex justify-content-end">
							<button pButton pRipple label="{{ 'general.save' | translate }}" type="submit"
								icon="pi pi-check" [disabled]="addEmailNotification || addSpecialDate"
								class="p-button-raised" (click)="onSubmitPageSetup()"></button>
						</div>
					</div>
					<div class="card">
						<div class="flex justify-content-start align-items-center gap-2">
							<i class="pi pi-calendar" style="font-size: 20px;"></i>
							<h6>{{ 'personal_area.special_dates_to_return' | translate }}</h6>
						</div>
						<div class="mt-3">
							<div class="flex flex-column gap-2">
								<p-table #dt1 [value]="specialDates" dataKey="id" [rowHover]="true" [paginator]="false"
									responsiveLayout="stack" class="simple-table">
									<ng-template pTemplate="header">
										<tr>
											<th>{{ 'personal_area.name' | translate }}</th>
											<th pSortableColumn="dateI">
												<div class="flex align-items-center">
													{{'personal_area.initial_date' | translate}}
													<p-sortIcon field="dateI">
													</p-sortIcon>
												</div>
											</th>
											<th pSortableColumn="dateF">
												<div class="flex align-items-center">
													{{'personal_area.final_date' | translate}}
													<p-sortIcon field="dateF">
													</p-sortIcon>
												</div>
											</th>
											<th pSortableColumn="returnLimitDate">
												<div class="flex align-items-center">
													{{'personal_area.limit_date_to_return' | translate }}
													<p-sortIcon field="returnLimitDate">
													</p-sortIcon>
												</div>
											</th>
											<th *ngIf="isAdmin" class="w-min"></th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-specialDate let-editing="editing"
										let-ri="rowIndex">
										<tr>
											<td>
												<span class="p-column-title"><b>{{ 'personal_area.name' | translate
														}}</b></span>
												{{specialDate.special_date}}
											</td>
											<td>
												<span class="p-column-title"><b>{{ 'personal_area.initial_date' |
														translate
														}}</b></span>
												{{specialDate.inicial_date}}
											</td>
											<td>
												<span class="p-column-title"><b>{{ 'personal_area.final_date' |
														translate
														}}</b></span>
												{{specialDate.end_date}}

											</td>
											<td>
												<span class="p-column-title"><b>{{ 'personal_area.limit_date_to_return'
														|
														translate }}</b></span>
												{{specialDate.return_limit_date}}
											</td>
											<td *ngIf="isAdmin" style="width: 4rem;">
												<span class="p-column-title"> </span>
												<button *ngIf="!editing" pButton pRipple type="button" pInitDeleteRow
													icon="pi pi-trash" (click)="deleteSpecialDate(specialDate)"
													class="p-button-rounded p-button-text"></button>
											</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="8">{{ 'general.no_data' | translate }}</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="loadingbody">
										<tr>
											<td colspan="8">{{ 'general.loading' | translate }}</td>
										</tr>
									</ng-template>
								</p-table>
								<p-button *ngIf="isAdmin" (onClick)="addSpecialDate = true" icon="pi pi-plus"
									class="button-add-more flex w-full justify-content-center"
									label="{{ 'orders.returns.addmore' | translate }}"></p-button>
								<div *ngIf="addSpecialDate && isAdmin"
									class="addMoreSpecialDate w-full flex justify-content-between gap-2 flex-wrap sm:flex-nowrap">
									<div class="flex flex-column w-full gap-2">
										<label htmlFor="special_date">{{ 'personal_area.name' | translate }}</label>
										<input pInputText type="text" required
											[(ngModel)]="selectedSpecialDate.special_date"
											[ngClass]="{'ng-invalid ng-dirty' : submittedSpecialDate && !selectedSpecialDate.special_date}">
									</div>
									<div class="flex flex-column w-full gap-2">
										<label htmlFor="rangeDates">{{ 'personal_area.range_dates' | translate
											}}</label>
										<p-calendar [showIcon]="true" [(ngModel)]="selectedSpecialDate.rangeDates"
											required (onClose)="addMinDate()" selectionMode="range"
											dateFormat="dd/mm/yy" class="w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedSpecialDate && (!selectedSpecialDate.rangeDates || (selectedSpecialDate.rangeDates && selectedSpecialDate.rangeDates[1] == null))}">
										</p-calendar>
									</div>
									<div class="flex flex-column w-full gap-2">
										<label htmlFor="return_limit_date">{{ 'personal_area.limit_date_to_return' |
											translate }}</label>
										<p-calendar [showIcon]="true"
											[(ngModel)]="selectedSpecialDate.return_limit_date" required
											[minDate]="minDate" inputId="basic" dateFormat="dd/mm/yy" class="w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedSpecialDate && (selectedSpecialDate.return_limit_date < minDate || !selectedSpecialDate.return_limit_date)}">
										</p-calendar>
									</div>
									<div class="flex justify-content-between align-items-end w-min">
										<button pButton pRipple type="button" icon="pi pi-check"
											class="p-button-rounded p-button-text"
											(click)="addMoreSpecialDate()"></button>
										<button pButton pRipple type="button" icon="pi pi-times"
											class="p-button-rounded p-button-text"
											(click)="cancelAddMoreSpecialDate()"></button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="flex justify-content-start align-items-center gap-2">
							<i class="pi pi-send" style="font-size: 20px;"></i>
							<h6>{{ 'personal_area.emails_notification' | translate }}</h6>
						</div>
						<div class="mt-3">
							<div class="flex flex-column gap-2">
								<p-table #dt1 [value]="emailsNotifications" dataKey="email" [rowHover]="true"
									editMode="row" [paginator]="false" responsiveLayout="scroll" class="simple-table">
									<ng-template pTemplate="header">
										<tr>
											<th>Email</th>
											<th *ngIf="isAdmin" class="w-min"></th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-email let-editing="editing" let-ri="rowIndex">
										<tr>
											<td>
												{{email.email}}
											</td>
											<td *ngIf="isAdmin" style="width: 4rem;">
												<button *ngIf="!editing" pButton pRipple type="button" pInitDeleteRow
													icon="pi pi-trash" (click)="deleteEmailNotification(email)"
													class="p-button-rounded p-button-text"></button>
											</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="8">{{ 'general.no_data' | translate }}</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="loadingbody">
										<tr>
											<td colspan="8">{{ 'general.loading' | translate }}</td>
										</tr>
									</ng-template>
								</p-table>
								<p-button *ngIf="isAdmin" (onClick)="addEmailNotification = true" icon="pi pi-plus"
									class="button-add-more flex w-full justify-content-center"
									label="{{ 'orders.returns.addmore' | translate }}"></p-button>
								<div *ngIf="addEmailNotification && isAdmin"
									class="addMoreSpecialDate w-full flex justify-content-between gap-2 flex-wrap sm:flex-nowrap">
									<div class="flex flex-column w-full gap-2">
										<label htmlFor="email">Email</label>
										<input pInputText type="text" required [(ngModel)]="email"
											[ngClass]="{'ng-invalid ng-dirty' : submittedEmailNotification && !emailValidated}">
									</div>
									<div class="flex justify-content-between align-items-end w-min">
										<button pButton pRipple type="button" icon="pi pi-check"
											class="p-button-rounded p-button-text"
											(click)="addMoreEmailNotification()"></button>
										<button pButton pRipple type="button" icon="pi pi-times"
											class="p-button-rounded p-button-text"
											(click)="cancelAddMoreEmailNotification()"></button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="flex justify-content-start align-items-center gap-2">
							<i class="pi pi-cog" style="font-size: 20px;"></i>
							<h6>{{
								'personal_area.returncountryconfiguration' |
								translate }}</h6>
						</div>
						<div class="mt-3">
							<div class="field" *ngIf="isAdmin">
								<p-table #dt1 [value]="countries" dataKey="country_id" [rowHover]="true" editMode="row"
									[paginator]="false" responsiveLayout="stack" class="simple-table">
									<ng-template pTemplate="header">
										<tr>
											<th>{{ 'personal_area.country_name' | translate }}</th>
											<th *ngIf="acceptExchange">{{ 'personal_area.exchange' | translate }}</th>
											<th *ngIf="accept2Hand">{{ 'personal_area.2hand' | translate }}</th>
											<th *ngIf="acceptrepair">{{ 'personal_area.repair' | translate }}</th>
											<th>{{ 'personal_area.label_auto' | translate }}</th>
											<th>{{ 'personal_area.carrier' | translate }}</th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-country let-rowIndex="rowIndex">
										<tr>
											<td>
												<img src="assets/demo/flags/flag_placeholder.png"
													[class]="'flag flag-' + country.country_code" width="30">
												{{country.country_name}}
											</td>
											<td *ngIf="acceptExchange">
												<span class="p-column-title"><b>{{ 'personal_area.exchange' | translate
														}}</b></span>
												<p-checkbox name="group2" value="country.country_id"
													[value]="country.country_id" [(ngModel)]="exchangeCountries"
													[inputId]="country.country_id">
												</p-checkbox>
											</td>
											<td *ngIf="accept2Hand && permission2Hand">
												<span class="p-column-title"><b>{{ 'personal_area.2hand' | translate
														}}</b></span>
												<p-checkbox name="group2" value="country.country_id"
													[value]="country.country_id" [(ngModel)]="_2handCountries"
													[inputId]="country.country_id">
												</p-checkbox>
											</td>
											<td *ngIf="acceptrepair && permissionRepair">
												<span class="p-column-title"><b>{{ 'personal_area.repair' | translate
														}}</b></span>
												<p-checkbox name="group2" value="country.country_id"
													[value]="country.country_id" [(ngModel)]="repairCountries"
													[inputId]="country.country_id">
												</p-checkbox>
											</td>
											<td>
												<span class="p-column-title"><b>{{ 'personal_area.label_auto' |
														translate }}</b></span>
												<p-checkbox name="group2" value="country.country_id"
													[value]="country.country_id" [(ngModel)]="labelAutoCountries"
													[inputId]="country.country_id"
													(click)="country.disabledCarrier = !country.disabledCarrier">
												</p-checkbox>
											</td>
											<td class="py-2">
												<p-dropdown appendTo="body" [options]="carriers"
													[(ngModel)]="countries_carriers[country.country_id]"
													placeholder="Select carrier" optionLabel="description"
													[showClear]="false" [disabled]="country.disabledCarrier" required
													class="table-stack-w100"
													[style]="{'width':'200px', 'maxWidth':'100%'}"
													[ngClass]="{'ng-invalid ng-dirty' : !countries_carriers[country.country_id]}">
												</p-dropdown>
											</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="8">{{ 'general.no_data' | translate }}</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="loadingbody">
										<tr>
											<td colspan="8">{{ 'general.loading' | translate }}</td>
										</tr>
									</ng-template>
								</p-table>
							</div>
							<div class="field" *ngIf="!isAdmin">
								<p-table #dt1 [value]="countries" dataKey="country_id" [rowHover]="true"
									[paginator]="false" responsiveLayout="stack">
									<ng-template pTemplate="header">
										<tr>
											<th pSortableColumn="country_name">
												<div class="flex align-items-center">
													{{ 'personal_area.country_name' | translate }}
													<p-sortIcon field="country_name"></p-sortIcon>
												</div>
											</th>
											<th pSortableColumn="disabledExchange">
												<div class="flex align-items-center">
													{{ 'personal_area.exchange' | translate
													}}<p-sortIcon field="disabledExchange"></p-sortIcon>
												</div>
											</th>
											<th pSortableColumn="disabledCarrier">
												<div class="flex align-items-center">
													{{ 'personal_area.label' | translate }}
													<p-sortIcon field="disabledCarrier"></p-sortIcon>
												</div>
											</th>
											<th>{{ 'personal_area.carrier' | translate }}</th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-country let-rowIndex="rowIndex">
										<tr>
											<td>
												<img src="assets/demo/flags/flag_placeholder.png"
													[class]="'flag flag-' + country.country_code" width="30">
												{{country.country_name}}
											</td>
											<td>
												<span class="p-column-title"><b>{{ 'personal_area.exchange' | translate
														}}</b></span>
												<div *ngIf="country.disabledExchange"
													class="wmsReturnStatus status-notapproved">
													{{ 'personal_area.not_accept' | translate }}
												</div>
												<div *ngIf="!country.disabledExchange"
													class="wmsReturnStatus status-approved">
													{{ 'personal_area.accept' | translate }}
												</div>
											</td>
											<td>
												<span class="p-column-title"><b>{{ 'personal_area.label' | translate
														}}</b></span>
												<div *ngIf="country.disabledCarrier"
													class="wmsReturnStatus status-notapproved">
													{{ 'personal_area.manually' | translate }}
												</div>
												<div *ngIf="!country.disabledCarrier"
													class="wmsReturnStatus status-approved">
													{{ 'personal_area.auto' | translate }}
												</div>
											</td>
											<td>
												<span class="p-column-title"><b>{{ 'personal_area.carrier' | translate
														}}</b></span>
												{{countries_carriers[country.country_id]?.description}}
											</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="8">{{ 'general.no_data' | translate }}</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="loadingbody">
										<tr>
											<td colspan="8">{{ 'general.loading' | translate }}</td>
										</tr>
									</ng-template>
								</p-table>
							</div>
						</div>
						<div class="w-full flex justify-content-end">
							<button *ngIf="isAdmin" pButton pRipple label="{{ 'general.save' | translate }}"
								type="submit" icon="pi pi-check" class="p-button-raised"
								(click)="onSubmitCountriesConfiguration()"></button>
						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="Header IIII" *ngIf="permissionReturn && accepreturn && [1, 4].includes(id_brand)">
				<ng-template pTemplate="header" class="white-space-nowrap">
					<span>{{ 'personal_area.returns_email' | translate }}</span>
				</ng-template>
				<p-toast key="tst2" [baseZIndex]="99999"></p-toast>
				<div class="card">
					<div class="flex justify-content-start align-items-center gap-2">
						<i class="pi pi-palette" style="font-size: 20px;"></i>
						<h6>{{ 'personal_area.email_setup' | translate }}</h6>
					</div>
					<div class="mt-3">
						<p-tabView [(activeIndex)]="activeTabIndex">
							<p-tabPanel header="{{ 'personal_area.refunds' | translate }}">
								<p-tabView>
									<p-tabPanel
										header="{{ 'personal_area.email_template_success_return' | translate }}">
										<angular-editor [placeholder]="'Enter text here...'"
											[(ngModel)]="email_template_success_return"
											[config]="config"></angular-editor>
									</p-tabPanel>
									<p-tabPanel header="{{ 'personal_area.email_template_label' | translate }}">
										<angular-editor [placeholder]="'Enter text here...'"
											[(ngModel)]="email_template_label" [config]="config"></angular-editor>
									</p-tabPanel>
								</p-tabView>
							</p-tabPanel>
							<p-tabPanel header="{{ 'personal_area.exchanges' | translate }}">
								<p-tabView>
									<p-tabPanel
										header="{{ 'personal_area.email_template_success_return' | translate }}">
										<angular-editor [placeholder]="'Enter text here...'"
											[(ngModel)]="email_template_success_exchange"
											[config]="config"></angular-editor>
									</p-tabPanel>
									<p-tabPanel header="{{ 'personal_area.email_template_label' | translate }}">
										<angular-editor [placeholder]="'Enter text here...'"
											[(ngModel)]="email_template_label_exchange"
											[config]="config"></angular-editor>
									</p-tabPanel>
								</p-tabView>
							</p-tabPanel>
							<p-tabPanel header="{{ 'personal_area.store_credit' | translate }}">
								<p-tabView>
									<p-tabPanel
										header="{{ 'personal_area.email_template_success_return' | translate }}">
										<angular-editor [placeholder]="'Enter text here...'"
											[(ngModel)]="email_template_success_credit"
											[config]="config"></angular-editor>
									</p-tabPanel>
									<p-tabPanel header="{{ 'personal_area.email_template_label' | translate }}">
										<angular-editor [placeholder]="'Enter text here...'"
											[(ngModel)]="email_template_label_credit"
											[config]="config"></angular-editor>
									</p-tabPanel>
								</p-tabView>
							</p-tabPanel>
						</p-tabView>
					</div>
					<div class="w-full flex justify-content-end">
						<button pButton pRipple label="{{ 'general.save' | translate }}" type="submit"
							icon="pi pi-check" class="p-button-raised" (click)="onSubmitEmails()"></button>
					</div>
				</div>
			</p-tabPanel>

			<p-tabPanel #painelRepairs header="Header IIIII" *ngIf="acceptrepair && permissionRepair" header="Repairs">
				<ng-template pTemplate="header">
					<span>{{ 'orders.repairs.repairs' | translate }}</span>
				</ng-template>
				<p-toast key="tst2" [baseZIndex]="99999"></p-toast>
				<div class="card">
					<div *ngIf="!loadingRepairs" class=" flex flex-wrap mb-4"
						style="justify-content: space-between; align-items: center;">
						<div class="flex justify-content-start align-items-center gap-2">
							<i class="pi pi-tags" style="font-size: 20px;"></i>
							<h6>{{ 'orders.repairs.services' | translate }}</h6>
						</div>
						<button pButton pRipple label="{{ 'orders.repairs.sync_tags' | translate }}"
							class="p-button-secundary " (click)="getTags_(true)">
							<span class="p-button-icon p-button-icon-left flex pi pi-refresh"></span>
						</button>
					</div>
					<div *ngIf="loadingRepairs" class="flex justify-content-center align-items-center min-h-screen">
						<div class="loading"></div>
						<span
							style="font-size: 15px; color: #6c757d; font-weight: 700; position: absolute; margin-top: -170px; margin-right: -43px;">{{
							'orders.repairs.loading_tags' | translate }}...</span>
					</div>
					<div *ngIf="!loadingRepairs" class="field">
						<div class="flex flex-column gap-2">
							<p-table [loading]="loadingRepairs" [value]="repair" [rowHover]="true" editMode="row"
								[paginator]="false" responsiveLayout="stack" [breakpoint]="'100px'">
								<ng-template pTemplate="header">
									<tr>
										<th>{{ 'orders.repairs.product_tag' | translate }}</th>
										<th>{{ 'orders.repairs.service_description' | translate }}</th>
										<th>{{ 'orders.repairs.price' | translate }} €</th>
										<th>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-data>
									<tr>
										<td> {{data.tags}}
										</td>
										<td>
											{{data.repairService}}
										</td>
										<td>
											{{data.repairPrice}}
										</td>
										<td style="width: 4rem;">
											<button style="padding-right: 20px;" *ngIf="!editing" pButton pRipple
												type="button" pInitDeleteRow icon="pi pi-trash"
												(click)="deleteRepairs(data)"
												class="p-button-rounded p-button-text"></button>
										</td>
									</tr>

								</ng-template>
								<ng-template pTemplate="emptymessage">
									<tr>
										<td colspan="8">{{ 'general.no_data' | translate }}</td>
									</tr>
								</ng-template>

							</p-table>
						</div>

						<p-button (onClick)="addSRepairs = true" icon="pi pi-plus"
							class="button-add-more flex w-full justify-content-center mb-5 mt-2"
							label="{{ 'orders.repairs.add_new_service' | translate }}"></p-button>

						<div *ngIf="addSRepairs">
							<div class=" flex flex-wrap">
								<div class=" col-12 sm:col-4 ">
									<p-dropdown appendTo="body" [virtualScroll]="true" [filter]="true"
										[showClear]="true" filterBy="description" [options]="statusProduct"
										[(ngModel)]="statusProductAux"
										placeholder="{{ 'orders.repairs.Select_one_product_tag' | translate }}"
										optionLabel="description" [showClear]="false" class="table-stack-w100"
										[style]="{'width': '-webkit-fill-available', 'maxWidth':'100%'}"
										[virtualScroll]="true" [virtualScrollItemSize]="20">
									</p-dropdown>
								</div>
								<div class="col-12 sm:col-4">
									<p-autoComplete #serviceInput [dropdown]="true"
										(completeMethod)="searchServices($event)" [suggestions]="servicesAutocomplete"
										type="Text" placeholder="{{ 'orders.repairs.service_description' | translate }}"
										[(ngModel)]="repairService" (ngModelChange)="isServiceinputValid($event)"
										[style]="{'width': '-webkit-fill-available', 'maxWidth':'100%'}"></p-autoComplete>
								</div>
								<div class=" col-12 md:col-3 sm:col-4 ">
									<input #priceInput pInputText type="number"
										placeholder="{{ 'orders.repairs.price' | translate }} €"
										[(ngModel)]="repairPrice" (ngModelChange)="isPricenputValid($event)" />
								</div>

								<div class=" col-3 sm:col-1 ">
									<div class="flex flex-wrap justify-content-center" style="width: max-content;">
										<button pButton pRipple type="button" icon="pi pi-check"
											class="p-button-rounded p-button-text"
											(click)="addRepairService()"></button>
										<button pButton pRipple type="button" icon="pi pi-times"
											class="p-button-rounded p-button-text"
											(click)="cancelRepairService()"></button>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</p-tabPanel>
			<p-tabPanel header="marketplace" *ngIf="accept2Hand && ( permission2Hand || permissionAdmin2Hand)">
				<ng-template pTemplate="header">
					<span>{{ 'personal_area.marketplace.marketplace' | translate }}</span>
				</ng-template>
				<div *ngIf="loadingAdminMarket" class="flex justify-content-center align-items-center min-h-screen">
					<div class="loading"></div>
				</div>
				<div class="mt-3 flex flex-column gap-3">
					<div *ngIf="!loadingAdminMarket && adminMarket && accept2Hand && permission2Hand" class="flex flex-column md:flex-row gap-3">
						<div class="w-full md:w-7 card">
							<div class="flex justify-content-start align-items-center gap-2">
								<i class="pi pi-id-card" style="font-size: 20px;"></i>
								<h6>{{ 'personal_area.marketplace.general' | translate }}</h6>
							</div>
							<div class="mt-3">
								<div class="col-12 flex gap-3 mb-2">
									<div class="w-full flex-column">
										<h6 for="first_name" class="flex justify-content-start mb-2">{{
											'personal_area.marketplace.first_name' | translate }}</h6>
										<input id="first_name" [(ngModel)]="adminMarket.FirstName" type="text"
											placeholder="{{ 'personal_area.marketplace.first_name' | translate }}"
											pInputText class="p-inputtext w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedAdminMarket && !adminMarket.FirstName}">
										<small *ngIf="submittedAdminMarket && !adminMarket.FirstName"
											class="p-error absolute flex gap-1 mt-1"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.required_field' | translate }}</small>
									</div>
									<div class="w-full flex-column">
										<h6 for="last_name" class="flex justify-content-start mb-2">{{
											'personal_area.marketplace.last_name' | translate }}</h6>
										<input id="last_name" [(ngModel)]="adminMarket.LastName" type="text"
											placeholder="{{ 'personal_area.marketplace.last_name' | translate }}"
											pInputText class="p-inputtext w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedAdminMarket && !adminMarket.LastName}">
										<small *ngIf="submittedAdminMarket && !adminMarket.LastName"
											class="p-error absolute flex gap-1 mt-1"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.required_field' | translate }}</small>
									</div>
								</div>
								<div class="col-12 flex mb-2">
									<div class="w-full flex-column">
										<h6 for="email" class="flex justify-content-start mb-2">{{
											'personal_area.marketplace.email_address' | translate }}</h6>
										<input id="email" [(ngModel)]="adminMarket.EmailAddress" type="text" disabled
											placeholder="{{ 'personal_area.marketplace.email_address' | translate }}"
											pInputText class="p-inputtext w-full">
									</div>
								</div>
							</div>
							<div class="flex w-full justify-content-end">
								<button pButton pRipple label="{{ 'general.save' | translate }}" type="submit"
									icon="pi pi-check" class="p-button-raised" (click)="updateMarketUserAdmin()"
									[disabled]="sucessSubmittedAdminMarket">
								</button>
							</div>
						</div>
						<div class="w-full md:w-5 card">
							<div class="flex justify-content-start align-items-center gap-2">
								<i class="pi pi-key" style="font-size: 20px;"></i>
								<h6>{{ 'personal_area.marketplace.security' | translate }}</h6>
							</div>
							<div class="mt-3">
								<div class="col-12 flex gap-3 mb-2">
									<div class="w-full flex-column">
										<h6 for="current_password" class="flex justify-content-start mb-2">{{
											'personal_area.marketplace.current_password' | translate }}</h6>
										<p-password id="current_password" [(ngModel)]="adminMarket.oldpassword"
											placeholder="{{ 'personal_area.marketplace.current_password' | translate }}"
											[toggleMask]="false" [feedback]="false" styleClass="w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedAdminMarketPassword && !adminMarket.oldpassword}"></p-password>
										<small *ngIf="submittedAdminMarketPassword && !adminMarket.oldpassword"
											class="p-error absolute flex gap-1 mt-1"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.invalid_password' | translate }}</small>
									</div>
								</div>
								<div class="col-12 flex mb-2">
									<div class="w-full flex-column">
										<div class="flex gap-2 justify-content-start mb-2">
											<h6 for="new_password" class="flex">{{
												'personal_area.marketplace.new_password' | translate }}</h6>
											<i class="pi pi-info-circle"
												pTooltip="{{ 'personal_area.marketplace.password_rules' | translate }}"></i>
										</div>
										<p-password id="new_password" [(ngModel)]="adminMarket.password"
											placeholder="{{ 'personal_area.marketplace.new_password' | translate }}"
											[toggleMask]="true" [feedback]="false" styleClass="w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedAdminMarketPassword && !validatePassword(adminMarket.password)}"></p-password>
										<small
											*ngIf="submittedAdminMarketPassword && !validatePassword(adminMarket.password)"
											class="p-error absolute flex gap-1 mt-1"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.invalid_password' | translate }}</small>
									</div>
								</div>
								<div class="col-12 flex mb-2">
									<div class="w-full flex-column">
										<h6 for="confirm_password" class="flex justify-content-start mb-2">{{
											'personal_area.marketplace.confirm_password' | translate }}</h6>
										<p-password id="confirm_password" [(ngModel)]="confirmPassword"
											placeholder="{{ 'personal_area.marketplace.confirm_password' | translate }}"
											[toggleMask]="true" [feedback]="false" styleClass="w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedAdminMarketPassword && (!validatePassword(adminMarket.password) || adminMarket.password !== confirmPassword)}"></p-password>
										<small
											*ngIf="submittedAdminMarketPassword && !validatePassword(adminMarket.password)"
											class="p-error absolute flex gap-1 mt-1"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.invalid_password' | translate }}</small>
										<small
											*ngIf="submittedAdminMarketPassword && validatePassword(adminMarket.password) && adminMarket.password !== confirmPassword"
											class="p-error absolute flex gap-1 mt-1"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.password_not_match' | translate }}</small>
									</div>
								</div>
							</div>
							<div class="flex w-full justify-content-end">
								<button pButton pRipple label="{{ 'general.save' | translate }}" type="submit"
									icon="pi pi-check" class="p-button-raised"
									[disabled]="sucessSubmittedAdminMarketPassword"
									(click)="updateMarketUserAdminPassword()">
								</button>
							</div>
						</div>
						
					</div>
					<div *ngIf="!loadingAdminMarket && adminMarket && accept2Hand && permission2Hand" class="flex flex-column md:flex-row gap-3">
						<div class="w-full md:w-full card">
							<div class="flex justify-content-start align-items-center gap-2">
								<i class="pi pi-images" style="font-size: 20px;"></i>
								<h6>{{ 'personal_area.marketplace.marketplace_images' | translate }}</h6>
							</div>
							<div class="flex justify-content-end align-items-center gap-2">
								<p-fileUpload #backupload mode="basic" name="myfile[]" accept="image/*"
										customUpload="true" [auto]="true" class="upload-images"
										chooseIcon="pi pi-upload"
										(uploadHandler)="postMarketplacePhoto($event, backupload)"
										chooseLabel="{{ 'personal_area.marketplace.upload_image' | translate }}">
								</p-fileUpload>
							</div>
							<div class="mt-3">
								<div class="col-12 flex gap-3 mb-2">
									<p-carousel [value]="adminMarket.bannerPhoto" styleClass="custom-carousel" [numVisible]="1" [numScroll]="1" [circular]="true" [autoplayInterval]="10000" [responsiveOptions]="responsiveOptions">
										<ng-template let-photo pTemplate="item">
											<div class="photo-item">
												<div class="photo-item-content">
													<div class="mb-3">
														<img [src]="photo.photo_link" class="photo-image" />
													</div>
													<div>
														<div class="car-buttons mt-5">
															<p-button type="button" styleClass="p-button-help p-button-rounded" icon="pi pi-times" (click)="deleteMarketplacePhoto(photo.photo_link)"></p-button>
														</div>
													</div>
												</div>
											</div>
										</ng-template>
									</p-carousel>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="!loadingAdminMarket && !adminMarket?.FirstName && !adminMarket?.EmailAddress && permission2Hand && accept2Hand"
						class="flex flex-column md:flex-row gap-3">
						<div class="w-full card">
							<div class="flex justify-content-start align-items-center gap-2">
								<i class="pi pi-user-plus" style="font-size: 20px;"></i>
								<h6>{{ 'personal_area.marketplace.create_account' | translate }}</h6>
							</div>
							<div class="mt-3">
								<div class="col-12 flex gap-3 mb-2">
									<div class="w-full flex-column mb-2">
										<h6 for="first_name" class="flex justify-content-start mb-2">{{
											'personal_area.marketplace.first_name' | translate }}</h6>
										<input id="first_name" [(ngModel)]="defaultAdmin.FirstName" type="text"
											placeholder="First name" pInputText class="p-inputtext w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedAdminMarket && !defaultAdmin.FirstName}">
										<small *ngIf="submittedAdminMarket && !defaultAdmin?.FirstName"
											class="p-error absolute flex gap-1 mt-2"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.required_field' | translate }}</small>
									</div>
									<div class="w-full flex-column mb-2">
										<h6 for="last_name" class="flex justify-content-start mb-2">{{
											'personal_area.marketplace.last_name' | translate }}</h6>
										<input id="last_name" [(ngModel)]="defaultAdmin.LastName" type="text"
											placeholder="{{ 'personal_area.marketplace.last_name' | translate }}"
											pInputText class="p-inputtext w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedAdminMarket && !defaultAdmin.LastName}">
										<small *ngIf="submittedAdminMarket && !defaultAdmin?.LastName"
											class="p-error absolute flex gap-1 mt-2"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.required_field' | translate }}</small>
									</div>
								</div>
								<div class="col-12 flex mb-2">
									<div class="w-full flex-column mb-2">
										<h6 for="email" class="flex justify-content-start mb-2">{{
											'personal_area.marketplace.email_address' | translate }}</h6>
										<input id="email" [(ngModel)]="defaultAdmin.Email_address" type="email"
											placeholder="{{'personal_area.marketplace.email_address' | translate }}"
											pInputText class="p-inputtext w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedAdminMarket && (!defaultAdmin.Email_address || defaultAdmin.Email_address && !validateEmail(defaultAdmin.Email_address))}">
										<small *ngIf="submittedAdminMarket && !defaultAdmin.Email_address"
											class="p-error absolute flex gap-1 mt-2"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.required_field' | translate }}</small>
										<small
											*ngIf="submittedAdminMarket && defaultAdmin.Email_address && !validateEmail(defaultAdmin.Email_address)"
											class="p-error absolute flex gap-1 mt-2"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.invalid_email' | translate }}</small>
									</div>
								</div>
								<div class="col-12 flex mb-2">
									<div class="w-full flex-column mb-3">
										<div class="flex gap-2 justify-content-start mb-2">
											<h6 for="new_password" class="flex">{{
												'personal_area.marketplace.password' | translate }}</h6>
											<i class="pi pi-info-circle"
												pTooltip="{{ 'personal_area.marketplace.password_rules' | translate }}"></i>
										</div>
										<p-password id="new_password" [(ngModel)]="defaultAdmin.password"
											placeholder="{{ 'personal_area.marketplace.password' | translate }}"
											[toggleMask]="true" [feedback]="false" styleClass="w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedAdminMarket && !validatePassword(defaultAdmin.password)}"></p-password>
										<small *ngIf="submittedAdminMarket && !validatePassword(defaultAdmin.password)"
											class="p-error absolute flex gap-1 mt-2"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.invalid_password' | translate }}</small>
									</div>
								</div>
								<div class="col-12 flex mb-2">
									<div class="w-full flex-column mb-3">
										<h6 for="confirm_password" class="flex justify-content-start mb-2">{{
											'personal_area.marketplace.confirm_password' | translate }}</h6>
										<p-password id="confirm_password" [(ngModel)]="confirmPassword"
											placeholder="{{ 'personal_area.marketplace.confirm_password' | translate }}"
											[toggleMask]="true" [feedback]="false" styleClass="w-full"
											[ngClass]="{'ng-invalid ng-dirty' : submittedAdminMarket && (!validatePassword(defaultAdmin.password) || defaultAdmin.password !== confirmPassword)}"></p-password>
										<small *ngIf="submittedAdminMarket && !validatePassword(defaultAdmin.password)"
											class="p-error absolute flex gap-1 mt-2"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.invalid_password' | translate }}</small>
										<small
											*ngIf="submittedAdminMarket && validatePassword(defaultAdmin.password) && defaultAdmin.password !== confirmPassword"
											class="p-error absolute flex gap-1 mt-2"><img
												src="assets/layout/images/error-icon.svg">{{
											'personal_area.marketplace.password_not_match' | translate }}</small>
									</div>
								</div>
							</div>
							<div class="flex w-full justify-content-end">
								<button pButton pRipple label="{{ 'general.save' | translate }}" type="submit"
									icon="pi pi-check" class="p-button-raised" (click)="createMarketUserAdmin()"
									[disabled]="sucessSubmittedAdminMarket">
								</button>
							</div>
						</div>
					</div>
					<div *ngIf="permissionAdmin2Hand && accept2Hand" class="w-full flex flex-column md:flex-row gap-3">
						<div class="w-full md:w-6 card">
							<div class="flex justify-content-start align-items-center gap-2">
								<i class="pi pi-check-circle" style="font-size: 20px;"></i>
								<h6>{{ 'personal_area.marketplace.auto_approve_items' | translate }}</h6>
							</div>
							<div class="mt-3">
								<div class="col-12 flex gap-3 justify-content-between align-items-center">
									<h6 for="confirm_password" class="flex justify-content-start">{{
										'personal_area.marketplace.approve_items_automatically' | translate }}</h6>
									<p-selectButton [options]="autoApproveOptions" class="w-max"
										[(ngModel)]="marketplaceOptions['2hand_auto_approve']" optionLabel="label"
										optionValue="value" (onChange)="updateMarketplaceOptions()"></p-selectButton>
								</div>
							</div>
						</div>
						<div class="w-full md:w-6 card">
							<div class="flex justify-content-start align-items-center gap-2">
								<i class="pi pi-shopping-cart" style="font-size: 20px;"></i>
								<h6>{{ 'personal_area.marketplace.checkout_fees' | translate }}</h6>
							</div>
							<div class="mt-3">
								<div class="col-12 flex gap-3 justify-content-between align-items-center">
									<div class="flex align-items-center gap-1">
										<h6 for="confirm_password" class="flex justify-content-start">{{
											'personal_area.marketplace.processing_fee' | translate }}</h6>
										<i class="pi pi-question-circle"
											pTooltip="{{ 'personal_area.marketplace.pf_content' | translate: {pf_price: marketplaceOptions.processing_percentage ? marketplaceOptions.processing_percentage + '% ' : marketplaceOptions.processing_value + 'EUR '} }}"></i>
									</div>
									<p-selectButton [options]="processingFeeOptions"
										[(ngModel)]="marketplaceOptions['2hand_processing_fee']" optionLabel="label"
										optionValue="value" (onChange)="updateMarketplaceOptions()"></p-selectButton>
								</div>
								<div class="col-12 flex gap-3 justify-content-between align-items-center">
									<div class="flex align-items-center gap-1">
										<h6 for="confirm_password" class="flex justify-content-start">{{
											'personal_area.marketplace.verify_fee' | translate }}</h6>
										<i class="pi pi-question-circle"
											pTooltip="{{ 'personal_area.marketplace.vf_content' | translate: {vf_price: marketplaceOptions.verify_percentage ? marketplaceOptions.verify_percentage + '% ' : marketplaceOptions.verify_value + 'EUR '} }}"></i>
									</div>
									<p-selectButton [options]="verifyFeeOptions"
										[(ngModel)]="marketplaceOptions['2hand_verify_fee']" optionLabel="label"
										optionValue="value" (onChange)="updateMarketplaceOptions()"></p-selectButton>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="w-full card">
						<div class="flex justify-content-start align-items-center gap-2">
							<i class="pi pi-wallet" style="font-size: 20px;"></i>
							<h6>{{ 'personal_area.marketplace.checkout_fees' | translate }}</h6>
						</div>
						<div class="mt-3">
							<div class="col-12 flex gap-3 justify-content-between align-items-center">
								<div class="flex align-items-center gap-1">
									<h6 for="confirm_password" class="flex justify-content-start">{{
										'personal_area.marketplace.processing_fee' | translate }}</h6>
								</div>
								<p-selectButton [options]="walletOptions"
									[(ngModel)]="marketplaceOptions['2hand_processing_fee']" optionLabel="label"
									optionValue="value" (onChange)="updateMarketplaceOptions()"></p-selectButton>
							</div>
						</div>
					</div> -->
				</div>
			</p-tabPanel>
		</p-tabView>
	</p-sidebar>
</div>
<p-dialog [(visible)]="deleteDialogSpecialDate" header="Confirm" [modal]="true" [draggable]="false" [resizable]="false"
	[style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-center">
		<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
		<span>{{ 'reception.delete_line' | translate }} <b>{{specialDate?.special_date}}</b>?</span>
	</div>
	<ng-template pTemplate="footer">
		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="{{ 'primeng.reject' | translate }}"
			(click)="deleteDialogSpecialDate = false"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="{{ 'primeng.accept' | translate }}"
			(click)="confirmDeleteSpecialDate()"></button>
	</ng-template>
</p-dialog>
<p-dialog [(visible)]="deleteDialogEmailNotification" header="Confirm" [modal]="true" [draggable]="false"
	[resizable]="false" [style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-center">
		<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
		<span>{{ 'reception.delete_line' | translate }} <b>{{emailNotification?.email}}</b>?</span>
	</div>
	<ng-template pTemplate="footer">
		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="{{ 'primeng.reject' | translate }}"
			(click)="deleteDialogEmailNotification = false"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="{{ 'primeng.accept' | translate }}"
			(click)="confirmDeleteEmailNotification()"></button>
	</ng-template>
</p-dialog>