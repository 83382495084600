<div class="layout-menu-container">
    <ul class="layout-menu" role="menu" (keydown)="onKeydown($event)">
        <li pages-menu class="layout-menuitem-category" *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true" role="none">
            <div *ngIf="item.permission">
                <div class="layout-menuitem-root-text" [attr.aria-label]="item.label">{{item.label}}</div>
                <ul role="menu">
                    <li pages-menuitem *ngFor="let child of item.items"  [item]="child" [index]="i" role="none"></li>
                </ul>
            </div>
        </li>
    </ul>
</div>
