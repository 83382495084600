<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<div *ngIf="!loading" class="grid m-4">
	<!-- <div class="col-12 md:col-12">
		<div class="card-shadow h-full flex flex-column gap-3">
			<a *ngIf="totalPending > 0" href="#/main/returns">
				<div class="w-full wmsReturnStatus status-warning">
					{{ 'orders.returns.pending_returns_count' | translate: {totalPending:totalPending} }}
				</div>
			</a>
			<div class="mb-0">
				<p> {{ 'orders.orders.orders_between' | translate }}:</p>
				<div class="flex gap-3 align-items-center">
					<p-calendar [showIcon]="true" [(ngModel)]="rangeDates" selectionMode="range" dateFormat="dd/mm/yy"
						(onClose)="ngOnInit()" [minDate]="minimumDate"></p-calendar>
					<div class="flex gap-1 align-items-center">
						<p-checkbox [(ngModel)]="checked" [binary]="true" inputId="binary"
							(onChange)="ngOnInit()"></p-checkbox>
						<label for="binary">{{ 'orders.returns.only_finalized' | translate }}</label>
					</div>
				</div>
			</div>
		</div>
	</div> -->
	<div class="col-12 md:col-6">
		<div class="card-shadow">
			<div class="flex flex-wrap justify-content-between mb-3">
				<h5 class="mb-3">{{ 'recycle.dash.most_brands' | translate }}</h5>
				<div class="bg-pink-100 border-round flex align-items-center justify-content-center"
					style="width: 2.5rem; height: 2.5rem;">
					<i class="pi pi-exclamation-circle text-pink-500 text-xl"></i>
				</div>
			</div>
			<div class="flex flex-column align-items-center justify-content-center"
				style="width: 100% !important; height: 100%;">
				<p-chart type="pie" [data]="pieDataBrands" height="350px"
					[style]="{'width': '90%', 'maxWidth': '350px'}" responsive="true"></p-chart>
			</div>

		</div>
	</div>
	<div class="col-12 md:col-6">
		<div class="card-shadow">
			<div class="flex flex-wrap justify-content-between mb-3">
				<h5 class="mb-3">{{ 'recycle.dash.what_they_do' | translate }}</h5>
				<div class="bg-pink-100 border-round flex align-items-center justify-content-center"
					style="width: 2.5rem; height: 2.5rem;">
					<i class="pi pi-exclamation-circle text-pink-500 text-xl"></i>
				</div>
			</div>
			<div class="flex flex-column align-items-center justify-content-center"
				style="width: 100% !important; height: 100%;">
				<p-chart type="pie" [data]="pieDataMethods" height="350px"
					[style]="{'width': '90%', 'maxWidth': '350px'}" responsive="true"></p-chart>
			</div>

		</div>
	</div>
</div>