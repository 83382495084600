import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/service/app.config.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BrandService } from 'src/app/services/brands/brand.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { TokenStorageService } from 'src/app/services/token-storage/token-storage.service';

@Component({
  selector: 'pages-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.sass']
})

export class InviteComponent implements OnInit {

  invite_code: any = '';

  dataLogin: any = { email: '', password: '', invite_code: '' };

  dataRegisterLogin: any = { password: '', invite_code: '' };

  submittedRegister: boolean = false;

  submittedLogin: boolean = false;

  errorLogin: boolean = false;

  errorRegister: boolean = false;

  errorLoginMessage: any;

  errorRegisterMessage: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    public configService: ConfigService,
    private permissions: PermissionsService,
    private brandService: BrandService,
    private readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(paramsId => {
      this.invite_code = paramsId.id;
    });
  }

  submitLoginInvite() {
    this.submittedLogin = true
    if (this.dataLogin.email && this.validateEmail(this.dataLogin.email) && this.dataLogin.password) {
      this.dataLogin.invite_code = this.invite_code
      this.authService
        .loginInvite(this.dataLogin)
        .subscribe((data) => {
          this.permissions.savePermissions(data.User.permissions);

          this.brandService.saveBrand(data.User.id_brand);
          this.translateService.use(data.User.language_code.match(/gb|pt/) ? data.User.language_code : 'gb')
          this.tokenStorage.saveToken(data.User['Bearer Token']);

          window.sessionStorage.setItem("language_code", data.User.language_code);
          window.sessionStorage.setItem("returncenter_url", data.User.returncenter_url);
          window.sessionStorage.setItem("2hand_link", data.User["2hand_link"]);
          window.sessionStorage.setItem("shop_domain", data.User.shop_domain);
          window.sessionStorage.setItem("wms", (data.User.logistic_op != undefined ? "true" : "false"));
          window.sessionStorage.setItem("id_token", data.User.id_token);
          window.sessionStorage.setItem("username", data.User.username);
          window.sessionStorage.setItem("id_user", data.User.id_user);
          window.sessionStorage.setItem("user_details", JSON.stringify(data.User));
          this.router.navigate(['main']);
          this.submittedRegister = false
        },
          error => {
            this.errorLogin = true;
            this.errorLoginMessage = error.error;
          })
    }
  }

  submitRegisterInvite() {
    this.submittedRegister = true
    if (this.dataRegisterLogin.password && this.validatePassword()) {
      this.dataRegisterLogin.invite_code = this.invite_code
      this.authService
        .formInvite(this.dataRegisterLogin)
        .subscribe((data) => {
          this.permissions.savePermissions(data.User.permissions);

          this.brandService.saveBrand(data.User.id_brand);
          this.translateService.use(data.User.language_code.match(/gb|pt/) ? data.User.language_code : 'gb')
          this.tokenStorage.saveToken(data.User['Bearer Token']);

          window.sessionStorage.setItem("language_code", data.User.language_code);
          window.sessionStorage.setItem("returncenter_url", data.User.returncenter_url);
          window.sessionStorage.setItem("2hand_link", data.User["2hand_link"]);
          window.sessionStorage.setItem("shop_domain", data.User.shop_domain);
          window.sessionStorage.setItem("wms", (data.User.logistic_op != undefined ? "true" : "false"));
          window.sessionStorage.setItem("id_token", data.User.id_token);
          window.sessionStorage.setItem("username", data.User.username);
          window.sessionStorage.setItem("id_user", data.User.id_user);
          window.sessionStorage.setItem("user_details", JSON.stringify(data.User));
          this.router.navigate(['main']);
          this.submittedLogin = false
        },
          error => {
            this.errorRegister = true;
            this.errorRegisterMessage = error.error;
          })
    }

  }

  validatePassword() {
    var passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?()/=",.;:_\\-])[A-Za-z\d#$@!%&*?()/=",.;:_\\-]{8,}$/;
    if (passwordRegex.test(this.dataRegisterLogin.password)) {
      return true
    } else {
      return false
    }
  }
  validateEmail(email) {
    var emailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailRegex.test(email)) {
      return true
    } else {
      return false
    }
  }
}
