<div class="surface-0 flex min-h-screen">
    <div class="hidden lg:flex"
        style="flex: 1 1 60%; background-image: url(assets/layout/images/login-img.svg); background-repeat: no-repeat; background-size: cover; background-position: right;">
    </div>
    <div class="flex-column flex align-items-center login-div">
        <div class="w-full h-min flex align-items-end justify-content-end">
            <img src="assets/layout/images/logo.svg" width="180px">
        </div>
        <div class="h-full flex flex-column align-items-start justify-content-center gap-5"
            style="max-width: 300px; width: 100%; min-height: 500px;">
            <h3>Log in to your account</h3>
            <div class="flex flex-column w-full mx-auto">
                <div class="flex-column mb-5">
                    <h6 for="email1" class="flex mb-2">{{ 'general.login.email' | translate }}</h6>
                    <input id="email1" [(ngModel)]="email" type="text" placeholder="Email address" pInputText
                        class="p-inputtext w-full" (keyup.enter)="onSubmit()"
                        [ngClass]="{'ng-invalid ng-dirty' : submitted && !email}">
                    <small *ngIf="submitted && !email" class="p-error absolute flex gap-1 mt-2"><img
                            src="assets/layout/images/error-icon.svg">Required field</small>
                </div>
                <div class="flex-column mb-6">
                    <h6 for="password1" class="flex mb-2">{{ 'general.login.password' | translate }}</h6>
                    <p-password id="password1" [(ngModel)]="password" placeholder="Password" [toggleMask]="true"
                        [feedback]="false" styleClass="w-full" (keyup.enter)="onSubmit()"
                        [ngClass]="{'ng-invalid ng-dirty' : submitted && (!password || password && errorLogin)}"></p-password>
                    <small *ngIf="submitted && !password" class="p-error absolute flex gap-1 mt-2"><img
                            src="assets/layout/images/error-icon.svg">Required field</small>
                    <small *ngIf="submitted && password && errorLogin" class="p-error absolute flex gap-1 mt-2"><img
                            src="assets/layout/images/error-icon.svg">Passwords do not match</small>
                </div>
                <button pButton pRipple (click)="onSubmit()" label="{{ 'general.login.login' | translate }}"
                    class="w-full p-button-secundary"></button>
            </div>
        </div>
    </div>
</div>

<router-outlet></router-outlet>