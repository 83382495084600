import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { BrandService } from '../../services/brands/brand.service';
import { WebhookService } from 'src/app/services/webhook/webhook.service';

import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CountryService } from 'src/app/services/country/country.service';
import { ReturnService } from 'src/app/services/return/return.service';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common'
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { RepairService } from 'src/app/services/repair/repair.service';
import { TabPanel, TabView } from 'primeng/tabview';

@Component({
    selector: 'pages-brands',
    templateUrl: './brands.component.html',
    styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

    pipe = new DatePipe('en-US');

    brands: any[];

    webhooks: any[];

    clonedwebhooks: { [s: string]: any; } = {};

    loading: boolean = true;

    loadingwebhooks: boolean = true;

    loadingRepairs: boolean = false;

    loadingBrandReturn: boolean = true;

    brandJson: any;

    id_brand: any;

    brandObject: any[];

    breadcumbItems: MenuItem[] = [];

    submitted: boolean = false;

    font: string;

    color: string;

    email_template_label: string;

    email_template_success_return: string;

    email_template_label_exchange: string;

    email_template_success_exchange: string;

    email_template_label_credit: string;

    email_template_success_credit: string;

    email_template_warehouse: string;

    limit_days: string;

    secondHanderange: number[] = [10, 90];

    background: string;

    logo: string;

    return_fonts: [];

    noreturn_tags: string[];

    proforma_shipping: boolean = false;

    noexchange_tags: string[];

    selectedFont: any;

    countries: any = [];

    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        // height: '25rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        sanitize: false,
        fonts: [],
        defaultParagraphSeparator: '',
        toolbarHiddenButtons: [
            ['fontName']
        ],
        //uploadUrl: 'v1/images', // if needed
    };

    specialDates: any = []

    selectedSpecialDate: any = { specialDate: null, rangeDates: null, returnLimitDate: null };

    addSpecialDate: boolean = false;

    specialDate: any;

    clonedSpecialDates: { [s: string]: any; } = {};

    deleteDialogSpecialDate = false;

    submittedSpecialDate: boolean = false;

    minDate: any = new Date();

    isAdmin: any = 0;

    carriers: any = [];

    selectedCarriers: any = [];

    emailsNotifications: any = [];

    emailNotification: any;

    addEmailNotification: boolean = false;

    submittedEmailNotification: boolean = false;

    deleteDialogEmailNotification: boolean = false;

    ccEmailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    emailValidated: boolean = false;

    email: any = null;

    exchangeCountries: any = [];

    repairCountries: any = [];

    _2handCountries: any = [];

    labelAutoCountries: any = [];

    countries_carriers: any = [];

    acceptExchange: boolean;

    accept2Hand: boolean;

    acceptrepair: boolean;

    accepreturn: boolean;

    warehouse: any = {
        "company_name": "",
        "name": "",
        "address": "",
        "city": "",
        "zipcode": "",
        "country": "",
        "phone": "",
        "email": ""
    }

    selectedCountry: any;

    countries_warehouse: any = [];

    settingSidebar: boolean = false;

    @ViewChild('dt') table: Table;
    @ViewChild('tv') tabView: TabView;
    
    activeTabIndex: number = 0;

    permissionReturn: boolean;

    permissionRepair: boolean;

    permission2Hand: boolean;

    permissionAdmin2Hand:boolean;

    isClosed: boolean = false;

    statusProduct: any = [];

    statusProductAux: any = [];

    repair: any = []

    repairService: any;

    repairPrice: any;

    isTagArchivedDraft: boolean = false;

    loadingAdminMarket: boolean = true;

    adminMarket: any = null;

    submittedAdminMarket: boolean = false;

    submittedAdminMarketPassword: boolean = false;

    confirmPassword: any = null;

    brandSetup: any = null;

    defaultAdmin: any = { FirstName: '', LastName: '', EmailAddress: '' };

    sucessSubmittedAdminMarket: boolean = false;

    sucessSubmittedAdminMarketPassword: boolean = false;

    marketplaceOptions: any = { '2hand_processing_fee': null, '2hand_verify_fee': null, '2hand_auto_approve': null };

    processingFeeOptions = [{ label: 'Brand', value: 1 }, { label: 'Seller', value: 0 }];

    verifyFeeOptions = [{ label: 'Brand', value: 1 }, { label: 'Buyer', value: 0 }];

    autoApproveOptions = [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }];

    walletOptions = [{ label: 'Discount code', value: 1 }, { label: 'Gift card', value: 0 }];

    marketplacePhoto: any = [];


    @ViewChild('painelRepairs') painelRepairs: TabPanel;

    constructor(
        private brandService: BrandService,
        private countryService: CountryService,
        private returnService: ReturnService,
        private webhookService: WebhookService,
        private messageService: MessageService,
        private router: Router,
        private readonly translateService: TranslateService,
        private location: Location,
        private permissions: PermissionsService,
        private repairService_: RepairService
    ) {

    }

    async ngOnInit() {
        // get if brand accept exchange ann/or 2hand
        var accept = await this.brandService.checkIfBrandAccept();
        this.acceptExchange = Boolean(accept.exchanges);
        this.accept2Hand = Boolean(accept["2hand"]);
        this.acceptrepair = Boolean(accept["repair"]);
        this.accepreturn = Boolean(accept['returns']);
        this.isClosed = false;
        const permissionsType = this.permissions.getPermissionsType();
        this.permissionReturn = this.permissions.checkPermissionsSubmenu(permissionsType.consultReturn);
        this.permissionRepair = this.permissions.checkPermissionsSubmenu(permissionsType.consultRepair);
        this.permission2Hand = this.permissions.checkPermissionsSubmenu(permissionsType.consult2hand);
        this.permissionAdmin2Hand = this.permissions.checkPermissionsSubmenu(permissionsType.consultAdmin2Hand);

        this.email_template_success_return = '';
        this.email_template_label = '';
        this.email_template_success_exchange = '';
        this.email_template_label_exchange = '';
        this.email_template_success_credit = '';
        this.email_template_label_credit = '';

        //load data to translate
        this.settingSidebar = true;

        await this.translateService.get('dummyTranslation').toPromise().then();

        this.translateService.stream('general.menu').subscribe(val => {
            this.breadcumbItems = [
                { label: '', icon: 'pi pi-home' },
                { label: val.personal_area.value },
                { label: val.personal_area.items.brand_details }
            ]
        });

        this.autoApproveOptions = [{ label: this.translateService.instant("general.yes"), value: 1 }, { label: this.translateService.instant("general.no"), value: 0 }];
        this.id_brand = this.brandService.getBrand();

        await this.countryService.getCountries().then(countries => {
            this.countries_warehouse = countries;
        })

        await this.brandService.getBrandById(this.id_brand).then(brand => {
            {
                let brandJson = JSON.stringify(brand.result);
                let brandObject = JSON.parse(brandJson);

                let date = new Date(brandObject.created_at).getTime();
                var created_at = new Date(date).toLocaleString();

                this.brands = [
                    {
                        id_brand: brandObject.id_brand,
                        brand_name: brandObject.brand_name,
                        created_at: created_at,
                        shopify_api: brandObject.shopify_api,
                        woocommerce: brandObject.woocommerce,
                        wms_id: brandObject.wms_id,
                        wms_account: brandObject.wms_account,
                        dynamics_id: brandObject.dynamics_id,
                        endpoint: brandObject.endpoint,
                        active: brandObject.active
                    }
                ];

                this.warehouse = {
                    "company_name": brandObject.company_name,
                    "name": brandObject.name,
                    "address": brandObject.address,
                    "city": brandObject.city,
                    "zipcode": brandObject.zipcode,
                    "country": brandObject.country,
                    "phone": brandObject.phone,
                    "email": brandObject.email,
                    "eori": brandObject.eori,
                    "uk_vat": brandObject.uk_vat
                }

                this.selectedCountry = brandObject.country;
            }
        });



        // change loading view
        this.loading = false;

        this.loadWebhooks(); // load webhook data
        this.loadbrandReturns(); // load brand_return data
    }

    itemClicked(event) {
        if (event.item.label === '') {
            this.router.navigate(['main']);
        }
    }

    ngOnDestroy(): void {
        this.messageService.clear();
    }

    async loadWebhooks() {
        await this.webhookService.getWebhooks().then(webhookslist => {
            {
                this.webhooks = webhookslist;
            }
        }).catch((error) => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: error.error.Error });
        });

        this.loadingwebhooks = false;
    }

    async loadbrandReturns() {
        await this.brandService.getIsAdminBrand().then(admin => {
            this.isAdmin = admin.isAdmin;
        });

        // get return free fonts
        await this.brandService.getBrandReturnFonts().then(brandResultFontsList => {
            this.return_fonts = brandResultFontsList;
        }).catch((error) => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: error.error.Error });
        });

        await this.brandService.getBrandReturn().then(brandResultlist => {
            {
                if (brandResultlist.length > 0) {
                    this.brandSetup = brandResultlist[0]
                    this.color = brandResultlist[0].color;
                    this.font = brandResultlist[0].font_family;
                    this.selectedFont = brandResultlist[0].font_family ? brandResultlist[0].font_family : null;
                    this.email_template_label = brandResultlist[0].email_template_label;
                    this.email_template_success_return = brandResultlist[0].email_template_success_return;
                    this.email_template_label_exchange = brandResultlist[0].email_template_label_exchange;
                    this.email_template_success_exchange = brandResultlist[0].email_template_success_exchange;
                    this.email_template_label_credit = brandResultlist[0].email_template_label_credit;
                    this.email_template_success_credit = brandResultlist[0].email_template_success_credit;
                    this.email_template_warehouse = brandResultlist[0].email_template_warehouse;
                    this.limit_days = brandResultlist[0].return_days_limit;
                    this.noreturn_tags = brandResultlist[0].noreturn_tag == undefined || brandResultlist[0].noreturn_tag == "" ? [] : brandResultlist[0].noreturn_tag.split("|");
                    this.noexchange_tags = brandResultlist[0].noexchange_tag == undefined || brandResultlist[0].noexchange_tag == "" ? [] : brandResultlist[0].noexchange_tag.split("|");
                    this.secondHanderange = [brandResultlist[0]["2hand_max_price"], brandResultlist[0]["2hand_min_price"]];
                    this.proforma_shipping = brandResultlist[0].proforma_shipping == 0 ? false : true;
                }
            }
        }).catch((error) => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: error.error.Error });
        });

        this.getReturnSpecialDates()
        this.emailsNotifications = await this.brandService.getNotificationEmailsByBrand();

        this.carriers = await this.brandService.getCarriersByBrand();

        await this.countryService.getCountries().then(countries => {
            this.countries = countries
            this.countries.map(country => {
                country.disabledCarrier = true
                country.disabledExchange = true
                country.country_code = country.country_code.toLowerCase();
                this.selectedCarriers[country.country_id] = null
            })
        })

        await this.brandService.getCountryAndCarriersByBrand().then(countries_carriers => {
            countries_carriers.map(country_carrier => {
                this.countries_carriers[country_carrier.id_country] = { id_carrier: country_carrier.id_carrier, description: country_carrier.description }
            })
        })

        if (this.permissionReturn && this.accepreturn)
            await this.returnService.getAllCountriesReturnLabelAuto().then(data => {
                var labelAutoCountries = []
                data.map(country => {
                    labelAutoCountries.push(country.country_id)
                    this.countries.find(c => c.country_id == country.country_id).disabledCarrier = false
                })
                this.labelAutoCountries = labelAutoCountries
            })

        // get exchange countries
        if (this.acceptExchange)
            await this.returnService.getAllCountriesReturnExchange().then(data => {
                var exchangeCountries = []
                data.map(country => {
                    exchangeCountries.push(country.country_id)
                    this.countries.find(c => c.country_id == country.country_id).disabledExchange = false
                })
                this.exchangeCountries = exchangeCountries
            })

        // get 2hand  -> _2handCountries
        if (this.accept2Hand && this.permission2Hand)
            await this.returnService.getAllCountriesReturn2Hand().then(data => {
                var _2handCountries = []
                data.map(country => {
                    _2handCountries.push(country.country_id)
                    this.countries.find(c => c.country_id == country.country_id).disabledExchange = false
                })
                this._2handCountries = _2handCountries
            })

        // get repair countries
        if (this.acceptrepair && this.permissionReturn)
            await this.returnService.getAllCountriesReturnRepair().then(data => {
                var repairCountries = []
                data.map(country => {
                    repairCountries.push(country.country_id)
                    this.countries.find(c => c.country_id == country.country_id).disabledExchange = false
                })
                this.repairCountries = repairCountries
            })

        this.loadingBrandReturn = false;
    }




    async changeWebhookSatus(item: any) {
        //this.loadingwebhooks = true;

        if (item.flag) {
            await this.webhookService.deleteWebhooks(item).then(webhookslist => {
                {
                    this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.uninstall_webhook") });
                    this.loadWebhooks(); // reload webhook data   
                }
            }).catch((error) => {
                this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.error_uninstall_webhook") });
            });
        }
        else {
            await this.webhookService.postWebhooks(item).then(webhookslist => {
                {
                    this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.install_webhook") });
                    this.loadWebhooks(); // reload webhook data      
                }
            }).catch((error) => {
                this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.error_install_webhook") });
            });
        }
    }


    async onEditComplete(item: any) {
        await this.webhookService.putWebhooks(item.data).then(webhookslist => {
            {
                this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.alter_webhook") });
                this.loadWebhooks(); // reload webhook data       
            }
        }).catch((error) => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.error_alter_webhook") });
        });
    }

    async onSubmitPageSetup() {
        console.log(this.brandSetup)
        let data = {
            color: this.color,
            font: this.selectedFont == " " ? undefined : this.selectedFont,
            email_template_label: this.email_template_label,
            email_template_success_return: this.email_template_success_return,
            email_template_warehouse: this.email_template_warehouse,
            limit_days: this.limit_days,
            background: this.brandSetup.background,
            logo: this.brandSetup.logo,
            noreturn_tag: this.noreturn_tags.join("|"),
            noexchange_tag: this.noexchange_tags.join("|"),
            secondHanderange: this.secondHanderange,
            proforma_shipping: (this.proforma_shipping == true ? 1 : 0)
        }

        await this.brandService.putBrandReturn(data).then(async brandReturn => {
            {
                this.messageService.add({ key: 'tst2', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.brand_return_sucess") });
                this.loadbrandReturns(); // reload data 
            }
        }).catch((error) => {
            this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.brand_return_error") });
        });
    }

    async onSubmitCountriesConfiguration() {
        var error = false
        var countries_carriers = []
        for (var country_id of this.labelAutoCountries) {
            if (this.countries_carriers[country_id]) {
                countries_carriers.push({ id_country: country_id, id_carrier: this.countries_carriers[country_id].id_carrier })
            } else {
                error = true
                break
            }
        }

        if (!error) {
            var countries_request_data = {
                countries: this.labelAutoCountries,
                exchanges: this.exchangeCountries,
                _2hand: this._2handCountries,
                repair: this.repairCountries
            }

            await this.returnService.addCountriesReturnLabelAuto(countries_request_data).then(
                async data => {
                    await this.brandService.postCountryAndCarriersByBrand(countries_carriers).then(
                        data => {
                            this.messageService.add({ key: 'tst2', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.country_configuration_sucess") });
                            this.loadbrandReturns(); // reload data
                        },
                        error => {
                            this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.country_configuration_error") });
                        }
                    )
                },
                error => {
                    this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.country_configuration_error") });
                }
            )
        } else {
            this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.country_configuration_error") });
        }
    }

    async onSubmitEmails() {

        let data = {};
        if (this.activeTabIndex == 0) {//refund 
            data = {
                email_template_label: this.email_template_label,
                email_template_success_return: this.email_template_success_return,
                email_template_warehouse: this.email_template_warehouse,
                index: this.activeTabIndex
            }
        }

        if (this.activeTabIndex == 1) {// exchanges 
            data = {
                email_template_success_exchange: this.email_template_success_exchange,
                email_template_label_exchange: this.email_template_label_exchange,
                email_template_warehouse: this.email_template_warehouse,
                index: this.activeTabIndex
            }
        }
        if (this.activeTabIndex == 2) {//store credit 
            data = {
                email_template_success_credit: this.email_template_success_credit,
                email_template_label_credit: this.email_template_label_credit,
                email_template_warehouse: this.email_template_warehouse,
                index: this.activeTabIndex
            }
        }
        await this.brandService.putEmailsBrandReturn(data).then(async brandReturn => {
            {
                this.messageService.add({ key: 'tst2', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.brand_return_sucess") });
                this.loadbrandReturns(); // reload data  
            }
        }).catch((error) => {
            this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.brand_return_error") });
        });
    }

    async onFileBackgroundUpload(event, fileUpload) {
        let fileReader64 = new FileReader();
        fileReader64.readAsBinaryString(event.files[0]);
        fileReader64.onload = async () => {
            let binaryData: any = fileReader64.result;
            this.brandSetup.background = window.btoa(binaryData);
        };
        fileUpload.clear()
    }

    async onFileLogoUpload(event, fileUpload) {
        let fileReader64 = new FileReader();
        fileReader64.readAsBinaryString(event.files[0]);
        fileReader64.onload = async () => {
            let binaryData: any = fileReader64.result;
            this.logo = window.btoa(binaryData);
        };
        fileUpload.clear()
    }

    async onFileBackgroundSelected(event) {
        let fileReader64 = new FileReader();
        fileReader64.readAsBinaryString(event.files[0]);
        fileReader64.onload = async () => {
            let binaryData: any = fileReader64.result;
            this.background = window.btoa(binaryData);
        };
    }

    async onFileLogoSelected(event) {
        let fileReader64 = new FileReader();
        fileReader64.readAsBinaryString(event.files[0]);
        fileReader64.onload = async () => {
            let binaryData: any = fileReader64.result;
            this.logo = window.btoa(binaryData);
        };
    }

    async getReturnSpecialDates() {
        await this.brandService.getReturnSpecialDate().then(specialDates => {
            var specialDatesAux = []
            for (var specialD of specialDates) {
                var specialDate = {
                    id: specialD.id,
                    special_date: specialD.special_date,
                    inicial_date: this.pipe.transform(specialD.inicial_date, 'dd/MM/yyyy'),
                    end_date: this.pipe.transform(specialD.end_date, 'dd/MM/yyyy'),
                    return_limit_date: this.pipe.transform(specialD.return_limit_date, 'dd/MM/yyyy')
                }
                specialDatesAux.push(specialDate)
            }
            this.specialDates = specialDatesAux
        });
    }

    async addMoreSpecialDate() {
        this.submittedSpecialDate = true;
        if (this.selectedSpecialDate.special_date != null && this.selectedSpecialDate.rangeDates != null &&
            this.selectedSpecialDate.return_limit_date != null && this.minDate != null && this.selectedSpecialDate.return_limit_date > this.selectedSpecialDate.rangeDates[1]) {
            this.addSpecialDate = false
            var newSpecialDate = {
                special_date: this.selectedSpecialDate.special_date,
                inicial_date: this.pipe.transform(this.selectedSpecialDate.rangeDates[0], 'yyyy-MM-dd HH:mm:ss'),
                end_date: this.pipe.transform(this.selectedSpecialDate.rangeDates[1], 'yyyy-MM-dd HH:mm:ss'),
                return_limit_date: this.pipe.transform(this.selectedSpecialDate.return_limit_date, 'yyyy-MM-dd HH:mm:ss'),
                id_brand: this.id_brand
            }
            await this.brandService.postReturnSpecialDate(newSpecialDate).then(
                (data) => {
                    this.getReturnSpecialDates()
                    this.selectedSpecialDate = { specialDate: null, rangeDates: null, returnLimitDate: null };
                    this.submittedSpecialDate = false;
                    this.messageService.add({ key: 'tst2', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.add_special_date_success") })
                },
                (error) => {
                    this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.add_special_date_error") });
                })
        }
    }

    cancelAddMoreSpecialDate() {
        this.addSpecialDate = false
        this.selectedSpecialDate = {}
        this.minDate = null
    }

    deleteSpecialDate(specialDate: any) {
        this.deleteDialogSpecialDate = true
        this.specialDate = { ...specialDate }
    }

    async confirmDeleteSpecialDate() {
        this.deleteDialogSpecialDate = false
        await this.brandService.deleteReturnSpecialDate([this.specialDate.id]).then(
            (data) => {
                this.specialDates = this.specialDates.filter(val => val.id !== this.specialDate.id)
                this.specialDate = {}
                this.messageService.add({ key: 'tst2', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.remove_special_date_success") })
            },
            (error) => {
                this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.remove_special_date_error") });
            })
    }

    async addMoreEmailNotification() {
        this.submittedEmailNotification = true
        this.emailValidated = this.ccEmailRegex.test(this.email)
        if (this.emailsNotifications.some(item => item.email === this.email)) {
            this.emailValidated = false
        }
        if (this.emailValidated) {
            this.addEmailNotification = false
            await this.brandService.postNotificationEmailsByBrand({ email: this.email }).then(
                async (data) => {
                    this.emailsNotifications = await this.brandService.getNotificationEmailsByBrand();
                    this.email = null
                    this.messageService.add({ key: 'tst2', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.add_email_notification_success") })
                },
                (error) => {
                    this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.remove_email_notification_error") });
                })
            this.submittedEmailNotification = false
            this.emailValidated = false
        }
    }

    cancelAddMoreEmailNotification() {
        this.addEmailNotification = false
        this.email = null
    }

    deleteEmailNotification(email: any) {
        this.deleteDialogEmailNotification = true
        this.emailNotification = { ...email }
    }

    async confirmDeleteEmailNotification() {
        this.deleteDialogEmailNotification = false
        await this.brandService.deleteNotificationEmailsByBrand([this.emailNotification.email]).then(
            (data) => {
                this.emailsNotifications = this.emailsNotifications.filter(val => val.email !== this.emailNotification.email)
                this.email = null
                this.messageService.add({ key: 'tst2', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.remove_email_notification_success") })
            },
            (error) => {
                this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.remove_email_notification_error") });
            })
    }

    checkDisabledField() {
        this.countries_carriers.length = this.labelAutoCountries.length
        if (this.labelAutoCountries.length == 0) {
            var countries = []
            this.countries.map(country => {
                country.disabledCarrier = true
                countries.push(country)
            })
            this.countries = countries
        } else {
            this.countries.map(country => {
                country.disabledCarrier = false
            })
        }
    }

    addMinDate() {
        if (this.selectedSpecialDate.rangeDates && this.selectedSpecialDate.rangeDates[1] > this.minDate) {
            this.minDate = this.selectedSpecialDate.rangeDates[1];
        }
    }

    async onSubmitWarehouse() {
        //this.warehouse.country = this.selectedCountry;
        await this.brandService.putWarehouseBrandReturn(this.warehouse).then(async brandReturn => {
            {
                this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.brand_return_sucess") });
            }
        }).catch((error) => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.brand_return_error") });
        });
    }


    teste(country_code: any) {
        this.selectedCountry = country_code.value;
        this.warehouse.country = this.selectedCountry;
    }

    closeSideBar() {
        if (!this.isClosed) {
            this.isClosed = true;
            this.location.back();
        }
    }

    allTags: any = []
    servicesAutocomplete: any = [];
    servicesAutocompleteAux: any = [];

    async getTags_(event: any) {
        if (this.loadingRepairs == true) return;

        if (this.painelRepairs?._selected && this.allTags.length == 0 || (event != undefined && event === true)) {
            this.loadingRepairs = true;
            this.allTags = await this.brandService.getTags();
            this.insertAllTags(this.allTags);
            await this.formatGetServices()
            this.loadingRepairs = false;
        }

        if (this.tabView.tabs[event.index].header == 'marketplace') {
            const marketplaceOptions = await this.brandService.get2HandMarketplaceOptions()
            this.marketplaceOptions = marketplaceOptions[0]
            const admins = await this.brandService.get2handAdmin()
            this.loadingAdminMarket = false
            this.adminMarket = admins[0]
        }
    }

    async formatGetServices(): Promise<any> {
        let data = null;
        this.repair = [];
        this.servicesAutocompleteAux = []
        await this.repairService_.getRepairServices(this.id_brand).then((response: any) => {
            data = response;

            const len = data.length;
            for (let i = 0; i < len; i++) {
                this.servicesAutocompleteAux.push(data[i].descriptionAux);
                this.repair.push({
                    tags: data[i].product_tag,
                    repairService: data[i].description,
                    repairPrice: data[i].price,
                    id_service: data[i].id_service,
                    descriptionAux: data[i].descriptionAux
                })
            }
            this.servicesAutocomplete.push(...this.servicesAutocompleteAux);
        }).catch((error: any) => {
            console.log(error);
            return;
        })



    }


    searchServices(event: any) {
        const query = event.query;
        this.servicesAutocomplete = this.servicesAutocompleteAux.filter((service: any) => {
            return service !== null && service.toLowerCase().includes(query.toLowerCase());
        }
        );

    }

    addSRepairs: boolean = false;


    insertAllTags(tags: string[]) {
        this.statusProduct = []
        const len = tags.length;
        for (let i = 0; i < len; i++) {
            this.statusProduct.push({
                description: tags[i]
            })
        }
    }

    async addRepairService() {
        if (this.statusProductAux != null && this.statusProductAux.description != null && this.repairService != null && this.repairPrice != null && this.priceValid && this.serviceValid) {
            this.repair.push({
                tags: this.statusProductAux.description,
                repairService: this.repairService,
                repairPrice: this.repairPrice.toFixed(2),
                id_service: null

            })
            await this.repairService_.addService(this.repair[this.repair.length - 1]).then().catch((error: any) => {
                console.log(error)

            });

            await this.repairService_.getRepairServices(this.id_brand).then((response: any) => {
                this.repair = []
                this.servicesAutocompleteAux = [];
                const len = response.length;
                for (let i = 0; i < len; i++) {
                    this.servicesAutocompleteAux.push(response[i].descriptionAux);
                    this.repair.push({
                        tags: response[i].product_tag,
                        repairService: response[i].description,
                        repairPrice: response[i].price,
                        id_service: response[i].id_service,
                        descriptionAux: response[i].descriptionAux
                    })
                }
            }).catch((error: any) => {
                console.log(error);
                return;
            })
            this.statusProductAux = null;
            this.repairService = null;
            this.repairPrice = null;
        }

    }


    cancelRepairService() {
        this.statusProductAux = null;
        this.repairService = null;
        this.repairPrice = null;
        this.addSRepairs = false;
    }



    repairServiceValidation = true;

    @ViewChild('serviceInput') serviceInput: any;
    @ViewChild('priceInput') priceInput: ElementRef;
    serviceValid: boolean = false;
    priceValid: boolean = false;

    isServiceinputValid(value: any): void {

        const inputElement: any = this.serviceInput.el.nativeElement;
        if (isNaN(Number(value)) == false) {
            inputElement.required = false;
            inputElement.classList.add('ng-invalid', 'ng-dirty');
            this.serviceValid = false;

        }
        else {
            inputElement.classList.remove('ng-invalid', 'ng-dirty');
            inputElement.required = true;
            this.serviceValid = true;
        }
    }

    isPricenputValid(value: any): void {

        const inputElement: HTMLInputElement = this.priceInput.nativeElement;
        if (isNaN(Number(value))) {
            inputElement.required = false;
            inputElement.classList.add('ng-invalid', 'ng-dirty');
            this.priceValid = false;

        }
        else {
            inputElement.classList.remove('ng-invalid', 'ng-dirty');
            inputElement.required = true;
            this.priceValid = true;
        }
        if (Number(value) < 0) {
            inputElement.required = false;
            inputElement.classList.add('ng-invalid', 'ng-dirty');
            this.priceValid = false;
        }
        else {
            this.priceValid = true;
        }
    }

    async deleteRepairs(repair: any): Promise<void> {

        await this.repairService_.deleteRepairService(this.id_brand, repair.id_service).then(() => {
            const index = this.repair.indexOf(repair);
            if (index !== -1) {
                this.repair.splice(index, 1);
                this.servicesAutocompleteAux = [];
                const len = this.repair.length;
                for (let i = 0; i < len; i++) {
                    this.servicesAutocompleteAux.push(this.repair[i].descriptionAux);
                }
            }

        }).catch((error: any) => {
            console.log(error);
        })

    }

    updateMarketUserAdmin() {
        this.submittedAdminMarket = true
        this.sucessSubmittedAdminMarket = true
        if (this.adminMarket) {
            const userAdminPasswrod = {
                CustomerID: this.adminMarket.CustomerID,
                first_name: this.adminMarket.FirstName,
                last_name: this.adminMarket.LastName,
            }
            this.brandService.updateMarketUserAdmin(userAdminPasswrod).then(
                (result) => {
                    this.submittedAdminMarket = false
                    this.sucessSubmittedAdminMarket = false
                    this.messageService.add({ key: 'tst2', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.marketplace.account_update_success") })
                },
                (error) => {
                    this.sucessSubmittedAdminMarket = false
                    this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.marketplace.account_update_error") })
                }
            )
        } else {
            this.sucessSubmittedAdminMarket = false
        }
    }

    updateMarketUserAdminPassword() {
        this.submittedAdminMarketPassword = true
        this.sucessSubmittedAdminMarketPassword = false
        if (this.adminMarket.oldpassword && this.adminMarket.password && (this.adminMarket.password == this.confirmPassword)) {
            const userAdminPasswrod = {
                CustomerID: this.adminMarket.CustomerID,
                oldpassword: this.adminMarket.oldpassword,
                password: this.adminMarket.password
            }
            this.brandService.updateMarketUserAdminPassword(userAdminPasswrod).then(
                (result) => {
                    this.adminMarket.oldpassword = ''
                    this.adminMarket.password = ''
                    this.confirmPassword = ''
                    this.submittedAdminMarketPassword = false
                    this.sucessSubmittedAdminMarketPassword = false
                    this.messageService.add({ key: 'tst2', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.marketplace.pwd_update_success") })
                },
                (error) => {
                    this.submittedAdminMarketPassword = false
                    this.sucessSubmittedAdminMarketPassword = false
                    this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.marketplace.pwd_update_error") })
                }
            )
        } else {
            this.sucessSubmittedAdminMarketPassword = false
        }
    }

    createMarketUserAdmin() {
        this.submittedAdminMarket = true
        this.sucessSubmittedAdminMarket = true
        console.log(this.defaultAdmin)
        if (this.defaultAdmin.FirstName && this.defaultAdmin.LastName && this.defaultAdmin.Email_address && this.validateEmail(this.defaultAdmin.Email_address) &&
            this.defaultAdmin.password && (this.defaultAdmin.password == this.confirmPassword)) {
            const userAdmin = {
                EmailAddress: this.defaultAdmin.Email_address,
                first_name: this.defaultAdmin.FirstName,
                last_name: this.defaultAdmin.LastName,
                password: this.defaultAdmin.password
            }
            this.brandService.createMarkerUseradmin(userAdmin).then(
                (result) => {
                    this.defaultAdmin.Email_address = ''
                    this.defaultAdmin.FirstName = ''
                    this.defaultAdmin.LastName = ''
                    this.defaultAdmin.oldpassword = ''
                    this.defaultAdmin.password = ''
                    this.confirmPassword = ''
                    this.submittedAdminMarket = false
                    this.sucessSubmittedAdminMarket = false
                    this.messageService.add({ key: 'tst2', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("personal_area.marketplace.userAdmin_create_success") })
                },
                (error) => {
                    this.sucessSubmittedAdminMarket = false
                    this.messageService.add({ key: 'tst2', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("personal_area.marketplace.userAdmin_create_error") })
                }
            )
        } else {
            this.sucessSubmittedAdminMarket = false
        }
    }

    validatePassword(password: any) {
        var passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?()/=",.;:_\\-])[A-Za-z\d#$@!%&*?()/=",.;:_\\-]{8,}$/;
        if (passwordRegex.test(password)) {
            return true
        } else {
            return false
        }
    }
    validateEmail(email) {
        var emailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailRegex.test(email)) {
            return true
        } else {
            return false
        }
    }

    updateMarketplaceOptions() {
        this.brandService.update2HandMarketplaceOptions(this.marketplaceOptions)
    }

    postMarketplacePhoto(event, fileUpload) {
        this.loadingAdminMarket = true;
        let fileReader64 = new FileReader();
        fileReader64.readAsBinaryString(event.files[0]);
        fileReader64.onload = async () => {
            let binaryData: any = fileReader64.result;
            const response = await this.brandService.postMarketplacePhoto({photo: window.btoa(binaryData)}).catch((error) => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: this.translateService.instant('general.error'),
                    detail: error.error.Error,
                });
                this.loadingAdminMarket = false;
            });
            this.adminMarket.bannerPhoto = response[0].bannerPhoto
            this.loadingAdminMarket = false;
            this.messageService.add({ 
                key: 'tst', 
                severity: 'success', 
                summary: this.translateService.instant("general.success"), 
                detail: this.translateService.instant("personal_area.marketplace.successInsert") 
            });
        };
        fileUpload.clear()
    }

    async deleteMarketplacePhoto(url_photo) {
        this.loadingAdminMarket = true;
        const response = await this.brandService.deleteMarketplacePhoto({url_photo: url_photo})
        this.adminMarket.bannerPhoto = response[0].bannerPhoto
        setTimeout(() => {
            this.loadingAdminMarket = false;
        }, 500);
        this.messageService.add({ 
            key: 'tst', 
            severity: 'success', 
            summary: this.translateService.instant("general.success"), 
            detail: this.translateService.instant("personal_area.marketplace.successDelete") 
        });
    }
}
