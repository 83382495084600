import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { LeftBarComponent } from './leftbar.component';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordModule } from 'primeng/password';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MainMenuModule } from '../menu/menu.module';
import { MenuModule } from 'primeng/menu';

@NgModule({
    imports: [
        MainMenuModule,
        CommonModule,
        DialogModule,
        ButtonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        PasswordModule,
        MessagesModule,
        MessageModule,
        ToastModule,
        OverlayPanelModule,
        MenuModule
    ],
    declarations: [
        LeftBarComponent
    ],
    exports: [
        LeftBarComponent
    ],
    providers: [MessageService]

})
export class LeftBarModule { }
