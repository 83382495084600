import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { TopBarComponent } from './topbar.component';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordModule } from 'primeng/password';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MainMenuModule } from '../menu/menu.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AvatarModule } from 'primeng/avatar';
import { MenuItemModule } from '../menuitem/menuitem.module';

@NgModule({
    imports: [
        MainMenuModule,
        CommonModule,
        DialogModule,
        ButtonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        PasswordModule,
        MessagesModule,
        MessageModule,
        ToastModule,
        OverlayPanelModule,
        AppRoutingModule,
        AvatarModule,
        MenuItemModule
    ],
    declarations: [
        TopBarComponent
    ],
    exports: [
        TopBarComponent
    ],
    providers: [MessageService]

})
export class TopBarModule { }
