import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from './menu.component';
import { MenuItemModule } from '../menuitem/menuitem.module';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MenuItemModule,
        TranslateModule
    ],
    declarations: [
        MainMenuComponent
    ],
    exports: [
        MainMenuComponent
    ],
    providers: [PermissionsService]

})

export class MainMenuModule { }
