import { NgModule } from '@angular/core';
import { DashRepairComponent } from "./dash-repair.component";
import { CommonModule } from '@angular/common';
import { ChartModule } from 'primeng/chart';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ChartModule,
        TranslateModule
    ],
    declarations: [
        DashRepairComponent
    ],
    exports: [
        DashRepairComponent
    ],

})
export class DashRepairModule { }