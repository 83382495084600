import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { DashRecycleComponent } from './dash-recycle.component';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { KeyFilterModule } from 'primeng/keyfilter';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ChartModule } from 'primeng/chart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DividerModule } from 'primeng/divider';
import { DataViewModule } from 'primeng/dataview';
import { CarouselModule } from 'primeng/carousel';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';


@NgModule({
    imports: [
        CommonModule,
        TableModule,
        CommonModule,
        CalendarModule,
        FormsModule,
        ToastModule,
        DialogModule,
        InputTextModule,
        InputMaskModule,
        KeyFilterModule,
        FileUploadModule,
        MultiSelectModule,
        DropdownModule,
        MessageModule,
        MessagesModule,
        ReactiveFormsModule,
        ContextMenuModule,
        ChartModule,
        OverlayPanelModule,
        BreadcrumbModule,
        DividerModule,
        DataViewModule,
        CarouselModule,
        MenuModule,
        ButtonModule,
        ImageModule,
        VirtualScrollerModule,
        TranslateModule,
        CheckboxModule
    ],
    declarations: [
        DashRecycleComponent
    ],
    exports: [
        DashRecycleComponent
    ]

})
export class DashRecycleModule { }
