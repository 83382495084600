<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<div *ngIf="!loading" class="h-full">
	<p-toast></p-toast>
	<p-table #dtreturns [value]="aShoppingBags" dataKey="idInterno" [columns]="selectedColumns" [rowHover]="true"
		[paginator]="true" [globalFilterFields]="['idInterno', 'order_name', 'customer', 'country']" [rows]="25"
		[rowsPerPageOptions]="[25,50,100]" [showCurrentPageReport]="true" [resizableColumns]="true"
		[reorderableColumns]="true" responsiveLayout="scroll" [responsive]="true" [(selection)]="selectedAShoppingBags"
		exportFilename="aShoppingBags" [loading]="loading"
		currentPageReportTemplate="{{ 'products.list_product.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
		<ng-template pTemplate="caption">
			<div class="flex justify-content-between gap-2 flex-wrap">
				<div class="flex flex-column w-full sm:w-auto sm:flex-row gap-2">
					<p-calendar [showIcon]="true" [(ngModel)]="rangeDates" selectionMode="range" dateFormat="dd/mm/yy"
						(onClose)="ngOnInit()" [minDate]="minimumDate"></p-calendar>
					<div class="flex gap-2 w-full sm:w-auto">
						<span class="p-input-icon-left w-full sm:w-auto">
							<i class="pi pi-search"></i>
							<input pInputText type="text" #filter
								(input)="dtreturns.filterGlobal($event.target.value, 'contains')"
								placeholder="{{ 'general.search_here' | translate }}" />
						</span>
						<div (click)="init2HandOrders()" class="flex align-items-center cursor-pointer"
							style="color: #6c757d;">
							<i class="pi pi-refresh"></i>
						</div>
					</div>
				</div>
				<div class="flex gap-2">
					<button pButton pRipple label="Export" class="p-button-outlined" (click)="exportCSV()">
						<span class="p-button-icon p-button-icon-left flex"><i class="pi pi-file-excel"></i></span>
					</button>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th style="width: 1rem">
					<p-tableHeaderCheckbox (click)="mainCheckboxClick()"></p-tableHeaderCheckbox>
				</th>
				<th pResizableColumn pReorderableColumn [pSortableColumn]="col.sort ? col.field : null"
					*ngFor="let col of columns" class="cursor-move">
					<div class="flex align-items-center"
						[ngClass]="{'cursor-pointer' : col.sort || col.filter, 'p-column-filter-menu': col.filter }">
						{{col.header}}
						<p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
						<i *ngIf="col.filter && activeStatus == null" class="pi pi-filter"
							(click)="openOverlay($event, op)"></i>
						<i *ngIf="col.filter && activeStatus != null" class="pi pi-filter-fill"
							(click)="openOverlay($event, op)"></i>
					</div>
				</th>
				<th style="width: 1rem; border-left: none;">
					<img src="assets/layout/images/config-icon.svg" (click)="openOverlay($event, op2)"
						class="cursor-pointer">
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-item let-columns="columns" let-ri="rowIndex">
			<tr (click)="openSideBar(item)" class="cursor-pointer">
				<td>
					<p-tableCheckbox [value]="item" (click)="checkboxClick($event)"></p-tableCheckbox>
				</td>
				<td *ngFor="let col of columns">
					<div class="flex w-min">
						<div *ngIf="col.field === 'dataCriacao'">
							{{ item.dataCriacao | date:'MMM d, h:mm a' }}
						</div>
						<div *ngIf="col.field != 'idInterno' && col.field !=='dataCriacao'">
							{{item[col.field]}}
						</div>
						<a *ngIf="col.field == 'idInterno'">
							{{item[col.field]}}
						</a>
						<div *ngIf="col.field == 'items'">
							<div *ngIf="item[col.field] == 1">&nbsp;item</div>
							<div *ngIf="item[col.field] !== 1">&nbsp;items</div>
						</div>
						<div *ngIf="col.field == 'refund_total'">
							&nbsp;{{item.currency}}
						</div>
					</div>
				</td>
				<td></td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="11">{{ 'general.no_data' | translate }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr>
				<td colspan="11">{{ 'general.loading' | translate }}</td>
			</tr>
		</ng-template>
	</p-table>
</div>
<p-overlayPanel #op2 (onHide)="hideOverlay()" (onShow)="showOverlay()">
	<ng-template pTemplate>
		<div class="flex flex-column gap-3">
			<h6>CUSTOMIZE COLUMNS</h6>
			<div>
				<div *ngFor="let col of colsOptional" class="field-checkbox">
					<p-checkbox name="columns" value="col" [value]="col" [(ngModel)]="selectedColumnsAux"
						[inputId]="col.field"></p-checkbox>
					<label [for]="col.field">{{col.header}}</label>
				</div>
			</div>
		</div>
		<div class="mt-6 flex">
			<p-button icon="pi pi-replay" (click)="revert($event, op2)" label="Revert to default"
				styleClass="p-button-text"></p-button>
			<p-button label="Customize" (click)="customize($event, op2)"></p-button>
		</div>
	</ng-template>
</p-overlayPanel>
<p-sidebar [(visible)]="visibleSidebar" position="right" [baseZIndex]="10000" (onHide)="hideSideBar()"
	(click)="menuShow = false" [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false"
	class="relative">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="hideSideBar()"></button>
		</div>
	</div>
	<div *ngIf="!aShoppingBag || loadingSidebar" class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div>
	<div *ngIf="aShoppingBag && !loadingSidebar" class="flex flex-column">
		<div class="card">
			<div class="flex justify-content-between mb-3">
				<div class="flex flex-column sm:flex-row gap-2">
					<div>
						<h5>Abandoned Shopping Bag | {{aShoppingBag.shopping_bag_id}}</h5>
					</div>
				</div>
				<!--<div class="relative flex flex-column align-items-end">
					<p-button *ngIf="app.isDesktop()" label="More actions" icon="pi pi-angle-down" iconPos="right"
						class="p-button-outlined" (click)="menu.toggle($event)"></p-button>
					<button *ngIf="aShoppingBag.id_order_line_status !== 9 && !app.isDesktop()" pButton pRipple type="button"
						icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text relative p-0 h-min"
						(click)="menu.toggle($event)"></button>
					<p-menu #menu [popup]="true" [model]="dropdown_options"></p-menu>
				</div>-->
			</div>
			<div class="grid">
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
						<p>Date: {{aShoppingBag.created_at}}</p>
					</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>Customer: <a>{{aShoppingBag.first_name}} {{aShoppingBag.last_name}}</a>
					</p>
				</div>
				
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<div class="flex flex-wrap gap-1">
						<p class="mb-0">{{ 'orders.returns.email' | translate }}:</p>
						<div class="flex gap-1">
							<p class="mb-0"><a href="mailto:{{aShoppingBag.email}}"
									(click)="$event.stopPropagation()">{{aShoppingBag.email}}</a></p>
							<p-button (click)="copiedToclipboard()" icon="pi pi-copy"
								styleClass="h-auto w-auto p-0 text-color p-button-rounded p-button-text" ngxClipboard
								[cbContent]="aShoppingBag.email"></p-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--quando carregas na listas que está em em main/returns-->
		<div>
			<div class="returns-summary flex flex-column ">
				<div class="w-full flex flex-column lg:flex-row gap-3">
					<div class="card w-full">
						<h5>Items</h5>
						<div class="flex align-items-start justify-content-center">
							<p-dataView class="w-full" #dv [value]="aShoppingBag.lines" [paginator]="false" layout="list">
								<ng-template let-returnline pTemplate="listItem">
									<div class="w-full" *ngIf="returnline.IDStatus !== 14">
										<div id="info-return"
											class="info-return flex flex-row flex-wrap gap-2 pt-5 pb-5 w-full justify-content-between">
											<div class="flex flex-column sm:flex-row gap-3 w-full">
												<div id="block-img-product"
													class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
													<img [src]="returnline.originalPhoto" [alt]="returnline.productName"
														class="w-7rem sm:w-10rem shadow-2" />
												</div>
												<div
													class="flex flex-wrap-reverse gap-3 w-full justify-content-between">
													<div class="md:text-left flex flex-column gap-3 max-w-full">
														<!--nome do itens-->
														<span
															class="align-items-start justify-content-start"><b>{{returnline.productName}}</b></span>
														<div class="flex align-items-center">
															<span class="mr-3 align-self-center md:align-self-end"><b>SKU:
																</b>{{returnline.sku}}</span>
														</div>
														<div class="flex align-items-center">
															<span class="mr-3 align-self-center md:align-self-end"><b>EAN:
																</b>{{returnline.ean}}</span>
														</div>
														<div class="flex">
															<div *ngIf="returnline.product_variant_option1 != null"
																class="mr-3 ">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option1}}: </b>
																	{{returnline.color}}</span>
															</div>
															<div *ngIf="returnline.product_variant_option2 != null"
																class="mr-3 ">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option2}}: </b>
																	{{returnline.size}}</span>
															</div>
															<div *ngIf="returnline.product_variant_option3 != null"
																class="mr-3 ">
																<span
																	class="align-items-start justify-content-start"><b>
																		{{returnline.product_variant_option3}}: </b>
																	{{returnline.option3}}</span>
															</div>
														</div>
														<div *ngIf="[49,50,51].includes(returnline.IDStatus) == false">
															<b>Price: </b>
															<span class="align-items-start justify-content-start">
																{{returnline.price}} {{aShoppingBag.currency}}</span>
														</div>
														<div
															*ngIf="(returnline.photos > 0 && returnline.images_data?.length == undefined)">
															<p-button
																(click)="onImagesClick(returnline.id_return,returnline.id_return_line)"
																label="{{ 'orders.returns.images' | translate }}"
																[badge]="returnline.photos"
																styleClass="p-button-outlined p-button-secondary">
															</p-button>
														</div>

														<div *ngIf="returnline.photos?.length > 0 ">
															<p-dataView [value]="returnline.photos">
																<ng-template let-linesImages pTemplate="listItem">
																	<div class="m-2">
																		<p-image [src]="linesImages.url_link" width="100"
																			[preview]="true">
																		</p-image>
																	</div>
																</ng-template>
															</p-dataView>
														</div>
													</div>
													
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</p-dataView>
						</div>
					</div>
					
				</div>
				
			</div>
		</div>
	</div>

</p-sidebar>
