import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BrandService } from 'src/app/services/brands/brand.service';
import { RecycleService } from 'src/app/services/recycle/recycle.service';
import { Table } from 'primeng/table';
import { ContextMenu } from 'primeng/contextmenu';


@Component({
  selector: 'app-recycle',
  templateUrl: './recycle.component.html',
  styleUrls: ['./recycle.component.scss']
})
export class RecycleComponent implements OnInit {

  pipe = new DatePipe('en-US');

  rangeDates: Date[] = [
    new Date(new Date().setDate(new Date().getDate() - 31)),
    new Date(),
  ];

  loading: Boolean = true;

  cols: any = [];

  colsOptional: any = [];

  selectedColumns: any = [];

  _selectedColumnsAux: any = [];

  idBrand: any;

  recycleStatus: any;

  statusOptions: any = [];

  recycles: any = [];

  recycle: any;

  selectedRecycles: any[] = [];

  selectedSubmission: {
    id: number,
    customer?: string,
    recyle_collect_name?: string,
    pickupCity?: string,
    tracking_number?: string,
    created_at?: string,
}

  items: any[] = [];

  selectedRecycle: any;

  menuShow: boolean = false;

  visibleSidebar: boolean = false;

  loadingSidebar: boolean = false;

  @ViewChild('dtrecycles') table: Table;

  @ViewChild('filter') filter: ElementRef;

  @ViewChild('cm') contextMenu!: ContextMenu;

  isHistoryTrue:boolean=false;
  dataHistory:any[]=[];


  dropdown_options: any = [
  {
    id: 'history_',
    label: 'History',
    icon: 'pi pi-history',
    command: () => {
      this.callHistory();
    },
    disabled: false,
    visible: true
  }
  ]

  constructor(private app: AppMainComponent,
    private readonly translateService: TranslateService,
    private messageService: MessageService,
    private recycleService: RecycleService,
    private brandService: BrandService,
    private route: ActivatedRoute) { }

  async ngOnInit() {

    this.dataHistory=[];
    this.loading = true;
    // general columns table
    this.cols = [
      { field: 'id', header: 'Subm. ID', sort: true, filter: false }
    ]
    this.colsOptional = [
      { field: 'customer', header: 'Customer', sort: true, filter: false },
      { field: 'recycle_collect_name', header: 'Pickup Point Name', sort: true, filter: false },
      { field: 'pickupCity', header: 'Pickup Point City', sort: true, filter: false },
      { field: 'tracking_number', header: 'Tracking Number', sort: true, filter: false },
      { field: 'created_at', header: 'Submission Date', sort: true, filter: false },
    ];
    
    // saved selected rows
    this.selectedColumns = this.cols.concat(this.colsOptional)
    this._selectedColumnsAux = this.cols.concat(this.colsOptional)

    this.idBrand = this.brandService.getBrand();

    const dateI = this.pipe.transform(this.rangeDates[0], 'yyyy-MM-dd');
    let dateF;
    if (this.rangeDates[1] == null) {
      dateF = dateI;
    } else {
      dateF = this.pipe.transform(this.rangeDates[1], 'yyyy-MM-dd');
    }

    // get recycle list
    await this.recycleService.getRecycles(this.idBrand, dateI, dateF).then((recycle) => {
      recycle.map((rep) => {        
       // rep.created_at = this.app.getDateOnFormatString(rep.created_at);
        rep.created_at = rep.created_at;//this.app.getDateOnFormatString(rep.created_at);
      })
      this.recycles = recycle;
    })
    this.loading = false;    
  }

  IsHistoryTrue(): void {
    this.isHistoryTrue = !this.isHistoryTrue;
  }

  hideSidebarHistory() {
    this.isHistoryTrue = false;
  }

  async callHistory() {
    document.getElementsByTagName('body')[0].classList.add('fixed')
    this.IsHistoryTrue();
  }

  IDStatusDescriptionTimeLine(event: any): string {
    if (event.change_type == "INSERT")
      return event.description_new;
    if (event.change_type == "UPDATE")
      return event.description_new;
    return event.description_old;
  }

  dateTimeLine(event: any): string {
    return this.app.getDateOnFormatString(event.change_timestamp);
  }

  adicionarEspacosAposVirgulas(texto: string | string[]): string {
    if (Array.isArray(texto)) {
        // Se for um array, junte os elementos com uma vírgula seguida de um espaço
        return texto.join(", ");
    } else {
        // Retorne o texto original se não for uma string ou array
        return texto;
    }
  }

  async openSideBar(selectedRecycle: any) {
    this.menuShow = false;
    this.recycle = null;
    this.visibleSidebar = true
    this.selectedRecycle = selectedRecycle;
    document.getElementsByTagName('body')[0].classList.add('fixed');

    // load recycle line data
    await this.recycleService.getRecycleLines(selectedRecycle.id).then((recycle) => {
      recycle.created_at = this.app.getDateOnFormatString(recycle.created_at);
      
      recycle.brands_answer = this.adicionarEspacosAposVirgulas(recycle.brands_answer);
      recycle.other_methods_answer = this.adicionarEspacosAposVirgulas(recycle.other_methods_answer);
      recycle.recycle_collect_street = this.adicionarEspacosAposVirgulas(recycle.recycle_collect_street);

      this.recycle = recycle;
    });
  }

  checkboxClick(e) {
    e.stopPropagation()
    var x = document.getElementsByClassName('p-checkbox-box')
    var y = document.getElementsByClassName('p-checkbox-icon')
    if (this.selectedRecycles.length > 0) {
      x[0].classList.add('p-highlight')
      y[0].classList.add('pi')
      y[0].classList.add('pi-minus')
    } else {
      x[0].classList.remove('p-highlight')
      y[0].classList.remove('pi')
      y[0].classList.remove('pi-minus')
    }
  }

  mainCheckboxClick() {
    var y = document.getElementsByClassName('p-checkbox-icon')
    if (this.selectedRecycles.length == this.recycles.length) {
      y[0].classList.remove('pi-minus')
    }
  }

  async exportCSV() {
    var selectionAux = this.table._selection
    if (this.table._selection.length == 0) {
      if (this.table.filteredValue) {
        this.table._selection = this.table.filteredValue
      } else {
        this.table._selection = this.table._value
      }
    }
    await this.table.exportCSV({ selectionOnly: true })
    this.table._selection = selectionAux
  }

  openOverlay(event, op) {
    event.stopPropagation();
    op.toggle(event)
  }

  showOverlay() {
    this.app.overlayActive = true
  }

  hideOverlay() {
    this.app.overlayActive = false
  }

  hideSideBar() {
    this.visibleSidebar = false;
    document.getElementsByTagName('body')[0].classList.remove('fixed');
  }

  @Input() get selectedColumnsAux(): any[] {
    return this._selectedColumnsAux;
  }

  set selectedColumnsAux(val: any[]) {
    //restore original order
    this._selectedColumnsAux = this.colsOptional.filter(col => val.includes(col));
  }

  customize(event, op) {
    this.selectedColumns = this.cols.concat(this._selectedColumnsAux)
    this.app.overlayActive = false
    op.hide(event)
  }

  revert(event, op) {
      this.selectedColumns = this.cols.concat(this.colsOptional)
      this._selectedColumnsAux = this.cols.concat(this.colsOptional)
      this.app.overlayActive = false
      op.hide(event)
  }

  remove(inside: boolean) {
    if (!inside)
        this.items = [];
}

  onContextMenuSelectProduct(selectedSubmission: any) { 
    let inside = false;

    this.translateService.stream('recycle').subscribe((val) => {
        if(this.selectedSubmission.tracking_number != null){
          this.remove(inside);
          inside = true;
          this.items = [{
                label: val.download_label,
                icon: 'pi pi-download',
                command: () => this.requestLabel(selectedSubmission.id)
            }];
        } else {
          this.remove(inside);
          inside = true;
          this.items = [{
            label: val.label_notavailable,
            icon: 'pi pi-exclamation-circle'           
          }]
        }
    });
  }

  requestLabel(id_submission: any) {
    // get Label to download
    this.recycleService.getRecycleLabel(id_submission)
        .then((label: any) => {
            if (label.length > 0) {
                const source = `data:application/pdf;base64,${label[0].label}`;
                const link = document.createElement('a');
                link.href = source;
                link.download = `submission(${id_submission})_label.pdf`;
                link.click();
            }
            else {
                console.log("No label found")
            }
        })
        .catch((error) => {
            console.log(error);
        });
  }
}
