import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BrandService } from 'src/app/services/brands/brand.service';
import { RepairService } from 'src/app/services/repair/repair.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-repair',
  templateUrl: './repair.component.html',
  styleUrls: ['./repair.component.scss']
})
export class RepairComponent implements OnInit {

  pipe = new DatePipe('en-US');

  rangeDates: Date[] = [
    new Date(new Date().setDate(new Date().getDate() - 31)),
    new Date(),
  ];

  loading: Boolean = true;

  cols: any = [];

  colsOptional: any = [];

  selectedColumns: any = [];

  _selectedColumnsAux: any = [];

  idBrand: any;

  repairStatus: any;

  statusOptions: any = [];

  repairs: any = [];

  repair: any;

  selectedRepairs: any[] = [];

  selectedRepair: any;

  menuShow: boolean = false;

  visibleSidebar: boolean = false;

  loadingSidebar: boolean = false;

  @ViewChild('dtrepairs') table: Table;

  @ViewChild('filter') filter: ElementRef;

  isHistoryTrue:boolean=false;
  dataHistory:any[]=[];


  dropdown_options: any = [
  {
    id: 'history_',
    label: 'History',
    icon: 'pi pi-history',
    command: () => {
      this.callHistory();
    },
    disabled: false,
    visible: true
  }
  ]

  constructor(private app: AppMainComponent,
    private readonly translateService: TranslateService,
    private messageService: MessageService,
    private repairService: RepairService,
    private brandService: BrandService,
    private route: ActivatedRoute) { }

  async ngOnInit() {

    this.dataHistory=[];
    this.loading = true;
    // general columns table
    this.cols = [
      { field: 'id_repair', header: 'Subm. ID', sort: true, filter: false }
    ]
    this.colsOptional = [
      { field: 'order_name', header: 'Order', sort: true, filter: false },
      { field: 'customer', header: 'Customer', sort: true, filter: false },
      { field: 'country', header: 'Country', sort: true, filter: false },
      { field: 'items', header: 'Items', sort: true, filter: false },
      { field: 'amount', header: 'Amount Total', sort: true, filter: false },
      { field: 'created_at', header: 'Request Date', sort: true, filter: false },
      { field: 'repair_status', header: 'Status', sort: true, filter: false }
    ];

    // saved selected rows
    this.selectedColumns = this.cols.concat(this.colsOptional)
    this._selectedColumnsAux = this.cols.concat(this.colsOptional)

    this.idBrand = this.brandService.getBrand();

    // get repair status from brand
    await this.repairService.getRepairStatus().then((status) => {
      this.repairStatus = status;
      for (let repairStatus of status) {
        this.statusOptions.push({
          label: repairStatus.description,
          value: repairStatus.description,
        });
      }
    });

    const dateI = this.pipe.transform(this.rangeDates[0], 'yyyy-MM-dd');
    let dateF;
    if (this.rangeDates[1] == null) {
      dateF = dateI;
    } else {
      dateF = this.pipe.transform(this.rangeDates[1], 'yyyy-MM-dd');
    }

    // get repair list
    await this.repairService.getRepairs(this.idBrand, dateI, dateF).then((repair) => {
      repair.map((rep) => {
        
       // rep.created_at = this.app.getDateOnFormatString(rep.created_at);
        rep.created_at = rep.created_at;//this.app.getDateOnFormatString(rep.created_at);
        rep.IDStatus = rep.repair_status;
        rep.repair_status = this.repairStatus.find((r: any) => r.id_repair_status == rep.repair_status).description;
      })

      this.repairs = repair;
    })

    this.loading = false;

  }

  IsHistoryTrue(): void {
    this.isHistoryTrue = !this.isHistoryTrue;
  }

  hideSidebarHistory() {
    this.isHistoryTrue = false;
  }

  async callHistory() {
    document.getElementsByTagName('body')[0].classList.add('fixed')
    this.IsHistoryTrue();
  }

  IDStatusDescriptionTimeLine(event: any): string {
    if (event.change_type == "INSERT")
      return event.description_new;
    if (event.change_type == "UPDATE")
      return event.description_new;
    return event.description_old;
  }

  dateTimeLine(event: any): string {
    return this.app.getDateOnFormatString(event.change_timestamp);
  }


  async getRepairJournal(id_repair: any) {
    this.dataHistory = [];
    try {
      const history = await this.repairService.getRepairJournal(id_repair);
      if (history.length > 0) {
        this.dataHistory = history;
      } else {
        this.dataHistory = ['undefined'];
      }
    } catch (error) {
      this.dataHistory = ['undefined'];
    }
  }

  async openSideBar(selectedRepair: any) {
    this.menuShow = false;
    this.repair = null;
    this.visibleSidebar = true
    this.selectedRepair = selectedRepair;
    document.getElementsByTagName('body')[0].classList.add('fixed');

    // load repair line data
    await this.repairService.getRepairLines(selectedRepair.id_repair).then((repair) => {
      repair.created_at = this.app.getDateOnFormatString(repair.created_at);
      this.repair = repair;
    });

    await this.getRepairJournal(selectedRepair.id_repair);

  }

  checkboxClick(e) {
    e.stopPropagation()
    var x = document.getElementsByClassName('p-checkbox-box')
    var y = document.getElementsByClassName('p-checkbox-icon')
    if (this.selectedRepairs.length > 0) {
      x[0].classList.add('p-highlight')
      y[0].classList.add('pi')
      y[0].classList.add('pi-minus')
    } else {
      x[0].classList.remove('p-highlight')
      y[0].classList.remove('pi')
      y[0].classList.remove('pi-minus')
    }
  }

  mainCheckboxClick() {
    var y = document.getElementsByClassName('p-checkbox-icon')
    if (this.selectedRepairs.length == this.repairs.length) {
      y[0].classList.remove('pi-minus')
    }
  }

  async exportCSV() {
    var selectionAux = this.table._selection
    if (this.table._selection.length == 0) {
      if (this.table.filteredValue) {
        this.table._selection = this.table.filteredValue
      } else {
        this.table._selection = this.table._value
      }
    }
    await this.table.exportCSV({ selectionOnly: true })
    this.table._selection = selectionAux
  }

  openOverlay(event, op) {
    event.stopPropagation();
    op.toggle(event)
  }

  showOverlay() {
    this.app.overlayActive = true
  }

  hideOverlay() {
    this.app.overlayActive = false
  }

  hideSideBar() {
    this.visibleSidebar = false;
    document.getElementsByTagName('body')[0].classList.remove('fixed');
}

  @Input() get selectedColumnsAux(): any[] {
    return this._selectedColumnsAux;
  }

  set selectedColumnsAux(val: any[]) {
    //restore original order
    this._selectedColumnsAux = this.colsOptional.filter(col => val.includes(col));
  }

  customize(event, op) {
    this.selectedColumns = this.cols.concat(this._selectedColumnsAux)
    this.app.overlayActive = false
    op.hide(event)
}

revert(event, op) {
    this.selectedColumns = this.cols.concat(this.colsOptional)
    this._selectedColumnsAux = this.cols.concat(this.colsOptional)
    this.app.overlayActive = false
    op.hide(event)
}

}
