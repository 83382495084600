<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<div *ngIf="loading_"
	style="width: 100vw; height: 100vh; z-index: 100000; position: absolute; background: #646565b0; left: 0; top: 0;">
	<div class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div>
</div>
<div *ngIf="!loading" class="h-full">
    <p-table #dt2hand [value]="_2hands" dataKey="product_child_id" [columns]="selectedColumns" [rowHover]="true"
		[paginator]="true" [globalFilterFields]="['product_child_id','customer','country','order_name','product_child_message_status']" [rows]="25"
		[rowsPerPageOptions]="[25,50,100]" [showCurrentPageReport]="true" [resizableColumns]="true"
		[reorderableColumns]="true" responsiveLayout="scroll" [responsive]="true" [(selection)]="selected2Hands"
		exportFilename="_2hands" [loading]="loading"
		currentPageReportTemplate="{{ 'products.list_product.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
		<ng-template pTemplate="caption">
			<div class="flex justify-content-between gap-2 flex-wrap">
				<div class="flex gap-2">
					<p-calendar [showIcon]="true" [(ngModel)]="rangeDates" selectionMode="range" dateFormat="dd/mm/yy"
						(onClose)="ngOnInit()" [minDate]="minimumDate"></p-calendar>
					<span class="p-input-icon-left w-full sm:w-auto">
						<i class="pi pi-search"></i>
						<input pInputText type="text" #filter 
							(input)="dt2hand.filterGlobal($event.target.value, 'contains')"
							placeholder="{{ 'general.search_here' | translate }}" />
					</span>
					<div (click)="ngOnInit()" class="flex align-items-center cursor-pointer" style="color: #6c757d;">
						<i class="pi pi-refresh"></i>
					</div>
				</div>
				<div class="flex gap-2">
					<button pButton pRipple label="Export" class="p-button-outlined" (click)="exportCSV()">
						<span class="p-button-icon p-button-icon-left flex"><i class="pi pi-file-excel"></i></span>
					</button>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th style="width: 1rem">
					<p-tableHeaderCheckbox (click)="mainCheckboxClick()"></p-tableHeaderCheckbox>
				</th>
				<th pResizableColumn pReorderableColumn [pSortableColumn]="col.sort ? col.field : null"
					*ngFor="let col of columns" class="cursor-move">
					<div class="flex align-items-center"
						[ngClass]="{'cursor-pointer' : col.sort || col.filter, 'p-column-filter-menu': col.filter }">
						{{col.header}}
						<p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
						<i *ngIf="col.filter && activeStatus == null" class="pi pi-filter"
							(click)="openOverlay($event, op)"></i>
						<i *ngIf="col.filter && activeStatus != null" class="pi pi-filter-fill"
							(click)="openOverlay($event, op)"></i>
					</div>
				</th>
				<th style="width: 1rem; border-left: none;">
					<img src="assets/layout/images/config-icon.svg" (click)="openOverlay($event, op2)"
						class="cursor-pointer">
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-item let-columns="columns" let-ri="rowIndex">
			<tr (click)="openSideBar(item)" class="cursor-pointer">
				<td>
					<p-tableCheckbox [value]="item" (click)="checkboxClick($event)"></p-tableCheckbox>
				</td>
				<td *ngFor="let col of columns">
					<div class="flex w-min"
						[ngClass]="col.field == 'product_child_message_status' ? 'sechand-badge status-' + item.product_child_id_status : ''">
						<div *ngIf="col.field === 'date'">
							{{ item.date | date:'MMM d, h:mm a' }}
						</div>
						<div *ngIf="col.field != 'product_child_id' && col.field!=='date'">
							{{ item[col.field] }}
						</div>
						<a *ngIf="col.field == 'product_child_id'">
							{{ item[col.field] }}
						</a>
						<div *ngIf="col.field == 'items'">
							<div *ngIf="item[col.field] == 1">&nbsp;item</div>
							<div *ngIf="item[col.field] !== 1">&nbsp;items</div>
						</div>
						<div *ngIf="col.field == 'total'">
							&nbsp;{{ item.currency }}
						</div>
					</div>
				</td>
				<td></td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="11">{{ 'general.no_data' | translate }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr>
				<td colspan="11">{{ 'general.loading' | translate }}</td>
			</tr>
		</ng-template>
	</p-table>
</div>

<p-sidebar [(visible)]="visibleSidebar" position="right" [baseZIndex]="10000" (onHide)="hideSideBar()"
	(click)="menuShow = false" [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false"
	class="relative">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="hideSideBar()"></button>
		</div>
	</div>
	<div *ngIf="!_2hand || loadingSidebar" class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div>
	<div *ngIf="_2hand" class="flex flex-column pb-6">
		<div class="card">
			<div class="flex flex-wrap justify-content-between justify-content-center mb-3">
				
				<div class=" flex flex-wrap space-between">
					<div class=" mr-2">
						<h5>Second Hand | {{_2hand.product_child_id}}</h5>
					</div>
					<div class="mr-2">
						<div [class]="'sechand-badge status-' + _2hand.product_child_id_status">{{_2hand.product_child_message_status}}</div>
					</div>
				</div>

					<div class="flex flex-wrap" *ngIf="_2hand.product_child_id_status == 2 ">
						<button  pButton pRipple label="{{ 'orders.2hand.view_product_online' | translate }}" class="p-button-outlined" (click)="toProductOnline(_2hand.product_child_id)">
							<span class="p-button-icon p-button-icon-left flex" style="margin-left: -14px;"><img
								src="assets/layout/images/StoreMajor.svg"></span>
						</button>
					</div>
				
				<!--
					uncomment to display More actions button
				<div class="relative flex flex-column align-items-end">
					 <p-button *ngIf="app.isDesktop()" label="More actions"
						icon="pi pi-angle-down" iconPos="right" class="p-button-outlined"
						(click)="menu.toggle($event)"></p-button>
					<button *ngIf="!app.isDesktop()" pButton pRipple type="button"
						icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text relative p-0 h-min"
						(click)="menu.toggle($event)"></button>
					<p-menu #menu [popup]="true" [model]="dropdown_options"></p-menu>
				</div>-->

			</div>

			<div *ngIf="_2hand.product_child_id_status == 1  || _2hand.product_child_id_status == 3 ; else elseLineStatus">
				<button [disabled]="updateLine"
					(click)="updateLineStatus(_2hand.product_child_id_status, _2hand.product_child_id)"
					pButton
					[ngStyle]="{'background-color':'var(--green-200)','color':'var(--gray-900)', 'border':'var(--yellow-200)'}"
					icon="pi pi-check-circle"
					label="{{ 'orders.returns.approve' | translate }}"
					iconPos="right" class="mb-2" style="float: right;"></button>
			</div>
			<!--
				uncomment to display the reject button
			<ng-template  #elseLineStatus>
				<button *ngIf="[2].includes(_2hand.product_child_id_status)" [disabled]="updateLine"
					(click)="updateLineStatus(_2hand.product_child_id_status, _2hand.product_child_id)"
					pButton
					[ngStyle]="{'background-color':'var(--pink-200)','color':'var(--gray-900)', 'border':'var(--yellow-200)'}"
					icon="pi pi-check-circle"
					label="{{ 'orders.returns.reject' | translate }}"
					iconPos="right" class="mb-2" style="float: right;"></button>
			</ng-template>
			-->
			<div class="grid">
				<div *ngIf="_2hand.order_name!='' && _2hand.order_name!= undefined  && _2hand.order_name!= null"
					class="col-12 sm:col-6 md:col-6 lg:col-4">
					<span>Order: {{_2hand.order_name}}</span>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>Submitted On: {{_2hand.order_created_at | date:'MMM d, h:mm a'}}</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>Customer: <a>{{_2hand.first_name}} {{_2hand.last_name}}</a>
					</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>{{ 'orders.returns.email' | translate }}:
						<a href="mailto:{{_2hand.email}}" (click)="$event.stopPropagation()">{{_2hand.email}}</a>
					</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-8">
					<p>{{ 'orders.returns.address' | translate }}: {{_2hand.product_address[0].AddressLine1}}
						{{_2hand.product_address[0].AddressLine2}} {{_2hand.product_address[0].City}} {{_2hand.product_address[0].ZIPCode}}, {{_2hand.product_address[0].country_name}}</p>
				</div>
				<!--<div class="col-12 sm:col-6 md:col-6 lg:col-4 pr-0">
					<p>Tracking:
						<a href="{{_2hand.track_link}}" (click)="$event.stopPropagation()"
							target="_blank" style="word-break: break-word;">{{_2hand.tracking_id}}</a>
					</p>
				</div>-->
			</div>
			
		</div>
		<div>
			<div class="sechands-summary flex flex-column gap-3">
				<div class="w-full flex flex-column lg:flex-row gap-3">
					<div class="card w-full">
						<!--<h5>{{ 'orders.2hand.second_hand_items' | translate }}</h5>-->
						<div class="flex align-items-start justify-content-center">
							<p-dataView class="w-full" #dv [value]="_2hand.lines" [paginator]="false" layout="list">
								<ng-template let-sechandline pTemplate="listItem">
									<div class="w-full">
										<div id="info-2hand"
											class="info-2hand flex flex-row flex-wrap gap-2 pt-5 pb-5 w-full justify-content-between">
											<div class="flex flex-column sm:flex-row gap-3 w-full">
												<div id="block-img-product"
													class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
													<img [src]="sechandline.photo" [alt]="sechandline.productname"
														class="w-7rem sm:w-10rem shadow-2" />
												</div>
												<div>

													<div class=" flex flex-wrap" style="width: 100%;">
														<div class="flex justify-content-start mr-5 mb-2 w-full"><span
																style=" text-transform:uppercase"><b>{{sechandline.ProductName}}</b></span>
														</div>
														<div class="flex"
															style="justify-content: space-evenly; flex-direction: row; width: 60%">

															<div class="flex flex-wrap"
																style="align-content: flex-start;">
																<div class="flex justify-content-start mr-5  mb-2">
																	<span style="overflow-wrap: anywhere;"><b>SKU:
																		</b>{{sechandline.sku}}</span>
																</div>
																<div class="flex justify-content-start mr-5  mb-2"
																	*ngIf="sechandline.product_variant_option1.value != null">
																	<span style="overflow-wrap: anywhere;">
																		<b>{{sechandline.product_variant_option1.key}}:
																		</b>
																		{{sechandline.product_variant_option1.value}}
																	</span>
																</div>
																<div class="flex justify-content-start mr-5  mb-2"
																	*ngIf="sechandline.product_variant_option2.value != null && sechandline.product_variant_option2.value !== 'N/A'">
																	<span style="overflow-wrap: anywhere;">
																		<b>{{sechandline.product_variant_option2.key}}:
																		</b>
																		{{sechandline.product_variant_option2.value}}
																	</span>
																</div>
																<div class="flex justify-content-start mr-5  mb-2"
																	*ngIf="sechandline.product_variant_option3.value != null">
																	<span style="overflow-wrap: anywhere;">
																		<b>{{sechandline.product_variant_option3.key}}:
																		</b>
																		{{sechandline.product_variant_option3.value}}
																	</span>
																</div>
																<div class="flex justify-content-start mr-5 mb-2">
																	<span style="overflow-wrap: anywhere;">
																		<b>{{'orders.returns.quantity' | translate }}:
																		</b>
																		{{sechandline.quantity}}
																	</span>
																</div>
																<div class="flex justify-content-start mr-5 mb-2">
																	<span style="overflow-wrap: anywhere;"><b>{{
																			'orders.2hand.condition' | translate }}:
																		</b>
																		{{sechandline.condition_description}}
																	</span>
																</div>

																<div>
																	<div class=" flex ">
																		<span class="flex flex-wrap">
																			<b style="margin-right: 2px;">{{
																				'orders.2hand.price' | translate }}:
																			</b>
																			<div
																				style="margin-right: 5px; overflow-wrap: anywhere;">
																				{{sechandline.total}}
																				{{_2hand.currency}}
																			</div>
																			<div class="flex align-items-center"
																				style="position: relative; overflow-wrap: anywhere;">
																				<hr
																					style=" height: 1px; border: none; background-color: black; position: absolute; width: -webkit-fill-available;">
																				<div> ({{sechandline.retail_price}}
																					{{_2hand.currency}})</div>
																			</div>
																		</span>
																	</div>
																	<!--<div class=" flex justify-content-start md:col-18 col-12" style="padding: 0.5rem;">
															<span>
																<b>Retail Price: </b>
																{{sechandline.retail_price}} {{_2hand.currency}} 
															</span>
															
														</div>-->
																</div>
																<div class="col-12"
																	*ngIf="sechandline.photos?.length > 0 ">
																	<p-dataView [value]="sechandline.photos">
																		<ng-template let-linesImages
																			pTemplate="listItem">
																			<div class="m-2">
																				<p-image [src]="linesImages.blob" class="cursor-pointer" width="100" (click)="imageEditor(linesImages)"> </p-image>

																			</div>
																		</ng-template>
																	</p-dataView>
																</div>
															</div>

														</div>
														<div class="flex flex-wrap comments ">

															<div *ngIf="sechandline.comment!= null && sechandline.comment!= '' && sechandline.comment!= undefined"
																class="flex justify-content-start mr-5 mb-2">
																<hr
																	style=" height: 90%; width: 1px; background-color: #dee2e6;  border: none; margin-right: 1rem;">
																<span [style]="{'white-space': 'pre-wrap'}"><b>{{
																		'orders.2hand.seller' | translate }}: </b>
																	<br>
																	{{sechandline.comment}}
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</p-dataView>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</p-sidebar>


<!--histórico sidebar-->
<p-sidebar  [(visible)]="isHistoryTrue"  position="right" [baseZIndex]="10001" (onHide)="hideSidebarHistory()"
	(click)="menuShow = false" [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false"
	class="relative">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="hideSidebarHistory()"></button>
		</div>
	</div>

	<div *ngIf="!dataHistory.length && dataHistory[0]!=='undefined'" class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div><div *ngIf=" dataHistory[0]=='undefined'" class="flex justify-content-center align-items-center min-h-screen">
		<p>No history avaliable</p>
	</div>
	<div *ngIf="isHistoryTrue && dataHistory && dataHistory.length && dataHistory[0]!=='undefined'">
		<div class="card-title" style="padding-top: 50px; padding-bottom: 50px;">History</div>
		<p-timeline [value]="dataHistory" class="w-full timeline">
			<ng-template pTemplate="content" let-event>
				{{dateTimeLine(event)}}
				<br>
				{{IDStatusDescriptionTimeLine(event)}}
			</ng-template>
		</p-timeline>
	</div>
</p-sidebar>

<p-overlayPanel #op2 (onHide)="hideOverlay()" (onShow)="showOverlay()">
	<ng-template pTemplate>
		<div class="flex flex-column gap-3">
			<h6>CUSTOMIZE COLUMNS</h6>
			<div>
				<div *ngFor="let col of colsOptional" class="field-checkbox">
					<p-checkbox name="columns" value="col" [value]="col" [(ngModel)]="selectedColumnsAux"
						[inputId]="col.field"></p-checkbox>
					<label [for]="col.field">{{col.header}}</label>
				</div>
			</div>
		</div>
		<div class="mt-6 flex">
			<p-button icon="pi pi-replay" (click)="revert($event, op2)" label="Revert to default"
				styleClass="p-button-text"></p-button>
			<p-button label="Customize" (click)="customize($event, op2)"></p-button>
		</div>
	</ng-template>
</p-overlayPanel>

<p-dialog #dialoImageEditor [modal]="true" [(visible)]="openImageEditor" (onShow)="openEditor()"
	[style]="{width: '90vw', height: '90vh'}" class="image-editor" (onHide)="destroyImageEditor()">
	<ng-template pTemplate="header" style="background-color: black;">
		<div style="width: 100%; height: 100%; background-color: black;"></div>
	</ng-template>
	<div #tuiImageEditor>
		<canvas></canvas>
	</div>

</p-dialog>