import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';

import { Table } from 'primeng/table';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { NewUser, User } from 'src/app/api/user';
import { UserService } from 'src/app/services/user/user.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { TranslateService } from '@ngx-translate/core';
import { BrandService } from 'src/app/services/brands/brand.service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    selector: 'pages-list-edit-user',
    templateUrl: './list_edit_user.component.html',
    styleUrls: ['./list_edit_user.component.sass']
})
export class ListEditUserComponent implements OnInit {

    newUser: NewUser = {
        username: '',
        email: '',
        password: '',
        id_brand: this.brandService.getBrand(),
        active: 1,
        permissions: []
    }

    confirm_password: string = '';

    confirm_email: string = '';

    submitted: boolean = false;

    valid_password: boolean = true;

    passwords_match: boolean = true;

    valid_email: boolean = true;

    emails_match: boolean = true;

    roles: any = [
        { name: 'Admin', code: 'admin' },
        { name: 'Representative', code: 'representative' },
        { name: 'Managers', code: 'managers' },
        { name: 'Special', code: 'special' },
        { name: 'Add to role', code: 'add_role' }
    ];

    userStatus: any = [
        { name: 'Inactive', code: 0 },
        { name: 'Active', code: 1 }
    ];

    activeStatus: any;

    createUserDialog: boolean = false;

    editUserDialog: boolean = false;

    usernameError: boolean = false;

    selectedUsers: any[] = [];

    usersAux: User[];

    user: User;

    users: User[];

    permissions: any;

    groups_permissions: any;

    selectedGroup: any;

    selectedPermissions: number[] = [];

    userDialog: boolean;

    loading: boolean;

    breadcumbItems: MenuItem[];

    userStatuses = [];;

    items;

    itemButon;

    cols: any = [];

    colsOptional: any = [];

    selectedColumns: any = [];

    _selectedColumnsAux: any[];

    @ViewChild('dtusers') table: Table;

    @ViewChild('filter') filter: ElementRef;

    constructor(
        private readonly app: AppMainComponent,
        private userService: UserService,
        private permissionsService: PermissionsService,
        private brandService: BrandService,
    ) { }

    async ngOnInit() {
        this.loading = true;
        let usersAux = [];

        this.cols = [
            { field: 'username', header: 'Username', sort: true, filter: false }
        ]

        this.colsOptional = [
            // { field: 'active_bool', header: 'Role', sort: true, filter: true },
            { field: 'active', header: 'Status', sort: true, filter: true },
            // { field: 'email', header: 'Last Login', sort: true, filter: false },
            { field: 'email', header: 'Email', sort: true, filter: false },
            // { field: 'email', header: 'Work Phone', sort: true, filter: false },
            // { field: 'email', header: 'Timezone', sort: true, filter: false }
        ];
        this.selectedColumns = this.cols.concat(this.colsOptional)
        this._selectedColumnsAux = this.cols.concat(this.colsOptional)

        this.permissions = await this.permissionsService.getPermissions();
        var groups_permissions = await this.permissionsService.getGroupsPermissions();
        this.groups_permissions = groups_permissions.groups_permissions;
        this.groups_permissions.map(group => {
            group.id_permission = group.id_permission.map(str => {
                return Number(str);
            });
        })

        this.userService.getUsers().subscribe(async users => {
            for (let user of users.result) {
                let permission = user.id_permission.map(str => {
                    return Number(str);
                });
                const u: User = {
                    id_user: user.id_user,
                    email: user.email,
                    username: user.username,
                    active: user.active,
                    active_bool: user.active == 1 ? { name: 'Active', code: 1 } : { name: 'Inactive', code: 0 },
                    id_brand: user.id_brand,
                    permissions: permission
                }
                usersAux.push(u);
            }
            this.usersAux = usersAux;
            this.users = usersAux;
            this.loading = false;
        });
    }

    async editUser(user: User) {
        let groups_permissions = await this.permissionsService.getGroupsPermissions();
        this.groups_permissions = groups_permissions.groups_permissions;
        this.groups_permissions.map(group => {
            group.id_permission = group.id_permission.map(str => {
                return Number(str);
            });
        })
        this.permissions = await this.permissionsService.getPermissions();
        this.selectedPermissions = user.permissions;
        this.user = { ...user };
        this.editUserDialog = true;
    }

    stopOthersEvents(e){
        e.stopPropagation();
    }

    updateUserStatus(user) {
        let activeUpdateUser = {
            data: {
                id_user: user.id_user,
                active: user.active
            }
        }
        this.userService.putActiveUser(activeUpdateUser).subscribe()
    }

    saveUser() {
        this.submitted = true;

        if (this.selectedPermissions.length != 0) {
            const activeUpdateUser = {
                data: {
                    id_user: this.user.id_user,
                    active: this.user.active
                }
            }
            const permissionsUpdateUser = {
                data: {
                    id_user: this.user.id_user,
                    permissions: this.selectedPermissions
                }
            }

            this.userService.putActiveUser(activeUpdateUser).subscribe(
                data => {
                    this.userService.putPermissionsUser(permissionsUpdateUser).subscribe(
                        data => {
                            const index = this.users.findIndex(item => item.id_user === this.user.id_user);
                            this.users[index] = this.user;
                            this.editUserDialog = false;
                        },
                        error => {
                        }
                    )
                },
                error => {
                }
            )
        }
    }

    selectPermissions() {
        this.selectedPermissions = [];
        for (let group of this.selectedGroup) {
            this.selectedPermissions = this.selectedPermissions.concat(group.id_permission)
        }
        let x = document.getElementsByTagName("p-checkbox")
        let y = Array.from(x);
        y.map(a => a.classList.add("ng-dirty"))
    }

    validatePermission(permission) {
        if (permission == 5) {
            this.selectedPermissions = this.selectedPermissions.filter(perm => perm != 6)
        } else if (permission == 7) {
            this.selectedPermissions = this.selectedPermissions.filter(perm => perm != 8)
        } else if (permission == 11) {
            this.selectedPermissions = this.selectedPermissions.filter(perm => perm != 9 && perm != 10)
        } else if (permission == 13) {
            this.selectedPermissions = this.selectedPermissions.filter(perm => perm != 12)
        }

        if (permission == 6) {
            this.selectedPermissions = this.selectedPermissions.concat([5])
        } else if (permission == 8) {
            this.selectedPermissions = this.selectedPermissions.concat([7])
        } else if (permission == 9 || permission == 10) {
            this.selectedPermissions = this.selectedPermissions.concat([11])
        } else if (permission == 12) {
            this.selectedPermissions = this.selectedPermissions.concat([13])
        }
        const checkboxElements = document.getElementsByTagName("p-checkbox")
        const checkboxElementsArray = Array.from(checkboxElements);
        checkboxElementsArray.map(a => a.classList.add("ng-dirty"))
    }

    @Input() get selectedColumnsAux(): any[] {
        return this._selectedColumnsAux;
    }

    set selectedColumnsAux(val: any[]) {
        //restore original order
        this._selectedColumnsAux = this.colsOptional.filter(col => val.includes(col));
    }

    createUser() {
        this.submitted = true
        this.usernameError = false
        this.newUser.permissions = this.selectedPermissions

        if (this.newUser.username && this.newUser.email && this.newUser.password && this.newUser.permissions.length != 0 && this.validatePassword(this.newUser.password) && this.validateEmail(this.newUser.email) && this.matchPasswords() && this.matchEmails()) {
            this.userService.postUser(this.newUser).subscribe(
                data => {
                    this.hideDialog()
                },
                error => {
                    this.usernameError = true
                })
        }
    }

    createUserInvite(){
        this.submitted = true
        this.usernameError = false
        this.newUser.permissions = this.selectedPermissions
        if (this.newUser.email && this.validateEmail(this.newUser.email)) {
            this.userService.postUserInvite(this.newUser).subscribe(
                data => {
                    this.hideDialog()
                },
                error => {
                    this.usernameError = true
                })
        }
    }

    hideDialog() {
        this.createUserDialog = false;
        this.submitted = false;
        this.newUser = {
            username: '',
            email: '',
            password: '',
            id_brand: this.brandService.getBrand(),
            active: 1,
            permissions: []
        };
        this.confirm_password = ''
        this.confirm_email = ''
        this.selectedPermissions = []
    }

    validatePassword(password) {
        var passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?()/=",.;:_\\-])[A-Za-z\d#$@!%&*?()/=",.;:_\\-]{8,}$/;
        if (passwordRegex.test(password)) {
            return true
        } else {
            return false
        }
    }

    validateEmail(email) {
        var emailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailRegex.test(email)) {
            return true
        } else {
            return false
        }
    }

    matchPasswords() {
        if (this.newUser.password == this.confirm_password) {
            return true
        } else {
            return false
        }
    }

    matchEmails() {
        if (this.newUser.email == this.confirm_email) {
            return true
        } else {
            return false
        }
    }

    customize(event, op) {
        this.selectedColumns = this.cols.concat(this._selectedColumnsAux)
        this.app.overlayActive = false
        op.hide(event)
    }

    revert(event, op) {
        this.selectedColumns = this.cols.concat(this.colsOptional)
        this._selectedColumnsAux = this.cols.concat(this.colsOptional)
        this.app.overlayActive = false
        op.hide(event)
    }

    checkboxClick(e) {
        e.stopPropagation()
        var x = document.getElementsByClassName('p-checkbox-box')
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedUsers.length > 0) {
            x[0].classList.add('p-highlight')
            y[0].classList.add('pi')
            y[0].classList.add('pi-minus')
        } else {
            x[0].classList.remove('p-highlight')
            y[0].classList.remove('pi')
            y[0].classList.remove('pi-minus')
        }
    }

    mainCheckboxClick() {
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedUsers.length == this.usersAux.length) {
            y[0].classList.remove('pi-minus')
        }
    }

    async exportCSV() {
        var selectionAux = this.table._selection
        if (this.table._selection.length == 0) {
            if (this.table.filteredValue) {
                this.table._selection = this.table.filteredValue
            } else {
                this.table._selection = this.table._value
            }
        }
        await this.table.exportCSV({ selectionOnly: true })
        this.table._selection = selectionAux
    }

    openOverlay(event, op) {
        event.stopPropagation();
        op.toggle(event)
    }

    filterTable() {
        this.users = this.usersAux.filter(user => user.active == this.activeStatus)
    }

    clearFilter(event, op) {
        this.activeStatus = null
        this.users = this.usersAux
        op.hide(event)
    }

}