import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { TokenStorageService } from '../services/token-storage/token-storage.service';
import { AuthService } from '../services/auth/auth.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
const TOKEN_HEADER_KEY = 'Authorization';  // for Spring Boot back-end
//const TOKEN_HEADER_KEY = 'x-access-token';    // for Node.js Express back-end

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

    private isRefreshing = false;

    constructor(
        private tokenService: TokenStorageService,
        private authService: AuthService,
        private router: Router    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {

        let authReq = req;

        const token = this.tokenService.getToken();
        if (token != null) {
            authReq = this.addTokenHeader(req, token);
        }

        //return next.handle(authReq);

        return next.handle(authReq).pipe(catchError(error => {

            if (error.status === 403) {
                this.handle403Forbidden(authReq, next, error);
            }
            //if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/signin') && error.status === 401) {
            //    return this.handle401Error(authReq, next);
            //}
            else if (error.status === 401) {
                this.handle401Error();
            }
            return throwError(error);
        }));


        //if (!req.headers.has('Content-Type')) {
        //    const authReq = req.clone({
        //        headers: req.headers.set('Content-Type', 'application/json')
        //    });
        //    return next.handle(authReq);
        //}

        //return next.handle(authReq);

    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        /* for Spring Boot back-end */
        return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        /* for Node.js Express back-end */
        //return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
    }

    private handle403Forbidden(request: HttpRequest<any>, next: HttpHandler, error) {
        //let data = JSON.stringify(error.error);
        let data = error.error.split(':');

        this.tokenService.saveToken(data[1]);
        request = this.addTokenHeader(request, data[1]);

        if (request.method == "GET") {
            this.authService.requestGET(request);
        }
        if (request.method == "POST") {
            this.authService.requestPOST(request);
        }

        return next.handle(request);


        //return next.handle(this.addTokenHeader(request, data[1]));

    }

    private handle401Error() {

        //this.authService.signOut().subscribe(() => {

        //    this.router.navigate(['login']);

        //});

        this.tokenService.signOut();
        this.router.navigate(['login']);



    }

}

//@Injectable()
//export class AuthInterceptor implements HttpInterceptor {

//    private isRefreshing = false;
//    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

//    constructor(private tokenService: TokenStorageService, private authService: AuthService) {
//    }

//    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
//        let authReq = req;
//        const token = this.tokenService.getToken();
//        if (token != null) {
//            authReq = this.addTokenHeader(req, token);
//        }

//        return next.handle(authReq).pipe(catchError(error => {
//            if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/signin') && error.status === 401) {
//                return this.handle401Error(authReq, next);
//            }
//            return throwError(error);
//        }));
//    }

//    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
//        if (!this.isRefreshing) {
//            this.isRefreshing = true;
//            this.refreshTokenSubject.next(null);
//            const token = this.tokenService.getRefreshToken();
//            if (token)
//                return this.authService.refreshToken(token).pipe(
//                    switchMap((token: any) => {
//                        this.isRefreshing = false;
//                        this.tokenService.saveToken(token.accessToken);
//                        this.refreshTokenSubject.next(token.accessToken);

//                        return next.handle(this.addTokenHeader(request, token.accessToken));
//                    }),
//                    catchError((err) => {
//                        this.isRefreshing = false;

//                        this.tokenService.signOut();
//                        return throwError(err);
//                    })
//                );
//        }
//        return this.refreshTokenSubject.pipe(
//            filter(token => token !== null),
//            take(1),
//            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
//        );
//    }

//    private addTokenHeader(request: HttpRequest<any>, token: string) {
//        /* for Spring Boot back-end */
//        return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
//        /* for Node.js Express back-end */
//        //return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
//    }

//    public addTokenHeaderRequest(req: HttpRequest<any>, next: HttpHandler) {
//        let token = this.tokenService.getToken();

//        const modifiedReq = req.clone({
//            headers: req.headers.set('Authorization', `Bearer ${token}`),
//        });

//        return next.handle(modifiedReq);
//    }

//}

//export const authInterceptorProviders = [
//    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
//];
