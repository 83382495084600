<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
    <div class="loading"></div>
</div>
<div *ngIf="!loading" class="h-full">
    <p-toast key="tst2" [baseZIndex]="99999"></p-toast>
    <p-contextMenu #cm [model]="items" class="setAsActiveCM"></p-contextMenu> 
    <p-table #dtproducts [value]="products" [columns]="selectedColumns" dataKey="product_id" [rowHover]="true" 
        (contextmenu)="onContextMenuSelectProduct(selectedOrder)" [(contextMenuSelection)]="selectedOrder" [contextMenu]="cm" 
        [paginator]="true" [globalFilterFields]="['identifier_id', 'title', 'category_description', 'arr_sku']" [rows]="25"
        [rowsPerPageOptions]="[25,50,100]" [showCurrentPageReport]="true" [resizableColumns]="true"
        [reorderableColumns]="true" responsiveLayout="scroll" [responsive]="true" [(selection)]="selectedProducts"
        exportFilename="products"
        currentPageReportTemplate="{{ 'products.list_product.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
        <ng-template pTemplate="caption">
            <div class="flex justify-content-between gap-2 flex-wrap">
                <div>
                    <span class="p-input-icon-left w-full sm:w-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dtproducts.filterGlobal($event.target.value, 'contains')" placeholder="{{ 'general.search_here' | translate }}" />
                    </span>
                </div>
                <div class="flex gap-2 flex-wrap">
                    <button pButton pRipple label="Change the 2HD status" class="p-button-outlined"
                        (click)="displayModal = true"></button>
                    <!-- <p-splitButton label="Set as active to 2HD" [model]="items" (onClick)="displayModal = true"
                        styleClass="p-button-outlined mr-2 mb-2"></p-splitButton> -->
                    <button pButton pRipple label="Export" class="p-button-outlined" (click)="exportCSV()">
                        <span class="p-button-icon p-button-icon-left flex"><i class="pi pi-file-excel"></i></span>
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 1rem">
                    <p-tableHeaderCheckbox (click)="mainCheckboxClick()"></p-tableHeaderCheckbox>
                </th>
                <th pResizableColumn pReorderableColumn [pSortableColumn]="col.sort ? col.field : null"
                    *ngFor="let col of columns" class="cursor-move">
                    <div class="flex align-items-center"
                        [ngClass]="{'cursor-pointer' : col.sort || col.filter, 'p-column-filter-menu': col.filter }">
                        {{col.header}}
                        <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
                        <i *ngIf="col.filter && activeStatus == null" class="pi pi-filter"
                            (click)="openOverlay($event, op)"></i>
                        <i *ngIf="col.filter && activeStatus != null" class="pi pi-filter-fill"
                            (click)="openOverlay($event, op)"></i>
                    </div>
                </th>
                <th style="width: 1rem; border-left: none;">
                    <img src="assets/layout/images/config-icon.svg" (click)="openOverlay($event, op2)"
                        class="cursor-pointer">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-columns="columns" let-ri="rowIndex">
            <tr [pContextMenuRow]="item" (click)="openSideBar(item)" class="cursor-pointer">
                <td>
                    <p-tableCheckbox [value]="item" (click)="checkboxClick($event)"></p-tableCheckbox>
                </td>
                <td *ngFor="let col of columns">
                    <div *ngIf="col.field != 'title'" class="flex align-items-center gap-1">
                        <div *ngIf="col.field == 'status'" class="status-{{item[col.field]}}"></div>
                        {{item[col.field]}}
                    </div>
                    <a *ngIf="col.field == 'title'">
                        {{item[col.field]}}
                    </a>
                </td>
                <td></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="11">{{ 'general.no_data' | translate }}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
            <tr>
                <td colspan="11">{{ 'general.loading' | translate }}</td>
            </tr>
        </ng-template>
    </p-table>
    <!--
        Se clicar nr pagina volta à página 0 e retorna as x linhas
        Se clicar no butão next page carrega as seguintes x linhas
        Se clicar no botão previous vai buscar as x linhas atrás
        Se clicar no botão de ordenar então ordena a tabela na bd e retorna ordenado


        <div class="flex gap-6 align-items-center">
        <div class="flex gap-3 align-items-center">
            <div>Records per page</div>
            <p-selectButton [options]="stateOptions" [(ngModel)]="value1" optionLabel="label" optionValue="value" (click)="rowsPerPage()"></p-selectButton>
        </div>
        <div>
            <button pButton pRipple type="button" icon="pi pi-chevron-left" class="p-button-rounded" [disabled]="page == 1" (click)="previousPageTable()"></button>
            {{page}}
            <button pButton pRipple type="button" icon="pi pi-chevron-right" class="p-button-rounded" [disabled]="testeArr.length < value1" (click)="nextPageTable()"></button>
        </div>
    </div> -->
</div>
<p-overlayPanel #op>
    <ng-template pTemplate>
        <div class="field-radiobutton">
            <p-radioButton name="activeStatus" value="1" [(ngModel)]="activeStatus" inputId="1"
                (click)="filterTable()"></p-radioButton>
            <label for="1">Active</label>
        </div>
        <div class="field-radiobutton">
            <p-radioButton name="activeStatus" value="0" [(ngModel)]="activeStatus" inputId="0"
                (click)="filterTable()"></p-radioButton>
            <label for="0">Inactive</label>
        </div>
        <button pButton pRipple type="button" label="Clear" class="p-button-text p-0" [disabled]="activeStatus == null"
            (click)="clearFilter($event, op)"></button>
    </ng-template>
</p-overlayPanel>
<p-overlayPanel #op2 (onHide)="hideOverlay()" (onShow)="showOverlay()">
    <ng-template pTemplate>
        <div class="flex flex-column gap-3">
            <h6>CUSTOMIZE COLUMNS</h6>
            <div>
                <div *ngFor="let col of colsOptional" class="field-checkbox">
                    <p-checkbox name="columns" value="col" [value]="col" [(ngModel)]="selectedColumnsAux"
                        [inputId]="col.field"></p-checkbox>
                    <label [for]="col.field">{{col.header}}</label>
                </div>
            </div>
        </div>
        <div class="mt-6 flex">
            <p-button icon="pi pi-replay" (click)="revert($event, op2)" label="Revert to default"
                styleClass="p-button-text"></p-button>
            <p-button label="Customize" (click)="customize($event, op2)"></p-button>
        </div>
    </ng-template>
</p-overlayPanel>
<p-sidebar [(visible)]="visibleSidebar" position="right" [baseZIndex]="10000" (onHide)="closeSideBar()"
    [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false">
    <div class="p-sidebar-header" style="padding: 1.25rem 0" [ngClass]="{'justify-content-between' : panel2}">
        <div *ngIf="panel2">
            <button pButton pRipple type="button" icon="pi pi-arrow-left" class="p-sidebar-close"
                (click)="closePanel2()"></button>
        </div>
        <div>
            <button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
                (click)="visibleSidebar = false"></button>
        </div>
    </div>
    <div *ngIf="!product" class="flex justify-content-center align-items-center min-h-screen">
        <div class="loading"></div>
    </div>
    <div *ngIf="product && panel1" class="flex flex-column gap-5 pb-6">
        <div class="flex flex-column gap-3">
            <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
                <h5>{{product.title}} | {{product.product_id}} </h5>
                <div *ngIf="product.id_status == 1" class="flex gap-2">
                    <div class="secondH-badge">SECONDHAND</div>
                    <button pButton pRipple label="{{ 'products.list_product.sellItems' | translate }}"
                        class="p-button-secundary" (click)="resellItems()">
                    </button>
                </div>
                <div *ngIf="product.id_status == 2" class="flex gap-6">
                    <div class="secondH-badgeInactive">SECONDHAND</div>
                </div>
                <!-- <div class="status-{{product.id_status}}"></div> -->
            </div>
            <div class="grid">
                <div *ngFor="let image of product.images" class="col-4 md:col-3 lg:col-2">
                    <img [src]="image.photo" width="100%" loading="lazy">
                </div>
            </div>
        </div>
        <div class="flex flex-column gap-3">
            <b>Variants</b>
            <p-table #dtproducts [value]="product.variants" [columns]="colsSideBar" dataKey="SKU" [rowHover]="true"
                responsiveLayout="scroll" [responsive]="true" [resizableColumns]="true" [reorderableColumns]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pResizableColumn pReorderableColumn [pSortableColumn]="col.sort ? col.field : null"
                            *ngFor="let col of columns" class="cursor-move">
                            <div class="flex align-items-center"
                                [ngClass]="{'cursor-pointer' : col.sort || col.filter, 'p-column-filter-menu': col.filter }">
                                {{col.header}}
                                <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-columns="columns" let-ri="rowIndex">
                    <tr>
                        <td *ngFor="let col of columns">
                            {{item[col.field]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div *ngIf="product.listings.length > 0" class="flex flex-column gap-3">
            <b>Listings</b>
            <div class="grid w-full">
                <div *ngFor="let listing of product.listings" class="col-6 sm:col-4 md:col-3 flex flex-column gap-2">
                    <a class="block relative h-19rem lg:h-24rem w-full h-full" target="_blank"
                        href="{{link_2hand + 'product/' + product.product_id + '?variant=' + listing.product_variant_id + '&listing=' + listing.listing_id}}">
                        <img [src]="listing.photo" width="100%" height="100%"
                            style="min-height: 100px; object-fit: cover;" loading="lazy">
                        <div class="absolute"
                            style="padding: .5rem; background-color: rgb(0,0,0,0.4); color: white; top: 10px; right: 10px;">
                            {{listing.condition_description}}</div>
                    </a>
                    <div>
                        <div>{{listing.FirstName}} {{listing.LastName}}</div>
                        <div>{{listing.price}} EUR</div>
                    </div>
                </div>
            </div>
            <!-- <div class="flex justify-content-end">
                <a [routerLink]="['/main/purchase_orders']" (click)="teste()">See more..</a>
            </div> -->
        </div>
        <div *ngIf="product?.Tags != null" class="flex flex-column gap-3">
            <b>Tags</b>
            <div class="flex flex-row flex-wrap gap-2">
                <div *ngFor="let tag of product.Tags.split(',')">
                    <div class="tag-product">{{tag}}</div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="panel2" class="flex flex-column gap-5 pb-6">
        <div class="flex flex-column gap-3">
            <div class="flex flex-row justify-content-between gap-3">
                <div class="flex flex-column gap-3">
                    <div class="flex flex-column gap-2">
                        <div>{{product.title}} | {{product.product_id}}</div>
                        <div>Size: XS</div>
                        <div>Color: Black</div>
                        <div>Price:{{listingTest[0].price}} EUR</div>
                        <div>Condition: {{listingTest[0].condition}}</div>
                        <div>Status: Status</div>

                    </div>
                    <div class="flex flex-wrap gap-3">
                        <img [src]="listingTest[0].photos[0]" width="200px" loading="lazy">
                        <img [src]="listingTest[0].photos[1]" width="200px" loading="lazy">
                    </div>
                    <div>
                        <div>Sold by:</div>
                        <div class="flex flex-row gap-2 align-items-center">
                            <div class="icon">
                                <p-avatar styleClass="mr-2" shape="circle"></p-avatar>
                            </div>
                            <div class="flex flex-column">
                                <div>{{listingTest[0].customer_id}}</div>
                                <div><small>email@email.com</small></div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <div class="flex flex-column gap-3">
                <b>Offers</b>
                <p-table #dtproducts [value]="offers" [columns]="colsOffers" dataKey="SKU" [rowHover]="true"
                    responsiveLayout="scroll" [responsive]="true" [resizableColumns]="true" [reorderableColumns]="true">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th pResizableColumn pReorderableColumn *ngFor="let col of columns">
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-columns="columns" let-ri="rowIndex">
                        <tr>
                            <td *ngFor="let col of columns">
                                {{item[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</p-sidebar>
<p-dialog header="Change the 2HD status of {{selectedProductsCount()}} products?" [(visible)]="displayModal" [modal]="true"
    [style]="{width: '50vw'}" [draggable]="false" [resizable]="false" [dismissableMask]="true">
    <p class="m-0">Changing the 2HD status will make those that are inactive available to selected sales channels and applications, and will disable those that are already active.</p>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" class="p-button-outlined" (click)="displayModal = false"></button>
        <button pButton pRipple label="Change the 2HD status" class="p-button-secundary" (click)="changeAll2HandStatus()"
            (click)="displayModal = false"></button>
    </ng-template>
</p-dialog> 
<p-dialog header="{{ 'orders.returns.resell_items.resell_items' | translate }}" [(visible)]="resellItemsDialog"
    [modal]="true" [style]="{maxWidth: '90%'}" [draggable]="false" [resizable]="false" class="simple-table">
    <ng-template pTemplate="content">
        <div class="flex flex-column relative gap-2">
            <!-- <div *ngIf="resellItems.return2hand.length == 0"
                class="w-full flex justify-content-center align-items-center">
                {{ 'orders.returns.resell_items.no_products_resell' | translate }}
            </div> -->
            <div class="flex flex-column gap-3">
                <div class="p-3 bg-yellow-100 text-yellow-600 flex align-items-center gap-1">
                    <i class="pi pi-info-circle"></i>
                    <div>{{ 'orders.returns.resell_items.products_created_warn' | translate }}</div>
                </div>
                <p-table [value]="product.variants" [(selection)]="selectedResellItems" dataKey="sku"
                    responsiveLayout="stack" class="non-overflow">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 3rem">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th class="px-2">{{ 'orders.returns.resell_items.product' | translate }}</th>
                            <th class="px-2">{{ 'orders.returns.resell_items.photos' | translate }}</th>
                            <th class="px-2">{{ 'orders.returns.resell_items.quantity' | translate }}</th>
                            <th class="px-2">{{ 'orders.returns.resell_items.condition' | translate }}</th>
                            <th class="px-2">{{ 'orders.returns.resell_items.price_resell' | translate }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-productline>
                        <tr>
                            <td style="display: table-cell; width: min-content !important;"
                                [ngClass]="{'no-border': selectedResellItems.includes(productline)}">
                                <p-tableCheckbox [value]="productline"></p-tableCheckbox>
                            </td>
                            <td class="px-2 h-full"
                                [ngStyle]="{'border': selectedResellItems.includes(productline) ? 'none !important' : ''}">
                                <div class="py-3 w-15rem h-full flex gap-3 align-items-center"
                                    style="border: none !important">
                                    <img *ngIf="productline.photos[0].photo != null" [src]="productline.photos[0].photo"
                                        [alt]="productline.title" class="w-3rem shadow-2" />
                                    <div class="h-full flex flex-column gap-2">
                                        <span>{{productline.sku}}</span>
                                        <span>{{productline.product_variant_option1_description}} /
                                            {{productline.product_variant_option2_description}}</span>
                                        <span>{{productline.price}} {{currency}}</span>
                                    </div>
                                </div>
                            </td>
                            <td class="w-full px-2 gap-3"
                                [ngClass]="{'no-border': selectedResellItems.includes(productline)}">
                                <div class="w-full flex flex-wrap gap-2 align-items-end">
                                    <div *ngFor="let photo of productline.photosResell" class="relative image-uploaded">
                                        <img src="{{'data:image/png;base64,' + photo}}" class="w-auto"
                                            style="height: 2.6rem;" />
                                        <button (click)="onRemovePhotoResell(productline, photo)"
                                            class="absolute right-0 top-0 p-1 h-full w-full cursor-pointer hidden">
                                            <i class="pi pi-trash"></i>
                                        </button>
                                    </div>
                                    <p-fileUpload #photosResellUpload name="demo[]" mode="basic" accept="image/*"
                                        customUpload="true" multiple="multiple" [auto]="true" chooseLabel="Add photo"
                                        (uploadHandler)="uploadPhotosResell($event, productline, photosResellUpload)"
                                        [disabled]="!selectedResellItems.includes(productline)" class="w-auto">
                                    </p-fileUpload>
                                </div>
                            </td>
                            <td class="px-2 gap-3" [ngClass]="{'no-border': selectedResellItems.includes(productline)}">
                                <p-inputNumber [(ngModel)]="productline.quantityResell"
                                    [inputId]="productline.quantityResell" [showButtons]="true" mode="decimal"
                                    suffix=" {{ 'orders.returns.resell_items.piece' | translate}}" [min]="1"
                                    class="table-column-full w-10rem"
                                    [disabled]="!selectedResellItems.includes(productline)">
                                </p-inputNumber>
                            </td>
                            <td class="relative px-2 gap-3"
                                [ngClass]="{'no-border': selectedResellItems.includes(productline)}">
                                <p-dropdown appendTo="body" [options]="options2Hand._2handcondictions"
                                    [(ngModel)]="productline.condition"
                                    (onChange)="changeCondition(productline); textAreaChange()"
                                    class="flex table-column-full w-12rem drop-full" placeholder="Select a condition"
                                    optionLabel="description" [showClear]="false"
                                    [disabled]="!selectedResellItems.includes(productline)"></p-dropdown>
                            </td>
                            <td class="px-2 w-full gap-3"
                                [ngClass]="{'no-border': selectedResellItems.includes(productline)}">
                                <div class="table-column-full w-10rem flex flex-row gap-2 align-items-center">
                                    <p-inputNumber [(ngModel)]="productline.resellPrice"
                                        inputId="productline.resellPrice" class="w-full" [showButtons]="true"
                                        mode="currency" [currency]="currency"
                                        [disabled]="!selectedResellItems.includes(productline)">
                                    </p-inputNumber>
                                    <i pTooltip="{{ 'orders.returns.resell_items.price_resell_explain' | translate: {price:productline.price,
									condition:productline.condition.description,
									resellPrice:productline.price*productline.condition.percentage_price/100,
									condition_percentage:productline.condition.percentage_price} }}" class="pi pi-question-circle"></i>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="selectedResellItems.includes(productline)" style="height: 100%; width: 100%;">
                            <td style="display: table-cell; width: min-content !important;"></td>
                            <td colspan="5"
                                style="position: relative; justify-content: start; height: auto; padding: 1px !important">
                                <textarea style=" position: relative; top:-8px; width: -webkit-fill-available"
                                    [(ngModel)]="productline.comment" maxlength={{maxLength}} id="texts"
                                    class="textarea-input comments" rows="1"
                                    placeholder={{textAreaCommentPlaceHolder(productline)}} pInputTextarea
                                    autoResize="autoResize" (input)="textAreaChange()"></textarea>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="{{ 'general.cancel' | translate }}"
            class="p-button-outlined p-button-danger" (click)="resellItemsDialog = false"></button>
        <button pButton pRipple label="{{ 'general.save' | translate }}" type="submit" (click)="submit2HandProducts()"
            [disabled]="selectedResellItems.length == 0 || !sample_conditionSave"></button>
    </ng-template>
</p-dialog>