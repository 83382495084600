<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<div *ngIf="!loading" class="h-full">
	<p-table #dtrepairs [value]="repairs" dataKey="id_repair" [columns]="selectedColumns" [rowHover]="true"
		[paginator]="true" [globalFilterFields]="['idInterno', 'order_name', 'customer', 'country']" [rows]="25"
		[rowsPerPageOptions]="[25,50,100]" [showCurrentPageReport]="true" [resizableColumns]="true"
		[reorderableColumns]="true" responsiveLayout="scroll" [responsive]="true" [(selection)]="selectedRepairs"
		exportFilename="repairs" [loading]="loading"
		currentPageReportTemplate="{{ 'products.list_product.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
		<ng-template pTemplate="caption">
			<div class="flex justify-content-between gap-2 flex-wrap">
				<div class="flex flex-column w-full sm:w-auto sm:flex-row gap-2">
					<p-calendar [showIcon]="true" [(ngModel)]="rangeDates" selectionMode="range" dateFormat="dd/mm/yy"
						(onClose)="ngOnInit()" [minDate]="minimumDate"></p-calendar>
					<div class="flex gap-2 w-full sm:w-auto">
						<span class="p-input-icon-left w-full sm:w-auto">
							<i class="pi pi-search"></i>
							<input pInputText type="text" #filter
								(input)="dtrepairs.filterGlobal($event.target.value, 'contains')"
								placeholder="{{ 'general.search_here' | translate }}" />
						</span>
						<div (click)="ngOnInit()" class="flex align-items-center cursor-pointer"
							style="color: #6c757d;">
							<i class="pi pi-refresh"></i>
						</div>
					</div>
				</div>
				<div class="flex gap-2">
					<button pButton pRipple label="Export" class="p-button-outlined" (click)="exportCSV()">
						<span class="p-button-icon p-button-icon-left flex"><i class="pi pi-file-excel"></i></span>
					</button>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th style="width: 1rem">
					<p-tableHeaderCheckbox (click)="mainCheckboxClick()"></p-tableHeaderCheckbox>
				</th>
				<th pResizableColumn pReorderableColumn [pSortableColumn]="col.sort ? col.field : null"
					*ngFor="let col of columns" class="cursor-move">
					<div class="flex align-items-center"
						[ngClass]="{'cursor-pointer' : col.sort || col.filter, 'p-column-filter-menu': col.filter }">
						{{col.header}}
						<p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
						<i *ngIf="col.filter && activeStatus == null" class="pi pi-filter"
							(click)="openOverlay($event, op)"></i>
						<i *ngIf="col.filter && activeStatus != null" class="pi pi-filter-fill"
							(click)="openOverlay($event, op)"></i>
					</div>
				</th>
				<th style="width: 1rem; border-left: none;">
					<img src="assets/layout/images/config-icon.svg" (click)="openOverlay($event, op2)"
						class="cursor-pointer">
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-item let-columns="columns" let-ri="rowIndex">
			<tr (click)="openSideBar(item)" class="cursor-pointer">
				<td>
					<p-tableCheckbox [value]="item" (click)="checkboxClick($event)"></p-tableCheckbox>
				</td>
				<td *ngFor="let col of columns">
					<div class="flex w-min"
						[ngClass]="col.field == 'repair_status' ? 'repair-badge status-' + item.IDStatus : ''">
						<div *ngIf="col.field === 'created_at'">
							{{ item.created_at | date:'MMM d, h:mm a' }}
						</div>
						<div *ngIf="col.field != 'idInterno' && col.field!== 'created_at'">
							{{item[col.field]}}
						</div>
						<a *ngIf="col.field == 'idInterno'">
							{{item[col.field]}}
						</a>
						<div *ngIf="col.field == 'items'">
							<div *ngIf="item[col.field] == 1">&nbsp;item</div>
							<div *ngIf="item[col.field] !== 1">&nbsp;items</div>
						</div>
						<div *ngIf="col.field == 'amount'">
							&nbsp;{{item.currency}}
						</div>
					</div>
				</td>
				<td></td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="11">{{ 'general.no_data' | translate }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr>
				<td colspan="11">{{ 'general.loading' | translate }}</td>
			</tr>
		</ng-template>
	</p-table>
</div>
<p-overlayPanel #op2 (onHide)="hideOverlay()" (onShow)="showOverlay()">
	<ng-template pTemplate>
		<div class="flex flex-column gap-3">
			<h6>CUSTOMIZE COLUMNS</h6>
			<div>
				<div *ngFor="let col of colsOptional" class="field-checkbox">
					<p-checkbox name="columns" value="col" [value]="col" [(ngModel)]="selectedColumnsAux"
						[inputId]="col.field"></p-checkbox>
					<label [for]="col.field">{{col.header}}</label>
				</div>
			</div>
		</div>
		<div class="mt-6 flex">
			<p-button icon="pi pi-replay" (click)="revert($event, op2)" label="Revert to default"
				styleClass="p-button-text"></p-button>
			<p-button label="Customize" (click)="customize($event, op2)"></p-button>
		</div>
	</ng-template>
</p-overlayPanel>

<p-sidebar [(visible)]="visibleSidebar" position="right" [baseZIndex]="10000" (onHide)="hideSideBar()"
	(click)="menuShow = false" [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false"
	class="relative">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="hideSideBar()"></button>
		</div>
	</div>
	<div *ngIf="!repair || loadingSidebar" class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div>
	<div *ngIf="repair && !loadingSidebar" class="flex flex-column pb-6">
		<div class="card">
			<!-- <div class="relative flex flex-column align-items-end" style="float: right;">
				<p-button *ngIf="app.isDesktop()" label="More actions"
				   icon="pi pi-angle-down" iconPos="right" class="p-button-outlined"
				   (click)="menu.toggle($event)"></p-button>
			   <button *ngIf="!app.isDesktop()" pButton pRipple type="button"
				   icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text relative p-0 h-min"
				   (click)="menu.toggle($event)"></button>
			   <p-menu #menu [popup]="true" [model]="dropdown_options"></p-menu>
			</div> -->
			<div class="flex justify-content-between mb-3">
				<div class="flex gap-2">
					<div>
						<h5>Repair Request | {{repair.id_repair}}</h5>
					</div>
					<div class="flex gap-1">
						<div [class]="'repair-badge status-' + repair.repair_status">{{repair.repair_status_dscpt}}
						</div>
					</div>
				</div>
			</div>



			<div class="grid">
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<span>Order: {{repair.order_name}}</span>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>Submitted On: {{repair.created_at}}</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>Customer: {{repair.customer}}</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-4">
					<p>{{ 'orders.returns.email' | translate }}:
						<a href="mailto:{{repair.email}}" (click)="$event.stopPropagation()">{{repair.email}}</a>
					</p>
				</div>
				<div class="col-12 sm:col-6 md:col-6 lg:col-8">
					<p>{{ 'orders.returns.address' | translate }}: {{repair.address_line1}}
						{{repair.address_line2}} {{repair.city}} {{repair.zipcode}} {{repair.province}}
						{{repair.country}}</p>
				</div>
				<div *ngIf="repair.tracking_number_1 != null" class="col-12 sm:col-6 md:col-6 lg:col-4 pr-0">
					<p>Tracking Rec.:
						<a href="{{repair.track_link}}{{repair.tracking_number_1}}" (click)="$event.stopPropagation()"
							target="_blank" style="word-break: break-word;">{{repair.tracking_number_1}}</a>
					</p>
				</div>
				<div *ngIf="repair.tracking_number_2 != null" class="col-12 sm:col-6 md:col-6 lg:col-4 pr-0">
					<p>Tracking Send:
						<a href="{{repair.track_link}}{{repair.tracking_number_2}}" (click)="$event.stopPropagation()"
							target="_blank" style="word-break: break-word;">{{repair.tracking_number_2}}</a>
					</p>
				</div>

			</div>
		</div>
		<!-- lines -->
		<div>
			<div class="repair-summary flex flex-column gap-3">
				<div class="w-full flex flex-column lg:flex-row gap-3">
					<div class="card w-full lg:w-8">
						<h5>Repairs Items</h5>
						<div class="flex align-items-start justify-content-center">
							<p-dataView class="w-full" #dv [value]="repair.lines" [paginator]="false" layout="list">
								<ng-template let-repairline pTemplate="listItem">
									<div class="w-full">
										<div id="info-repair"
											class="info-repair flex flex-row flex-wrap gap-2 pt-5 pb-5 w-full justify-content-between">
											<div class="flex flex-column sm:flex-row gap-3 w-full">
												<div id="block-img-product"
													class="flex flex-wrap w-full sm:flex-column gap-3 sm:w-min block-img-product p-0">
													<img [src]="repairline.Image_url" [alt]="repairline.title"
														class="w-7rem sm:w-10rem shadow-2" />
												</div>
												<div class="flex flex-wrap gap-3 w-full justify-content-between">
													<div class="md:text-left flex flex-column gap-3 max-w-full">
														<span
															class="align-items-start justify-content-start"><b>{{repairline.title}}</b></span>
														<div class="flex align-items-center">
															<span class="mr-3 align-self-center md:align-self-end"><b>SKU:
																</b>{{repairline.SKU}}</span>
														</div>
														<div class="flex">
															<div *ngIf="repairline.option_1_value != null"
																class="mr-3 ">
																<span class="align-items-start justify-content-start">
																	<b>{{repairline.option_1_key}}: </b>
																	{{repairline.option_1_value}}</span>
															</div>
															<div *ngIf="repairline.option_2_value != null"
																class="mr-3 ">
																<span class="align-items-start justify-content-start">
																	<b>{{repairline.option_2_key}}: </b>
																	{{repairline.option_2_value}}</span>
															</div>
															<div *ngIf="repairline.option_3_value != null"
																class="mr-3 ">
																<span class="align-items-start justify-content-start">
																	<b>{{repairline.option_3_key}}: </b>
																	{{repairline.option_3_value}}</span>
															</div>
														</div>
														<div>
															<span class="align-items-start justify-content-start"><b>Service:
																</b>
																{{repairline.description}}</span>
														</div>
														<div class="flex align-items-center">
															<span class="mr-3 align-self-center md:align-self-end"><b>
																	{{'orders.returns.quantity' | translate }}: </b>
																{{repairline.qnt_sub}}</span>
															<span class="mr-3 align-self-center md:align-self-end"><b>
																	{{'orders.returns.received' | translate }}: </b>
																{{repairline.qnt_rec}}</span>
														</div>
														<div>
															<b>Price: </b>
															<span class="align-items-start justify-content-start">
																{{repairline.price}} {{repair.currency}}</span>
														</div>
														<div
															*ngIf="repairline.comment != null && repairline.comment != ''">
															<span class="align-items-start justify-content-start"><b>
																	Comment: </b>
																{{repairline.comment}}</span>
														</div>
														<div *ngIf="repairline.photos?.length > 0 ">
															<p-dataView [value]="repairline.photos">
																<ng-template let-linesImages pTemplate="listItem">
																	<div class="m-2">
																		<p-image [src]="linesImages.photo" width="100"
																			[preview]="true">
																		</p-image>
																	</div>
																</ng-template>
															</p-dataView>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</p-dataView>
						</div>
					</div>
					<div class="card w-full lg:w-4">
						<div class="flex-1 align-items-start justify-content-start">
							<div>
								<div class="flex justify-content-between mb-3">
									<span
										class="flex align-items-center justify-content-center font-bold text-xl">{{'orders.returns.summary'
										| translate }}</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span
										class="flex align-items-start justify-content-start">{{'orders.returns.subtotal'
										| translate }}</span>
									<span
										class="flex align-items-center justify-content-end">{{repair.subtotal}}{{repair.currency}}</span>
								</div>
								<div class="flex justify-content-between mb-2">
									<span
										class="flex align-items-start justify-content-start">{{'orders.returns.shippingcost'
										| translate}}</span>
									<span
										class="flex align-items-center justify-content-end">{{repair.shipping_cost}}{{repair.currency}}</span>
								</div>
								<!-- <div class="flex justify-content-between flex-wrap mb-2">
									<span class="flex align-items-start justify-content-start">{{'orders.returns.processingfee' | translate}}</span>
									<span class="flex align-items-center justify-content-end"> {{repair.fee}}{{repair.currency}} </span>
								</div> -->
								<div class="flex justify-content-between flex-wrap mt-3 mb-2">
									<span class="flex align-items-start justify-content-start font-bold">Total
										Amount</span>
									<span
										class="flex align-items-center justify-content-end font-bold">{{repair.amount}}{{repair.currency}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</p-sidebar>

<!--histórico sidebar-->
<p-sidebar [(visible)]="isHistoryTrue" position="right" [baseZIndex]="10001" (onHide)="hideSidebarHistory()"
	(click)="menuShow = false" [style]="{width: '85%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false"
	class="relative">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="hideSidebarHistory()"></button>
		</div>
	</div>

	<div *ngIf="!dataHistory.length && dataHistory[0]!=='undefined'"
		class="flex justify-content-center align-items-center min-h-screen">
		<div class="loading"></div>
	</div>
	<div *ngIf=" dataHistory[0]=='undefined'" class="flex justify-content-center align-items-center min-h-screen">
		<p>No history avaliable</p>
	</div>
	<div *ngIf="isHistoryTrue && dataHistory && dataHistory.length && dataHistory[0]!=='undefined'">
		<div class="card-title" style="padding-top: 50px; padding-bottom: 50px;">History</div>
		<p-timeline [value]="dataHistory" class="w-full timeline">
			<ng-template pTemplate="content" let-event>
				{{dateTimeLine(event)}}
				<br>
				{{IDStatusDescriptionTimeLine(event)}}
			</ng-template>
		</p-timeline>
	</div>
</p-sidebar>