import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '../../service/app.config.service';
import { AppConfig } from '../../api/appconfig';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service'
import { MessageService } from 'primeng/api';
import { TokenStorageService } from '../../services/token-storage/token-storage.service';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { BrandService } from '../../services/brands/brand.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'pages-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.sass'],
  styles:[`
    :host ::ng-deep .pi-eye{
      transform:scale(1.6);
      margin-right: 1rem;
      color: var(--primary-color) !important;
    }

    :host ::ng-deep .pi-eye-slash{
      transform:scale(1.6);
      margin-right: 1rem;
      color: var(--primary-color) !important;
    }
  `]
})

export class LoginComponent implements OnInit {

    config: AppConfig;
  
    subscription: Subscription;

    email: string = null;

    password: string = null;

    submitted: boolean = false;

    errorLogin: boolean = false;

    data: object = {};

    constructor(
        private tokenStorage: TokenStorageService,
        private router: Router,
        public configService: ConfigService,
        private authService: AuthService,
        private permissions: PermissionsService,
        private brandservice: BrandService,
        private readonly translateService: TranslateService
    ) { }

    ngOnInit(): void {
        document.getElementsByTagName('body')[0].classList.remove('fixed')
        this.config = this.configService.config;
        this.subscription = this.configService.configUpdate$.subscribe(config => { this.config = config; });
    }

    onSubmit() {
        this.submitted = true;
        if (this.email && this.password) {
            this.data = { 'email': this.email, 'password': this.password };

            this.authService.signIn(this.data).subscribe(
                async data => {
                    //this.result = data;
                  // data.User.permissions.splice(16,1)
                    this.permissions.savePermissions(data.User.permissions);
                   
                    this.brandservice.saveBrand(data.User.id_brand);
                    this.translateService.use(data.User.language_code.match(/gb|pt/) ? data.User.language_code : 'gb')
                    this.tokenStorage.saveToken(data.User['Bearer Token']);

                    window.sessionStorage.setItem("language_code", data.User.language_code);
                    window.sessionStorage.setItem("returncenter_url", data.User.returncenter_url);
                    window.sessionStorage.setItem("2hand_link", data.User["2hand_link"]);
                    window.sessionStorage.setItem("shop_domain", data.User.shop_domain);
                    window.sessionStorage.setItem("wms", (data.User.logistic_op != undefined ? "true" : "false"));
                    window.sessionStorage.setItem("id_token", data.User.id_token);
                    window.sessionStorage.setItem("username", data.User.username);
                    window.sessionStorage.setItem("id_user", data.User.id_user);
                    window.sessionStorage.setItem("user_details", JSON.stringify(data.User));
                    this.router.navigate(['main']);
                    this.submitted = false;
                    
                },
                error => {
                    this.errorLogin = true;
                    this.tokenStorage.signOut();
                }
            );
        }
    }
}
