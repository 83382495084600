import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ContextMenu } from 'primeng/contextmenu';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { OrdersBrand } from 'src/app/api/brand';
import { CountryService } from 'src/app/services/country/country.service';
import { OrdersBrandService } from 'src/app/services/orders_brand/orders_brand.service';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { BrandService } from 'src/app/services/brands/brand.service';
import { AppMainComponent } from 'src/app/app.main.component';
import * as JSZip from 'jszip';
import * as saveAs from 'file-saver';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
const moment = require('moment');

@Component({
    selector: 'pages-orders_brands',
    templateUrl: './orders_brands.component.html',
    styleUrls: ['./orders_brands.component.scss']
})
export class OrdersBrandsComponent implements OnInit {
    pipe = new DatePipe('en-US');

    minimumDate: Date = new Date('06/15/2022');

    ccRegex: RegExp = /(.*?)/;

    ccEmailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    rangeDates: Date[] = [
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(),
    ];
    manifestDialog: boolean = false;

    totalOrders: number = 0;

    ordersOnHold: number = 0;

    ordersFullfield: number = 0;

    ordersRestocked: number = 0;

    ordersRTO: number = 0;

    regex_example: string;

    orderDialog: boolean;

    submitted: boolean;

    selectedOrders: any[] = [];

    ordersBrands: OrdersBrand[];

    orderBrand: OrdersBrand;

    ordersBrandsAux: OrdersBrand[];

    selectedOrder: OrdersBrand;

    loading: boolean = true;

    isZipError: boolean;

    isNameError: boolean;

    isEmailError: boolean;

    trackingStatuses;

    dateIAux: string;

    dateFAux: string;

    orderStatuses = [];

    idBrand: any;

    loadingNewItens: Boolean = false;

    loadingNewItensAux: Boolean = false;

    viewInIcon: string;

    ispaginationLoading: boolean = true;

    lastItem: any = null;

    howManyOrdersRemoved: number = 0;

    isCalendarDisabled: boolean = true;

    lastRow(row: any) {//get row to show only one loading icon in page loading shopify
        this.lastItem = row;
    }

    cols: any = [
        { field: 'order_name', header: 'Order', sort: true, filter: false }
    ];

    colsOptional: any = [
        { field: 'customer', header: 'Customer', sort: true, filter: false },
        { field: 'address', header: 'Address', sort: true, filter: false },
        { field: 'date', header: 'Order Date', sort: true, filter: false },
        { field: 'country_code', header: 'Country', sort: true, filter: false },
        { field: 'totalAux', header: 'Total', sort: true, filter: false },
        { field: 'tracking', header: 'Tracking', sort: true, filter: false },
        { field: 'wmsStatus', header: 'WMS Status', sort: true, filter: false }
    ];

    selectedColumns: any = [];

    _selectedColumnsAux: any = [];

    orderStatus = new Map([
        ['fulfilled', 'pi-check'],
        ['unfulfilled', 'pi-times'],
        ['partial', 'pi-exclamation-circle'],
        ['restocked', 'pi-fast-backward']
    ]);

    // carrierLink = new Map([
    //     [
    //         'DHL Parcel',
    //         'https://clientesparcel.dhl.es/LiveTrackingN/ModifyMyShipment/',
    //     ],
    //     [
    //         'DHL Express',
    //         'https://www.dhl.com/us-en/home/tracking.html?tracking-id=',
    //     ],
    //     ['DPD', 'https://dpd.pt/track-and-trace?reference='],
    // ]);

    items = [];

    breadcumbItems: MenuItem[] = [];

    @ViewChild('dtorders') table: Table;

    @ViewChild('filter') filter: ElementRef;

    @ViewChild('cm') contextMenu!: ContextMenu;

    permissionReturn: boolean;

    acceptreturn: boolean;

    labelDialog = false;

    inputNBoxes: number;

    inputWeight: number;

    generatedLabel: boolean = false;

    finantial_statusAux: string = "";

    getFulfilled: boolean = true;

    getRTO: boolean = false;

    //removeFulfilledAux:any=[];

    constructor(
        private app: AppMainComponent,
        private ordersBrandService: OrdersBrandService,
        private brandService: BrandService,
        private countryService: CountryService,
        private messageService: MessageService,
        private readonly translateService: TranslateService,
        private permissions: PermissionsService
    ) { }

    async ngOnInit() {
        this.loading = true;
        const permissionsType = this.permissions.getPermissionsType();
        this.permissionReturn = this.permissions.checkPermissionsSubmenu(permissionsType.consultReturn);
        const accept = JSON.parse(window.sessionStorage.getItem("brands_permissions"));
        this.acceptreturn = Boolean(accept["returns"]);


        this.idBrand = this.brandService.getBrand();
        if (this.idBrand == 1) {
            if (!this.colsOptional.find(col => col.field == 'documents')) {
                this.colsOptional.push({ field: 'documents', header: 'Documents', sort: false, filter: false })
            }
        }

        if ([6, 7].includes(this.idBrand)) {
            this.getFulfilled = false;
            const existsFinancialStatus = this.colsOptional.some((item: any) => item.field === 'financial_status');
            if (!existsFinancialStatus)
                this.colsOptional.push(
                    { field: 'financial_status', header: 'Financial Status', sort: true, filter: false }
                )
        }

        this.selectedColumns = this.cols.concat(this.colsOptional)
        this._selectedColumnsAux = this.cols.concat(this.colsOptional)

        this.orderBrand = {
            id: 0,
            order_name: "",
            email: "",
            shipping_address: {
                first_name: "",
                address1: "",
                phone: "",
                city: "",
                zip: "",
                province: "",
                country: "",
                last_name: "",
                address2: "",
                company: "",
                latitude: 0,
                longitude: 0,
                name: "",
                country_code: "",
                province_code: "",
            },
            carrier: "",
            tracking: 0,
            carrier_link: "",
            tags: "",
            order_status: "",
            error: [],
            date: this.pipe.transform(
                new Date(),
                'dd/MM/yyyy HH:mm'
            ),
            line_items: [],
            refunds: [],
            subtotal: 0,
            shipping_total: 0,
            total: "",
            currency: "",
            rto: false,
            _moloni_sent: "",
            isLocalPickup: false,
            financial_status: "",
            discount_ambar: false,
            payment_gateway_names: [],
            fulfillment_status: null,
            financial_statusAux2: "",
            totalDiscount: 0,
            totalAux: "",
            archivedwms: 0,
            errorwms: 0
        };

        //load data to translate
        await this.translateService.get('dummyTranslation').toPromise().then();

        this.translateService.stream('general.menu').subscribe((val) => {
            this.breadcumbItems = [
                { label: '', icon: 'pi pi-home' },
                { label: val.orders.value },
                { label: val.orders.items.consult_orders },
            ];
        });

        this.translateService.stream('orders.orders').subscribe((val) => {
            this.orderStatuses = [
                { label: val.fulfilled, value: 'fulfilled' },
                { label: val.unfulfilled, value: 'unfulfilled' },
                { label: val.partial, value: 'partial' },
                { label: val.restocked, value: 'restocked' },
            ];
        });


        const dateI = this.pipe.transform(this.rangeDates[0], 'yyyy-MM-dd');
        let dateF;
        if (this.rangeDates[1] == null) {
            dateF = dateI;
        } else {
            dateF = this.pipe.transform(this.rangeDates[1], 'yyyy-MM-dd');
        }

        let page = 0;
        if (this.idBrand == 4) {//all woocomerce brands
            page = -1;
        }
        await this.ordersBrandService
            .getOrdersByDate(dateI, dateF, page, 100, this.getFulfilled, this.getRTO)
            .then(async (orders) => {
                if (orders.orders.length == 0) {
                    this.ordersBrandsAux = orders.orders;
                    this.ordersBrands = orders.orders;
                    return;
                }
                orders = this.orderDate(orders, 'desc');
                const ordersBrandsAux = [];
                let shipping_address;
                await orders.orders.map(async (order) => {
                    if (order.fulfillment_status == undefined || order.fulfillment_status == null) {
                        order.fulfillment_status = '';
                    }
                    if (order.financial_status != null && order.financial_status != undefined) {
                        order.financial_statusAux2 = order.financial_status;
                        this.finantial_statusAux = order.financial_status;
                        if (order.financial_status == "paid" && [6, 7].includes(this.idBrand)) {
                            this.finantial_statusAux = "Paid"
                        } else if ([6, 7].includes(this.idBrand)) {
                            this.finantial_statusAux = "Unpaid";
                        }
                    }

                    //tirar de array para não fazer esta verificação
                    let carrier = null,
                        tracking = null,
                        carrier_link = null,
                        order_status = order.fulfillment_status;
                    if (order.fulfillments[0] != null) {
                        //carrier = order.fulfillments[0].tracking_company; // while company name is not correct in shopify
                        order.fulfillments[0].tracking_url = order.fulfillments[0].tracking_url || "" // remove nulls
                        carrier = (order.fulfillments[0].tracking_url.includes("parcel") == true ? "DHL Parcel" :
                            order.fulfillments[0].tracking_url.includes("ctt") == true ? "CTT" : "DHL Express");
                        tracking = order.fulfillments[0].tracking_number;
                        carrier_link = order.fulfillments[0].tracking_url;
                    }

                    if (order.fulfillment_status != undefined) {
                        if (order.fulfillment_status.includes('null')) {
                            order_status = 'unfulfilled';
                        }
                    }

                    if (order.shipping_address != undefined) {
                        shipping_address = {
                            first_name: order.shipping_address.first_name,
                            address1: order.shipping_address.address1,
                            phone: order.shipping_address.phone != '' ? order.shipping_address.phone : order.customer.default_address.phone,
                            city: order.shipping_address.city,
                            zip: order.shipping_address.zip,
                            province: order.shipping_address.province,
                            country: order.shipping_address.country,
                            last_name: order.shipping_address.last_name,
                            address2: order.shipping_address.address2,
                            company: order.shipping_address.company,
                            latitude: order.shipping_address.latitude,
                            longitude: order.shipping_address.longitude,
                            name: order.shipping_address.name,
                            country_code:
                                order.shipping_address.country_code,
                            province_code:
                                order.shipping_address.province_code,
                        };
                    } else if (order.customer != undefined) {
                        shipping_address = {
                            first_name:
                                order.customer.default_address.first_name,
                            address1:
                                order.customer.default_address.address1,
                            phone: order.customer.default_address.phone,
                            city: order.customer.default_address.city,
                            zip: order.customer.default_address.zip,
                            province:
                                order.customer.default_address.province,
                            country: order.customer.default_address.country,
                            last_name:
                                order.customer.default_address.last_name,
                            address2:
                                order.customer.default_address.address2,
                            company: order.customer.default_address.company,
                            latitude:
                                order.customer.default_address.latitude,
                            longitude:
                                order.customer.default_address.longitude,
                            name: order.customer.default_address.name,
                            country_code:
                                order.customer.default_address.country_code,
                            province_code:
                                order.customer.default_address
                                    .province_code,
                        };
                    }
                    else {
                        shipping_address = {}
                    }

                    const orderBrand: OrdersBrand = {
                        id: order.id,
                        order_name: order.name,
                        email: order.email,
                        customer: shipping_address.name,
                        address: shipping_address.address1,
                        country_code: shipping_address.country_code,
                        shipping_address: shipping_address,
                        carrier: carrier,
                        tracking: tracking,
                        carrier_link: carrier_link,
                        tags: order.tags,
                        order_status: order_status,
                        error: order.error,
                        //date: this.app.getDateOnFormatString(order.created_at),
                        date: order.created_at,
                        line_items: order.line_items,
                        refunds: order.refunds,
                        subtotal: order.total_line_items_price_set.presentment_money.amount,
                        shipping_total: order.total_shipping_price_set.presentment_money.amount,
                        total: ((Number(order.total_line_items_price_set.presentment_money.amount) + Number(order.total_shipping_price_set.presentment_money.amount)) - order.discounts).toFixed(2),
                        currency: order.currency,
                        order_ship_status: order.order_status_ship,
                        label: order.label,
                        pod: order.pod,
                        current_subtotal_price_set: order.current_subtotal_price_set,
                        rto: order.rto,
                        _moloni_sent: order?._moloni_sent?.value,
                        isLocalPickup: order.isLocalPickup,
                        financial_status: this.finantial_statusAux,
                        discount_ambar: order.discount_ambar,
                        payment_gateway_names: order.payment_gateway_names,
                        fulfillment_status: order.fulfillment_status,
                        financial_statusAux2: order.financial_statusAux2,
                        totalDiscount: order.discounts.toFixed(2),
                        totalAux: (((Number(order.total_line_items_price_set.presentment_money.amount) + Number(order.total_shipping_price_set.presentment_money.amount)) - order.discounts).toFixed(2) + " " + order.current_subtotal_price_set.presentment_money.currency_code).toString(),
                        archivedwms: order.archivedwms,
                        errorwms: order.errorwms
                    };
                    orderBrand.icon = this.orderStatus.get(
                        orderBrand.order_status
                    );
                    /*if(!this.getFulfilled && order.fulfillment_status=="fulfilled"){
                        this.removeFulfilledAux.push({//save fulfilled
                            index: index,
                            orders:orderBrand
                        });
                        return;
                    }*/

                    ordersBrandsAux.push(orderBrand);
                });
                this.ordersBrandsAux = ordersBrandsAux;
                this.ordersBrands = ordersBrandsAux;
                
            });
            
        this.dateIAux = dateI;
        this.dateFAux = dateF;
        this.loading = false;
        this.ispaginationLoading = true

        const orderStatusFields =
            document.getElementsByClassName('order-status');
        const arr = Array.from(orderStatusFields);
        arr.map((orderStatusField) => {
            orderStatusField.removeAttribute('disabled');
        });

        this.totalOrders = this.ordersBrands?.length;
        this.ordersOnHold = this.ordersBrands?.filter(
            (el) =>
                el.order_status == 'unfulfilled'
        ).length;
        this.ordersFullfield = this.ordersBrands?.filter(
            (el) => el.order_status == 'fulfilled' ||
                el.order_status == 'partial'
        ).length;
        this.ordersRestocked = this.ordersBrands?.filter(
            (el) => el.order_status == 'restocked'
        ).length;
        this.ordersRTO = this.ordersBrands?.filter(
            (el) =>
                el.rto == true
        ).length;


        // let obj = document.getElementById('totalOrders');
        // this.animate(obj, 0, this.totalOrders, 1000);
        // let obj1 = document.getElementById('ordersOnHold');
        // this.animate(obj1, 0, this.ordersOnHold, 1000);
        // let obj2 = document.getElementById('ordersFullfield');
        // this.animate(obj2, 0, this.ordersFullfield, 1000);
        // let obj3 = document.getElementById('ordersRestocked');
        // this.animate(obj3, 0, this.ordersRestocked, 1000);
        // let obj4 = document.getElementById('ordersRTO');
        // this.animate(obj4, 0, this.ordersRTO, 1000);


    }

    async init(page, numberRows) {
        if (this.loadingNewItensAux) return;
        this.loadingNewItens = true;
        this.loadingNewItensAux = true;
        const dateI = this.pipe.transform(this.rangeDates[0], 'yyyy-MM-dd');
        let dateF;
        if (this.rangeDates[1] == null) {
            dateF = dateI;
        } else {
            dateF = this.pipe.transform(this.rangeDates[1], 'yyyy-MM-dd');
        }
        await this.ordersBrandService
            .getOrdersByDate(dateI, dateF, page, numberRows, this.getFulfilled, this.getRTO)
            .then(async (orders) => {
                if (orders.orders.length == 0) return;
                if (this.idBrand != 4)//woocomerce does not need sorting
                    orders = this.orderDate(orders, 'desc');
                const ordersBrandsAux = [];
                let shipping_address;
                await orders.orders.map(async (order) => {
                    if (order.fulfillment_status == undefined || order.fulfillment_status == null)
                        order.fulfillment_status = '';

                    if (order.financial_status != null && order.financial_status != undefined) {
                        this.finantial_statusAux = order.financial_status;
                        order.financial_statusAux2 = order.financial_status;
                        if (order.financial_status == "paid" && [6, 7].includes(this.idBrand)) {
                            this.finantial_statusAux = "Paid"
                        } else if ([6, 7].includes(this.idBrand)) {
                            this.finantial_statusAux = "Unpaid";
                        }
                    }
                    //tirar de array para não fazer esta verificação
                    let carrier = null,
                        tracking = null,
                        carrier_link = null,
                        order_status = order.fulfillment_status;
                    if (order.fulfillments[0] != null) {
                        //carrier = order.fulfillments[0].tracking_company; // while company name is not correct in shopify
                        order.fulfillments[0].tracking_url = order.fulfillments[0].tracking_url || "" // remove nulls
                        carrier = (order.fulfillments[0].tracking_url.includes("parcel") == true ? "DHL Parcel" :
                            order.fulfillments[0].tracking_url.includes("ctt") == true ? "CTT" : "DHL Express");
                        tracking = order.fulfillments[0].tracking_number;
                        carrier_link = order.fulfillments[0].tracking_url;
                    }

                    if (order.fulfillment_status != undefined) {
                        if (order.fulfillment_status.includes('null')) {
                            order_status = 'unfulfilled';
                        }
                    }

                    if (order.shipping_address != undefined) {
                        shipping_address = {
                            first_name: order.shipping_address.first_name,
                            address1: order.shipping_address.address1,
                            phone: order.shipping_address.phone,
                            city: order.shipping_address.city,
                            zip: order.shipping_address.zip,
                            province: order.shipping_address.province,
                            country: order.shipping_address.country,
                            last_name: order.shipping_address.last_name,
                            address2: order.shipping_address.address2,
                            company: order.shipping_address.company,
                            latitude: order.shipping_address.latitude,
                            longitude: order.shipping_address.longitude,
                            name: order.shipping_address.name,
                            country_code: order.shipping_address.country_code,
                            province_code: order.shipping_address.province_code,
                        };
                    } else if (order.customer != undefined) {
                        shipping_address = {
                            first_name: order.customer.default_address.first_name,
                            address1: order.customer.default_address.address1,
                            phone: order.customer.default_address.phone,
                            city: order.customer.default_address.city,
                            zip: order.customer.default_address.zip,
                            province: order.customer.default_address.province,
                            country: order.customer.default_address.country,
                            last_name: order.customer.default_address.last_name,
                            address2: order.customer.default_address.address2,
                            company: order.customer.default_address.company,
                            latitude: order.customer.default_address.latitude,
                            longitude: order.customer.default_address.longitude,
                            name: order.customer.default_address.name,
                            country_code: order.customer.default_address.country_code,
                            province_code: order.customer.default_address.province_code,
                        };
                    } else {
                        shipping_address = {};
                    }

                    const orderBrand: OrdersBrand = {
                        id: order.id,
                        order_name: order.name,
                        email: order.email,
                        customer: shipping_address.name,
                        address: shipping_address.address1,
                        country_code: shipping_address.country_code,
                        shipping_address: shipping_address,
                        carrier: carrier,
                        tracking: tracking,
                        carrier_link: carrier_link,
                        tags: order.tags,
                        order_status: order_status,
                        error: order.error,
                        date: order.created_at,
                        line_items: order.line_items,
                        refunds: order.refunds,
                        subtotal: order.total_line_items_price_set.presentment_money.amount,
                        shipping_total: order.total_shipping_price_set.presentment_money.amount,
                        total: ((Number(order.total_line_items_price_set.presentment_money.amount) + Number(order.total_shipping_price_set.presentment_money.amount)) - order.discounts).toFixed(2),
                        currency: order.currency,
                        order_ship_status: order.order_status_ship,
                        label: order.label,
                        pod: order.pod,
                        current_subtotal_price_set: order.current_subtotal_price_set,
                        rto: order.rto,
                        _moloni_sent: order?._moloni_sent?.value,
                        isLocalPickup: order.isLocalPickup,
                        financial_status: this.finantial_statusAux,
                        discount_ambar: order.discount_ambar,
                        payment_gateway_names: order.payment_gateway_names,
                        fulfillment_status: order.fulfillment_status,
                        financial_statusAux2: order.financial_statusAux2,
                        totalDiscount: order.discounts.toFixed(2),
                        totalAux: (((Number(order.total_line_items_price_set.presentment_money.amount) + Number(order.total_shipping_price_set.presentment_money.amount)) - order.discounts).toFixed(2) + " " + order.current_subtotal_price_set.presentment_money.currency_code).toString(),
                        archivedwms: order.archivedwms,
                        errorwms: order.errorwms
                    };
                    orderBrand.icon = this.orderStatus.get(
                        orderBrand.order_status
                    );

                    ordersBrandsAux.push(orderBrand);
                });
                this.ordersBrandsAux = this.ordersBrandsAux.concat(ordersBrandsAux);
                this.ordersBrands = this.ordersBrands.concat(ordersBrandsAux);

            });
        /*this.ordersBrandsAux = this.orderDate2(this.ordersBrandsAux, 'desc');
        this.ordersBrands = this.orderDate2(this.ordersBrands, 'desc');*/
        this.dateIAux = dateI;
        this.dateFAux = dateF;
        this.loadingNewItens = false;
        this.loadingNewItensAux = false;
        this.ispaginationLoading = true
        const orderStatusFields =
            document.getElementsByClassName('order-status');
        const arr = Array.from(orderStatusFields);
        arr.map((orderStatusField) => {
            orderStatusField.removeAttribute('disabled');
        });

        this.totalOrders = this.ordersBrands?.length;
        this.ordersOnHold = this.ordersBrands?.filter(
            (el) =>
                el.order_status == 'unfulfilled'
        ).length;
        this.ordersFullfield = this.ordersBrands?.filter(
            (el) => el.order_status == 'fulfilled' ||
                el.order_status == 'partial'
        ).length;
        this.ordersRestocked = this.ordersBrands?.filter(
            (el) => el.order_status == 'restocked'
        ).length;
        this.ordersRTO = this.ordersBrands?.filter(
            (el) =>
                el.rto == true
        ).length;


    }


    orderDate(data: any, order: string): any {
        if (order === 'desc') {

            const sortByHour = (a, b) => {
                const aDateTime = moment(a.created_at, 'YYYY-MM-DDTHH:mm:ssZ');
                const bDateTime = moment(b.created_at, 'YYYY-MM-DDTHH:mm:ssZ');

                if (aDateTime.isBefore(bDateTime)) {
                    return 1; //asc change to negative
                }
                if (aDateTime.isAfter(bDateTime)) {
                    return -1; // asc change to positive
                }

                return 0;
            };

            data.orders = data.orders.sort((a, b) => {
                const aa = moment(a.created_at, 'YYYY-MM-DD').valueOf();
                const bb = moment(b.created_at, 'YYYY-MM-DD').valueOf();

                if (aa === bb) {
                    return sortByHour(a, b);
                }
                return bb - aa;
            });

            return data;
        }
    }
    orderDate2(data: any, order: string): any {
        if (order === 'desc') {

            const sortByHour = (a, b) => {
                const aDateTime = moment(a.date, 'YYYY-MM-DDTHH:mm:ssZ');
                const bDateTime = moment(b.date, 'YYYY-MM-DDTHH:mm:ssZ');

                if (aDateTime.isBefore(bDateTime)) {
                    return 1; //asc change to negative
                }
                if (aDateTime.isAfter(bDateTime)) {
                    return -1; // asc change to positive
                }

                return 0;
            };

            data = data.sort((a, b) => {
                const aa = moment(a.date, 'YYYY-MM-DD').valueOf();
                const bb = moment(b.date, 'YYYY-MM-DD').valueOf();

                if (aa === bb) {
                    return sortByHour(a, b);
                }
                return bb - aa;
            });

            return data;
        }
    }


    openDialog(item) {
        this.orderBrand = { ...item };
        this.orderDialog = true;
        // if ([6, 7, 1, 4].includes(this.idBrand))
        //     this.labelAmbarAvaliable(this.orderBrand.id);
    }

    openOverlay(event, op) {
        event.stopPropagation();
        op.toggle(event)
    }

    showOverlay() {
        this.app.overlayActive = true
    }

    hideOverlay() {
        this.app.overlayActive = false
    }

    @Input() get selectedColumnsAux(): any[] {
        return this._selectedColumnsAux;
    }

    set selectedColumnsAux(val: any[]) {
        //restore original order
        this._selectedColumnsAux = this.colsOptional.filter(col => val.includes(col));
    }

    customize(event, op) {
        this.selectedColumns = this.cols.concat(this._selectedColumnsAux)
        this.app.overlayActive = false
        op.hide(event)
    }

    revert(event, op) {
        this.selectedColumns = this.cols.concat(this.colsOptional)
        this._selectedColumnsAux = this.cols.concat(this.colsOptional)
        this.app.overlayActive = false
        op.hide(event)
    }

    async editOrder(order: OrdersBrand) {

        let isZipErrorAux = false;
        let isNameErrorAux = false;
        let isEmailErrorAux = false;
        this.orderBrand = { ...order };
        this.orderDialog = true;

        setTimeout(function () {
            let elem = document.getElementsByClassName("p-dialog-title")[0]
            let div = document.getElementsByClassName("rto_div")[0]
            if (div) {
                elem.append(div)
            }
        });

        if (order.error != null) {
            await this.countryService
                .getCountrByCode(order.shipping_address.country_code)
                .then((country) => {
                    this.regex_example = country.country_ziperror;
                    this.ccRegex = new RegExp(country.country_ziprule);
                });

            order.error.forEach(function (e) {
                const errorField = e.error.substring(6);
                if (errorField == 'shipping_address.zip_code') {
                    isZipErrorAux = true;
                } else if (
                    errorField == 'shipping_address.first_name' ||
                    errorField == 'shipping_address.last_name'
                ) {
                    isNameErrorAux = true;
                } else if (errorField == 'email') {
                    isEmailErrorAux = true;
                }
                setTimeout(function () {
                    const errorDisabledField =
                        document.getElementById(errorField);
                    errorDisabledField.removeAttribute('disabled');
                }, 100);
            });
        }
        this.isZipError = isZipErrorAux;
        this.isNameError = isNameErrorAux;
        this.isEmailError = isEmailErrorAux;

        // calcule line total
        for (let line of this.orderBrand.line_items) {
            line.total = (Number(line.price_set.presentment_money.amount) * line.quantity).toFixed(2);
        }
    }

    async rowSelected(event: any) {
        let order: OrdersBrand = event.data
        this.editOrder(order)
    }

    async rowGoTO(order: OrdersBrand) {
        const shop_domain = window.sessionStorage.getItem("shop_domain");

        if (shop_domain == undefined) {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: this.translateService.instant('orders.orders.noshop_domain'),
            });

            return;
        }


        var order_id = order.id;

        if (order_id == undefined) {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: this.translateService.instant('orders.orders.noiddefined'),
            });
        }

        window.open("https://" + shop_domain + '/admin/orders/' + order_id, '_blank').focus();
    }

    hideDialog() {
        this.orderDialog = false;
        this.submitted = false;
    }

    saveOrder() {
        this.submitted = true;
        const invalidFields = document.getElementsByClassName(
            'ng-invalid ng-dirty'
        );
        let fieldsError = false;
        if (invalidFields.length != 0) {
            fieldsError = true;
        }
        if (!fieldsError) {
            this.orderBrand.error = null;
            this.orderBrand.shipping_address.name =
                this.orderBrand.shipping_address.first_name +
                ' ' +
                this.orderBrand.shipping_address.last_name;
            let orderBrand = {
                id: this.orderBrand.id,
                email: this.orderBrand.email,
                error: [{}],
                shipping_address: this.orderBrand.shipping_address,
            };

            this.ordersBrandService.putOrder(orderBrand).subscribe(
                (data) => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: this.translateService.instant(
                            'orders.orders.success'
                        ),
                        detail: this.translateService.instant(
                            'orders.orders.update_success'
                        ),
                    });
                },
                (error) => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: this.translateService.instant('general.error'),
                        detail: this.translateService.instant(
                            'orders.orders.error_update'
                        ),
                    });
                }
            );

            this.ordersBrands = [...this.ordersBrands];
            this.orderDialog = false;
        } else {
            this.messageService.add({
                key: 'tst',
                severity: 'warn',
                summary: this.translateService.instant('orders.orders.warning'),
                detail: this.translateService.instant(
                    'orders.orders.warning_update'
                ),
            });
        }
    }

    filterOrdersByStatus(orderStatus) {
        const orderStatusFields =
            document.getElementsByClassName('order-status');

        const arr = Array.from(orderStatusFields);
        arr.map((orderStatusField) => {
            if (orderStatusField.id == orderStatus) {
                if (!orderStatusField.classList.contains('active')) {
                    orderStatusField.classList.add('active');
                } else {
                    orderStatusField.classList.remove('active');
                }
            } else {
                orderStatusField.classList.remove('active');
            }
        });

        var orderStatusField = document.getElementById(orderStatus);
        if (orderStatusField.classList.contains('active')) {
            if (orderStatus == 'total') {
                this.ordersBrands = this.ordersBrandsAux;
            } else if (orderStatus == 'onHold') {
                this.ordersBrands = this.ordersBrandsAux.filter(
                    (e) =>
                        e.order_status == 'unfulfilled'
                );
            } else if (orderStatus == 'fullfield') {
                this.ordersBrands = this.ordersBrandsAux.filter(
                    (e) => e.order_status == 'fulfilled' ||
                        e.order_status == 'partial'
                );
            } else if (orderStatus == 'restocked') {
                this.ordersBrands = this.ordersBrandsAux.filter(
                    (e) => e.order_status == 'restocked'
                );
            } else if (orderStatus == 'rto') {
                this.ordersBrands = this.ordersBrandsAux.filter(
                    (e) => e.rto == true
                );
            } else {
                this.ordersBrands = this.ordersBrandsAux.filter(
                    (e) => e.order_status == orderStatus
                );
            }
        } else {
            this.ordersBrands = this.ordersBrandsAux;
        }
    }

    animate(obj, initVal, lastVal, duration) {
        let startTime = null;
        let currentTime = Date.now();

        const step = (currentTime) => {
            if (!startTime) {
                startTime = currentTime;
            }
            const progress = Math.min((currentTime - startTime) / duration, 1);
            obj.innerHTML = Math.floor(
                progress * (lastVal - initVal) + initVal
            );

            if (progress < 1) {
                window.requestAnimationFrame(step);
            } else {
                window.cancelAnimationFrame(window.requestAnimationFrame(step));
            }
        };
        window.requestAnimationFrame(step);
    }

    remove(inside: boolean) {
        if (!inside)
            this.items = [];
    }

    onContextMenuSelect(selectedOrder: any) {

        if (this.idBrand == 4) {
            let inside = false;
            if (selectedOrder != undefined && selectedOrder.order_status != undefined && selectedOrder.order_status == 'completed') {
                this.translateService.stream('orders.orders').subscribe((val) => {
                    this.remove(inside);
                    inside = true;

                    if (this.acceptreturn && this.permissionReturn)
                        this.items = [

                            {
                                label: val.new_return,
                                icon: 'pi pi-fw pi-times',
                                command: () => this.newReturn(this.selectedOrder),
                            }
                        ];

                    if (window.sessionStorage.getItem("wms") == "false") {
                        this.remove(inside);
                        inside = true;
                        this.items.push({
                            label: val.new_RTO,
                            icon: 'pi pi-fw pi-box',
                            command: () => this.newRTO(this.selectedOrder)
                        })
                    }
                });
            }

            if (this.selectedOrder._moloni_sent != undefined && this.selectedOrder._moloni_sent != null) {
                this.remove(inside);
                inside = true;
                this.items.push({
                    label: "Proforma",
                    icon: 'pi pi-fw pi-box',
                    command: () => this.requestProforma(this.selectedOrder.id, this.selectedOrder.order_name, this.selectedOrder._moloni_sent)
                });
            }

            if (this.selectedOrder.isLocalPickup == true) {
                this.remove(inside);
                inside = true;
                this.items.push({
                    label: "Send to  warehouse",
                    icon: 'pi pi-fw pi-box',
                    command: () => this.requestLabel(this.selectedOrder.id, this.selectedOrder.order_name)
                });
            }

            if (this.selectedOrder.label != 0) {
                this.remove(inside);
                inside = true;
                this.items.push({
                    label: "Label",
                    icon: 'pi pi-fw pi-box',
                    command: () => this.requestLabel(this.selectedOrder.id, this.selectedOrder.order_name)
                });
            }
            if (this.selectedOrder.pod != 0) {
                this.remove(inside);
                inside = true;
                this.items.push({
                    label: "POD",
                    icon: 'pi pi-fw pi-box',
                    command: () => this.requestPOD(this.selectedOrder.id, this.selectedOrder.order_name)
                });
            }

            this.remove(inside);
            if (this.items.length == 0)
                this.contextMenu.hide();
        }
        else {
            if (selectedOrder.order_status !== 'fulfilled') {
                this.translateService.stream('orders.orders').subscribe((val) => {
                    this.items = [
                        {
                            label: val.view_in_shopify,
                            icon: 'pi pi-fw pi-search',
                            command: () => this.rowGoTO(this.selectedOrder),
                        }
                    ];
                });
            } else {
                this.translateService.stream('orders.orders').subscribe((val) => {
                    this.items = [
                        {
                            label: val.view_in_shopify,
                            icon: 'pi pi-fw pi-search',
                            command: () => this.rowGoTO(this.selectedOrder),
                        }]
                    if (this.acceptreturn && this.permissionReturn)
                        this.items.push(
                            {
                                label: val.new_return,
                                icon: 'pi pi-fw pi-times',
                                command: () => this.newReturn(this.selectedOrder),
                            }
                        )
                    if (window.sessionStorage.getItem("wms") == "false") {
                        this.items.push({
                            label: val.new_RTO,
                            icon: 'pi pi-fw pi-box',
                            command: () => this.newRTO(this.selectedOrder)
                        })
                    }
                });
            }

            if (this.idBrand == 1) {
                this.items.push({
                    label: "Proforma",
                    icon: 'pi pi-fw pi-box',
                    command: () => this.requestProforma(this.selectedOrder.id, this.selectedOrder.order_name, this.selectedOrder._moloni_sent)
                });
                if (this.selectedOrder.label != 0) {
                    this.items.push({
                        label: "Label",
                        icon: 'pi pi-fw pi-box',
                        command: () => this.requestLabel(this.selectedOrder.id, this.selectedOrder.order_name)
                    });
                }
                if (this.selectedOrder.pod != 0) {
                    this.items.push({
                        label: "POD",
                        icon: 'pi pi-fw pi-box',
                        command: () => this.requestPOD(this.selectedOrder.id, this.selectedOrder.order_name)
                    });
                }
            }
        }

    }

    newReturn(order: OrdersBrand) {
        const returncenter_url = window.sessionStorage.getItem("returncenter_url");

        if (returncenter_url == undefined) {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: this.translateService.instant('orders.orders.noreturncenter_url'),
            });

            return;
        }

        var order_name = order.order_name;

        if (order_name.includes("exc-")) {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: this.translateService.instant('orders.orders.noreturnedorder'),
            });

            return;
        }

        if (order.order_name.charAt(0) == '#') {
            order_name = order.order_name.substring(1);
        }

        let id_token = window.sessionStorage.getItem("id_token");
        window.open(returncenter_url + '?ordername=' + order_name + '&email=' + order.email + '&id_token=' + (id_token == "undefined" ? "" : id_token), '_blank').focus();
    }

    newRTO(order: OrdersBrand) {
        this.ordersBrandService.postRTO(order.id).then(
            data => {
                this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant("general.success"), detail: this.translateService.instant("orders.orders.rto_created") });
            },
            error => {
                this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant("general.error"), detail: this.translateService.instant("orders.orders.error_rto") });
            })
    }

    clear(table: Table) {
        table.clear();
        this.filter.nativeElement.value = '';
    }

    requestLabel(id_order: any, order_name: any) {
        // get Label to download
        this.ordersBrandService.getOrderBrandLabel(id_order)
            .then((label) => {
                if (label.length > 0) {
                    const source = `data:application/pdf;base64,${label[0].label}`;
                    const link = document.createElement('a');
                    link.href = source;
                    link.download = `${order_name}_label.pdf`;
                    link.click();
                }
                else {
                    console.log("No label found")
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async sendWarehouse(id_order: any): Promise<void> {
        const data = await this.ordersBrandService.wareHouse(id_order);
        if (data.value === null) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: data.error_ });
        } else {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Success' });
        }

    }

    async openDialogLabel(orderBrand) {
        if (orderBrand.label == 1) {
            this.requestLabel(orderBrand.id, orderBrand.order_name)
            this.labelDialog = false;
        }
        else {
            this.labelDialog = true;
        }
    }

    async generateLabel(order_id: any, order_name: any) {
        this.generatedLabel = true;
        if (this.inputNBoxes && this.inputWeight && this.inputNBoxes > 0 && this.inputWeight > 0) {
            await this.ordersBrandService.getGenerateLabel(order_id, this.inputWeight, this.inputNBoxes)
                .then((label) => {
                    if (label != null) {
                        const source = `data:application/pdf;base64,${label}`;
                        const link = document.createElement('a');
                        link.href = source;
                        link.download = `${order_name}_label.pdf`;
                        link.click();
                    }
                    this.labelDialog = false;
                    this.generatedLabel = false;
                })
                .catch((error) => {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
                });
        }
    }

    labelOnHide() {
        this.inputWeight = null;
        this.inputNBoxes = null;
        this.labelDialog = false;
        this.generatedLabel = false;
    }

    async getInvoice(id_order: any) {
        const data = await this.ordersBrandService.getInvoice(id_order)
            .then((invoice) => {
                if (invoice != null) {
                    const source = `data:application/pdf;base64,${invoice}`;
                    const link = document.createElement('a');
                    link.href = source;
                    link.download = `${id_order}_invoice.pdf`;
                    link.click();
                }
            })
            .catch((error) => {
                console.log(error);
                let description = "";
                if (error.error.Error.status != undefined && error.error.Error.status1 != null && error.error.Error.status == 404)
                    description = "A sua fatura ainda não esta pronta, tente mais tarde";
                else
                    description = error.error.Error.message;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: description });
            });


    }

    requestPOD(id_order: any, order_name: any) {
        // get Label to download
        this.ordersBrandService.getOrderBrandPOD(id_order)
            .then((label) => {
                if (label.length > 0) {
                    const source = `data:application/pdf;base64,${label[0].pod}`;
                    const link = document.createElement('a');
                    link.href = source;
                    link.download = `${order_name}_pod.pdf`;
                    link.click();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    requestProforma(id_order: any, order_name: any, moloni_sent: any) {

        // get proforma to download
        this.ordersBrandService.getOrderBrandProforma(id_order, (moloni_sent == undefined ? "" : moloni_sent))
            .then((label) => {
                if (this.idBrand != 4) {
                    if (label.length > 0) {
                        const source = `data:application/pdf;base64,${label}`;
                        const link = document.createElement('a');
                        link.href = source;
                        link.download = `${order_name}_proforma.pdf`;
                        link.click();
                    }
                }
                else {
                    window.open(label, '_blank').focus();
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }

    async requestDocuments(e, order) {
        e.stopPropagation();
        const zip = new JSZip();

        let proformaBlob, labelBlob, podBlob
        if (this.idBrand == 1) {
            await this.ordersBrandService.getOrderBrandProforma(order.id, "")
                .then((proforma) => {
                    if (proforma.length > 0) {
                        proformaBlob = proforma
                    }
                })
            zip.file("proforma.pdf", proformaBlob, { base64: true });

            if (order.label == 1) {
                await this.ordersBrandService.getOrderBrandLabel(order.id)
                    .then((label) => {
                        if (label.length > 0) {
                            labelBlob = label
                        }
                    })
                zip.file("label.pdf", labelBlob, { base64: true });
            }
            if (order.pod == 1) {
                await this.ordersBrandService.getOrderBrandPOD(order.id)
                    .then((pod) => {
                        if (pod.length > 0) {
                            podBlob = pod
                        }
                    })
                zip.file("pod.pdf", podBlob, { base64: true });
            }
        }

        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                saveAs(content, "documents_" + order.id + ".zip");
            });
    }

    checkboxClick(e) {
        e.stopPropagation()
        var x = document.getElementsByClassName('p-checkbox-box')
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedOrders.length > 0) {
            x[0].classList.add('p-highlight')
            y[0].classList.add('pi')
            y[0].classList.add('pi-minus')
        } else {
            x[0].classList.remove('p-highlight')
            y[0].classList.remove('pi')
            y[0].classList.remove('pi-minus')
        }
    }

    mainCheckboxClick() {
        var y = document.getElementsByClassName('p-checkbox-icon')
        if (this.selectedOrders.length == this.ordersBrandsAux.length) {
            y[0].classList.remove('pi-minus')
        }
    }

    async exportCSV() {
        var selectionAux = this.table._selection
        if (this.table._selection.length == 0) {
            if (this.table.filteredValue) {
                this.table._selection = this.table.filteredValue
            } else {
                this.table._selection = this.table._value
            }
        }
        await this.table.exportCSV({ selectionOnly: true })
        this.table._selection = selectionAux
    }


    async onPageChange(event: any) {

        if (this.idBrand == 4) {//for woocommerce is page number 
            //const currentPage = Math.floor(event.first / event.rows) + 1;

            //const totalPages = Math.ceil(this.ordersBrandsAux.length / itemsPerPage);
            const date = this.ordersBrandsAux[this.ordersBrandsAux.length - 1].date;

            this.createRows(event.rows - 1);

            const isLastPage = ((event.first + event.rows)) >= this.ordersBrandsAux.length;

            if (!isLastPage) this.loadingNewItens = false; else if (isLastPage && this.loadingNewItensAux) this.loadingNewItens = true;

            //get page, to backend, need pages to get the orders
            const page = Math.ceil(this.ordersBrandsAux.length / 100) + 1;
            //get 200 orders, two cicles in the woocommerce
            if (isLastPage && this.ordersBrandsAux.length % 200 == 0) await this.init(page, 100);

        }
        /* else if ([1, 2, 3, 5, 6, 7].includes(this.idBrand)  && !this.isCalendarDisabled) {// for shopify is id order
 
             // let since=0;
             this.createRows(event.rows - 1);
             const isLastPage = ((event.first + event.rows)) >= this.ordersBrandsAux.length;
             const date= this.ordersBrandsAux[this.ordersBrandsAux.length-1].date;
 
             if (!isLastPage) this.loadingNewItens = false; else if (isLastPage && this.loadingNewItensAux) this.loadingNewItens = true;
 
             //since = this.ordersBrandsAux[this.ordersBrandsAux.length-1].id;
             if (isLastPage && event.rows < 100 && this.numbertotalWithRemoved % 51 == 0) {
                 await this.init(this.since, 51, date);//100 orders at time
                 return;
 
             }
 
             if ((isLastPage && event.rows == 100) && (this.numbertotalWithRemoved % 101 == 0 || this.numbertotalWithRemoved % 101 == 51)) {
                 await this.init(this.since, 101, date);//100 orders at time
                 return;
             }
 
         }
         else if ([1, 2, 3, 5, 6, 7].includes(this.idBrand)  && this.isCalendarDisabled) {// for shopify is id order no calendar
 
             // let since=0;
             this.createRows(event.rows - 1);
             const isLastPage = ((event.first + event.rows)) >= this.ordersBrandsAux.length;
             const date= this.ordersBrandsAux[this.ordersBrandsAux.length-1].date;
 
             if (!isLastPage) this.loadingNewItens = false; else if (isLastPage && this.loadingNewItensAux) this.loadingNewItens = true;
 
             //since = this.ordersBrandsAux[this.ordersBrandsAux.length-1].id;
             if (isLastPage && event.rows < 100 && this.ordersBrandsAux.length % 51 == 0) {
                 await this.init(this.since, 51, date);//100 orders at time
                 return;
 
             }
 
             if ((isLastPage && event.rows == 100) && (this.ordersBrandsAux.length % 101 == 0 || this.ordersBrandsAux.length % 101 == 51)) {
                 await this.init(this.since, 101, date);//100 orders at time
                 return;
             }
 
         }*/

    }

    createRowsArr = [];

    createRows(createRowsAux: number) {
        this.createRowsArr = [];
        for (let i = 0; i < createRowsAux; i++) {
            this.createRowsArr.push(i);
        }
    }

    teste() {
        this.isCalendarDisabled = false;
    }

    openModalManifest() {
        this.manifestDialog = true;
    }

    async downloadManifest() {

        await this.ordersBrandService.getManifestAmbar().then((report) => {
            if ([6].includes(this.idBrand)) {
                if (report != null && report != undefined) {
                    const source = `data:application/pdf;base64,${report}`;
                    const link = document.createElement('a');
                    link.href = source;
                    link.download = `manifest.pdf`;
                    link.click();

                    this.messageService.add({ severity: 'success', summary: 'Manifesto descarregado', detail: 'Success' });
                }
            }

        })
            .catch((error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error });
            });
        this.manifestDialog = false;
    }


    async changeFulfilled() {
        this.loading = true;
        this.ordersBrands = []
        await this.init(0, 100)
        this.loading = false;

    }

    copiedToclipboard() {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Copied to clipboard.' });
    }

    /*   updateRowsfulfilled(){
          if(this.getFulfilled==true){//add fulfilled
              for (let i = 0; i < this.removeFulfilledAux.length; i++) {
                  this.ordersBrands.splice(this.removeFulfilledAux[i].index, 0,this.removeFulfilledAux[i].orders);
              }
              //console.log(this.ordersBrands.length)
              //console.log("add")
          }
          else{
              for (let i = this.removeFulfilledAux.length-1; i >=0; i--) {//you have to start at the end because of the change in index positions
                  this.ordersBrands.splice(this.removeFulfilledAux[i].index, 1);
              }
              //console.log(this.ordersBrands.length)
              //console.log("remove")
          }
  
  
      }*/


}








