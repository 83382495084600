import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { BrandService } from './services/brands/brand.service';
@Injectable({
    providedIn: 'root'
})


export class DashboardGuard implements CanActivate {
    constructor(private router: Router, private brandService:BrandService) {}
  
    async canActivate(): Promise<boolean> {
      const accept= await this.brandService.checkIfBrandAccept();
       window.sessionStorage.setItem("brands_permissions", JSON.stringify(accept));
      
      if (!Boolean(accept["2hand"]) && ! Boolean(accept["repair"]) && !Boolean(accept["returns"])) {
       
       this.router.navigate(['/main/orders_brands']);
    } else {
      return true;
    }
  
      return false; 
    }
  }
