<p-tabView #tabView [(activeIndex)]="activeTabIndex">
    <ng-container *ngIf="acceptreturn && permissionReturn">
        <p-tabPanel header="Returns">
            <pages-dash-return></pages-dash-return>
        </p-tabPanel>
    </ng-container>
    <ng-container *ngIf="accept2Hand && permission2Hand">
        <p-tabPanel header="2Hand">
            <pages-dash-2hand [indexTab]="activeTabIndex" [currentTab2h]="getActivatedTab()"></pages-dash-2hand>
        </p-tabPanel>
    </ng-container>

    <ng-container *ngIf="acceptrepair && permissionRepair">
        <p-tabPanel header="Repairs">
            <pages-dash-repair [indexTab]="activeTabIndex" [currentTabR]="getActivatedTab()"></pages-dash-repair>
        </p-tabPanel>
    </ng-container>
    <ng-container *ngIf="acceptrecycle && permissionRecycle">
        <p-tabPanel header="Recycle">
            <pages-dash-recycle></pages-dash-recycle>
        </p-tabPanel>
    </ng-container>
</p-tabView>