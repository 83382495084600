import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//const AUTH_API = 'http://localhost:3000/api/';

//const AUTH_API = 'https://bko-dev.valeriushub.com/BKO/api/';
const AUTH_API = environment.url;

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    isLoggedIn = false;

    constructor(private http: HttpClient) {
    }

    async requestGET(request) {

        let data = await this.http.get<any>(request).toPromise();
        return data;
    }

    requestPOST(request) {

        let body = request.body;
        if (body != null) {
            body = {};
        }

        return this.http.post<any>(request.url, body);
    }

    signIn(data: object) {

        return this.http.post<any>(AUTH_API + 'users/login', { 'data': data });
    }

    signOut() {

        return this.http.post<any>(AUTH_API + 'users/logout', {});
    }

    isAuthenticated() {
        this.isLoggedIn = true;
        return this.isLoggedIn;
    }

    loginInvite(dataLogin) {
        return this.http.post<any>(AUTH_API + 'users/loginInvite', dataLogin);
    }

    formInvite(dataLogin) {
        return this.http.post<any>(AUTH_API + 'users/formInvite', dataLogin);
    }

    async changeBrand(id_brand){
        return await this.http.put<any>(AUTH_API + 'users/changeBrand', id_brand);
    }
    //handleError(error: HttpErrorResponse) {
    //    let msg = '';
    //    if (error.error instanceof ErrorEvent) {
    //        // client-side error
    //        msg = error.error.message;
    //    } else {
    //        // server-side error
    //        msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    //    }
    //    return throwError(msg);
    //}

    refreshToken(token: string) {
        return this.http.post<any>(AUTH_API + 'refreshtoken', {
            refreshToken: token
        }, httpOptions);
    }

}
