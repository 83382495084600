import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root'
})
export class SupplierService {

    constructor(private http: HttpClient) { }

    async getSuppliers() {

        let data = await this.http.get<any>(AUTH_API + 'supplier/getSuppliers').toPromise();
        return data;
    }

    async getAllSuppliers() {

        let data = await this.http.get<any>(AUTH_API + 'supplier/getAllSuppliers').toPromise();
        return data;
    }

    async getSuppliersById(id_supplier) {

        let data = await this.http.get<any>(AUTH_API + 'supplier/getSupplier?id_supplier=' + id_supplier).toPromise();
        return data;
    }

    postSupplier(data) {

        return this.http.post<any>(AUTH_API + 'supplier', data);
    }

    putSupplier(data) {

        return this.http.put<any>(AUTH_API + 'supplier/updateSupplier', data);
    }
}
