import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuItemComponent } from './menuitem.component';
import { MenuService } from '../../service/app.menu.service';

import { AppRoutingModule } from '../../app-routing.module';
import { DialogModule } from 'primeng/dialog';
import { PasswordModule } from 'primeng/password';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';

@NgModule({
    imports: [
        CommonModule,
        AppRoutingModule,
        PasswordModule,
        DialogModule,
        FormsModule,
        ButtonModule
    ],
    declarations: [
        MenuItemComponent
    ],
    exports: [
        MenuItemComponent
    ],
    providers: [
        MenuService
    ]
})

export class MenuItemModule { }
