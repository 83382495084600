import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { NotfoundComponent } from './pages/notfound/notfound.component';
import { LoginComponent } from './pages/login/login.component';

import { AuthService } from './services/auth/auth.service';
import { AuthGuard } from './auth.guard';

import { AppMainComponent } from './app.main.component';

import { InsertReceptionComponent } from './pages/receipt_product/insert_reception/insert_reception.component';
import { PurchaseOrdersComponent } from './pages/receipt_product/purchase_orders/purchase_orders.component';

import { BrandsComponent } from './pages/brands/brands.component';
import { OrdersBrandsComponent } from './pages/orders_brands/orders_brands.component';


import { POComponent } from './pages/purchase_order/purchase_order.component';
import { CreateSupplierComponent } from './pages/supplier/create_supplier/create_supplier.component';
import { ListEditSupplierComponent } from './pages/supplier/list_edit_supplier/list_edit_supplier.component';
import { ReturnsComponent } from './pages/returns/returns.component';
import { RepairComponent } from './pages/repair/repair.component';
import { RecycleComponent } from './pages/recycle/recycle.component';
import { SecHandComponent } from './pages/2hand/2hand.component';
import { ReturnLineComponent } from './pages/returns/return_line/returnline.component';
import { ListProductComponent } from './pages/product/list_product/list_product.component';
import { CreateUserComponent } from './pages/user/create_user/create_user.component';
import { ListEditUserComponent } from './pages/user/list_edit_user/list_edit_user.component';
import { MyAccountComponent } from './pages/user/my_account/my_account.component';
import { SecondHandOrdersComponent } from './pages/2hand_orders/2hand_orders.component';
import { AbandonedShoppingBagsComponent } from './pages/abandoned_shopping_bags/abandoned_shopping_bags.component';
import { DashBoardComponent } from './pages/dashboard/dashboard.component';
import { DashReturnComponent } from './pages/returns/dash-return/dash-return.component';
import { Dash2HandComponent } from './pages/2hand/dash-2hand/dash-2hand.component';
import { DashRepairComponent } from './pages/repair/dash-repair/dash-repair.component';
import { DashboardGuard } from './dashboard.guard';
import { InviteComponent } from './pages/invite/invite.component';

const PERMISSIONS_TYPE = {
    createOperation: 1,
    turnManager: 2,
    createUser: 3,
    managePermissions: 4,
    consultOrders: 5,
    correctOrders: 6,
    consultProducts: 7,
    importProducts: 8,
    addSuppliers: 9,
    deleteSuppliers: 10,
    consultSuppliers: 11,
    enterProductReceipt: 12,
    consultProductReception: 13,
    brandDetails: 14,
    alterarPassword: 15,
    consultPurchaseOrder: 16,
    consultReturn: 17,
    consultRepair: 19,
    consult2handOrder:18,
    consultAbandonedBags:18,
    consultRecycle: 21
}


const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    {
        path: 'main', component: AppMainComponent,
        children: [
            {path: '', component: DashBoardComponent,
            loadChildren: ()=> import('./pages/dashboard/dashboard.module').then(m=>m.DashBoardModule),
            canActivate: [DashboardGuard]},
            {
                path: 'insert_reception', component: InsertReceptionComponent,
                loadChildren: () => import('./pages/receipt_product/insert_reception/insert_reception.module').then(m => m.InsertReceptionModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.enterProductReceipt }
            },
            {
                path: 'purchase_orders', component: PurchaseOrdersComponent,
                loadChildren: () => import('./pages/receipt_product/purchase_orders/purchase_orders.module').then(m => m.PurchaseOrdersModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultProductReception }
            },
            {
                path: 'brands', component: BrandsComponent,
                loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.brandDetails }
            },
            {
                path: 'orders_brands', component: OrdersBrandsComponent,
                loadChildren: () => import('./pages/orders_brands/orders_brands.module').then((m) => m.OrdersBrandsModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultOrders },
            },
            {
                path: 'create_supplier', component: CreateSupplierComponent,
                loadChildren: () => import('./pages/supplier/create_supplier/create_supplier.module').then(m => m.CreateSupplierModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.addSuppliers }
            },
            {
                path: 'list_edit_supplier', component: ListEditSupplierComponent,
                loadChildren: () => import('./pages/supplier/list_edit_supplier/list_edit_supplier.module').then(m => m.ListEditSupplierModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultSuppliers }
            },
            {
                path: 'dash-return', component: DashReturnComponent,
                loadChildren: () => import('./pages/returns/dash-return/dash-return.module').then(m => m.DashReturnModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultReturn }
            },
            {
                path: 'dash-2hand', component: Dash2HandComponent,
                loadChildren: () => import('./pages/2hand/dash-2hand/dash-2hand.module').then(m => m.Dash2HandModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultReturn }
            },
            {
                path: 'dash-repair', component: DashRepairComponent,
                loadChildren: () => import('./pages/repair/dash-repair/dash-repair.module').then(m => m.DashRepairModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultReturn }
            },
            {
                path: 'returns', component: ReturnsComponent,
                loadChildren: () => import('./pages/returns/returns.module').then(m => m.ReturnsModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultReturn }
            },{
                path: '2hand', component: SecHandComponent,
                loadChildren: () => import('./pages/2hand/2hand.module').then(m => m.SecHandModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consult2handOrder }
            },
            {
                path: 'returnline/:id_return', component: ReturnLineComponent,
                loadChildren: () => import('./pages/returns/return_line/returnline.module').then(m => m.ReturnLineModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultReturn }
            },
            {
                path: 'repair', component: RepairComponent,
                loadChildren: () => import('./pages/repair/repair.module').then(m => m.RepairModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultRepair }
            },
            {
                path: 'recycle', component: RecycleComponent,
                loadChildren: () => import('./pages/recycle/recycle.module').then(m => m.RecycleModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultRecycle }
            },
            {
                path: 'list_product', component: ListProductComponent,
                loadChildren: () => import('./pages/product/list_product/list_product.module').then(m => m.ListProductModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultProducts }
            },
            {
                path: 'create_user', component: CreateUserComponent,
                loadChildren: () => import('./pages/user/create_user/create_user.module').then(m => m.CreateUserModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.createUser }
            },
            {
                path: 'list_edit_user', component: ListEditUserComponent,
                loadChildren: () => import('./pages/user/list_edit_user/list_edit_user.module').then(m => m.ListEditUserModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.createUser }
            },
            {
                path: 'purchaseOrder', component: POComponent,
                loadChildren: () => import('./pages/purchase_order/purchase_order.module').then((m) => m.POModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultPurchaseOrder }
            },
            {
                path: 'my_account', component: MyAccountComponent,
                loadChildren: () => import('./pages/user/my_account/my_account.module').then(m => m.MyAccountModule), canActivate: [AuthGuard],
                data: { permission: PERMISSIONS_TYPE.consultPurchaseOrder }
            },
            {
                path: '2hand_orders', component: SecondHandOrdersComponent,
                loadChildren: () => import ('./pages/2hand_orders/2hand_orders.module').then(m=> m.SecondHandOrdersModule)
                , canActivate: [AuthGuard]
                , data: { permission: PERMISSIONS_TYPE.consult2handOrder }
            },
            {
                path: 'abandoned_shopping_bags', component: AbandonedShoppingBagsComponent,
                loadChildren: () => import ('./pages/abandoned_shopping_bags/abandoned_shopping_bags.module').then(m=> m.AbandonedShoppingBagsModule)
                , canActivate: [AuthGuard]
                , data: { permission: PERMISSIONS_TYPE.consultAbandonedBags }
            }
        ]
    },
    { path: 'invite/:id', component: InviteComponent },
    { path: 'pages/notfound', component: NotfoundComponent },
    { path: '**', redirectTo: 'pages/notfound' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true }),

        //    RouterModule.forRoot([
        //        {
        //            path: '', component: LoginComponent,
        //            children: [
        //                { path: 'main', component: AppMainComponent },
        //                //{ path: '', component: DashboardComponent },
        //                { path: 'test', component: TestComponent },
        //                {path: 'uikit/formlayout', component: FormLayoutComponent},
        //                {path: 'uikit/input', component: InputComponent},
        //                {path: 'uikit/floatlabel', component: FloatLabelComponent},
        //                {path: 'uikit/invalidstate', component: InvalidStateComponent},
        //                {path: 'uikit/button', component: ButtonComponent},
        //                {path: 'uikit/table', component: TableComponent},
        //                {path: 'uikit/list', component: ListComponent},
        //                {path: 'uikit/tree', component: TreeComponent},
        //                {path: 'uikit/panel', component: PanelsComponent},
        //                {path: 'uikit/overlay', component: OverlaysComponent},
        //                {path: 'uikit/media', component: MediaComponent},
        //                {path: 'uikit/menu', loadChildren: () => import('./components/menus/menus.module').then(m => m.MenusModule)},
        //                {path: 'uikit/message', component: MessagesComponent},
        //                {path: 'uikit/misc', component: MiscComponent},
        //                {path: 'uikit/charts', component: ChartsComponent},
        //                {path: 'uikit/file', component: FileComponent},
        //                {path: 'pages/crud', component: CrudComponent},
        //                {path: 'pages/timeline', component: TimelineComponent},
        //                {path: 'pages/empty', component: EmptyComponent},
        //                {path: 'icons', component: IconsComponent},
        //                {path: 'blocks', component: BlocksComponent},
        //                {path: 'documentation', component: DocumentationComponent}
        //            ],
        //        },
        //        {path:'pages/landing', component: LandingComponent},
        //        {path:'pages/login', component: LoginComponent},
        //        {path:'pages/error', component: ErrorComponent},
        //        {path:'pages/notfound', component: NotfoundComponent},
        //        {path:'pages/access', component: AccessComponent},
        //        {path: '**', redirectTo: 'pages/notfound'},
        //    ], {scrollPositionRestoration: 'enabled', anchorScrolling:'enabled'})
    ],
    providers: [AuthService, AuthGuard],
    exports: [RouterModule],
})
export class AppRoutingModule {}
