import { Observable, interval, BehaviorSubject, combineLatest, animationFrameScheduler, of  } from 'rxjs';
import { distinctUntilChanged, endWith, map, switchMap, takeWhile } from 'rxjs/operators';
const easeOutQuad = (x: number): number => x * (2 - x);

export class AnimatedCount{

    constructor(){
    }

    async createCurrentCount(count: number, duration: number): Promise<Observable<number>> {
        if(count==null) count=0;
        const currentValueSubject = new BehaviorSubject<number>(count);
    
        combineLatest([of(count), of(duration)]).pipe(
          switchMap(([count, duration]) => {
            // get the time when animation is triggered
            const startTime = animationFrameScheduler.now();
    
            return interval(0, animationFrameScheduler).pipe(
              // calculate elapsed time
              map(() => animationFrameScheduler.now() - startTime),
              // calculate progress
              map((elapsedTime) => elapsedTime / duration),
              // complete when progress is greater than 1
              takeWhile((progress) => progress <= 1),
              // apply quadratic ease-out
              // for faster start and slower end of counting
              map(easeOutQuad),
              // calculate current count
              map((progress) => Math.round(progress * count)),
              endWith(count),
              distinctUntilChanged()
            );
          })
        ).subscribe((currentValue) => {
          currentValueSubject.next(currentValue);
        });
    
        return currentValueSubject.asObservable();
      }
    
}