import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { ReturnService } from 'src/app/services/return/return.service';

@Component({
    selector: 'pages-returnline',
    templateUrl: './returnline.component.html',
    styleUrls: ['returnline.component.scss'],
})
export class ReturnLineComponent implements OnInit {
    pipe = new DatePipe('en-US');

    breadcumbItems: MenuItem[] = [];

    dropdown_options = [
        {
            id: 'resend_email',
            label: 'Resend Email Received',
            icon: 'pi pi-check',
            command: () => {
                this.EmailReturnLabel();
            },
            disabled: false,
            visible: true
        },
        {
            id: 'wms',
            label: 'Resend to WMS',
            icon: 'pi pi-box',
            command: () => {
                this.ReturnWms();
            },
            disabled: false,
            visible: true
        },
        {
            id: 'editreturn',
            label: 'Edit Return',
            icon: 'pi pi-pencil',
            command: () => {
                this.editReturn();
                window.location.reload();
            },
            disabled: true,
            visible: true
        },
        {
            id: 'label',
            label: 'Download Labels',
            icon: 'pi pi-file',
            command: () => {
                this.ReturnLabel();
            },
            disabled: false,
            visible: true
        },
        {
            id: 'refunded',
            label: 'Refunded',
            icon: 'pi pi-check-circle',
            command: () => {
                this.refunded();
            },
            disabled: false,
            visible: true
        },
        {
            id: 'editTracking',
            label: 'Edit Tracking',
            icon: 'pi pi-pencil',
            command: () => {
                this.displayTracking = true;
            },
            disabled: false,
            visible: true
        },
        {
            id: 'cancelReturn',
            label: 'Cancel Return',
            icon: 'pi pi-times',
            command: () => {
                this.cancelReturn();
            },
            disabled: false,
            visible: true
        },
        {
            id: 'archiveReturn',
            label: 'Archive Return',
            icon: 'pi pi-inbox',
            command: () => {
                this.archiveReturn();
            },
            disabled: false,
            visible: true
        }
    ];

    displayAcceptRefund: boolean = false;

    optionsRefund: any;

    optionSelect: any;

    displayAcceptExchange: boolean = false;

    variantSizeSelect: any = [];

    selectedNotExpectedItem: any;

    quantityArrayNotExpectedItem: any = [1];

    quantityNotExpectedItem: number = 1;

    subtotalRefundNotExpectedItem: number = 0;

    totalRefundNotExpectedItem: number = 0;

    submittedDialog: boolean = false;

    arraySizesCount = [0]

    totalQuantitySelectedNotExpectedItem: number = 1;

    hasExchange: any = [];

    notExpectedOptions = [
        {
            id: 'acceptExchange',
            label: 'Accept Exchange',
            icon: 'pi pi-pencil',
            command: () => {
                this.acceptExchange();;
            },
            disabled: false
        },
        {
            id: 'acceptRefund',
            label: 'Accept Refund',
            icon: 'pi pi-pencil',
            command: () => {
                this.acceptRefund();
            },
            disabled: false
        }
    ];

    // carrierLink = new Map([
    //     [
    //         'DHL Parcel',
    //         'https://clientesparcel.dhl.es/LiveTrackingN/ModifyMyShipment/',
    //     ],
    //     [
    //         'DHL Express',
    //         'https://www.dhl.com/us-en/home/tracking.html?tracking-id=',
    //     ],
    //     ['DPD', 'https://dpd.pt/track-and-trace?reference='],
    // ]);

    subtotal: string = '0';

    processing_fee: number = 0;

    refund_total: string = '0';

    approved = [];

    refused = [];

    return: any = {};

    line_images: any = [];

    refund_items: number = 0;

    loading: boolean;

    order_exc_detail: any = {};

    order_detail_receive: any = [];

    order_items: any = [];

    all_exchange = false;

    submittedLabel = false;

    displayLabel = false;

    displayTracking = false;

    labelUpload = []

    labelAuto = true;

    idTracking: any;

    selectedItemReceive = "0";

    selectedItemAdd = false;

    displayReceive = false;

    expected_items: any = [];

    unexpected_items: any = [];

    unexpected_items_loaded = false;

    received_temp_item: any = {};

    received_items: any = [];

    variantQuantities: any;

    itemsToExchangeCount: number = 0;

    itemsApprovedCount: number = 0;

    itemsRejectedCount: number = 0;

    @ViewChild('dt') table: Table;

    @ViewChild('filter') filter: ElementRef;

    constructor(
        private returnService: ReturnService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private readonly translateService: TranslateService
    ) { }

    async ngOnInit() {
        this.loading = true;
        //load data to translate
        await this.translateService.get('dummyTranslation').toPromise().then();

        this.translateService.stream('general.menu').subscribe((val) => {
            this.breadcumbItems = [
                { label: '', icon: 'pi pi-home' },
                { label: val.orders.value },
                { label: val.orders.items.consult_returns },
            ];
        });

        let id_return = this.route.snapshot.paramMap.get('id_return');
        await this.returnLines(id_return);
        this.return.lines.map(line => {
            if (line.IDStatus == 50 || line.IDStatus == 2) {
                this.itemsApprovedCount = this.itemsApprovedCount + line.quantity
            } else if (line.IDStatus == 1 || line.IDStatus == 49) {
                this.itemsRejectedCount = this.itemsRejectedCount + line.quantity
            } else if (![49, 50, 2, 1].includes(line.IDStatus)) {
                if ([51, 52, 53, 54, 55, 57].includes(line.IDStatus)) {
                    this.itemsApprovedCount = this.itemsApprovedCount + line.quantity
                } else if (line.IDStatus == 56) {
                    this.itemsRejectedCount = this.itemsRejectedCount + line.quantity
                }
            }
        })
        this.itemsToExchangeCount = this.itemsApprovedCount + this.itemsRejectedCount

        this.translateService.stream('general.menu').subscribe((val) => {
            this.breadcumbItems = [
                { label: '', icon: 'pi pi-home' },
                { label: val.returns.value },
                { label: val.returns.items.return_lines },
            ];
        });
        this.loading = false;
        this.resizeWindow();
        window.addEventListener('resize', this.resizeWindow)
    }

    resizeWindow() {
        var imgBlock, flagBlock, infoReturnBlock, blockLength;
        var width = document.body.offsetWidth
        setTimeout(() => {
            imgBlock = document.getElementsByClassName('block-img-product')
            flagBlock = document.getElementsByClassName('block-approve-or-no')
            infoReturnBlock = document.getElementsByClassName('info-return')
            blockLength = Array.from(imgBlock).length

            if (width <= 991) {
                for (var x = 0; x < blockLength; x++) {
                    imgBlock[x].append(flagBlock[x])
                    if (width >= 576) {
                        flagBlock[x].classList.add("w-full")
                    } else {
                        flagBlock[x].classList.remove("w-full")
                    }
                }
            } else {
                for (var x = 0; x < blockLength; x++) {
                    infoReturnBlock[x].append(flagBlock[x])
                    flagBlock[x].classList.remove("w-full")
                }
            }
        })
    }

    async returnLines(id_return: any) {
        await this.returnService.getReturnLines(id_return).then(
            async (returnline) => {
                this.return = returnline.data;
                this.return.started_at = this.pipe.transform(
                    this.return.started_at,
                    'yyyy-MM-dd HH:mm'
                );
                //this.return.carrier_link = this.carrierLink.get('DHL Parcel');

                if (this.return.lines.length > 0) {
                    // check if all lines are exchange
                    this.all_exchange = true;
                    for (let line of this.return.lines) {
                        if (![49, 50, 51, 52, 53, 54, 55, 56, 57, 16, 11, 12, 10, 9].includes(line.IDStatus)) {
                            this.all_exchange = false;
                            break;
                        }
                        if (line.IDStatus == 9 && !line.return_reason_text.includes('(EXC)')) {
                            this.all_exchange = false;
                            break;
                        }
                    }

                    // check for extra lines received from WMS service endpoint
                    this.return.linesExtra = [];
                    for (let line of this.return.lines) {
                        if ([14].includes(line.IDStatus)) {
                            line.variantSizeSelect = [];
                            line.arraySizesCount = [0];
                            line.availableQuantityNotExpectedItemAux = line.quantityReceived
                            line.totalQuantitySelectedNotExpectedItem = 1
                            line.quantityArrayNotExpectedItem = [1]
                            line.quantityNotExpectedItem = 1
                            line.notExpectedOptions = [
                                {
                                    id: 'acceptExchange',
                                    label: 'Accept Exchange',
                                    icon: 'pi pi-pencil',
                                    command: () => {
                                        this.acceptExchange();;
                                    },
                                    disabled: false
                                },
                                {
                                    id: 'acceptRefund',
                                    label: 'Accept Refund',
                                    icon: 'pi pi-pencil',
                                    command: () => {
                                        this.acceptRefund();
                                    },
                                    disabled: false
                                }
                            ]
                            this.return.linesExtra.push(line);
                        }
                    }
                }

                // receive items manualy
                if (this.return.logistic_op == "") {

                    let receive_exist = false;
                    for (let options of this.dropdown_options) {
                        if (options.id == 'receive') {
                            receive_exist = true;
                            break;
                        }
                    }

                    if (!receive_exist) {
                        this.dropdown_options.push({
                            id: 'receive',
                            label: 'Receive (manual)',
                            icon: 'pi pi-arrow-circle-down',
                            command: () => {
                                this.ReturnReceive();
                            },
                            disabled: false,
                            visible: true
                        })
                    }
                }
                this.CalculateTotals();
                this.options_disable();
            }
        ).catch((error) => {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: this.translateService.instant('general.error'),
                detail: error.error,
            });
        });
    }
    async updateLineStatus(IDStatus: any, return_id: any, return_line_id: any) {
        let line = {
            IDStatus:
                IDStatus == 1
                    ? 2
                    : IDStatus == 49
                        ? 50
                        : IDStatus == 50
                            ? 49
                            : 1,
            id_return: return_id,
            id_return_line: return_line_id,
        };

        var returnAux = this.return.lines.find(ret => ret.id_return_line == return_line_id)
        if (line.IDStatus == 50 || line.IDStatus == 2) {
            this.itemsApprovedCount = this.itemsApprovedCount + returnAux.quantity
            this.itemsRejectedCount = this.itemsRejectedCount - returnAux.quantity
        } else if (line.IDStatus == 1 || line.IDStatus == 49) {
            this.itemsApprovedCount = this.itemsApprovedCount - returnAux.quantity
            this.itemsRejectedCount = this.itemsRejectedCount + returnAux.quantity
        }
        await this.returnService.putReturnLinesStatus(line)
            .then((returnList) => {
                {
                    this.returnLines(return_id);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    CalculateTotals() {
        // calculate total refund items, calculate Subtotal,
        this.refund_items = 0;
        this.subtotal = '0';
        this.refund_total = '0';

        for (let line of this.return.lines) {
            if (![49, 50, 51, 52, 53, 54, 55, 56, 57, 1, 16, 11, 12, 10, 14].includes(line.IDStatus) && ![13, 9].includes(this.return.IDStatus)) {
                this.refund_items = this.refund_items + 1;
                this.subtotal = (
                    Number(this.subtotal) + Number(line.total)
                ).toFixed(2);
            } else if (![49, 50, 51, 52, 53, 54, 55, 56, 57, 1, 4, 16, 11, 12, 10, 14].includes(line.IDStatus) && [13, 9].includes(this.return.IDStatus)) {
                this.refund_items = this.refund_items + 1;
                this.subtotal = (
                    Number(this.subtotal) + Number(line.total)
                ).toFixed(2);
            }
        }
        // calculate refund total
        this.refund_total = Math.max(
            Number(this.refund_total) +
            (Number(this.subtotal) +
                Number(this.return.shipping_cost) -
                Number(this.return.fee_total)),
            0
        ).toFixed(2);
    }

    options_disable() {
        // options disability
        const received: any = [0, 9, 13, 15, 17, 51, 55, 57];
        const refunded: any = [13, 15, 51, 55];
        const resendable: any = [2, 3, 50];
        const notApproved: any = [1, 49];
        const cancelledOrArchived: any = [98, 99];
        if (resendable.includes(this.return.IDStatus)) {
            for (let options of this.dropdown_options) {
                if (options.id == 'wms') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'label') {
                    options.disabled = false;
                    continue;
                }
                if (options.id == 'resend_email') {
                    options.disabled = false;
                    continue;
                }
                if (options.id == 'receive') {
                    options.disabled = true;
                    continue;
                }
            }
        } else if (notApproved.includes(this.return.IDStatus)) {
            for (let options of this.dropdown_options) {
                if (options.id == 'wms') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'label') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'resend_email') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'receive') {
                    options.disabled = true;
                    continue;
                }
            }
        } else if (this.return.IDStatus == 16 || this.return.IDStatus == 56) {
            for (let options of this.dropdown_options) {
                if (options.id == 'wms') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'label') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'resend_email') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'editreturn') {
                    options.disabled = false;
                    continue;
                }
                if (options.id == 'receive') {
                    options.disabled = true;
                    continue;
                }
            }
        } else if (this.return.IDStatus == 10 || this.return.IDStatus == 54) {
            for (let options of this.dropdown_options) {
                if (options.id == 'wms') {
                    options.disabled = false;
                    continue;
                }
                if (options.id == 'label') {
                    options.disabled = false;
                    continue;
                }
                if (options.id == 'resend_email') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'editreturn') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'receive') {
                    options.disabled = true;
                    continue;
                }
            }
        } else if (this.return.IDStatus == 13 || this.return.IDStatus == 51) {
            for (let options of this.dropdown_options) {
                if (options.id == 'wms') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'label') {
                    options.disabled = false;
                    continue;
                }
                if (options.id == 'resend_email') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'editreturn') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'receive') {
                    options.disabled = true;
                    continue;
                }
            }
        }
        else {
            for (let options of this.dropdown_options) {
                if (options.id == 'wms') {
                    options.disabled = true;
                    continue;
                }
                if (options.id == 'label') {
                    options.disabled = false;
                    continue;
                }
                if (options.id == 'resend_email') {
                    options.disabled = false;
                    continue;
                }
                if (options.id == 'receive') {
                    options.disabled = false;
                    continue;
                }
            }
        }

        if (this.return.exchange_flag == 1) {
            this.dropdown_options.find(option => option.id == 'refunded').visible = false
        } else {
            if (refunded.includes(this.return.IDStatus)) {
                this.dropdown_options.find(option => option.id == 'refunded').disabled = false
            } else {
                this.dropdown_options.find(option => option.id == 'refunded').disabled = true
            }
        }

        // remove WMS call if no wms 
        if (window.sessionStorage.getItem("wms") == "true") {
            this.dropdown_options.find(option => option.id == 'wms').visible = true
        }
        else if (window.sessionStorage.getItem("wms") == "false") {
            this.dropdown_options.find(option => option.id == 'wms').visible = false
        }

        if (cancelledOrArchived.includes(this.return.IDStatus)) {
            for (let option of this.dropdown_options) {
                option.disabled = true
                if (option.id == "label") {
                    option.disabled = false
                }
                if(option.id == 'cancelReturn' || option.id == 'archiveReturn'){
                    option.visible = false
                }
            }
        }

        if (received.includes(this.return.IDStatus)) {
            this.dropdown_options.find(option => option.id == 'cancelReturn').visible = false
            this.dropdown_options.find(option => option.id == 'archiveReturn').visible = false
        }
    }

    EmailReturnLabel() {
        this.messageService.add({ key: 'tst', severity: 'info', summary: this.translateService.instant('orders.returns.waitemail'), detail: this.translateService.instant('orders.returns.emailsending'), sticky: true });
        let return_data = {
            id_return: this.return.id_return,
        };

        this.returnService.postEmailReturnLabel(return_data)
            .then((email) => {
                if (email.statusCode == '10000') {
                    this.messageService.clear();
                    this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.success'), detail: this.translateService.instant('orders.returns.emailsent') });
                }
            })
            .catch((error) => {
                this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant('general.error'), detail: this.translateService.instant('orders.returns.emailnotsent') });
            });
    }

    ReturnLabel() {
        this.loading = true;
        this.returnService.getReturnLabel(this.return.id_return)
            .then((label) => {
                {
                    if (label.Label.length > 0) {
                        const source = `data:application/pdf;base64,${label.Label}`;
                        const link = document.createElement('a');
                        link.href = source;
                        link.download = `${this.return.order_name}.pdf`;
                        link.click();
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        this.loading = false;
    }

    async editReturn() {
        let editStatus;

        if (this.return.IDStatus === 16) {
            editStatus = 1;
        } else if (this.return.IDStatus === 56) {
            editStatus = 49;
        }

        let request_data = {
            id_return: this.return.id_return,
            IDStatus: editStatus,
        };

        await this.returnService.putReturnFinalize(request_data)
            .then((response) => {
                {
                    if (response.statusCode == 10000) {
                        this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.success'), detail: this.translateService.instant('orders.returns.editreturn') });
                    }
                    this.options_disable();
                }
            })
            .catch((error) => {
                this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.error'), detail: this.translateService.instant('orders.returns.editreturnerror') });
            });
    }

    async ReturnFinalize() {
        var countriesLabelAuto = await this.returnService.getAllCountriesReturnLabelAuto();
        if (this.return.pre_return == 0 && countriesLabelAuto.find(e => e.country_code == this.return.country) == undefined && !this.submittedLabel) {
            this.idTracking = null;
            this.labelAuto = false;
            this.openDialogLabel();
            setTimeout(() => {
                document.getElementsByClassName('p-fileupload')[0]["style"].border = 'none';
            });
        } else {
            this.loading = true;
            //check lines to return status
            let statusreturn;
            let rejected = true;
            for (const rline of this.return.lines) {
                if (rline.IDStatus == 2) {
                    statusreturn = 2;
                    break;
                } else if (rline.IDStatus == 50) {
                    statusreturn = 50;
                    continue;
                }
            }

            for (const rline of this.return.lines) {
                if (rline.IDStatus == 2) {
                    rejected = false;
                    break;
                } else if (rline.IDStatus == 50) {
                    rejected = false;
                    break;
                }
            }

            if (rejected) {
                for (const rline of this.return.lines) {
                    if (rline.IDStatus == 1) {
                        statusreturn = 16;
                        break;
                    } else if (rline.IDStatus == 49) {
                        statusreturn = 56;
                        continue;
                    }
                }
            }

            let request_data = {
                id_return: this.return.id_return,
                IDStatus: statusreturn,
                labelAuto: this.labelAuto,
                hasLabel: Boolean(this.return.pre_return)
            };
            await this.returnService.putReturnFinalize(request_data)
                .then((response) => {
                    {
                        if (response.statusCode == 10000) {
                            this.messageService.add({
                                key: 'tst',
                                severity: 'success',
                                summary:
                                    this.translateService.instant(
                                        'general.success'
                                    ),
                                detail: this.translateService.instant('orders.returns.finalized'),
                            });
                        }
                        this.returnLines(this.return.id_return).then(() => {
                            if (
                                this.return.IDStatus == 10 ||
                                this.return.IDStatus == 54
                            ) {
                                this.messageService.add({
                                    key: 'tst',
                                    severity: 'warn',
                                    summary:
                                        this.translateService.instant(
                                            'general.error'
                                        ),
                                    detail: this.translateService.instant('orders.returns.wms_not_insert')
                                });
                            }
                        });
                        var refundTotal_data = {
                            id_return: request_data.id_return,
                            refund_total: this.refund_total
                        }
                        this.returnService.updateRefundTotal(refundTotal_data);
                        this.options_disable();
                    }
                })
                .catch((error) => {
                    if (error.error.message == 'emailerror') {
                        this.messageService.add({
                            key: 'tst',
                            severity: 'success',
                            summary:
                                this.translateService.instant('general.success'),
                            detail: this.translateService.instant('orders.returns.wms_not_insert')
                        });
                        this.messageService.add({
                            key: 'tst',
                            severity: 'warn',
                            summary: this.translateService.instant('general.error'),
                            detail: this.translateService.instant('orders.returns.emailnotsent')
                        });
                    }
                });
            this.loading = false;
            this.submittedLabel = false;
        }
    }

    async ReturnFinalizeExchange() {
        this.loading = true;
        var lines = []
        for (var x of this.return.linesExtra) {
            var i = 0;
            for (var y of x.quantityArrayNotExpectedItem) {
                var line = {
                    id_return_line: x.id_return_line,
                    quantity: y,
                    EAN: x.variantSizeSelect[i].ean,
                    SKU: x.variantSizeSelect[i].sku,
                    variant_id: x.variantSizeSelect[i].variant_id
                }
                i++
                lines.push(line)
            }
        }
        var body = {
            id_return: this.return.id_return,
            lines: lines
        }

        await this.returnService.returnUnexpectedExchange(body).then(
            data => {
                this.loading = false;
                this.ngOnInit();
                this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.success'), detail: this.translateService.instant('orders.returns.newretturncreated') });
            },
            error => {
                this.loading = false;
                this.messageService.add({ key: 'tst', severity: 'error', summary: this.translateService.instant('general.error'), detail: this.translateService.instant('orders.returns.exchange_not_possible') });
            }
        )

        await this.returnLines(this.return.id_return);
    }

    onUpload(event) {
        document.getElementsByClassName('p-fileupload')[0]["style"].border = 'none';
        if (document.getElementsByClassName('p-fileupload-files').length > 0) {
            document.getElementsByClassName('p-fileupload-files')[0]["style"].display = 'block';
        }
        for (let file of event.files) {
            this.labelUpload.push(file);
        }
        this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
    }

    openDialogLabel() {
        this.displayLabel = true;
        setTimeout(() => {
            if (document.getElementsByClassName('p-fileupload-files').length > 0) {
                document.getElementsByClassName('p-fileupload-files')[0]["style"].display = 'none';
            }
        }, 100);
    }

    onRemoveFile() {
        this.labelUpload.pop();
        document.getElementsByClassName('p-fileupload') ? document.getElementsByClassName('p-fileupload')[0]["style"].border = 'none' : null;
    }

    closeDialogLabel() {
        if (this.labelUpload.length > 0) {
            var file = this.labelUpload[0];
            var filebase64;
            let fileReader64 = new FileReader();
            fileReader64.readAsBinaryString(file);
            fileReader64.onload = async () => {
                let binaryData: any = fileReader64.result;
                filebase64 = window.btoa(binaryData);
                var data_request = {
                    id_return: this.return.id_return,
                    label: filebase64,
                    idTracking: this.idTracking?.replace(/\s+/g, "")
                }
                await this.returnService.addReturnLabel(data_request).then(
                    (data) => {
                        this.ReturnFinalize();
                    },
                    (error) => {
                        this.messageService.add({
                            key: 'tst',
                            severity: 'warn',
                            summary: this.translateService.instant('general.error'),
                            detail: this.translateService.instant('orders.returns.label_error')
                        });
                    }
                );
            };
            this.submittedLabel = true
            this.displayLabel = false
        } else {
            document.getElementsByClassName('p-fileupload')[0]["style"].border = '1px solid red';
            document.getElementsByClassName('p-fileupload')[0]["style"].borderRadius = '6px';
            this.messageService.add({ key: 'tst', severity: 'warn', summary: this.translateService.instant("general.warn"), detail: this.translateService.instant("users.warning_update") });
        }
    }

    ReturnWms() {
        let request_data = {
            id_return: this.return.id_return,
        };

        this.returnService.putReturnWMS(request_data)
            .then((response) => {
                if (response.statusCode == '10000') {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary:
                            this.translateService.instant('general.success'),
                        detail: this.translateService.instant('orders.returns.updated_WMS'),
                    });
                } else if (response.statusCode == '10001') {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: this.translateService.instant('general.error'),
                        detail: this.translateService.instant('orders.returns.error_updating_WMS')
                    });
                }
                this.returnLines(this.return.id_return);
                this.options_disable();
            })
            .catch((error) => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: this.translateService.instant('general.error'),
                    detail: this.translateService.instant('orders.returns.error_updating_WMS')
                });
            });
    }

    async refunded() {
        this.loading = true;
        let request_data = {
            id_return: this.return.id_return,
        };
        await this.returnService.putReturnStatusToDone(request_data).then(
            async (data) => {
                let id_return = this.route.snapshot.paramMap.get('id_return');
                this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.success'), detail: this.translateService.instant('orders.returns.refunded') });
                await this.returnLines(id_return);
            },
            (error) => {
                this.loading = false;
                this.messageService.add({
                    key: 'tst',
                    severity: 'warn',
                    summary: this.translateService.instant('general.error'),
                    detail: this.translateService.instant('orders.returns.error_refunded')
                });
            }
        );

        this.loading = false;
    }

    async onImagesClick(id_return: any, id_return_line: any) {
        // reset images log
        this.line_images = [];

        // get images
        await this.returnService.getReturnLineImages(
            id_return,
            id_return_line
        ).then(async (lineimages) => {
            for (let lineimage of lineimages) {
                this.line_images.push(lineimage);
            }
        });

        for (let return_line of this.return.lines) {
            if (
                return_line.id_return == id_return &&
                return_line.id_return_line == id_return_line
            ) {
                return_line.images_data = this.line_images;
            }
        }
    }

    itemClicked(event) {
        if (event.item.label === this.breadcumbItems[0].label) {
            this.router.navigate(['main']);
        }
        if (event.item.label === this.breadcumbItems[1].label) {
            this.router.navigate(['main/returns']);
        }
        if (event.item.label === this.breadcumbItems[2].label) {
            this.router
                .navigate([`main/returnline/${this.return.id_return}`])
                .then(() => {
                    window.location.reload();
                });
        }
    }

    async getOrderExchangeDetails(order_id_exc: any, op: any, event: any) {
        //this.order_exc_detail = {};
        await this.returnService.getOrderExchangeDetails(order_id_exc).then(
            async (orderExchange) => {
                this.order_exc_detail = orderExchange;
            }
        );
        op.toggle(event);
    }


    async ReturnReceive() {

        // reset data
        this.expected_items = [];
        this.unexpected_items = [];
        this.unexpected_items_loaded = false;
        this.received_temp_item = {};
        this.received_items = [];
        this.selectedItemReceive = "0";
        this.selectedItemAdd = false;

        // get items from order for autocomplete 
        await this.returnService.getOrderDetails(this.return.order_id, this.return.id_return).then(
            async (orderData) => {
                this.order_items = orderData;

                // organize data in groups
                for (let orderLine of orderData.items) {
                    if (orderLine.Expeted) {
                        this.expected_items.push({
                            "label": orderLine.SKU, "value": orderLine.id
                        });
                    } else {
                        this.unexpected_items.push({
                            "label": orderLine.SKU, "value": orderLine.id
                        });
                    }
                }

                this.order_detail_receive = [{
                    label: "Expected", value: "expected",
                    items: this.expected_items
                }
                ];

                if (this.unexpected_items.length == 0)
                    this.unexpected_items_loaded = true;

            }
        )

        this.displayReceive = true;
    }

    UnexpectedItems() {
        this.unexpected_items_loaded = true; // hide message

        this.order_detail_receive = [{
            label: "Expected", value: "expected",
            items: this.expected_items
        },
        {
            label: "Unexpected", value: "unexpected",
            items: this.unexpected_items
        }
        ];

        this.messageService.add({
            key: 'tst',
            severity: 'success',
            summary:
                this.translateService.instant('orders.returns.list_items_updated'),
            detail: this.translateService.instant('orders.returns.add_unexpeted_items')
        });
    }

    ChooseItemReceive(event: any) {

        this.received_temp_item = {}
        // auto field inputs
        for (let item of this.order_items.items) {
            if (item.id == event.value) {
                this.received_temp_item = {
                    "id": item.id,
                    "sku": item.SKU,
                    "ean": item.EAN,
                    "quantity": item.Quantity
                }
                this.selectedItemAdd = true
                break;
            }
        }
    }

    AddReceiveItem() {
        for (let item of this.order_items.items) {
            if (item.id == this.received_temp_item.id) {

                for (let received_item of this.received_items) {
                    if (received_item.id == this.received_temp_item.id) {


                        received_item.quantity = parseInt(received_item.quantity) + parseInt(this.received_temp_item.quantity);

                        this.received_temp_item = {};
                        this.selectedItemAdd = false
                        this.selectedItemReceive = "0";

                        return;
                    }
                }

                this.received_items.push({
                    "id": this.received_temp_item.id,
                    "sku": this.received_temp_item.sku,
                    "ean": this.received_temp_item.ean,
                    "quantity": this.received_temp_item.quantity,
                    "quantity_expected": item.Expeted == false ? 0 : item.Quantity
                })
                break;
            }
        }

        this.received_temp_item = {};
        this.selectedItemAdd = false
        this.selectedItemReceive = "0";
    }

    removeReceiveItem(item: any) {
        this.received_items = this.received_items.filter(function (value: any) {
            return value.id != item.id
        })
    }

    async SendReceiveItems() {
        let send_data: any = [];

        for (let received_item of this.received_items) {
            send_data.push({
                "product_code": received_item.sku,
                "product_ean": received_item.ean,
                "quantity_exception": received_item.quantity_expected,
                "quantity": received_item.quantity
            })
        }

        let data = {
            "process_type": "Devolução de Cliente",
            "warehouse_date": "",
            "warehouse_id": 0,
            "original_id": this.order_items.original_id,
            "logistic_op": "",
            "external_number": "",
            "items": send_data
        }

        await this.returnService.postReturnReceiveItems(data).then(async (returnresponse) => {
            this.returnLines(this.return.id_return);
            this.displayReceive = false;
        })
            .catch((error) => {
                console.log(error);
            });
    }

    onSubmitTracking(event?: any): void {
        if (this.return.tracking_id) {
            this.return.tracking_id = this.return.tracking_id.replace(/\s+/g, "")
            var data = {
                id_return: this.return.id_return,
                idTracking: this.return.tracking_id
            }
            if (event === undefined) {
                this.returnService.updateTrackingId(data)
                this.displayTracking = false
            } else {
                if (event.key === "Enter") {
                    this.returnService.updateTrackingId(data)
                    this.displayTracking = false
                    this.messageService.add({ key: 'tst', severity: 'success', summary: this.translateService.instant('general.success'), detail: this.translateService.instant('orders.returns.updated_tracking') });
                }
            }
        }
    }

    async acceptExchange() {
        this.displayAcceptExchange = true;
        this.submittedDialog = false;
        if (this.selectedNotExpectedItem.arraySizesCount.length == 0) {
            this.variantSizeSelect = []
            this.selectedNotExpectedItem.arraySizesCount = [0]
            this.selectedNotExpectedItem.quantityArrayNotExpectedItem = [1]
            this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem = 1
            this.checkIfHasExchange()
        }
        if (this.selectedNotExpectedItem.variantSizeSelect.length >= 1) {
            this.newSize()
        }

        this.variantQuantities = await this.returnService.getVariantQuantity(this.selectedNotExpectedItem.id_product, this.selectedNotExpectedItem.variant_id)
        for (var variantQnt of this.variantQuantities) {
            if (variantQnt.quantity == 0) {
                variantQnt.disabled = true
            } else {
                variantQnt.disabled = false
            }
        }
    }

    decrementQuantity(index?: number) {
        if (index !== undefined) {
            this.selectedNotExpectedItem.quantityArrayNotExpectedItem[index]--;
        } else {
            this.selectedNotExpectedItem.quantityNotExpectedItem--;
            this.subtotalRefundNotExpectedItem -= Number(this.selectedNotExpectedItem.price)
        }
        this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem--;
    }

    incrementQuantity(index?: number) {
        if (index !== undefined) {
            this.selectedNotExpectedItem.quantityArrayNotExpectedItem[index]++;
        } else {
            this.selectedNotExpectedItem.quantityNotExpectedItem++;
            this.subtotalRefundNotExpectedItem += Number(this.selectedNotExpectedItem.price)
        }
        this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem++;
    }

    async acceptRefund() {
        this.displayAcceptRefund = true;
        this.submittedDialog = false;
        this.optionSelect = null;
        this.selectedNotExpectedItem.quantityNotExpectedItem = 1
        this.subtotalRefundNotExpectedItem = Number(this.selectedNotExpectedItem.price)
        this.optionsRefund = await this.returnService.getOptionsReturn();
        this.optionsRefund = this.optionsRefund.filter(option => !option.option.includes('(EXC)'))
    }

    async refundItem() {
        this.submittedDialog = true;
        if (this.optionSelect) {
            this.loading = true;
            var body = {
                id_return: this.selectedNotExpectedItem.id_return,
                id_return_line: this.selectedNotExpectedItem.id_return_line,
                quantity: this.selectedNotExpectedItem.quantityNotExpectedItem,
                option_id: this.optionSelect.option_id
            }

            await this.returnService.returnUnexpectedRefund(body)
            this.selectedNotExpectedItem.availableQuantityNotExpectedItemAux = this.selectedNotExpectedItem.availableQuantityNotExpectedItemAux - this.quantityNotExpectedItem
            this.displayAcceptRefund = false;
            await this.returnLines(this.return.id_return);
            this.loading = false
            this.checkDisabled()
        } else {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: this.translateService.instant("general.warn"), detail: this.translateService.instant("users.warning_update") });
        }
    }

    exchangeItem() {
        this.submittedDialog = true;
        if (this.variantSizeSelect[0] == null) {
            this.selectedNotExpectedItem.variantSizeSelect = []
            this.variantSizeSelect = []
            this.hasExchange = []
            this.return.linesExtra.map(line => this.hasExchange.concat(line.variantSizeSelect))
        }
        if (this.variantSizeSelect.length > 0 && this.variantSizeSelect.length == this.selectedNotExpectedItem.arraySizesCount.length) {
            var variantSizeSelect = this.variantSizeSelect
            this.selectedNotExpectedItem.variantSizeSelect = variantSizeSelect
            this.loading = true;
            var qnt = this.selectedNotExpectedItem.quantityArrayNotExpectedItem.reduce((partialSum, a) => partialSum + a, 0);
            this.displayAcceptExchange = false;
            this.selectedNotExpectedItem.availableQuantityNotExpectedItemAux = this.selectedNotExpectedItem.quantityReceived - qnt
            this.checkIfHasExchange()
            setTimeout(() => {
                this.loading = false
            })
            this.checkDisabled()
        } else {
            this.messageService.add({ key: 'tst', severity: 'warn', summary: this.translateService.instant("general.warn"), detail: this.translateService.instant("users.warning_update") });
        }
    }

    newSize() {
        this.selectedNotExpectedItem.arraySizesCount.push(this.selectedNotExpectedItem.arraySizesCount[this.selectedNotExpectedItem.arraySizesCount.length - 1] + 1)
        this.selectedNotExpectedItem.quantityArrayNotExpectedItem.push(1)
        this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem++;
    }

    deleteSizeSelect(x: number, returnline?: any) {
        if (returnline) {
            this.selectedNotExpectedItem = returnline
            this.selectedNotExpectedItem.availableQuantityNotExpectedItemAux += this.selectedNotExpectedItem.quantityArrayNotExpectedItem[x]
            this.checkDisabled();
        }
        if (returnline || this.selectedNotExpectedItem.arraySizesCount.length > 1) {
            this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem = this.selectedNotExpectedItem.totalQuantitySelectedNotExpectedItem - this.selectedNotExpectedItem.quantityArrayNotExpectedItem[x];
            this.selectedNotExpectedItem.variantSizeSelect.splice(x, 1)
            this.variantSizeSelect.splice(x, 1)
            this.selectedNotExpectedItem.quantityArrayNotExpectedItem.splice(x, 1)
            this.selectedNotExpectedItem.arraySizesCount = Array.from(Array(this.selectedNotExpectedItem.arraySizesCount.length - 1).keys())
        }
        this.checkIfHasExchange()
    }

    checkDisabled() {
        if (this.selectedNotExpectedItem.availableQuantityNotExpectedItemAux <= 0) {
            this.selectedNotExpectedItem.notExpectedOptions.map(option => option.disabled = true)
        } else {
            this.selectedNotExpectedItem.notExpectedOptions.map(option => option.disabled = false)
        }
    }

    checkIfHasExchange() {
        this.return.linesExtra.map(line => {
            this.hasExchange = [];
            this.hasExchange = this.hasExchange.concat(line.variantSizeSelect)
        })
    }

    cancelReturn() {
        var data = {
            id_return: this.return.id_return
        }
        this.returnService.cancelReturn(data)
        this.returnLines(this.return.id_return);
    }

    archiveReturn() {
        var data = {
            id_return: this.return.id_return
        }
        this.returnService.archiveReturn(data)
        this.returnLines(this.return.id_return);
    }
}
