import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { RepairService } from 'src/app/services/repair/repair.service';
import { AnimatedCount } from '../../dashboard/animated-count';

@Component({
    selector: 'pages-dash-repair',
    templateUrl: './dash-repair.component.html',
    styleUrls: ['dash-repair.component.scss'],
})

export class DashRepairComponent implements OnInit {
    loading:boolean=true;
    nRepairs:any=null;
    tValueRepairs:any=null;
    mRProducts:any=null;
    mPService:any=null;
    @Input() indexTab: number;
    dashStruct: Dashstruct;
    dashStructAux: Dashstruct;
    animatedCount: AnimatedCount = new AnimatedCount();
    isAnimated: boolean = false;
    @Input() currentTabR:string;
    currentTabRAux:string;
    

    constructor(
        private repairService:RepairService
        ) {
            this.dashStructAux = {
                nRepairs: null,
                tValueRepairs: null,
                mRProducts: null,
                mPService: null,
            }
            this.dashStruct = {
                nRepairs: null,
                tValueRepairs: null,
                mRProducts: null,
                mPService: null,
            }


        }

    async ngOnInit(): Promise<void> {
        await this.init();
       
    }

    async ngOnChanges(changes: SimpleChanges) {
        this.currentTabRAux=changes.currentTabR.currentValue;
        if (changes.currentTabR.currentValue == 'Repairs' && this.loading == false && !this.isAnimated) {
            await this.startAnimations();
            this.isAnimated = true;
        }
    }

    async init(): Promise<void>{
        this.dashStruct.nRepairs= await this.repairService.getNRepairs();
        this.dashStruct.tValueRepairs= await this.repairService.getTValueRepairs();
        this.dashStruct.mRProducts = await this.repairService.getMRProducts();
        this.dashStruct.mPService =  await this.repairService.getMPService();
        this.loading = false;
        if(this.currentTabRAux=='Repairs')
            await this.startAnimations();
    }

    async startAnimations() {
        const sub1 = (await this.animatedCount.createCurrentCount(this.dashStruct.nRepairs, 1000)).subscribe((currentValue) => {
            this.dashStructAux.nRepairs = currentValue;
        },
            null,
            () => {
                this.dashStructAux.nRepairs = this.dashStruct.nRepairs
                sub1.unsubscribe();
            }
        );

        const sub2 = (await this.animatedCount.createCurrentCount(this.dashStruct.tValueRepairs, 1000)).subscribe((currentValue) => {
            this.dashStructAux.tValueRepairs = currentValue;
        },
            null,
            () => {
                this.dashStructAux.tValueRepairs = this.dashStruct.tValueRepairs
                sub2.unsubscribe();
            }
        );
        this.isAnimated = true;

    }


}

interface Dashstruct {
    nRepairs: any;
    tValueRepairs: any;
    mRProducts: any;
    mPService: any;
}