import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { ReturnService } from 'src/app/services/return/return.service';

@Component({
    selector: 'pages-dash-return',
    templateUrl: './dash-return.component.html',
    styleUrls: ['dash-return.component.scss'],
})
export class DashReturnComponent implements OnInit {
    pipe = new DatePipe('en-US');

    dateI: any;

    dateF: any;

    rangeDates: Date[] = null;

    minimumDate: Date = new Date('06/15/2022');

    checked: boolean = false;

    totalPending: any;

    returnStatus: any;

    returnOptions: any;

    bestProducts: any;

    breadcumbItems: MenuItem[] = [];

    pieDataStatus: any;

    barDataOptions: any;

    barOptions: any;

    barDataBestProducts: any;

    stackedOptions: any;

    paymentReturns: any;

    totalPaymentReturns: any;

    totalReturn: any;

    productSelect: any = {
        product_id: "",
        Image_url: "",
        ProductName: "",
        Color: "",
        SKUs: []
    }

    colors: any = [
        '#29a87c',
        '#ff3a67',
        '#f8a72d',
        '#0096d6',
        '#64c2e7',
        '#47CB9A',
        '#152f50',
        '#877f7d'
    ]

    colorsHover: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#7E57C2',
        '#FFA726',
        '#00bb7e',
        '#495057',
        '#66BB6A'
    ]

    heightImg: number = 180;

    loading: boolean = true;

    @ViewChild('dt') table: Table;

    @ViewChild('filter') filter: ElementRef;

    constructor(
        private returnService: ReturnService,
        private router: Router,
        private readonly translateService: TranslateService
    ) { }

    async ngOnInit() {
        this.translateService.stream('general.menu').subscribe((val) => {
            this.breadcumbItems = [
                { label: '', icon: 'pi pi-home' },
                { label: val.returns.value },
                { label: val.returns.dashboard }
            ];
        });

        this.totalPending = (await this.returnService.getTotalPendingReturn())[0].pending;

        if (this.rangeDates != null) {
            this.dateValues();
            this.returnStatus = await this.returnService.getTotalStatusReturn(this.dateI, this.dateF)
            this.returnOptions = await this.returnService.getTotalReasonReturn(this.checked, this.dateI, this.dateF)
            this.bestProducts = await this.returnService.getBestProducts(this.checked, this.dateI, this.dateF)
            this.paymentReturns = await this.returnService.getTotalPaymentReturns(this.checked, this.dateI, this.dateF);
        } else {
            this.returnStatus = await this.returnService.getTotalStatusReturn('', '')
            this.returnOptions = await this.returnService.getTotalReasonReturn(this.checked, '', '')
            this.bestProducts = await this.returnService.getBestProducts(this.checked, '', '')
            this.paymentReturns = await this.returnService.getTotalPaymentReturns(this.checked, '', '');
        }

        var max = 0;
        var productSelect;
        this.bestProducts.map(best => {
            const arr = best.reason.map(sku => sku.value)
            var totalReturn = 0
            totalReturn = arr.reduce((accumulator, currentValue) => accumulator + currentValue, totalReturn);
            if (totalReturn > max) {
                productSelect = best
                max = totalReturn
            }
        })

        var colors_products = []
        if(productSelect == undefined) {productSelect={}; productSelect.SKUs=[]; productSelect.SKUs[0]={}, productSelect.SKUs[0].productName={}}
        var colors = productSelect.SKUs.map(sku => sku.option_1_value)
        colors = colors.filter((item, index) => colors.indexOf(item) === index);
        colors.map(color => {
            var product = productSelect.SKUs.filter(sku => sku.option_1_value == color)
            colors_products.push({
                color: color,
                products: product
            })
        })

        this.totalReturn = max
        this.productSelect = {
            product_id: productSelect.product,
            Image_url: productSelect.SKUs[0].Image_url,
            ProductName: productSelect.SKUs[0].ProductName,
            Colors_products: colors_products,
            SKUs: productSelect.SKUs
        }

        this.loading = false;

        let totalPaymentReturns = 0;
        this.totalPaymentReturns = (this.paymentReturns.reduce(function (accumulator, curValue) {
            return accumulator + Number(curValue.value)
        }, totalPaymentReturns)).toFixed(2)

        this.createPieStatusDate()
        this.createBarOptionsDate()
        this.createBarBestProductsDate()
    }

    calculate(value: any) {
        var result = value * 100 / this.totalPaymentReturns
        if (Number(value) == 0) {
            return '0%'
        }
        if (result == 100) {
            return result + '%'
        }
        return result.toFixed(2) + '%'
    }

    dateValues() {
        this.dateI = this.pipe.transform(this.rangeDates[0], 'yyyy-MM-dd');
        this.dateF;
        if (this.rangeDates[1] == null) {
            this.dateF = this.dateI;
        } else {
            this.dateF = this.pipe.transform(this.rangeDates[1], 'yyyy-MM-dd');
        }
    }

    async createPieStatusDate() {
        const statusName = this.returnStatus.map(status => status.name);
        const statusValue = this.returnStatus.map(status => status.value);

        this.pieDataStatus = {
            labels: statusName,
            datasets: [
                {
                    data: statusValue,
                    backgroundColor: this.colors
                }
            ]
        };
    }

    async createBarOptionsDate() {
        const optionsName = this.returnOptions.map(option => option.name.includes('(EXC)') ? option.name.substring(5) : option.name);
        const optionsValue = this.returnOptions.map(option => option.value);

        if (this.rangeDates != null) {
            var returnOptionsTotal = await this.returnService.getTotalReasonReturn(this.checked, '', '')
            const optionsValueTotal = returnOptionsTotal.map(option => option.value);
            this.barDataOptions = {
                labels: optionsName,
                datasets: [
                    {
                        label: 'Total',
                        backgroundColor: '#FFA726',
                        data: optionsValueTotal
                    },
                    {
                        label: 'Range dates',
                        backgroundColor: '#66BB6A',
                        data: optionsValue
                    }
                ],
            };
        } else {
            this.barDataOptions = {
                labels: optionsName,
                datasets: [
                    {
                        label: 'Total',
                        backgroundColor: '#ff3a67',
                        data: optionsValue
                    }
                ],
            };
        }

        this.barOptions = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            hover: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            scales: {
                xAxes: {
                    ticks: {
                        maxRotation: 50,
                        callback: (value: any, index: any, values: any) => {
                            let label = optionsName[value];
                            if (label == null) {
                                return '';
                            } else {
                                var productNameArr = label.split(' ')
                                var productName0 = []
                                var productName1 = []
                                var count = 0
                                productNameArr.map(i => {
                                    if (count < productNameArr.length / 2) {
                                        productName0 = productName0.concat(i)
                                    } else {
                                        productName1 = productName1.concat(i)
                                    }
                                    count++
                                })
                            }
                            return [productName0?.join(' '), productName1?.join(' ')];
                        },
                    },
                }
            }
        };
    }

    async createBarBestProductsDate() {
        const productsName = this.bestProducts.map(product => product.product);
        const optionsReturn = this.bestProducts.map(product => product.reason.map(option => option.name));
        let optionsName = optionsReturn.reduce((list, sub) => list.concat(sub), [])
        optionsName = optionsName.filter((item, index) => optionsName.indexOf(item) === index);
        const productsOptions = this.bestProducts.map(product => product.reason.map(option => option));
        var datasetsAux = [];
        var j = 0;
        optionsName.map(option => {
            var data = []
            var i = 0;
            productsName.map(product => {
                var x = productsOptions[i].find(a => a.name == option)
                data.push(x?.value)
                i++
            })
            datasetsAux.push({
                type: 'bar',
                label: option.includes('(EXC)') ? option.substring(5) : option,
                backgroundColor: this.colors[j],
                data: data,
            })
            j++;
        })

        this.barDataBestProducts = {
            labels: productsName,
            datasets: datasetsAux,
        };

        this.stackedOptions = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            hover: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            scales: {
                xAxes: {
                    stacked: true,
                    ticks: {
                        maxRotation: 50,
                        callback: (value: any, index: any, values: any) => {
                            let label = productsName[value];
                            if (label == null) {
                                return '';
                            } else {
                                var productNameArr = label.split(' ')
                                var productName0 = []
                                var productName1 = []
                                var count = 0
                                productNameArr.map(i => {
                                    if (count < productNameArr.length / 2) {
                                        productName0 = productName0.concat(i)
                                    } else {
                                        productName1 = productName1.concat(i)
                                    }
                                    count++
                                })
                            }
                            return [productName0?.join(' '), productName1?.join(' ')];
                        },
                    },
                },
                yAxes: {
                    stacked: true
                }
            }
        };
    }

    itemClicked(event) {
        if (event.item.label === this.breadcumbItems[0].label) {
            this.router.navigate(['main']);
        }
        if (event.item.label === this.breadcumbItems[1].label) {
            this.router.navigate(['main/returns']);
        }
    }
}
