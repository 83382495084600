<div class="grid">
    <div class="col-12">
        <p-breadcrumb [style]="{background: 'none' , border: 'none'}" [model]="breadcumbItems"
            (onItemClick)="itemClicked($event)"></p-breadcrumb>
        <div class="card">
            <p-toast key="tst" [baseZIndex]="99999"></p-toast>
            <div *ngIf="loadingCreate" class="p-datatable-loading-overlay p-component-overlay ng-star-inserted"
                style="z-index: 1000; display: flex; justify-content: center; align-items: center;">
                <i class="p-datatable-loading-icon pi pi-spin pi-spinner"></i>
            </div>
            <form [formGroup]="reactiveForm">
                <div class="p-fluid p-formgrid grid" style="justify-content: flex-end;">
                    <div class="field col-12 md:col-5">
                        <label htmlFor="username">{{ 'users.username' | translate }} <small>*</small></label>
                        <input pInputText type="text" pInputText required formControlName="username"
                            [ngClass]="{'ng-invalid ng-dirty' : submitted && !username}">
                    </div>
                    <div id="password" class="field col-8 md:col-5">
                        <label htmlFor="password">{{ 'users.password' | translate }} <small>*</small></label>
                        <p-password [(ngModel)]="password" [toggleMask]="true" formControlName="password" required
                            [ngClass]="{'ng-invalid ng-dirty' : submitted && !password}"></p-password>
                        <i class="pi pi-copy" (click)="copyPassword()"></i>
                    </div>
                    <div class="field col-4 md:col-2" style="display: flex; align-self: flex-end;">
                        <button pButton pRipple label="{{ 'users.generate' | translate }}"
                            (click)="generatePassword()"></button>
                    </div>
                    <div class=" field col-12 md:col-7">
                        <label htmlFor="email">Email <small>*</small></label>
                        <input pInputText type="text" pInputText required formControlName="email"
                            [ngClass]="{'ng-invalid ng-dirty' : submitted && !email}">
                    </div>
                    <div class="field col-12 md:col-5">
                        <label htmlFor="group">{{ 'users.group' | translate }}</label>
                        <p-multiSelect [options]="groups_permissions" [(ngModel)]="selectedGroup"
                            formControlName="selectedGroup" (onChange)="selectPermissions()"
                            defaultLabel="Select group permissions" [filter]="false" [showToggleAll]="true"
                            class="multiselect-custom">
                            <ng-template let-value pTemplate="selectedItems">
                                <div class="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2"
                                    *ngFor="let option of selectedGroup">
                                    <div>{{ 'users.groups_description.' + option.id_group | translate }}</div>
                                </div>
                                <div *ngIf="!selectedGroup || selectedGroup.length === 0">
                                    {{ 'users.select_group_permissions' | translate }}
                                </div>
                            </ng-template>
                            <ng-template let-group pTemplate="item">
                                <div class="flex align-items-centerc">
                                    <div>{{ 'users.groups_description.' + group.id_group | translate }}</div>
                                </div>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                    <div class="field col-12 md:col-12">
                        <label htmlFor="permissions">{{ 'users.permissions' | translate }} <small>*</small></label>
                        <div class=" grid">
                            <div *ngFor="let permission of permissions" class="field-checkbox col-6 md:col-3">
                                <p-checkbox name="{{permission.id_permission}}" value="{{permission.id_permission}}"
                                    [value]="permission.id_permission" [(ngModel)]="selectedPermissions"
                                    formControlName="selectedPermissions" [inputId]="permission.id_permission" required
                                    [ngClass]="{'ng-invalid ng-dirty' : submitted && !selectedPermissions}"
                                    (click)="validatePermission(permission.id_permission, $event)"></p-checkbox>
                                <label [for]="permission.id_permission">{{ 'users.permissions_description.' +
                                    permission.id_permission | translate }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="field col-12 md:col-2" *ngIf="!isError">
                        <button id="btn-create" type="reset" value="Reset" pButton pRipple
                            label="{{ 'general.create' | translate }}" icon="pi pi-plus" (click)="onSubmit()"
                            style="justify-content: center;"></button>
                    </div>
                    <div class="field col-12 md:col-2" *ngIf="isError">
                        <button id="btn-create" type="button" value="Button" pButton pRipple
                            label="{{ 'general.create' | translate }}" icon="pi pi-plus" (click)="onSubmit()"
                            style="justify-content: center;"></button>
                    </div>
                </div>
                <small>* {{ 'users.required_fields' | translate }}</small>
            </form>
        </div>
    </div>
</div>