import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { TabView } from 'primeng/tabview';
import { BrandService } from 'src/app/services/brands/brand.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';

@Component({
    selector: 'pages-dash',
    templateUrl: './dashboard.component.html',
    styleUrls: ['dashboard.component.scss'],
})

export class DashBoardComponent implements OnInit,  AfterViewInit  {
    activeTabIndex: number = 0;
    accept2Hand:boolean;
    acceptrepair:boolean;
    acceptreturn:boolean;
    acceptrecycle:boolean;
    permissionReturn:boolean;
    permissionRepair:boolean;
    permission2Hand:boolean;
    permissionRecycle:boolean;
    @ViewChild('tabView') tabView: TabView;
    activeTabHeader: string;

    constructor(
        private brandService:BrandService,
        private permissions: PermissionsService
    ){
       
    }

    async ngOnInit(): Promise<void> {
      //  var accept = await this.brandService.checkIfBrandAccept();
        const accept = JSON.parse(window.sessionStorage.getItem("brands_permissions"));
        this.accept2Hand = Boolean(accept["2hand"]);
        this.acceptrepair = Boolean(accept["repair"]);
        this.acceptreturn = Boolean(accept["returns"]);
        this.acceptrecycle = Boolean(accept["recycle"]);
        const permissionsType = this.permissions.getPermissionsType();
        this.permissionReturn=this.permissions.checkPermissionsSubmenu(permissionsType.consultReturn);
        this.permissionRepair =this.permissions.checkPermissionsSubmenu(permissionsType.consultRepair);
        this.permission2Hand =this.permissions.checkPermissionsSubmenu(permissionsType.consult2hand);
        this.permissionRecycle=this.permissions.checkPermissionsSubmenu(permissionsType.consultRecycle);
      
    }

    ngAfterViewInit(){
        this.getActivatedTab();
    }

    getActivatedTab():any{
        if(this.tabView!= undefined){
        const activeTab = this.tabView.tabs.find((tab) => tab.selected);
        if (activeTab) {
          return this.activeTabHeader = activeTab.header;
        }
    }
        return null;
    }

}  