<div *ngIf="loading" class="flex justify-content-center align-items-center min-h-screen">
	<div class="loading"></div>
</div>
<div *ngIf="!loading" class="h-full">
	<p-table #dt1 id="pltable" [columns]="cols" [value]="pls" dataKey="id" [paginator]="true" [rowHover]="true"
		[globalFilterFields]="['identifier', 'supplier', 'statusDescription']" [expandedRowKeys]="expandedRows"
		responsiveLayout="scroll" [rows]="25" [rowsPerPageOptions]="[25,50,100]" [showCurrentPageReport]="true"
		currentPageReportTemplate="{{ 'reception.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
		<ng-template pTemplate="caption">
			<div class="flex justify-content-between gap-2 flex-wrap">
				<div class="flex flex-wrap mb-2">
					<!-- <p-dropdown [options]="sortOptions" [(ngModel)]="selectedOption" (onChange)="onSortChange($event)"
						class="w-full-mb" [style]="{'minWidth':'16rem'}">
					</p-dropdown> -->
					<span class="p-input-icon-left mr-2">
						<i class="pi pi-search"></i>
						<input pInputText type="text" #filter
							(input)="dt1.filterGlobal($event.target.value, 'contains')"
							placeholder="{{ 'reception.search_PO' | translate }}"
							class="p-inputtext p-component p-eleme[(ngModel)]=nt w-full" />
					</span>
					<div (click)="ngOnInit()" class="flex align-items-center cursor-pointer" style="color: #6c757d;">
						<i class="pi pi-refresh"></i>
					</div>
				</div>
				<div class="flex gap-2">
					<button pButton pRipple label="Add new reception" class="p-button-secundary"
						(click)="openSidebar()">
						<span class="p-button-icon p-button-icon-left flex"><img
								src="assets/layout/images/desktop-icon.svg"></span>
					</button>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="body" let-pl let-expanded="expanded">
			<tr>
				<td style="width: 2rem;">
					<button type="button" pButton pRipple [pRowToggler]="pl"
						class="p-button-text p-button-rounded p-button-plain"
						[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
						(click)="expandItem(pl)"></button>
				</td>
				<td style="width: 15%;"><b>{{pl.identifier}}</b></td>
				<td style="width: 15%;">{{pl.supplier}}</td>
				<td style="width: 15%;">{{pl.statusDescription}}</td>
				<td style="width: 15%;"><b *ngIf="pl.totalEstimatedQuant">{{ 'reception.total_estimated' | translate
						}}</b> {{pl.totalEstimatedQuant}}</td>
				<td style="width: 15%;"><b *ngIf="pl.totalEstimatedQuant">{{ 'reception.total_received' | translate
						}}</b> {{pl.totalReceivedQuant}}</td>
				<td style="width: 2rem;">
					<div class="flex-row">
						<button pButton pRipple type="button" icon="pi pi-window-maximize"
							(click)="DialogExpandItem(pl)" class="p-button-rounded p-button-text"></button>
						<a *ngIf="pl.fileUploaded" download="{{pl.identifier}}.xlsx"
							id="linkExcelFile-{{pl.identifier}}"><button pButton pRipple type="button"
								icon="pi pi-file-excel" (click)="exportAsXlsxFile(pl)"
								class="p-button-rounded p-button-text"></button></a>
					</div>
				</td>
				<td style="width: 2rem;" *ngIf="!pl.fileUploaded"></td>
			</tr>
		</ng-template>
		<ng-template pTemplate="rowexpansion" let-po>
			<tr style="border: 1px solid #dee2e6;">
				<td colspan="7">
					<div>
						<p-table #dt2 id="potable" [value]="po.orders" rowGroupMode="subheader" sortMode="single"
							responsiveLayout="scrollable" [scrollable]="true" dataKey="SKU"
							[loading]="loadingSubTables">
							<ng-template pTemplate="header">
			<tr>
				<th>{{ 'reception.SKU' | translate }}
					<p-columnFilter type="text" field="SKU" display="menu"
						placeholder="{{ 'reception.search_SKU' | translate }}">
					</p-columnFilter>
				</th>
				<th>{{ 'reception.EAN' | translate }}
					<p-columnFilter type="text" field="EAN" display="menu"
						placeholder="{{ 'reception.search_EAN' | translate }}">
					</p-columnFilter>
				</th>
				<th>{{ 'reception.name' | translate }}
					<p-columnFilter type="text" field="name" display="menu"
						placeholder="{{ 'reception.search_name' | translate }}">
					</p-columnFilter>
				</th>
				<th>{{ 'reception.color' | translate }}
					<p-columnFilter type="text" field="color" display="menu"
						placeholder="{{ 'reception.search_color' | translate }}">
					</p-columnFilter>
				</th>
				<th>{{ 'reception.size' | translate }}
					<p-columnFilter type="text" field="size" display="menu"
						placeholder="{{ 'reception.search_size' | translate }}">
					</p-columnFilter>
				</th>
				<th>{{ 'reception.estimated_amount' | translate }}
					<p-columnFilter type="text" field="QuantityEstimated" display="menu"
						placeholder="{{ 'reception.search_estimated_amount' | translate }}">
					</p-columnFilter>
				</th>
				<th>{{ 'reception.received_amount' | translate }}
					<p-columnFilter type="text" field="QuantityReceived" display="menu"
						placeholder="{{ 'reception.search_received_amount' | translate }}">
					</p-columnFilter>
				</th>
				<th>Status</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-order let-rowIndex="rowIndex">
			<tr pRowGroupHeader *ngIf="po.rowGroupMetadata[order.PoNumber].index === rowIndex">
				<td colspan="5" style="min-width: 200px;">
					<b>{{order.PoNumber}}</b>
				</td>
			</tr>
			<tr>
				<td>
					<span class="p-column-title"><b>SKU</b></span>
					{{order.SKU}}
				</td>
				<td>
					<span class="p-column-title"><b>EAN</b></span>
					{{order.EAN}}
				</td>
				<td>
					<span class="p-column-title"><b>Name</b></span>
					{{order.name}}
				</td>
				<td>
					<span class="p-column-title"><b>Color</b></span>
					{{order.color}}
				</td>
				<td>
					<span class="p-column-title"><b>Size</b></span>
					{{order.size}}
				</td>
				<td>
					<span class="p-column-title"><b>Quantity Estimated</b></span>
					{{order.QuantityEstimated}}
				</td>
				<td>
					<span class="p-column-title"><b>Quantity Received</b></span>
					{{order.QuantityReceived}}
				</td>
				<td *ngIf="order.status > 10 && !(order.status > 14)">
					<span class="p-column-title"><b>Status</b></span>
					<i class="pi pi-exclamation-triangle" title="{{order.statusDescription}}"></i>
				</td>
				<td *ngIf="order.status == 10">
					<span class="p-column-title"><b>Status</b></span>
					<i class="pi pi-check" title="{{order.statusDescription}}"></i>
				</td>
				<td *ngIf="!(order.status >= 10 && !(order.status > 14))">
					<span class="p-column-title"><b>Status</b></span>
					<i class="pi pi-spinner" title="{{order.statusDescription}}"></i>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="8">{{ 'general.no_data' | translate }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr>
				<td colspan="8">{{ 'general.loading' | translate }}</td>
			</tr>
		</ng-template>
	</p-table>
</div>
</td>
</tr>
</ng-template>
<ng-template pTemplate="emptymessage">
	<tr>
		<td colspan="8">{{ 'general.no_data' | translate }}</td>
	</tr>
</ng-template>
<ng-template pTemplate="loadingbody">
	<tr>
		<td colspan="8">{{ 'general.loading' | translate }}</td>
	</tr>
</ng-template>
</p-table>

<p-dialog header="teste" [(visible)]="displayDialog" modal="false" showEffect="fade"
	[breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '80%'}">
	<ng-template pTemplate="header">
		<b> {{dialogPO.identifier}} </b>
		<div><b>{{ 'reception.total_estimated' | translate }}</b> {{dialogPO.totalEstimatedQuant}}</div>
		<div><b>{{ 'reception.total_received' | translate }}</b> {{dialogPO.totalReceivedQuant}}</div>
	</ng-template>
	<p-table #dt2 id="potabledialog" [value]="dialogPO.orders" rowGroupMode="subheader" sortMode="single"
		responsiveLayout="stack" [scrollable]="true" dataKey="SKU" [loading]="loadingTableDialog"
		[globalFilterFields]="['SKU', 'EAN', 'name', 'color', 'size']">
		<ng-template pTemplate="caption">
			<div class="table-header">
				<span class="p-input-icon-left m-0">
					<i class="pi pi-search"></i>
					<input pInputText type="text" #filter (input)="dt2.filterGlobal($event.target.value, 'contains')"
						placeholder="{{ 'reception.search' | translate }}"
						class="p-inputtext p-component p-eleme[(ngModel)]=nt w-full" />
				</span>
			</div>
		</ng-template>
		<ng-template pTemplate="header">
			<tr>
				<th pSortableColumn="SKU">
					<div class="flex align-items-center">
						{{ 'reception.SKU' | translate }}
						<p-sortIcon field="SKU"></p-sortIcon>
					</div>
				</th>
				<th pSortableColumn="EAN">
					<div class="flex align-items-center">
						{{ 'reception.EAN' | translate }}
						<p-sortIcon field="EAN"></p-sortIcon>
					</div>
				</th>
				<th pSortableColumn="name">
					<div class="flex align-items-center">
						{{ 'reception.name' | translate }}
						<p-sortIcon field="name"></p-sortIcon>
					</div>
				</th>
				<th pSortableColumn="color">
					<div class="flex align-items-center">
						{{ 'reception.color' | translate }}
						<p-sortIcon field="color"></p-sortIcon>
					</div>
				</th>
				<th pSortableColumn="size">
					<div class="flex align-items-center">
						{{ 'reception.size' | translate }}
						<p-sortIcon field="size"></p-sortIcon>
					</div>
				</th>
				<th pSortableColumn="QuantityEstimated">
					<div class="flex align-items-center">
						{{ 'reception.estimated_amount' | translate }}
						<p-sortIcon field="QuantityEstimated"></p-sortIcon>
					</div>
				</th>
				<th pSortableColumn="QuantityReceived">
					<div class="flex align-items-center">
						{{ 'reception.received_amount' | translate }}
						<p-sortIcon field="QuantityReceived"></p-sortIcon>
					</div>
				</th>
				<th>Status</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-order let-rowIndex="rowIndex">
			<tr pRowGroupHeader *ngIf="dialogPO.rowGroupMetadata[order.PoNumber].index === rowIndex">
				<td colspan="5" style="min-width: 200px;">
					<b>{{order.PoNumber}}</b>
				</td>
			</tr>
			<tr>
				<td>
					<span class="p-column-title"><b>SKU</b></span>
					{{order.SKU}}
				</td>
				<td>
					<span class="p-column-title"><b>EAN</b></span>
					{{order.EAN}}
				</td>
				<td>
					<span class="p-column-title"><b>Name</b></span>
					{{order.name}}
				</td>
				<td>
					<span class="p-column-title"><b>Color</b></span>
					{{order.color}}
				</td>
				<td>
					<span class="p-column-title"><b>Size</b></span>
					{{order.size}}
				</td>
				<td>
					<span class="p-column-title"><b>Quantity Estimated</b></span>
					{{order.QuantityEstimated}}
				</td>
				<td>
					<span class="p-column-title"><b>Quantity Received</b></span>
					{{order.QuantityReceived}}
				</td>
				<td *ngIf="order.status > 10 && !(order.status > 14)">
					<span class="p-column-title"><b>Status</b></span>
					<i class="pi pi-exclamation-triangle" title="{{order.statusDescription}}"></i>
				</td>
				<td *ngIf="order.status == 10">
					<span class="p-column-title"><b>Status</b></span>
					<i class="pi pi-check" title="{{order.statusDescription}}"></i>
				</td>
				<td *ngIf="!(order.status >= 10 && !(order.status > 14))">
					<span class="p-column-title"><b>Status</b></span>
					<i class="pi pi-spinner" title="{{order.statusDescription}}"></i>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="8">{{ 'general.no_data' | translate }}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="loadingbody">
			<tr>
				<td colspan="8">{{ 'general.loading' | translate }}</td>
			</tr>
		</ng-template>







		Product Reception
		Consult reception
		NU_IN_44_20221207

	</p-table>

	<ng-template pTemplate="footer">
		<button pButton icon="pi pi-check" (click)="displayDialog=false" label="Ok" class="p-button-outlined"></button>
	</ng-template>
</p-dialog>
</div>
<p-sidebar [(visible)]="createReceptionSidebar" position="right" [baseZIndex]="10000"
	[style]="{width: '75%', padding: '0px 0px 0px 30px'}" [showCloseIcon]="false" (onHide)="closeSidebar()">
	<div class="p-sidebar-header" style="padding: 1.25rem 0">
		<div>
			<button pButton pRipple type="button" icon="pi pi-times" class="p-sidebar-close"
				(click)="closeSidebar()"></button>
		</div>
	</div>
	<div class="flex flex-column gap-5 pb-6">
		<div class="flex flex-column sm:flex-row gap-3">
			<div class="upload-block">
				<p-fileUpload name="myfile[]" accept=".xlsx" customUpload="true" [auto]="true"
					(uploadHandler)="onBeforeUpload($event)" (onClear)="onRemoveFile()" (onRemove)="onRemoveFile()"
					chooseLabel="{{ 'reception.load_file' | translate }}">
					<ng-template pTemplate="toolbar">
						<button pButton pRipple id="btnExcelSample" icon="pi pi-book" (click)="downloadSampleExcel()"
						style="background-color: #29a87c; border: #29a87c;"	class="e-btn p-button-success" pTooltip="XLS Template" tooltipPosition="bottom"></button>
					</ng-template>
				</p-fileUpload>
			</div>
			<div class="extra-block">
				<div>
					<p-dropdown appendTo="body" [options]="suppliers" [(ngModel)]="selectedSupplier" [style]="{'width': '100%'}"
						optionLabel="name" (onChange)="editIdFile()"></p-dropdown>
					<div *ngIf="!selectedSupplier">
						<p style="color: #F59E0B; margin-top: 0.5rem"><i class="pi pi-exclamation-triangle"></i>
							{{ 'reception.action_error' | translate }}</p>
					</div>
					<div class="mt-1 mb-3" style="word-break: break-all;"><b>{{idFile}}</b></div>
				</div>
				<div *ngIf="selectedSupplier" class="m-0 flex flex-column gap-1">
					<div id="transfer-block" class="flex w-full justify-content-between">
						<p class="flex align-items-center m-0">{{ 'reception.transfer_submit' | translate }}</p>
						<p-selectButton class="flex justify-content-start" style="flex: 0 0 auto"
							[options]="stateOptions" [(ngModel)]="transfer" optionLabel="label" optionValue="value"
							(onChange)="changeSuppliersArray()"></p-selectButton>
					</div>
					<button pButton pRipple label="{{ 'reception.submit_file' | translate }}"
						[style]="{'width':'100%', 'overflow': 'visible'}" [disabled]="purchaseOrders.length == 0"
						(click)="onSubmit()"></button>
					<div *ngIf="loadingUpload" class="p-datatable-loading-overlay p-component-overlay ng-star-inserted"
						style="z-index: 1000; display: flex; justify-content: center; align-items: center;">
						<i class="p-datatable-loading-icon pi pi-spin pi-spinner"></i>
					</div>
				</div>
				<div *ngIf="!selectedSupplier" style="margin: 0">
					<div id="transfer-block" class="field flex w-full justify-content-between align-items-center gap-1 inline-flex">
						<p class="flex align-items-center m-0">{{ 'reception.transfer_submit' | translate }}</p>
						<p-selectButton class="flex justify-content-start"  style="flex: 0 0 auto;"
							[options]="stateOptions" [(ngModel)]="transfer" optionLabel="label" optionValue="value"
							(onChange)="changeSuppliersArray()"></p-selectButton>
					</div>
					<button disabled pButton pRipple label="{{ 'reception.submit_file' | translate }}"
						[style]="{'width':'100%', 'overflow': 'visible'}" (click)="onSubmit()"></button>
					<div *ngIf="loadingUpload" class="p-datatable-loading-overlay p-component-overlay ng-star-inserted"
						style="z-index: 1000; display: flex; justify-content: center; align-items: center;">
						<i class="p-datatable-loading-icon pi pi-spin pi-spinner"></i>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="deletedRows > 0" class="field col-12 md:col-12" style="padding: 0;">
			<p-message severity="warn"
				text="{{ 'reception.error_lines_deleted' | translate: {deletedRows:deletedRows} }}"></p-message>
		</div>
		<p-table #dt1 [value]="purchaseOrders" dataKey="id_line" editMode="row" [rowHover]="true" [paginator]="true"
			[loading]="loading" responsiveLayout="scroll"
			[globalFilterFields]="['id_po','SKU','ProductName','EAN','Color','Size','Quantity']" [rows]="10"
			[rowsPerPageOptions]="[10,25,50]" [showCurrentPageReport]="true"
			currentPageReportTemplate="{{ 'reception.current_page_report' | translate:{'first':'{{first}}','last':'{{last}}', 'totalRecords':'{{totalRecords}}'} }}">
			<ng-template pTemplate="caption">
				<div class="flex justify-content-between flex-column sm:flex-row">
					<div class="flex gap-2 sm:flex-row">
						<button pButton pRipple label="{{ 'general.new' | translate }}" icon="pi pi-plus"
							class="search-100 mb-2" style="width: 116px;" (click)="openNew()"></button>
						<button pButton pRipple id="btnExcelSubmit" icon="pi pi-file-excel"
							(click)="exportExcelOrders()"  class="e-btn p-button-success mb-2" pTooltip="XLS"
							tooltipPosition="bottom" [disabled]="purchaseOrders.length == 0"></button>
					</div>
					<div class="flex gap-2 sm:flex-row">
						<button pButton class="p-button-outlined mb-2" icon="pi pi-filter-slash"
							(click)="clear(dt1)"></button>
						<span class="p-input-icon-left search-100 mb-2">
							<i class="pi pi-search"></i>
							<input pInputText type="text" #filter
								(input)="dt1.filterGlobal($event.target.value, 'contains')"
								placeholder="{{ 'general.search_here' | translate }}" class="w-full" />
						</span>
					</div>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th>
						<div class="flex justify-content-between align-items-center">
							{{ 'reception.po_number' | translate }}
							<p-columnFilter type="text" field="id_po" display="menu"
								placeholder="{{ 'reception.search_po_number' | translate }}">
							</p-columnFilter>
						</div>
					</th>
					<th>
						<div class="flex justify-content-between align-items-center">
							{{ 'reception.SKU' | translate }}
							<p-columnFilter type="text" field="SKU" display="menu"
								placeholder="{{ 'reception.search_SKU' | translate }}">
							</p-columnFilter>
						</div>
					</th>
					<th>
						<div class="flex justify-content-between align-items-center">
							{{ 'reception.name' | translate }}
							<p-columnFilter type="text" field="ProductName" display="menu"
								placeholder="{{ 'reception.search_name' | translate }}"></p-columnFilter>
						</div>
					</th>
					<th>
						<div class="flex justify-content-between align-items-center">
							{{ 'reception.EAN' | translate }}
							<p-columnFilter type="text" field="EAN" display="menu"
								placeholder="{{ 'reception.search_EAN' | translate }}">
							</p-columnFilter>
						</div>
					</th>
					<th style="width: 10rem">
						<div class="flex justify-content-between align-items-center">
							{{ 'reception.color' | translate }}
							<p-columnFilter type="text" field="Color" display="menu"
								placeholder="{{ 'reception.search_color' | translate }}"></p-columnFilter>
						</div>
					</th>
					<th>
						<div class="flex justify-content-between align-items-center">
							{{ 'reception.size' | translate }}
							<p-columnFilter type="text" field="Size" display="menu"
								placeholder="{{ 'reception.search_size' | translate }}"></p-columnFilter>
						</div>
					</th>
					<th>
						<div class="flex justify-content-between align-items-center">
							{{ 'reception.quantity' | translate }}
							<p-columnFilter type="numeric" field="Quantity" display="menu"
								placeholder="{{ 'reception.search_quantity' | translate }}"></p-columnFilter>
						</div>
					</th>
					<th style="width: 8rem;"></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-po let-editing="editing" let-ri="rowIndex">
				<tr [pEditableRow]="po"
					[ngClass]="{'error-line' : !po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check)}"
					[ngStyle]="{'background': !po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check) ? '#ffe7e6' : 'transparent'}"
					title="{{ !po.SKU_check && !po.EAN_check ? this.translateService.instant('reception.no_sku_no_ean' , {sku: po.SKU, ean: po.EAN}) : 
						!po.SKU_EAN_check ? this.translateService.instant('reception.sku_ean' , {sku: po.SKU, ean: po.EAN}) : 
						!po.SKU_check && po.EAN_check ? this.translateService.instant('reception.no_sku_ean' , {sku: po.SKU, ean: po.EAN}) :
						po.SKU_check && !po.EAN_check ? this.translateService.instant('reception.sku_no_ean' , {sku: po.SKU, ean: po.EAN}) :
						''}}">
					<td>
						<span class="p-column-title"><b>PO Number</b></span>
						<i *ngIf="(po.SKU_EAN_check && (!po.SKU_check || !po.EAN_check))"
							class="pi pi-exclamation-triangle"
							style="position: absolute; left: -20px; color: #cc8925;"></i>
						<i *ngIf="(!po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check))" class="pi pi-times-circle"
							style="position: absolute; left: -20px; color: #ff5757"></i>
						<i *ngIf="(!po.SKU_EAN_check && (!po.SKU_check || !po.EAN_check))" class="pi pi-times-circle"
							style="position: absolute; left: -20px; color: #ff5757"></i>
						{{po.id_po}}
					</td>
					<td>
						<span class="p-column-title"><b>SKU</b></span>
						<p-cellEditor *ngIf="!po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check)">
							<ng-template pTemplate="input">
								<input pInputText type="text" [(ngModel)]="po.SKU" required
									[ngClass]="{'ng-invalid' : error}">
							</ng-template>
							<ng-template pTemplate="output">
								{{po.SKU}}
							</ng-template>
						</p-cellEditor>
						<div *ngIf="!(!po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check))">
							{{po.SKU}}
						</div>
					</td>
					<td>
						<span class="p-column-title"><b>Name</b></span>
						{{po.ProductName}}
					</td>
					<td>
						<span class="p-column-title"><b>EAN</b></span>
						<p-cellEditor *ngIf="!po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check)">
							<ng-template pTemplate="input">
								<input pInputText type="text" [(ngModel)]="po.EAN" required
									[ngClass]="{'ng-invalid' : error}">
							</ng-template>
							<ng-template pTemplate="output">
								{{po.EAN}}
							</ng-template>
						</p-cellEditor>
						<div *ngIf="!(!po.SKU_EAN_check || (!po.SKU_check && !po.EAN_check))">
							{{po.EAN}}
						</div>
					</td>
					<td>
						<span class="p-column-title"><b>Color</b></span>
						{{po.Color}}
					</td>
					<td>
						<span class="p-column-title"><b>Size</b></span>
						{{po.Size}}
					</td>
					<td style="width: 10rem;">
						<span class="p-column-title"><b>Quantity</b></span>
						<p-cellEditor>
							<ng-template pTemplate="input">
								<input pInputText type="number" [(ngModel)]="po.Quantity" min="0" required>
							</ng-template>
							<ng-template pTemplate="output">
								{{po.Quantity}}
							</ng-template>
						</p-cellEditor>
					</td>
					<td style="width: 8rem;">
						<span class="p-column-title"></span>
						<div style="text-align:center; display: flex; ">
							<button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
								(click)="onRowEditInit(po)" class="p-button-rounded p-button-text"></button>
							<button *ngIf="!editing" pButton pRipple type="button" pInitDeleteRow icon="pi pi-trash"
								(click)="deleteProduct(po, ri)" class="p-button-rounded p-button-text"></button>
							<button id="btn-save-order" *ngIf="editing" pButton pRipple type="button" pSaveEditableRow
								icon="pi pi-check" (click)="onRowEditSave(po)"
								class="p-button-rounded p-button-text p-button-success mr-2"></button>
							<button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
								(click)="onRowEditCancel(po, ri)"
								class="p-button-rounded p-button-text p-button-danger"></button>
						</div>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="11">{{ 'general.no_data' | translate }}</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="loadingbody">
				<tr>
					<td colspan="11">{{ 'general.loading' | translate }}</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</p-sidebar>
<p-dialog [(visible)]="purchaseOrderDialog" id="new-product" [style]="{width: '650px'}" header="Product Details"
	[modal]="true" [draggable]="false" [resizable]="false" class="p-fluid" class="non-overflow">
	<ng-template pTemplate="content">
		<div class="p-fluid p-formgrid grid">
			<div class="field col-12 md:col-6">
				<label for="id_po">{{ 'reception.po_number' | translate }} <small>*</small></label>
				<p-autoComplete [(ngModel)]="purchaseOrder.id_po" required [suggestions]="filteredPoNumber"
					(completeMethod)="filterField($event, 'poNumber')" [dropdown]="true"
					(ngModelChange)="checkFields(purchaseOrder, 'poNumber')"
					[ngClass]="{'ng-invalid ng-dirty' : (submitted && !purchaseOrder.id_po) || fieldPoNumberError}">
					<ng-template let-poNumber pTemplate="item">
						{{poNumber}}
					</ng-template>
				</p-autoComplete>
			</div>
			<div class="field col-12 md:col-6">
				<label for="sku">{{ 'reception.SKU' | translate }} <small>*</small></label>
				<p-autoComplete [(ngModel)]="purchaseOrder.SKU" required [suggestions]="filteredSKU"
					(completeMethod)="filterField($event, 'SKU')" [dropdown]="true"
					(ngModelChange)="checkFields(purchaseOrder, 'SKU')"
					[ngClass]="{'ng-invalid ng-dirty' : (submitted && !purchaseOrder.SKU) || fieldSKUError}">
					<ng-template let-SKU pTemplate="item">
						{{SKU}}
					</ng-template>
				</p-autoComplete>
			</div>
			<div class="field col-12 md:col-6">
				<label for="ean">{{ 'reception.EAN' | translate }} <small>*</small></label>
				<p-autoComplete [(ngModel)]="purchaseOrder.EAN" required [suggestions]="filteredEAN"
					(completeMethod)="filterField($event, 'EAN')" [dropdown]="true"
					(ngModelChange)="checkFields(purchaseOrder, 'EAN')"
					[ngClass]="{'ng-invalid ng-dirty' : (submitted && !purchaseOrder.EAN) || fieldEANError}">
					<ng-template let-EAN pTemplate="item">
						{{EAN}}
					</ng-template>
				</p-autoComplete>
			</div>
			<div class="field col-12 md:col-6">
				<label for="Quantity">{{ 'reception.quantity' | translate }} <small>*</small></label>
				<input id="Quantity" pInputText type="number" [(ngModel)]="purchaseOrder.Quantity" min="0" required
					[ngClass]="{'ng-invalid ng-dirty' : submitted && !purchaseOrder.Quantity}">
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<button pButton pRipple label="{{ 'general.cancel' | translate }}" icon="pi pi-times" class="p-button-text"
			(click)="purchaseOrderDialog = false"></button>
		<button pButton pRipple label="{{ 'general.save' | translate }}" icon="pi pi-check" class="p-button-text"
			(click)="createPurchaseOrder()"></button>
		<div class="col-12 md:col-12" style="text-align: left;">
			<small>* {{ 'reception.required_fields' | translate }}</small>
		</div>
	</ng-template>
</p-dialog>
<p-dialog [(visible)]="deleteProductDialog" header="Confirm" [modal]="true" [draggable]="false" [resizable]="false"
	[style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-center">
		<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
		<span *ngIf="purchaseOrder">{{ 'reception.delete_line' | translate }}
			<b>{{purchaseOrder.SKU}}</b>?</span>
	</div>
	<ng-template pTemplate="footer">
		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="{{ 'primeng.reject' | translate }}"
			(click)="deleteProductDialog = false"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="{{ 'primeng.accept' | translate }}"
			(click)="confirmDelete()"></button>
	</ng-template>
</p-dialog>