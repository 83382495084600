import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;
const ID_BRAND = 'id_brand';
const SECRET_KEY = "YourSecretKeyForEncryption&Descryption";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) { }

    async putLangUser(lang: any) {
        let data = await this.http.put<any>(AUTH_API + 'users/updateLang', { "lang": lang }).toPromise().catch((error) => {
            throw error;
        });
    }

    postUser(user) {

        let data = this.http.post<any>(AUTH_API + 'users/createPromise', user);
        return data;
    }

    getUsers() {

        let data = this.http.get<any>(AUTH_API + 'users/GetUsersBrand');
        return data;
    }

    putActiveUser(user) {

        let data = this.http.put<any>(AUTH_API + 'users/updateActive', user);
        return data;
    }

    putPermissionsUser(user) {

        let data = this.http.put<any>(AUTH_API + 'users/updatePermissions', user);
        return data;
    }

    putPasswordUser(user) {

        let data = this.http.put<any>(AUTH_API + 'users/updatePassword', user);
        return data;
    }

    postUserInvite(user){
        let data = this.http.post<any>(AUTH_API + 'users/invite', user);
        return data;
    }
}
