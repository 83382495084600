import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { InputMaskModule } from 'primeng/inputmask';
import { CommonModule } from '@angular/common';
import { ListEditSupplierComponent } from './list_edit_supplier.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { KeyFilterModule } from 'primeng/keyfilter';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        ButtonModule,
        CommonModule,
        DialogModule,
        ReactiveFormsModule,
        TableModule,
        ToastModule,
        FormsModule,
        KeyFilterModule,
        InputTextModule,
        InputMaskModule,
        FileUploadModule,
        DropdownModule,
        MessageModule,
        BreadcrumbModule,
        TranslateModule
    ],
    declarations: [
        ListEditSupplierComponent
    ],
    exports: [
        ListEditSupplierComponent
    ],
    providers: [MessageService]

})
export class ListEditSupplierModule { }
