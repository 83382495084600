import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TokenStorageService } from '../../services/token-storage/token-storage.service';

import { AuthService } from '../../services/auth/auth.service'
import { UserService } from '../../services/user/user.service'

import { AppMainComponent } from '../../app.main.component';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { BrandService } from 'src/app/services/brands/brand.service';

@Component({
    selector: 'pages-leftbar',
    templateUrl: './leftbar.component.html',
    styleUrls: ['./leftbar.component.scss']
})
export class LeftBarComponent implements OnInit {

    model: any[];

    countryFlag: any;

    displayModal: Boolean = false;

    current_password: string;

    new_password: string;

    confirm_password: string;

    password_match: boolean = true;

    current_password_error: boolean = false;

    submitted: boolean = false;

    items = [{
        items: [
            {
                label: 'Change password',
                icon: 'pi pi-refresh',
                command: () => {
                    this.displayModal = true;
                    this.current_password = null, this.new_password = null, this.confirm_password = null;
                }
            },
            {
                label: 'Log out',
                icon: 'pi pi-times',
                command: () => {
                    this.logOut();
                }
            }
        ]
    }]

    actual_brand: any;

    itemsBrandsList: any = [{ items: [] }];

    constructor(
        private tokenService: TokenStorageService,
        public permissions: PermissionsService,
        public appMain: AppMainComponent,
        private authService: AuthService,
        private router: Router,
        private translateService: TranslateService,
        private userService: UserService,
        private brandService: BrandService,
    ) { }

    ngOnInit() {
        var user_details = JSON.parse(sessionStorage.getItem('user_details'))
        var brands_list = user_details.brands_list.filter(brand => brand.id_brand != user_details.id_brand)
        brands_list.map(brand => {
            this.itemsBrandsList[0].items.push({
                label: brand.brand_name,
                command: () => {
                    this.changeBrand({ id_brand: brand.id_brand })
                }
            })
        })
        this.actual_brand = user_details.brands_list.find(brand => brand.id_brand == user_details.id_brand)
        this.countryFlag = this.translateService.currentLang == 'en' ? 'gb' : this.translateService.currentLang;
    }

    changeLang() {
        let lang = this.translateService.currentLang == 'gb' ? 'pt' : 'gb'
        this.translateService.use(lang);
        this.countryFlag = lang;
        window.sessionStorage.setItem("language_code", lang);
        this.userService.putLangUser(lang);
    }

    async submitPassword() {
        this.submitted = true;

        if (this.new_password == this.confirm_password && this.new_password.length <= 30) {
            this.password_match = true;
            let passwordUpdateUser = {
                data: {
                    old_password: this.current_password,
                    password: this.new_password
                }
            }
            this.userService.putPasswordUser(passwordUpdateUser).subscribe(
                data => {
                    this.displayModal = false;
                    this.current_password_error = false;
                    this.password_match = true;
                },
                error => {
                    this.current_password_error = true;
                })
        } else {
            this.password_match = false;
        }
    }

    validatePassword() {
        var passwordRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?()/=",.;:_\\-])[A-Za-z\d#$@!%&*?()/=",.;:_\\-]{8,}$/;
        if (passwordRegex.test(this.new_password)) {
            return true
        } else {
            return false
        }
    }

    logOut() {
        this.authService.signOut().subscribe(() => {
            this.router.navigate(['login']);
        });
        this.tokenService.signOut();
    }

    async changeBrand(id_brand) {
        await (await this.authService.changeBrand(id_brand)).subscribe(async (data) => {
            this.permissions.savePermissions(data.User.permissions);
            this.brandService.saveBrand(data.User.id_brand);
            this.translateService.use(data.User.language_code.match(/gb|pt/) ? data.User.language_code : 'gb')
            this.tokenService.saveToken(data.User['Bearer Token']);

            const accept = await this.brandService.checkIfBrandAccept();
            window.sessionStorage.setItem("brands_permissions", JSON.stringify(accept));
            window.sessionStorage.setItem("language_code", data.User.language_code);
            window.sessionStorage.setItem("returncenter_url", data.User.returncenter_url);
            window.sessionStorage.setItem("2hand_link", data.User["2hand_link"]);
            window.sessionStorage.setItem("shop_domain", data.User.shop_domain);
            window.sessionStorage.setItem("wms", (data.User.logistic_op != undefined ? "true" : "false"));
            window.sessionStorage.setItem("id_token", data.User.id_token);
            window.sessionStorage.setItem("username", data.User.username);
            window.sessionStorage.setItem("id_user", data.User.id_user);
            window.sessionStorage.setItem("user_details", JSON.stringify(data.User));
            window.location.reload();
        });
    }
}
