import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { forEach } from 'jszip';
import { isArray } from 'util';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(private http: HttpClient) { }

    async getProducts() {

        let data = await this.http.get<any>(AUTH_API + 'product/2hand/products').toPromise();
        return data;
    }

    async getProductsById(idProduct) {

        let data = await this.http.get<any>(AUTH_API + 'product/2hand/products/listings?product_id=' + idProduct).toPromise();
        return data[0];
    }

    postProduct(data) {
        return this.http.post<any>(AUTH_API + 'product', data);
    }

    async get2HandOptions() {
        let data = await this.http.get<any>(AUTH_API + 'product/addmarketplace').toPromise();
        return data;
    }

    async put2HandOptions(data:any){
        let productsList = []
       
        for(let product of data){
            productsList.push({
                id: product.product_id
            })
        }

        const productList = {
            products : productsList
        }
        
        let response = await this.http.put<any>(AUTH_API + 'product/2hand/products', productList, { observe: 'response' }).toPromise();
        
        return response;
    }

    async addResellProducts(sellProducts: any) {
        let data = await this.http.post<any>(AUTH_API + 'product/addmarketplace', sellProducts).toPromise();
        return data;
    }
}
