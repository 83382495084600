import { Component } from '@angular/core';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [ConfirmationService]
})
export class AppComponent {

    menuMode = 'overlay';

    subscription: Subscription;

    constructor(public translate: TranslateService, public primengConfig: PrimeNGConfig, private confirmationService: ConfirmationService) {
        translate.addLangs(['gb', 'pt']);
        translate.setDefaultLang('pt');

        const browserLang = translate.getBrowserLang();
        
        let lang = browserLang.match(/gb|pt/) ? browserLang : 'gb';
        lang = window.sessionStorage.getItem("language_code") == undefined ? lang : window.sessionStorage.getItem("language_code")
        this.changeLang(lang);

        this.subscription = this.translate.stream('primeng').subscribe(data => {
            this.primengConfig.setTranslation(data);
        });
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
        document.documentElement.style.fontSize = '14px';
    }

    changeLang(lang: string) {
        this.translate.use(lang);
    }
}
