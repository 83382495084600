import { Component, OnDestroy, OnInit } from '@angular/core';

import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppConfig } from '../../api/appconfig';

import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../../app.main.component';

import { ConfigService } from '../../service/app.config.service';

@Component({
    selector: 'pages-config',
    templateUrl: './config.component.html',
    styleUrls: ['./config.component.sass']
})

export class ConfigComponent implements OnInit, OnDestroy {

    scale: number = 14;

    config: AppConfig;

    subscription: Subscription;

    visible = false;

    constructor(public app: AppComponent, public appMain: AppMainComponent, public configService: ConfigService, public primengConfig: PrimeNGConfig) {}

    ngOnInit() {
        this.config = this.configService.config;
        this.subscription = this.configService.configUpdate$.subscribe(config => {
            this.config = config;
            this.scale = 14;

            this.applyScale();
        });

        // getting theme
        let themeElement = document.getElementById('theme-css');
        let theme = "";
        let dark = false;
        let themeStorage = window.sessionStorage.getItem('theme') == undefined ? 'lara-light-indigo' : window.sessionStorage.getItem('theme')

        theme = themeStorage.includes('light') ? 'lara-light-indigo' : 'lara-dark-indigo' ;
        dark = themeStorage.includes('light') ? false : true;
        this.visible = themeStorage.includes('light') ? false : true;

        themeElement.setAttribute('href', 'assets/theme/' + theme + '/theme.css');
        this.configService.updateConfig({ ...this.config, ...{ theme, dark } });
    }

    onConfigButtonClick(event) {
        let themeElement = document.getElementById('theme-css');
        let theme = "";
        let dark = false;
        let visible = false;

        theme = themeElement.getAttribute("href").includes('light') ?  'lara-dark-indigo' : 'lara-light-indigo';
        dark = themeElement.getAttribute("href").includes('light') ? true : false;
        visible = themeElement.getAttribute("href").includes('light') ? true : false;

        themeElement.setAttribute('href', 'assets/theme/' + theme + '/theme.css');
        this.configService.updateConfig({ ...this.config, ...{ theme, dark } });
        this.visible = visible;
        window.sessionStorage.setItem("theme", theme);
    }

    applyScale() {
        document.documentElement.style.fontSize = this.scale + 'px';
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
