<ng-container>

    <a [attr.href]="item.url" (click)="itemClick($event)" [ngClass]="item.class"
        *ngIf="(!item.routerLink || item.items) && item.visible !== false" [attr.target]="item.target"
        [attr.tabindex]="0" [attr.aria-label]="item.label" role="menuitem" pRipple>
        <!-- <i [ngClass]="item.icon" class="layout-menuitem-icon"></i> -->
        <div class="icon">
            <img [src]="item.icon">
        </div>
        <span>{{item.label}}</span>
        <span class="menuitem-badge" *ngIf="item.badge">{{item.badge}}</span>
        <i class="pi pi-fw {{active ? 'pi-angle-up' : 'pi-angle-down'}} ml-auto" *ngIf="item.items"></i>
    </a>

    <a (click)="itemClick($event)" *ngIf="(item.routerLink && !item.items) && item.visible !== false && item.permission"
        [ngClass]="item.class" [routerLink]="item.routerLink"
        routerLinkActive="active-menuitem-routerlink router-link-exact-active"
        [routerLinkActiveOptions]="{exact: !item.preventExact}" [attr.target]="item.target" [attr.tabindex]="0"
        [attr.aria-label]="item.label" role="menuitem" pRipple>
        <!-- <i [ngClass]="item.icon" class="layout-menuitem-icon"></i> -->
        <div class="icon">
            <img *ngIf="item.icon" [src]="item.icon">
        </div>
        <span>{{item.label}}</span>
        <span class="p-tag p-badge ml-auto" *ngIf="item.badge">{{item.badge}}</span>
        <i class="pi pi-fw {{active ? 'pi-angle-up' : 'pi-angle-down'}} ml-auto" *ngIf="item.items"></i>
    </a>

    <ul *ngIf="(item.items && active) && item.visible !== false"
        [@children]="(active ? 'visibleAnimated' : 'hiddenAnimated')" role="menu">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
            <li pages-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass" role="none"></li>
        </ng-template>
    </ul>

</ng-container>

<p-dialog header="Change Password" [(visible)]="displayModal" [modal]="true" [style]="{width: '400px', maxWidth: '90%'}"
    [draggable]="false" [resizable]="false">
    <div class="grid">
        <div class="col-12 flex flex-column">
            <div>Your password must be at least 8 characters, can’t begin or end with a space and include at least one:
            </div>
            <div class="flex flex-column">
                <div class="flex gap-1">
                    <div class="col-6">-Lower case letter</div>
                    <div class="col-6">-Number</div>
                </div>
                <div class="flex gap-1">
                    <div class="col-6">-Uppercase letter</div>
                    <div class="col-6">-Special character</div>
                </div>
            </div>
        </div>
        <div class="col-12 flex">
            <div class="w-full flex-column mb-3">
                <h6 for="current_password" class="flex mb-2">Current password</h6>
                <p-password id="current_password" [(ngModel)]="current_password" [toggleMask]="false" [feedback]="false"
                    styleClass="w-full"
                    [ngClass]="{'ng-invalid ng-dirty' : submitted && current_password_error}"></p-password>
                <small *ngIf="submitted && current_password_error" class="p-error absolute flex gap-1 mt-2"><img
                        src="assets/layout/images/error-icon.svg">Invalid password</small>
            </div>
        </div>
        <div class="col-12 flex">
            <div class="w-full flex-column mb-3">
                <h6 for="new_password" class="flex mb-2">New password</h6>
                <p-password id="new_password" [(ngModel)]="new_password" [toggleMask]="true" [feedback]="false"
                    styleClass="w-full"
                    [ngClass]="{'ng-invalid ng-dirty' : submitted  && (!validatePassword() || new_password.length > 30)}"></p-password>
                <small *ngIf="submitted && !validatePassword()" class="p-error absolute flex gap-1 mt-2"><img
                        src="assets/layout/images/error-icon.svg">Invalid password</small>
                <small *ngIf="submitted && new_password.length > 30"
                    class="p-error absolute flex gap-1 mt-2"><img src="assets/layout/images/error-icon.svg">Password
                    must be less than 30 characters</small>
            </div>
        </div>
        <div class="col-12 flex">
            <div class="w-full flex-column mb-3">
                <h6 for="confirm_password" class="flex mb-2">Confirm new password</h6>
                <p-password id="confirm_password" [(ngModel)]="confirm_password" [toggleMask]="true" [feedback]="false"
                    styleClass="w-full"
                    [ngClass]="{'ng-invalid ng-dirty' : submitted && (!validatePassword() || confirm_password.length > 30)}"></p-password>
                <small *ngIf="submitted && !validatePassword()" class="p-error absolute flex gap-1 mt-2"><img
                        src="assets/layout/images/error-icon.svg">Invalid password</small>
                <small *ngIf="submitted && validatePassword() && confirm_password.length <= 30 && !password_match"
                    class="p-error absolute flex gap-1 mt-2"><img src="assets/layout/images/error-icon.svg">Passwords
                    doesn't match</small>
                <small *ngIf="submitted && confirm_password.length > 30"
                    class="p-error absolute flex gap-1 mt-2"><img src="assets/layout/images/error-icon.svg">Password
                    must be less than 30 characters</small>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button (onClick)="submitPassword()" label="Change password" styleClass="w-full"
            [disabled]="!current_password || !new_password || !confirm_password || !validatePassword()"></p-button>
    </ng-template>
</p-dialog>