<div class="surface-0 flex flex-row-reverse min-h-screen">
    <a class="z-5 absolute top-0 right-0" [routerLink]="['/main']" style="margin: 35px 25px;">
        <img src="assets/layout/images/logo.svg" width="180px">
    </a>
    <div class="z-4 hidden lg:flex"
        style="flex: 1 1 300px; transform: scaleX(-1); background-image: url(assets/layout/images/login-img.svg); background-repeat: no-repeat; background-size: cover; background-position: right;">
    </div>
    <div class="mt-5 login-div flex flex-column">
        <div class="py-5 md:p-5 md:px-8">
            <div class="p-3 flex align-items-center bg-yellow-100 text-yellow-600 gap-1">
                <i class="pi pi-exclamation-triangle"></i>
                <span>Access your account using the email address that received the invitation. If you don't have an
                    account, registration will be done via that same email.</span>
            </div>
        </div>
        <div class="w-full h-full flex flex-column md:flex-row px-0 py-5 md:p-5 md:px-8 gap-8">
            <div class="w-full h-full max-w-full md:max-w-25rem flex flex-column align-items-start gap-5">
                <h6 class="uppercase">Login in to your account</h6>
                <div class="flex flex-column w-full mx-auto">
                    <div class="flex-column mb-4">
                        <h6 for="email1" class="flex mb-2">{{ 'general.login.email' | translate }}</h6>
                        <input id="email1" [(ngModel)]="dataLogin.email" type="text" placeholder="Email address"
                            pInputText class="p-inputtext w-full" (keyup.enter)="submitLoginInvite()"
                            [ngClass]="{'ng-invalid ng-dirty' : submittedLogin && (!dataLogin.email || dataLogin.email && (!validateEmail(dataLogin.email) || errorLogin))}">
                        <small *ngIf="submittedLogin && !dataLogin.email" class="p-error absolute flex gap-1 mt-2"><img
                                src="assets/layout/images/error-icon.svg">Required field</small>
                        <small *ngIf="submittedLogin && dataLogin.email && !validateEmail(dataLogin.email)"
                            class="p-error absolute flex gap-1 mt-2"><img
                                src="assets/layout/images/error-icon.svg">Invalid email</small>
                    </div>
                    <div class="flex-column mb-6">
                        <h6 for="password1" class="flex mb-2">{{ 'general.login.password' | translate }}</h6>
                        <p-password id="password1" [(ngModel)]="dataLogin.password" placeholder="Password"
                            [toggleMask]="true" [feedback]="false" styleClass="w-full"
                            [ngClass]="{'ng-invalid ng-dirty' : submittedLogin && (!dataLogin.password || dataLogin.password && errorLogin)}"
                            (keyup.enter)="submitLoginInvite()"></p-password>
                        <small *ngIf="submittedLogin && !dataLogin.password"
                            class="p-error absolute flex gap-1 mt-2"><img
                                src="assets/layout/images/error-icon.svg">Required field</small>
                        <small *ngIf="submittedLogin && dataLogin.password && errorLogin"
                            class="p-error absolute flex gap-1 mt-2"><img
                                src="assets/layout/images/error-icon.svg">Login error: {{errorLoginMessage}}</small>
                    </div>
                    <button pButton pRipple (click)="submitLoginInvite()"
                        label="{{ 'general.login.login' | translate }}" class="w-full p-button-secundary"></button>
                </div>
            </div>
            <div class="w-full h-full max-w-full md:max-w-25rem flex flex-column align-items-start gap-5">
                <h6 class="uppercase">Register your new account</h6>
                <div class="flex flex-column w-full mx-auto">
                    <div class="flex-column mb-6">
                        <h6 for="password1" class="flex mb-2">{{ 'general.login.password' | translate }}</h6>
                        <p-password id="password1" [(ngModel)]="dataRegisterLogin.password" placeholder="Password"
                            [toggleMask]="true" [feedback]="false" styleClass="w-full"
                            [ngClass]="{'ng-invalid ng-dirty' : submittedRegister && (!dataRegisterLogin.password || dataRegisterLogin.password && (!validatePassword() || dataRegisterLogin.password.length > 30 || errorRegister))}"
                            (keyup.enter)="submitRegisterInvite()"></p-password>
                        <small *ngIf="submittedRegister && !dataRegisterLogin.password"
                            class="p-error absolute flex gap-1 mt-2"><img
                                src="assets/layout/images/error-icon.svg">Required field</small>
                        <div class="flex gap-1 mt-2"
                            *ngIf="submittedRegister && dataRegisterLogin.password && !validatePassword()">
                            <img src="assets/layout/images/error-icon.svg">
                            <div class="flex flex-column">
                                <small class="p-error">Must have at least 8 characters</small>
                                <small class="p-error">Use at least 1 letter and 1 special character and number</small>
                            </div>
                        </div>
                        <small *ngIf="submittedRegister && dataRegisterLogin.password.length > 30"
                            class="p-error absolute flex gap-1 mt-2"><img
                                src="assets/layout/images/error-icon.svg">Password
                            must be less than 30 characters</small>
                        <small
                            *ngIf="submittedRegister && dataRegisterLogin.password && validatePassword() && dataRegisterLogin.password.length <= 30 && errorRegister"
                            class="p-error absolute flex gap-1 mt-2"><img
                                src="assets/layout/images/error-icon.svg">Register error:
                            {{errorRegisterMessage}}</small>
                    </div>
                    <button pButton pRipple (click)="submitRegisterInvite()"
                        label="{{ 'general.login.register' | translate }}" class="w-full p-button-secundary"></button>
                </div>
            </div>
        </div>
    </div>
</div>