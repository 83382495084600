import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const AUTH_API = environment.url;

@Injectable({
    providedIn: 'root'
})
export class WebhookService {

    constructor(private http: HttpClient) { }

    async getWebhooks() {

        let data =  await this.http.get<any>(AUTH_API + 'webhook').toPromise().catch((error) => {
            throw error;
        });
        return data;
    }

    async postWebhooks(data){
        let result =  await this.http.post<any>(AUTH_API + 'webhook', data).toPromise();
        return result;
    }

    async deleteWebhooks(data){
        let result =  await this.http.delete<any>(AUTH_API + 'webhook', {body:data} ).toPromise();
        return result;
    }

    async putWebhooks(data){
        let result =  await this.http.put<any>(AUTH_API + 'webhook', data ).toPromise();
        return result;
    }
}
